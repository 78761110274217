import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { TitleEdit } from 'saas/page/product/common/Title/TitleEdit'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { getProductTypeTitle } from 'type/product/ProductType'
import { nbsp } from 'util/typo'

export const Title = observer(() => {
  const { json, nameEdit } = productSaas.it
  const { type, name } = json

  const onEdit = useLatestCallback(() => {
    productSaas.it.openNameEdit()
  })

  return <Space gap={16} opposite>
    <Vertical wide>
      <Typo size={12}>
        {nameEdit ? nbsp : getProductTypeTitle(type)}
      </Typo>
      {!nameEdit && <Space gap={8} height={32}>
        <Typo size={18} wrap>
          {name}
        </Typo>
        <IconButton small action={onEdit}>
          <Icon name="edit" size={16} />
        </IconButton>
      </Space>}
      {nameEdit && <TitleEdit />}
    </Vertical>
  </Space>
})
