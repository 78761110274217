import { Field } from 'component/Field'
import { SearchSelect, SelectOption } from 'component/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'

export const BrandSelect = observer(() => {
  const { brand, brands } = managerSearchStore

  const options: SelectOption[] = brands?.map(brand => ({
    value: brand,
    label: brand,
  }))

  const onChange = useLatestCallback((value: string[]) => {
    managerSearchStore.brand = value
  })

  return <Field label={i18n('collection.Brand')}>
    <SearchSelect wide multiple value={brand} options={options} onChange={onChange} />
  </Field>
})
