import { Image, ImageItem, ImageType } from 'type/Image'

export class ImageAsset {
  readonly imageId: string
  readonly type: ImageType
  readonly url: string

  static fromItem(type: ImageType, item: ImageItem): ImageAsset {
    const { id, url } = item
    return new ImageAsset(id, type, url)
  }

  static fromImage(type: ImageType, response: Image): ImageAsset {
    const { asset_id, url } = response
    return new ImageAsset(asset_id, type, url)
  }

  private constructor(imageId: string, type: ImageType, url: string) {
    this.imageId = imageId
    this.type = type
    this.url = url
  }
}
