import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { SearchView } from 'page/internal/search/SearchView'
import { Page } from 'saas/screen/Page'
import { home } from 'store/home'
import { managerSearchStore } from 'store/manager/search'

export const SearchPage = observer(() => {
  useEffectOnce(() => {
    const companyId = managerSearchStore.parseCompanyId() ?? home.company.optional?.company.company_id
    const company = home.companies.findRoute(companyId)
    if (!company) return

    managerSearchStore.init(company.company_id)
  })

  return <Page>
    <SearchView />
  </Page>
})
