// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo-link-4c6d{width:100%;max-width:min(240px,100vw - 36px)}.Logo-logo-4c6d{transition:color 200ms ease-in-out;color:var(--ui-text-color);width:100%;height:72px}.Logo-logo-4c6d:hover{color:var(--ui-primary-color)}.Logo-sep-4c6d{flex:0 0 auto;width:1px;height:32px;background-color:currentColor;opacity:.1}.Logo-title-4c6d{flex:0 0 auto;font-size:13px;font-weight:700;line-height:16px;white-space:pre}`, "",{"version":3,"sources":["webpack://./src/saas/screen/logo/Logo.module.scss"],"names":[],"mappings":"AAAA,gBACE,UAAA,CACA,iCAAA,CAGF,gBACE,kCAAA,CACA,0BAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,6BAAA,CAGF,eACE,aAAA,CACA,SAAA,CACA,WAAA,CACA,6BAAA,CACA,UAAA,CAGF,iBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Logo-link-4c6d`,
	"logo": `Logo-logo-4c6d`,
	"sep": `Logo-sep-4c6d`,
	"title": `Logo-title-4c6d`
};
module.exports = ___CSS_LOADER_EXPORT___;
