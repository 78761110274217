import PlusSvg from 'asset/plus-circle.svg'
import { Glass, Sticker } from 'component/Glass'
import { Image } from 'component/Image'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageFile } from 'store/base/ImageFile'
import { home } from 'store/home'

export const AddSourceCard = observer(() => {
  const { form } = home.product.it.sources.it
  const { error } = form.image
  const image = form.image.value?.url

  const onFile = useLatestCallback((file: File) => {
    form.image.value = ImageFile.fromFile(file)
    form.image.touch = true
  })

  return <Glass less>
    <Upload accept="image" width={100} pic error={error} onFile={onFile}>
      {image ? <Image src={image} /> : <PlusSvg width={24} height={24} />}
    </Upload>
    {!image && <Sticker none pin={[null, 0, 8, 0]}>
      <Typo size={12} secondary pre center>
        {i18n('source.UploadSourceImage')}
      </Typo>
    </Sticker>}
  </Glass>
})
