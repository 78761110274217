// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareButton-button-e5fd>span{display:none}@media all and (min-width: 760px){.ShareButton-button-e5fd>span{display:block}}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/ShareButton/ShareButton.module.scss"],"names":[],"mappings":"AACE,8BACE,YAAA,CAGF,kCACE,8BACE,aAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ShareButton-button-e5fd`
};
module.exports = ___CSS_LOADER_EXPORT___;
