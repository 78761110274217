import { Button } from 'component/Button'
import { Option, Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { CompanyStore } from 'store/company/CompanyStore'

export const SelectCompanyStep = observer(() => {
  const { company, companies } = inviteUsers.it

  const options = companies.map((company) => {
    const { company_id, title } = company
    return ({
      value: company_id,
      label: title,
      data: company,
    })
  })

  const onCompany = useLatestCallback((_value: string, option?: Option<CompanyStore>) => {
    inviteUsers.it.company = option?.data
  })

  return <Vertical gap={24}>
    <Typo size={16} secondary>
      {i18n('invite.SelectCompany')}
    </Typo>

    <Vertical gap={12}>
      <Select value={company?.company_id} options={options} onChange={onCompany} />
    </Vertical>

    <Space gap={12} right>
      <Button primary disabled={!company} action={inviteUsers.it.onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
