import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { StandProductStore } from 'saas/store/product/stand/StandProductStore'
import { TryOnProductStore } from 'saas/store/product/tryon/TryOnProductStore'
import { Product } from 'type/product/Product'
import { STAND, TRY_ON } from 'type/product/ProductType'

export function createProduct(company_id: string, json: Product): ProductSaasStore | undefined {
  const { type } = json
  switch (type) {
    case TRY_ON:
      return new TryOnProductStore(company_id, json)
    case STAND:
      return new StandProductStore(company_id, json)
    default:
      console.error('unknown product', type, json)
      return undefined
  }
}
