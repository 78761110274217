// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductCard-card-f9cb{align-self:stretch}.ProductCard-single-f9cb{position:relative}.ProductCard-info-f9cb{width:100%}.ProductCard-comments-f9cb{position:absolute;inset:0;background-color:var(--ui-background-color)}@media(width > 480px){.ProductCard-single-f9cb{width:344px;overflow:hidden}}@media(width <= 480px){.ProductCard-single-f9cb{width:calc(100vw - 50px);overflow:hidden}}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/ProductCard.module.scss"],"names":[],"mappings":"AAKA,uBACE,kBAAA,CAGF,yBACE,iBAAA,CAGF,uBACE,UAAA,CAGF,2BACE,iBAAA,CACA,OAAA,CACA,2CAAA,CAGF,sBACE,yBACE,WAAA,CACA,eAAA,CAAA,CAIJ,uBACE,yBACE,wBAAA,CACA,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ProductCard-card-f9cb`,
	"single": `ProductCard-single-f9cb`,
	"info": `ProductCard-info-f9cb`,
	"comments": `ProductCard-comments-f9cb`
};
module.exports = ___CSS_LOADER_EXPORT___;
