import { Input } from 'component/Input'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productCreate } from 'saas/store/product/ProductCreateStore'

export const NameInput = observer(() => {
  const { value, error, onChange } = productCreate.it.name

  return <Vertical gap={4}>
    <Typo size={12}>{i18n('product.ProductName')}</Typo>
    <Input value={value} onChange={onChange} error={error} focus />
  </Vertical>
})
