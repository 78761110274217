export type nil = null | undefined

export function notNil<T>(value: T | null | undefined): value is T {
  return value != null
}

export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return value === undefined
}
