import { AppSvg } from 'app/config/icon'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { formatManagerRoute } from 'store/manager/routes'
import css from './ManagerLogo.module.scss'

export const ManagerLogo = observer(() => {
  const companyId = home.company.optional?.company.company_id
  const link = formatManagerRoute({ page: 'company', companyId })

  const onHome = useLatestCallback(() => home.goHome())

  return <Link path={link}>
    <Space className={css.home} height={42} gap={8} fit onClick={onHome}>
      <AppSvg w={120} />
      <div className={css.sep} />
      <div className={css.title}>
        {i18n('header.CatalogDirectory')}
      </div>
    </Space>
  </Link>
})
