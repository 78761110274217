import { Space } from 'component/Space'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ProductCard } from 'page/internal/products/ProductCard'
import { useRef, useState } from 'react'
import { home } from 'store/home'

const STEP = 24

export const ProductList = observer(() => {
  const filter = home.productFilters.filter
  const items = home.collection.it.items.it

  const [end, setEnd] = useState(STEP)
  const divRef = useRef<HTMLDivElement>(null)
  const topRef = useRef(true)

  const update = useLatestCallback(() => {
    const root = divRef.current
    if (!root) return

    if (document.body.style.overflow === 'hidden') return

    const r = root.getBoundingClientRect()
    const top = window.scrollY === 0
    const bottom = r.top + r.height <= window.innerHeight

    if (bottom) {
      setEnd(Math.min(items.length, end + STEP))
    }

    topRef.current = top
  })

  const reset = useLatestCallback(() => {
    setEnd(STEP)
    window.scroll(0, 0)
  })

  useEffectOnce(() => reset())
  useEffectOnce(() => reaction(() => home.productFilters.filter, () => reset()))

  useEffectOnce(() => {
    const id = setInterval(update, 100)
    return () => clearInterval(id)
  })

  return <Space ref={divRef} gap={16} top wrap>
    {items.filter(item => !item.store.present || filter(item.store.it))
      .slice(0, end)
      .map((item, index) => <ProductCard key={item.key} item={item} num={index + 1} />)}
  </Space>
})
