import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productCreate, ProductCreateStore } from 'saas/store/product/ProductCreateStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

export const Title = observer(() => {
  const { busy } = productsSaas.it

  const onCreate = useLatestCallback(() => {
    const { config } = productsSaas.it
    productCreate.open(new ProductCreateStore(config))
  })

  return <Space gap={16} opposite>
    <Typo size={18} bold>
      {i18n('product.AllProducts')}
    </Typo>
    <Button disabled={busy} action={onCreate}>
      {i18n('product.CreateProduct')}
    </Button>
  </Space>
})
