import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const LanguageSelect = observer(() => {
  const { lang } = stand.it.edit
  const en = lang === 'en'

  const onRu = useLatestCallback(() => {
    stand.it.edit.lang = 'ru'
  })

  const onEn = useLatestCallback(() => {
    stand.it.edit.lang = 'en'
  })

  return <>
    <Typo size={14} bold>{i18n('label.Language')}</Typo>
    <Gap height={8} />
    <Space gap={8}>
      <Button primary={!en} onClick={onRu}>
        {i18n('language.Russian')}
      </Button>
      <Button primary={en} onClick={onEn}>
        {i18n('language.English')}
      </Button>
    </Space>
  </>
})
