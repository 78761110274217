import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { Preview } from 'component/Image'
import { FitImage } from 'component/Image/FitImage'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { useDeleting } from 'feature/deleting/useDeleting'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { InferenceImageToolbar } from 'page/home/product/result/InferenceCard/InferenceImageToolbar'
import { home } from 'store/home'
import { InferenceImageStore } from 'store/inference/InferenceImageStore'
import { InferenceItem } from 'store/inference/InferenceItem'

type Props = {
  inference: InferenceItem
  image?: InferenceImageStore
}

export const InferenceImageCard = observer(({ inference, image }: Props) => {
  const canDelete = inference.store.it.canDeleteImage
  const sources = inference.store.it.sources
  const deleting = useDeleting()
  const control = usePreviewControl()

  const renderToolbar = useLatestCallback((source: string) => {
    const image = inference.store.it.images.find(image => image?.url === source)
    if (!image) return null
    return <InferenceImageToolbar inference={inference} image={image} />
  })

  const onClick = useLatestCallback(() => {
    control.setPreview(image?.url)
  })

  const onDelete = useLatestCallback(async () => {
    if (!image) return
    const product = home.product.it
    deleting.start(3000, () => {
      product.inferences.optional?.deleteInferenceImage(inference, image)
    })
  })

  const onLike = useLatestCallback(async () => {
    if (image) await image.setLike(!image.like)
  })

  const action = image?.url ? onClick : undefined

  return <Card width={80} pic action={action}>
    {deleting.box}
    {image && <Glass fill>
      <FitImage src={image.url} />
      <Preview strong control={control} sources={sources} toolbar={renderToolbar} />
      <Sticker pin={[null, 2, 2, null]}>
        <IconButton small action={onLike}>
          <Icon name="favorite" size={14} fill={image.like} primary={image.like} />
        </IconButton>
      </Sticker>
      <Sticker pin={[null, null, 2, 2]} hidden>
        <IconButton small action={onDelete} disabled={!canDelete}>
          <Icon name="delete" size={14} />
        </IconButton>
      </Sticker>
    </Glass>}
  </Card>
})
