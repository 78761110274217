// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner-container-d8d1{display:flex;align-items:center;justify-content:center;--spinner-width: 25px}.Spinner-spinner-d8d1{--spinner-size: 5;--line-color: var(--ui-primary-color);--line-alpha: 1;--ring-color: var(--ui-text-color);--ring-alpha: 5%;--ring-size: 3;font-size:calc(var(--spinner-size)*var(--spinner-width));width:var(--spinner-width);height:var(--spinner-width);border-radius:50%}.Spinner-spinner-d8d1 .Spinner-line-d8d1{fill:none;stroke:var(--line-color);stroke-width:var(--ring-size);opacity:var(--line-alpha);stroke-linecap:round;transform-origin:50% 50%;transform:rotate3d(0, 0, 1, 0deg);animation:2156ms Spinner-spinner-arc-d8d1 ease-in-out infinite,1829ms Spinner-spinner-rotate-d8d1 linear infinite}.Spinner-ring-d8d1{fill:none;stroke:var(--ring-color);stroke-width:var(--ring-size);opacity:var(--ring-alpha)}@keyframes Spinner-spinner-rotate-d8d1{to{transform:rotate3d(0, 0, 1, 360deg)}}@keyframes Spinner-spinner-arc-d8d1{from{stroke-dasharray:0 150;stroke-dashoffset:0}to{stroke-dasharray:100 150;stroke-dashoffset:-140}}`, "",{"version":3,"sources":["webpack://./src/component/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,qBAAA,CAGF,sBACE,iBAAA,CACA,qCAAA,CACA,eAAA,CACA,kCAAA,CACA,gBAAA,CACA,cAAA,CAEA,wDAAA,CACA,0BAAA,CACA,2BAAA,CACA,iBAAA,CAGF,yCACE,SAAA,CACA,wBAAA,CACA,6BAAA,CACA,yBAAA,CACA,oBAAA,CACA,wBAAA,CACA,iCAAA,CACA,iHAAA,CAGF,mBACE,SAAA,CACA,wBAAA,CACA,6BAAA,CACA,yBAAA,CAGF,uCACE,GACE,mCAAA,CAAA,CAIJ,oCACE,KACE,sBAAA,CACA,mBAAA,CAEF,GACE,wBAAA,CACA,sBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Spinner-container-d8d1`,
	"spinner": `Spinner-spinner-d8d1`,
	"line": `Spinner-line-d8d1`,
	"spinnerArc": `Spinner-spinner-arc-d8d1`,
	"spinnerRotate": `Spinner-spinner-rotate-d8d1`,
	"ring": `Spinner-ring-d8d1`
};
module.exports = ___CSS_LOADER_EXPORT___;
