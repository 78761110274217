import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageUpload } from 'saas/page/company/common/ImageUpload'
import { RolesSection } from 'saas/page/company/manage/modal/EditMemberModal/RolesSection'
import { memberEdit } from 'saas/store/MemberEditStore'

export const EditMemberModal = observer(() => {
  const { member, name, email, phone, description, picture } = memberEdit.it

  const onCancel = useLatestCallback(() => {
    memberEdit.close()
  })

  const onSave = useLatestCallback(async () => {
    if (!memberEdit.it.check()) return
    void memberEdit.it.save()
    memberEdit.close()
  })

  return <Modal stable title={i18n('member.EditMember')} onCancel={onCancel}>
    <Vertical gap={8}>
      <Space gap={16} top>
        <ImageUpload field={picture} original={member.user_pic} title={i18n('member.Picture')} />
        <Vertical gap={8} wide>
          <TextField field={name} label={i18n('label.Name')} />
          <TextField field={email} label={i18n('label.Email')} />
          <TextField field={phone} label={i18n('label.Phone')} />
        </Vertical>
      </Space>
      <TextField rows={4} field={description} label={i18n('label.Description')} />
    </Vertical>
    <RolesSection />
    <Space spread gap={12}>
      <Button primary action={onSave}>{i18n('common.Save')}</Button>
      <Button action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
