import { Select } from 'component/Select/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { can } from 'store/can'
import { ProductStore } from 'store/product'
import { buildInternalStatusOptions, fixItemStatus, InternalStatus } from 'type/InternalStatus'

type Props = {
  className?: string
  product: ProductStore
}

export const InternalStatusSelect = observer(({ className, product }: Props) => {
  const { companyId } = product
  const { status, review } = product.form
  const value = fixItemStatus(status.value)
  const allowed = can.SystemAdmin(companyId)
  const [busy, setBusy] = useState(false)

  const options = buildInternalStatusOptions()

  const onChange = useLatestCallback(async (value: InternalStatus | '') => {
    setBusy(true)
    try {
      status.onChange(value || undefined)
      if (value === 'done') review.onChange('review')
      await product.saveStatuses()
    } finally {
      setBusy(false)
    }
  })

  return <Select className={className} value={value} onChange={onChange}
    options={options} disabled={busy || !allowed} allowClear={false} hover
    title={i18n('placeholder.ItemStatus')} placeholder={i18n('placeholder.ItemStatus')} />
})
