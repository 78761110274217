import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { TagsField } from 'form/TagsField'
import { TextField } from 'form/TextField'
import { UidInput } from 'form/UidInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { CountriesSelect } from 'modal/EditCollectionModal/CountriesSelect'
import { GenderRadioGroup } from 'modal/EditCollectionModal/GenderRadioGroup'
import { ImagesUpload } from 'modal/EditCollectionModal/ImagesUpload'
import { ModesSelect } from 'modal/EditCollectionModal/ModesSelect'
import { modal } from 'modal/index'

export const EditCollectionModal = observer(() => {
  const store = modal.editCollectionModal.it
  const {
    creating, form, collectionId, name, title, description,
    link, promoCodes, privateViewers, styleId, tags,
  } = store

  const canSave = !form.error
  const header = creating ? i18n('collection.AddCollection') : i18n('collection.EditCollection')

  const onCancel = useLatestCallback(() => {
    modal.editCollectionModal.close()
  })

  const onSave = useLatestCallback(async () => {
    if (!form.check()) return

    if (creating) await store.create()
    else await store.save()

    store.close()
  })

  return <Modal stable title={header} onCancel={onCancel}>
    <Vertical gap={12}>
      {collectionId && <UidInput uid={collectionId} />}
      <TextField field={name} label={i18n('label.Name')}
        placeholder={i18n('collection.CollectionName')} />
      <TextField field={title} label={i18n('label.Title')}
        placeholder={i18n('placeholder.Title')} />
      <TextField field={description} rows={4} label={i18n('label.Description')}
        placeholder={i18n('placeholder.Description')} />
      <TextField field={link} label={i18n('label.Link')}
        placeholder={i18n('placeholder.Link')} />
      <TagsField field={promoCodes} label={i18n('label.PromoCodes')}
        placeholder={i18n('placeholder.PromoCodes')} />
      <TagsField field={privateViewers} label={i18n('label.PrivateViewersEmails')}
        placeholder={i18n('placeholder.PrivateViewersEmails')} />
      <TextField field={styleId} label={i18n('label.CollectionStyleId')}
        placeholder={i18n('placeholder.CollectionStyleId')} />
      <GenderRadioGroup />
      <CountriesSelect />
      <ModesSelect />
      <TagsField field={tags} label={i18n('label.Tags')}
        placeholder={i18n('placeholder.Tags')} />
      <ImagesUpload />
    </Vertical>
    <Space spread gap={16}>
      <Button primary large action={onSave} disabled={!canSave}>
        {creating ? i18n('common.Create') : i18n('common.Save')}
      </Button>
      <Button large action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
