import { ArrayStore } from 'store/base/ArrayStore'
import { mx } from 'store/base/mx'
import { ImageAsset } from 'store/image/ImageAsset'
import { Item } from 'type/Item'

export class ProductDataStore {
  private _json = mx.ref<Item>()
  private _assets = new ArrayStore<ImageAsset>([])
  private _collectionName = mx.ref<string>()

  constructor(json: Item) {
    this.update(json)
  }

  get json(): Item {
    return this._json.it
  }

  get product_name(): string {
    return this._json.it.product_name ?? ''
  }

  get images(): readonly string[] {
    return this._assets.value.map(image => image.url)
  }

  get assets(): readonly ImageAsset[] {
    return this._assets.value
  }

  get collectionName(): string | undefined {
    return this._collectionName.optional
  }

  set collectionName(value: string | undefined) {
    this._collectionName.it = value
  }

  update(json: Item): void {
    const { images } = json
    this._json.it = json
    this._assets.value = images?.map(item => ImageAsset.fromItem('item', item)) ?? []
  }
}
