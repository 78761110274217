import { MessageInstance, NoticeType } from 'antd/es/message/interface'
import { clsx } from 'clsx'
import { i18n } from 'i18n'
import { ReactElement } from 'react'
import css from './Notice.module.scss'

type Content = string | ReactElement

const duration = 5
let _api: MessageInstance

function getTypeClass(type: NoticeType) {
  if (type === 'warning') return css.info
  if (type === 'success') return css.success
  if (type === 'error') return css.error
}

function open(type: NoticeType, content: Content) {
  if (!_api) {
    if (typeof content === 'string') console.warn(`[${type}] `, content)
    return
  }
  const className = clsx(css.notice, getTypeClass(type))
  void _api.open({ type, content, className, duration })
}

export function setMessageApi(api: MessageInstance): void {
  _api = api
}

export const notice = {
  info(content: Content): void {
    open('warning', content)
  },

  success(content?: Content | undefined): void {
    content = content || i18n('common.Success')
    open('success', content)
  },

  error(content?: Content): void {
    content = content || i18n('common.Error')
    open('error', content)
  },
}
