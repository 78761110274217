import { AppSvg } from 'app/config/icon'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import css from './Logo.module.scss'

export const Logo = observer(() => {
  return <Link className={css.link} path="/">
    <Space className={css.logo} gap={8} pad={[0, 20]}>
      <AppSvg w={120} />
      <div className={css.sep} />
      <div className={css.title}>
        SAAS
      </div>
    </Space>
  </Link>
})
