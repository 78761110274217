// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchSelect-select-2785 .ant-select-selector{padding:4px}.SearchSelect-select-2785 .ant-select-selection-overflow{gap:4px}`, "",{"version":3,"sources":["webpack://./src/component/Select/SearchSelect.module.scss"],"names":[],"mappings":"AACE,+CACE,WAAA,CAGF,yDACE,OAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `SearchSelect-select-2785`
};
module.exports = ___CSS_LOADER_EXPORT___;
