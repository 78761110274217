import { CopyIconButton } from 'component/Button/CopyIconButton'
import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { can } from 'store/can'
import { formatDate } from 'util/date'
import { emdash } from 'util/typo'

type Props = {
  store: ApiKeyStore
}

export const KeyBox = observer(({ store }: Props) => {
  const { company_id } = companyEdit.it
  const { name, value, created_at, readonly } = store
  const text = store.valueText
  const good = !!value && !readonly

  const onEdit = useLatestCallback(async () => {
    companyEdit.it.openKeyEditModal(store)
  })

  const onDelete = useLatestCallback(async () => {
    companyEdit.it.keyDeleting = store
  })

  return <Space gap={[8, 16]} wide wrap>
    <Space height={20} minWidth={160} flex="1">
      <Typo size={14} ellipsis mono secondary={!name || !good}>{name || emdash}</Typo>
    </Space>
    <Space minWidth={70} margin={[0, 0, -2, 0]}>
      <Typo size={12} secondary>{formatDate(created_at) ?? emdash}</Typo>
    </Space>
    <Space gap={8} wrap>
      <Typo size={14} mono balance>
        {text}
      </Typo>
      <Space gap={0} margin={[-2, 0]}>
        <CopyIconButton small data={value} disabled={!good} />
        {can.ManageApiKeys(company_id) && <IconButton small action={onEdit} disabled={!good}>
          <Icon name="edit" size={16} />
        </IconButton>}
        {can.ManageApiKeys(company_id) && <IconButton small action={onDelete} error disabled={!good}>
          <Icon name="delete" size={16} />
        </IconButton>}
      </Space>
    </Space>
  </Space>
})
