import { Button } from 'component/Button'
import { Field } from 'component/Field'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { ProfileSelect } from 'modal/TestItemModal/ProfileSelect'

export const TestItemModal = observer(() => {
  const store = modal.testItemModal.it
  const { canTest } = store

  const onCancel = useLatestCallback(() => {
    modal.testItemModal.close()
  })

  const onDelete = useLatestCallback(async () => {
    modal.testItemModal.close()
    await store.testItem()
  })

  return <Modal title={i18n('item.TestItem')} onCancel={onCancel}>
    <Field label={i18n('label.Profile')}>
      <ProfileSelect />
    </Field>
    <Space spread gap={12}>
      <Button primary action={onDelete} disabled={!canTest}>
        {i18n('item.TestItem')}
      </Button>
      <Button action={onCancel}>
        {i18n('common.Cancel')}
      </Button>
    </Space>
  </Modal>
})
