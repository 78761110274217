import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { SkipStep } from 'modal/UploadCollectionModal/SkipStep'

export const ExistingStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const store = mod.data.it.existing

  return <SkipStep store={store}>
    {i18n('catalog.FoundCountRowsWithExistingItems', { count: store.items.length })}
  </SkipStep>
})
