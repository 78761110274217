import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'

export const FinalStep = observer(() => {
  const { company, users, onBack, canInvite, onInvite } = inviteUsers.it
  const title = company?.title

  return <Vertical gap={24}>
    <Typo size={16} secondary>
      {i18n('invite.InviteUsers')}
    </Typo>

    <Space gap={8}>
      <Typo size={12} secondary>
        {i18n('label.Company')}
      </Typo>
      <Typo size={12}>
        {title}
      </Typo>
    </Space>

    <Typo size={12}>
      {i18n('invite.SendInvitationsToCountUsers', { count: users.length })}
    </Typo>

    <Space gap={12} right wrap>
      <Button action={onBack}>
        {i18n('common.GoBack')}
      </Button>
      <Button primary disabled={!canInvite} action={onInvite}>
        {i18n('invite.SendInvites')}
      </Button>
    </Space>
  </Vertical>
})
