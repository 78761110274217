// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DemoItem-item-2cce{align-self:stretch;min-height:100%;width:300px}@media(width < 480px){.DemoItem-item-2cce{width:100%}}.DemoItem-image-2cce{aspect-ratio:var(--ui-aspect-ratio);border-radius:12px;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/page/demo/DemoItem.module.scss"],"names":[],"mappings":"AAAA,oBACE,kBAAA,CACA,eAAA,CACA,WAAA,CAEA,sBALF,oBAMI,UAAA,CAAA,CAIJ,qBACE,mCAAA,CACA,kBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `DemoItem-item-2cce`,
	"image": `DemoItem-image-2cce`
};
module.exports = ___CSS_LOADER_EXPORT___;
