import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Preview } from 'component/Image'
import { FitImage } from 'component/Image/FitImage'
import { PreviewControl, usePreviewControl } from 'component/Image/PreviewControl'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { TextArea } from 'component/TextArea'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { useMemo } from 'react'
import { SourceStore } from 'store/generation/SourceStore'
import { home } from 'store/home'
import { SHOW_MANUAL } from 'type/ClientConfig'
import { compact } from 'util/array'

interface ImageCardProps {
  image: string | undefined,
  control: PreviewControl
}

const ImageCard = observer(({ image, control }: ImageCardProps) => {
  const onClick = useLatestCallback(() => {
    if (image) control.setPreview(image)
  })

  const action = image ? onClick : undefined

  return <Card width={80} pic center tertiary={!image} action={action}>
    {image && <FitImage src={image} />}
    {!image && <Icon name="remove" tertiary />}
  </Card>
})

interface Props {
  source: SourceStore
}

export const SourceSection = observer(({ source }: Props) => {
  const { images, shape_type, prompt, manualLink, canEdit, canSave, canAutomatic, canDelete } = source
  const sources = useMemo(() => compact(images), [images])
  const control = usePreviewControl()

  const onChangePrompt = useLatestCallback((value: string) => {
    source.setPrompt(value)
  })

  const onSave = useLatestCallback(async () => {
    await source.save()
  })

  const onDelete = useLatestCallback(async () => {
    await modal.openDeleteSourceModal(home.product.it, source)
  })

  const onAutomatic = useLatestCallback(async () => {
    await source.runAutomatic()
  })

  return <Vertical gap={8} top wide>
    <Space gap={4}>
      {images.map((image, index) => <ImageCard key={index} image={image} control={control} />)}
    </Space>

    <Vertical gap={8}>
      <Space opposite wrap>
        <Typo size={12} secondary>{i18n('label.DefaultPrompt')}</Typo>

        <Space gap={8}>
          <Typo size={12} secondary>{i18n('label.ClothSection')}</Typo>
          <Typo size={14}>{capitalize(shape_type)}</Typo>
        </Space>
      </Space>

      <TextArea rows={4} readonly={!canEdit} value={prompt} onChange={onChangePrompt} />
    </Vertical>

    <Space gap={16} top opposite>
      <Space gap={8} wrap>
        <Button primary action={onSave} disabled={!canSave}>
          {i18n('common.Save')}
        </Button>
        <Button action={onAutomatic} disabled={!canAutomatic}>
          {i18n('source.Automatic')}
        </Button>
        {SHOW_MANUAL && <Link href={manualLink}>
          <Button link compact>
            {i18n('source.Manual')}
          </Button>
        </Link>}
      </Space>
      <Space gap={8}>
        <IconButton action={onDelete} disabled={!canDelete}>
          <Icon name="delete" />
        </IconButton>
      </Space>
    </Space>

    <Preview sources={sources} control={control} />
  </Vertical>
})
