// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs-head-23fd{color:red}.Breadcrumbs-item-23fd{max-width:calc(100vw - 48px)}`, "",{"version":3,"sources":["webpack://./src/section/Head/manager/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,uBACE,SAAA,CAIF,uBACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `Breadcrumbs-head-23fd`,
	"item": `Breadcrumbs-item-23fd`
};
module.exports = ___CSS_LOADER_EXPORT___;
