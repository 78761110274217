import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { FormSection } from 'page/home/product/generation/FormSection'
import { OpenButton } from 'page/home/product/generation/OpenButton'
import { SourceSection } from 'page/home/product/generation/SourceSection'
import { home } from 'store/home'

export const GenerationTab = observer(() => {
  const { sources } = home.product.it
  const { open } = sources.it.form

  return <Vertical gap={24}>
    {sources.it.sources.map(source => <SourceSection key={source.id} source={source} />)}
    {open ? <FormSection /> : <OpenButton />}
  </Vertical>
})
