// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductImage-count-62ee{position:absolute;inset:4px 8px auto auto;color:var(--ui-background-color);text-shadow:0 0 4px var(--ui-text-color)}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/ProductImage.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,uBAAA,CACA,gCAAA,CACA,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": `ProductImage-count-62ee`
};
module.exports = ___CSS_LOADER_EXPORT___;
