import { ConfigProvider, ThemeConfig } from 'antd'
import { ReactNode, useMemo } from 'react'

const ANTD = 'antd'

function buildTheme(): ThemeConfig {
  return {
    hashed: false,
    cssVar: { key: ANTD },
    token: {
      borderRadius: 4,
      colorBorder: '#d0d0d0', // --ui-border-color
      colorPrimary: '#3F80EA', // --ui-primary-color
      colorLink: '#1f1f1f', // --ui-secondary-color
      colorLinkHover: '#8b8b8b', // --ui-text-color-secondary
      fontSize: 14,
      fontFamily: 'inherit',
      lineHeight: 1.4,
    },
  }
}

type Props = {
  children?: ReactNode
}

export function configureAntd(container: HTMLElement) {
  container.classList.add(ANTD)
}

export const Theme = ({ children }: Props) => {
  const theme = useMemo(buildTheme, [])

  return <ConfigProvider theme={theme}>
    {children}
  </ConfigProvider>
}
