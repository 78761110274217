// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyItem-item-67d1{height:32px;width:100%;padding:0 8px;border-radius:var(--ui-border-radius)}.CompanyItem-item-67d1:hover{background-color:var(--ui-background-color-soft);color:var(--ui-text-color)}.CompanyItem-icon-67d1{opacity:0;transition:opacity 200ms ease-in-out}.CompanyItem-item-67d1:hover .CompanyItem-icon-67d1{opacity:1}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/CompanyItem.module.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,UAAA,CACA,aAAA,CACA,qCAAA,CAGF,6BACE,gDAAA,CACA,0BAAA,CAGF,uBACE,SAAA,CACA,oCAAA,CAGF,oDACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `CompanyItem-item-67d1`,
	"icon": `CompanyItem-icon-67d1`
};
module.exports = ___CSS_LOADER_EXPORT___;
