import { Typography } from 'antd'
import { CSSProperties, ReactNode } from 'react'

type Props = {
  children?: ReactNode,
  rows?: number
}

export function Text(props: Props) {
  const { children, rows } = props
  const style: CSSProperties = { marginBottom: 0 }

  return <Typography.Paragraph style={style} ellipsis={{ rows }}>
    {children}
  </Typography.Paragraph>
}
