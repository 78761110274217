// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MemberSection-table-8c1b{width:fit-content;border-collapse:collapse;margin:0 -8px}.MemberSection-table-8c1b td:nth-child(1){width:80px}.MemberSection-table-8c1b td:nth-child(2){width:320px}.MemberSection-table-8c1b td:nth-child(3){width:100px}.MemberSection-table-8c1b td:nth-child(4){width:100px}.MemberSection-table-8c1b td:nth-child(5){width:100px}.MemberSection-table-8c1b td:nth-child(6){width:200px}.MemberSection-table-8c1b thead td{white-space:nowrap;vertical-align:top;padding:12px 8px;font-size:14px;color:var(--ui-text-color-secondary)}.MemberSection-table-8c1b tbody td{vertical-align:top;padding:12px 8px;border-top:1px solid var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/manage/MemberSection.module.scss"],"names":[],"mappings":"AAAA,0BACE,iBAAA,CACA,wBAAA,CACA,aAAA,CAIA,0CACE,UAAA,CAGF,0CACE,WAAA,CAGF,0CACE,WAAA,CAGF,0CACE,WAAA,CAGF,0CACE,WAAA,CAGF,0CACE,WAAA,CAWF,mCACE,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA,CACA,oCAAA,CAKF,mCACE,kBAAA,CACA,gBAAA,CACA,oDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `MemberSection-table-8c1b`
};
module.exports = ___CSS_LOADER_EXPORT___;
