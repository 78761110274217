// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuButton-button-c473{z-index:1;position:absolute;top:24px;right:-40px;width:24px;height:24px;color:var(--ui-text-color) !important;transition:right 200ms ease-in-out;background-color:var(--ui-background-color) !important}.MenuButton-button-c473:hover{color:var(--ui-text-color-secondary) !important}.MenuButton-right-c473{right:-40px}.MenuButton-left-c473{right:-12px}.MenuButton-close-c473.MenuButton-left-c473:hover{right:calc(-24px + var(--ui-border-radius))}@media(width <= 480px){.MenuButton-open-c473{right:16px}}.MenuButton-icon-c473,.MenuButton-expand-c473,.MenuButton-collapse-c473{position:absolute;inset:0;width:24px;height:24px;opacity:0;transition:opacity 200ms ease-in-out}.MenuButton-button-c473:not(:hover) .MenuButton-icon-c473{opacity:1}.MenuButton-button-c473:hover .MenuButton-show-c473{opacity:1}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/MenuButton.module.scss"],"names":[],"mappings":"AAAA,wBACE,SAAA,CACA,iBAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,qCAAA,CACA,kCAAA,CACA,sDAAA,CAGF,8BACE,+CAAA,CAGF,uBACE,WAAA,CAGF,sBACE,WAAA,CAGF,kDACE,2CAAA,CAGF,uBACE,sBACE,UAAA,CAAA,CAIJ,wEACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,oCAAA,CAGF,0DACE,SAAA,CAGF,oDACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `MenuButton-button-c473`,
	"right": `MenuButton-right-c473`,
	"left": `MenuButton-left-c473`,
	"close": `MenuButton-close-c473`,
	"open": `MenuButton-open-c473`,
	"icon": `MenuButton-icon-c473`,
	"expand": `MenuButton-expand-c473`,
	"collapse": `MenuButton-collapse-c473`,
	"show": `MenuButton-show-c473`
};
module.exports = ___CSS_LOADER_EXPORT___;
