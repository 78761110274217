import { Button } from 'component/Button'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { FormField } from 'form/FormField'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { DESCRIPTION_LIMIT } from 'modal/UploadCollectionModal/store/CatalogStepStore'

export const CreateCatalogStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const { collections, catalog_name, website, description, form } = mod.catalogStep

  const left = DESCRIPTION_LIMIT - description.value.length

  const onSelect = useLatestCallback(() => {
    mod.catalogStep.create = false
  })

  const onFocus = useLatestCallback((focus: boolean) => {
    if (focus) return
    website.value = website.value.replace(/^(https?:\/*)+/, '')
  })

  return <Vertical gap={24}>
    <Space gap={16} opposite>
      <Typo size={16} secondary>
        {i18n('catalog.CreateNewCollection')}
      </Typo>
      {collections.length > 0 && <Button link compact action={onSelect}>
        {i18n('catalog.SelectCollection')}
      </Button>}
    </Space>

    <Vertical gap={12}>
      <TextField field={catalog_name} label={i18n('label.CatalogName')}
        placeholder={i18n('placeholder.CatalogName')} />

      <FormField field={website} label={i18n('label.Website')}>
        <Input value={website.value} placeholder={i18n('placeholder.Website')}
          before={<Typo size={14} secondary>https://</Typo>}
          onChange={website.onChange} onFocus={onFocus} />
      </FormField>

      <Vertical gap={4}>
        <TextField field={description} rows={4} label={i18n('label.Description')}
          limit={DESCRIPTION_LIMIT} placeholder={i18n('placeholder.Description')} />
        <Typo size={12} secondary>
          {i18n('text.CountCharactersLeft', { count: left })}
        </Typo>
      </Vertical>
    </Vertical>

    <Space gap={12} right>
      <Button primary disabled={form.error} action={mod.onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
