import { captureConsoleIntegration, getGlobalScope, init } from '@sentry/react'
import { app_version, development, production, staging } from 'app/config/constants'

const DSN = 'https://bbbb5f3ba78f391a870dd06573b6d87b@o4507930409959424.ingest.de.sentry.io/4508003449897040'
const DSN_DEVELOPMENT = 'https://2710405ae3b1559ddefeca87d7f57e98@o4507930409959424.ingest.de.sentry.io/4508020335050832'
const dsn = development ? DSN_DEVELOPMENT : DSN
const environment = production ? 'production' : staging ? 'staging' : development ? 'development' : 'unknown'

export function initSentry() {
  if (development && process.env.DEVELOPMENT_SENTRY !== 'true') return

  init({
    dsn,
    environment,
    release: app_version,
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
    ],
    attachStacktrace: true,
  })

  if (development) getGlobalScope().setTag('application', 'saas-client')
}
