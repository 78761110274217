import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'
import { useMemo, useRef } from 'react'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { companyEdit, CompanyEditStore } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'

export class CompanyEditLoadingStore {
  private _canceled = false
  private _loading = true
  private _error?: string

  constructor(company_id: string | undefined) {
    makeAutoObservable(this)
    void this.process(company_id)
  }

  private async process(company_id: string | undefined): Promise<void> {
    try {
      companyEdit.close()
      if (!company_id) return
      if (!can.ViewCompanyInformation(company_id)) return
      await companiesSaas.load()
      if (this._canceled) return
      const company = companiesSaas.companies.find(co => co.company_id === company_id)
      if (!company) return
      await company.load()
      if (this._canceled) return
      companyEdit.it = new CompanyEditStore(company)
    } catch (e) {
      console.error('loading failed', e)
      this.error = this.error || i18n('common.Error')
    } finally {
      this.loading = false
    }
  }

  get loading(): boolean {
    return this._loading
  }

  get error(): string | undefined {
    return this._error
  }

  cancel() {
    this._canceled = true
  }

  private set loading(value: boolean) {
    this._loading = value
  }

  private set error(value: string) {
    this._error = value
  }
}

export function useCompanyEditLoadingStore(company_id?: string | undefined): CompanyEditLoadingStore {
  const prev = useRef<CompanyEditLoadingStore>()
  return useMemo(() => {
    prev.current?.cancel()
    return prev.current = new CompanyEditLoadingStore(company_id)
  }, [company_id])
}
