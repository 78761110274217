import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ApiKey } from 'saas/page/product/tryon/ApiKey/ApiKey'
import { EmbedCode } from 'saas/page/product/tryon/Details/EmbedCode'
import { StatusSwitch } from 'saas/page/product/tryon/Details/StatusSwitch'

export const Details = observer(() => {
  return <Vertical>
    <StatusSwitch />
    <Gap height={24} />
    <EmbedCode />
    <Gap height={16} />
    <ApiKey />
  </Vertical>
})
