import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import css from './ImageInput.module.scss'

export const ImageInput = observer(() => {
  const original = stand.it.json.image
  const { image } = stand.it.edit
  const { value, busy, src } = image
  const changed = busy || value !== original

  const onFile = useLatestCallback((file: File) => {
    image.file = file
  })

  const onDelete = useLatestCallback(() => {
    image.value = undefined
  })

  const onUndo = useLatestCallback(() => {
    image.value = original
  })

  return <>
    <Space gap={8}>
      <Typo size={12} bold>{i18n('stand.MetaImage')}</Typo>
      {busy && <Spinner size={16} />}
    </Space>
    <Gap height={4} />
    <Space fit>
      <Upload active={!!src} accept="image" onFile={onFile}>
        <Space className={clsx(css.image, !value && css.pale)} center>
          {src ? <img src={src} /> : <Icon name="image" size={16} />}
        </Space>
      </Upload>
      {src && <Sticker pin={[0, 0, null, null]}>
        <IconButton small action={onDelete}>
          <Icon size={16} name="close" />
        </IconButton>
      </Sticker>}
      {changed && <Sticker pin={[null, 0, 0, null]}>
        <IconButton small action={onUndo}>
          <Icon size={16} name="undo" />
        </IconButton>
      </Sticker>}
    </Space>
  </>
})
