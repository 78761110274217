import { Button } from 'component/Button'
import { Option, Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { useMemo } from 'react'
import { CollectionStore } from 'store/collection'

export const SelectCatalogStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const { collection, collections } = mod.catalogStep

  const options = useMemo<Option<CollectionStore>[]>(() => collections.map(collection => ({
    value: collection.collectionId,
    label: collection.data.name,
    data: collection,
  })), [collections])

  const onCreate = useLatestCallback(() => {
    mod.catalogStep.create = true
  })

  const onCollection = useLatestCallback((_value: unknown, option?: Option<CollectionStore>) => {
    mod.catalogStep.collection = option?.data
  })

  return <Vertical gap={24}>
    <Space gap={16} opposite>
      <Typo size={16} secondary>
        {i18n('catalog.SelectCollection')}
      </Typo>
      <Button link compact action={onCreate}>
        {i18n('catalog.CreateNewCollection')}
      </Button>
    </Space>

    <Vertical gap={12}>
      <Select value={collection?.collectionId} options={options} onChange={onCollection} />
    </Vertical>

    <Space gap={12} right>
      <Button primary disabled={!collection} action={mod.onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
