// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentPreview-preview-88f6>*{flex:0 0 auto}.CommentPreview-preview-88f6>:last-child{flex:1 1 0}`, "",{"version":3,"sources":["webpack://./src/page/internal/comments/CommentPreview.module.scss"],"names":[],"mappings":"AACE,+BACE,aAAA,CAGF,yCACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": `CommentPreview-preview-88f6`
};
module.exports = ___CSS_LOADER_EXPORT___;
