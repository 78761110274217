import { FormStore } from 'form/store/FormStore'
import { BooleanStore } from 'store/base/BooleanStore'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'

export class SupportModalStore {
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly email = this.form.field(new StringStore(''), { required: true })
  readonly phone = this.form.field(new StringStore(''))
  readonly details = this.form.field(new StringStore(''), { required: true })
  readonly consent = this.form.field(new BooleanStore(false), { required: true })
  readonly captcha = this.form.field(new BooleanStore(false), { required: true })

  constructor() {
    this.name.value = me.user.name ?? ''
    this.email.value = me.user.email ?? ''
  }
}
