import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { LoadingPage } from 'feature/page/LoadingPage'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Page } from 'saas/screen/Page'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { can } from 'store/can'
import { route } from 'store/route'
import { CompanyCard } from './CompanyCard'

export const CompaniesPage = observer(() => {
  const [loading, setLoading] = useState(true)

  useEffectOnce(async () => {
    await companiesSaas.load()
    const company = companiesSaas.companies[0]
    if (company && companiesSaas.companies.length === 1) {
      const { company_id } = company
      const canView = can.ViewCompanyInformation(company_id)
      if (canView) route.replace(`/company/${company_id}/manage`)
    }
    setLoading(false)
  })

  const onCreate = useLatestCallback(() => {
    route.push('/companies/create')
  })

  if (loading) return <LoadingPage />

  return <Page>
    <Vertical gap={24}>
      <Space gap={16} opposite>
        <Typo size={18} bold>
          {i18n('label.Companies')}
        </Typo>
        {can.CreateCompany() && <Button action={onCreate}>
          {i18n('company.CreateNew')}
        </Button>}
      </Space>
      <Space gap={16} top wrap>
        {companiesSaas.companies.map(co =>
          <CompanyCard key={co.company_id} company={co} />)}
      </Space>
    </Vertical>
  </Page>
})
