import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { getCompanyGroups, groupsSaas, GroupsStore } from 'saas/store/groups/GroupsStore'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'

type Params = {
  company_id: string
  product_id: string
}

type Data = {
  groups: GroupsStore
  product: ProductSaasStore
}

export class SaasProductPageController implements PageController<Params, Data> {
  async load({ company_id, product_id }: Params): Promise<Data | undefined> {
    home.selectCompany(company_id)
    await companiesSaas.load()
    const company = companiesSaas.companies.find(c => c.company_id === company_id)
    const groups = getCompanyGroups(company_id)
    if (!company) return
    const _company = company.load()
    const _groups = groups.load()
    await productsSaas.load(company_id).load()
    await _company
    await _groups
    const { products } = productsSaas.get(company_id)
    const product = products.find(product => product.product_id === product_id)
    if (!product) return
    return { groups, product }
  }

  select(params: Params, data: Data): void {
    productsSaas.select(params.company_id)
    groupsSaas.open(data.groups)
    productSaas.open(data.product)
  }

  close(): void {
    productSaas.optional?.close()
    productDeleteModal.close()
  }
}

export const saasProductPageController = new SaasProductPageController()
