import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import css from './Breadcrumbs.module.scss'

export const Breadcrumbs = observer(() => {
  const company = home.company.optional
  const collection = home.collection.optional?.data.name
  const product = home.product.optional?.data.product_name

  const onCompany = useLatestCallback(async () => {
    await home.openCompany(home.company.it)
  })

  const onCollection = useLatestCallback(async () => {
    await home.openCollection(home.collection.it)
  })

  if (!company || (!collection && !product)) return null

  return <Space className={css.head} wrap>
    <Space className={css.item}>
      <Button text small compact action={onCompany}>
        {company.title}
      </Button>
      {collection && <Icon name="chevron_right" secondary />}
    </Space>
    <Space className={css.item}>
      {collection && <Button text small compact title={collection} action={onCollection}>
        {collection}
      </Button>}
      {product && <Icon name="chevron_right" secondary />}
    </Space>
    {product && <Space className={css.item}>
      <Button text small compact>{product}</Button>
    </Space>}
  </Space>
})
