import { Input } from 'component/Input'
import { TextArea } from 'component/TextArea'
import { FormField } from 'form/FormField'
import { FieldStore } from 'form/store/FieldStore'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

type Props = {
  field: FieldStore<string>
  label: string
  placeholder?: string
  disabled?: boolean
  autofocus?: boolean
  limit?: number
  mono?: boolean
  rows?: number
  onFocus?(focus: boolean): void
}

export const TextField = observer((props: Props) => {
  const { field, label, placeholder, disabled, autofocus, limit, mono, rows, onFocus } = props
  const { value } = field
  const area = rows != null

  const ref = useRef<HTMLInputElement | null>(null)

  useEffectOnce(() => {
    if (autofocus) ref.current?.focus()
  })

  const onChange = useLatestCallback((value: string) => {
    field.value = value
  })

  return <FormField field={field} label={label}>
    {area && <TextArea ref={ref} value={value} placeholder={placeholder} disabled={disabled}
      mono={mono} limit={limit} rows={rows} onChange={onChange} onFocus={onFocus} />}
    {!area && <Input ref={ref} value={value} placeholder={placeholder} disabled={disabled}
      mono={mono} limit={limit} onChange={onChange} onFocus={onFocus} />}
  </FormField>
})
