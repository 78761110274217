import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { ChartSerie, getSerieTitle } from 'saas/store/stat/ChartSerie'

type Props = {
  serie: ChartSerie
}

export function LegendItem({ serie }: Props) {
  const { id, color } = serie
  const title = getSerieTitle(id)

  return <Space gap={8}>
    <Space width={16} height={16} round style={{ backgroundColor: color }} />
    <Typo size={12}>{title}</Typo>
  </Space>
}
