import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { StatisticsView } from 'saas/page/statistics/StatisticsView'
import { Page } from 'saas/screen/Page'
import { statisticsController } from 'saas/store/stat/StatisticsController'
import { statistics } from 'saas/store/stat/StatisticsStore'

export const StatisticsPage = observer(() => {
  useMemo(() => void statisticsController.init(), [])

  if (statistics.error) return <ErrorPage />
  if (!statistics.company_id) return <NotFoundPage />
  if (!statistics.ready) return <LoadingPage />

  return <Page>
    <StatisticsView />
  </Page>
})
