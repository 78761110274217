import { Card } from 'component/Card'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import css from './Preview.module.scss'

export const Preview = observer(() => {
  return <Card pad={16} width={400} top secondary>
    <Card className={css.stub} wide height={600} center tertiary>
      <Icon size={16} name="close" tertiary />
    </Card>
  </Card>
})
