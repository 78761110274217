import { makeAutoObservable, observable } from 'mobx'
import { api } from 'store/api'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'
import { SerialProcessStore } from 'store/base/process/SerialProcessStore'
import { Product } from 'type/product/Product'
import { ProductsConfig } from 'type/product/ProductsConfig'
import { addProduct, deleteProduct, updateProduct } from 'type/product/utils'

export class ProductsConfigStore {
  private readonly serial = new SerialProcessStore()
  readonly company_id: string
  json: ProductsConfig

  constructor(company_id: string, json: ProductsConfig) {
    makeAutoObservable<this, 'serial'>(this, { serial: false, company_id: false, json: observable.ref })
    this.company_id = company_id
    this.json = json
  }

  get busy(): boolean {
    return this.serial.busy
  }

  async addProduct(product: Product): Promise<void> {
    this.json = addProduct(this.json, product)
    await this.serial.call(() => api.createProductConfig(product))
  }

  async updateProduct(product: Product): Promise<void> {
    this.json = updateProduct(this.json, product)
    await this.serial.call(() => api.updateProductConfig(product))
  }

  async deleteProduct(product: Product): Promise<void> {
    const { company_id, product_id } = product
    this.json = deleteProduct(this.json, product)
    await this.serial.call(() => api.deleteProductConfig(company_id, product_id))
  }
}

export async function factory(company_id: string): Promise<ProductsConfigStore> {
  const config = await api.getProductsConfig(company_id)
  return new ProductsConfigStore(company_id, config)
}

export const productsConfig = new AsyncStoreController(factory)
