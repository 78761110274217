import { makeAutoObservable } from 'mobx'

export class StringStore {
  private _value: string

  constructor(value: string = '') {
    this._value = value
    makeAutoObservable(this)
  }

  get value(): string {
    return this._value
  }

  set value(value: string) {
    this._value = value || ''
  }

  get empty(): boolean {
    return !this._value.length
  }

  readonly onChange = (value: string | null | undefined) => {
    this._value = value ?? ''
  }
}
