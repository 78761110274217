import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { mx } from 'store/base/mx'
import { GenerationForm } from 'store/generation/GenerationForm'
import { SourceStore } from 'store/generation/SourceStore'
import { me } from 'store/me'
import { Source, UploadSourceRequest } from 'type/Source'

export class SourcesStore {
  private readonly itemId: string
  private _sources = new ArrayStore<SourceStore>([])
  private _form = mx.ref(new GenerationForm())

  constructor(itemId: string, images: Source[]) {
    this.itemId = itemId
    for (const image of images) {
      this._sources.add(SourceStore.fromSource(image))
    }
    this._form.it.open = images.length === 0
  }

  get sources(): readonly SourceStore[] {
    return this._sources.value
  }

  get form(): GenerationForm {
    return this._form.it
  }

  readonly closeForm = () => {
    this._form.it = new GenerationForm()
    this._form.it.open = false
  }

  readonly createSource = async () => {
    if (!this.form.form.check()) return

    const request = this.buildUploadRequest()
    const image = this.form.image.value?.url
    const item = SourceStore.fromRequest(request, image)
    this._sources.add(item)
    this._form.it = new GenerationForm()

    const response = await api.uploadSourceImage(request)
    item.afterUpload(response)
    notice.success(i18n('source.SourceCreated'))
  }

  async deleteSource(source: SourceStore) {
    const { source_image_id, image_id } = source
    if (!source_image_id) throw new Error('no source id')
    if (!image_id) throw new Error('no image id')

    this._sources.remove(source)
    await api.deleteSourceImage(image_id, source_image_id)
    notice.success(i18n('source.SourceDeleted'))
  }

  private buildUploadRequest(): Required<UploadSourceRequest> {
    const shape_type = this.form.shape.value
    const image = this.form.image.value?.file

    if (!shape_type) throw new Error('no shape')
    if (!image) throw new Error('no image')

    return {
      user_id: me.user.user_id,
      item_id: this.itemId,
      shape_type,
      prompt: this.form.prompt.value,
      image,
    }
  }
}
