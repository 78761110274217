import { Card } from 'component/Card'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { Head } from 'page/home/product/Head'
import { ProductSection } from 'page/home/product/ProductSection'
import { home } from 'store/home'

export const ProductSectionCard = observer(() => {
  const { itemId } = home.product.it

  return <Card key={itemId} secondary vertical width={480} minHeight={400} pad={16}>
    <Vertical gap={24}>
      <Head />
      <ProductSection />
    </Vertical>
  </Card>
})
