import { Radio } from 'component/Radio/Radio'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { ReactNode } from 'react'

type RadioItem = {
  key?: string
  value: string
  label: ReactNode
}

type Props = {
  allowUncheck?: boolean
  items: RadioItem[]
  value: string | undefined
  onChange(value: string | undefined): void
}

type ItemProps = {
  item: RadioItem,
  props: Props
}

function Item({ item, props }: ItemProps) {
  const { allowUncheck } = props
  const { value, label } = item
  const checked = value === props.value

  const onChange = useLatestCallback((checked: boolean) => {
    props.onChange(checked ? value : undefined)
  })

  return <Radio allowUncheck={allowUncheck} checked={checked} onChange={onChange}>
    {label}
  </Radio>
}

export const RadioGroup = (props: Props) => {
  const { items } = props

  return <Space gap={8} wrap>
    {items.map(item => <Item key={item.key ?? item.value} item={item} props={props} />)}
  </Space>
}
