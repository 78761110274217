import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { can } from 'store/can'
import { home } from 'store/home'

export const Head = observer(() => {
  const store = home.product.it
  const { companyId, visible } = store
  const { product_name } = store.data

  const onTest = useLatestCallback(async () => {
    await modal.openTestItemModal(store)
  })

  return <Space gap={16} opposite top>
    <Space gap={8} top>
      <Typo size={18} bold>
        {product_name}
      </Typo>
      <Space height={24}>
        <Icon size={16} name={visible ? 'visibility' : 'visibility_off'} secondary />
      </Space>
    </Space>
    {can.AddAndEditProducts(companyId) && <Space height={24}>
      <Button primary icon={<Icon name={'qr_code'} />} action={onTest}>
        {i18n('item.TestItem')}
      </Button>
    </Space>}
  </Space>
})
