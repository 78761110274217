import { renderApp } from 'app/App'
import { initTitle } from 'app/config/title'
import { configureDayjs } from 'app/controller/dayjs'
import { lang } from 'app/controller/language'
import { security } from 'app/controller/security'
import { initSentry } from 'app/controller/sentry'
import { OverlayScrollbars } from 'overlayscrollbars'

export function startup(): void {
  initSentry()
  configureDayjs()
  initTitle()
  void lang.start()
  security.start()
  renderApp()
  OverlayScrollbars({
    target: document.body,
    cancel: {
      nativeScrollbarsOverlaid: true,
    },
  }, {
    update: {
      elementEvents: [['.os-transitionend', 'transitionend']],
    },
  }, {})
}
