import { app_domain } from 'app/config/constants'
import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import LogoDresscodeSvg from './logo-dresscode.svg'
import LogoShowoffSvg from './logo-showoff.svg'
import css from './LogoInput.module.scss'

export const LogoInput = observer(() => {
  const dresscode = app_domain === 'dresscode'
  const original = stand.it.json.logo
  const { logo } = stand.it.edit
  const { value, busy, src } = logo
  const changed = busy || value !== original

  const onFile = useLatestCallback((file: File) => {
    logo.file = file
  })

  const onDelete = useLatestCallback(() => {
    logo.value = undefined
  })

  const onUndo = useLatestCallback(() => {
    logo.value = original
  })

  return <>
    <Space gap={8}>
      <Typo size={12} bold>{i18n('label.Logo')}</Typo>
      {busy && <Spinner size={16} />}
    </Space>
    <Gap height={4} />
    <Space fit>
      <Upload active={!!src} accept="image" onFile={onFile}>
        <Space className={clsx(css.logo, !value && css.pale)} center>
          {src ? <img src={src} /> : dresscode ? <LogoDresscodeSvg /> : <LogoShowoffSvg />}
        </Space>
      </Upload>
      {src && <Sticker pin={[0, -24, null, null]}>
        <IconButton small action={onDelete}>
          <Icon size={16} name="close" />
        </IconButton>
      </Sticker>}
      {changed && <Sticker pin={[null, -24, 0, null]}>
        <IconButton small action={onUndo}>
          <Icon size={16} name="undo" />
        </IconButton>
      </Sticker>}
    </Space>
  </>
})
