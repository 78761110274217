import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { getNextId } from 'util/id'

export class UploadImageFile {
  readonly id = getNextId()
  private _url: string | undefined
  private _file: File | undefined

  constructor() {
    makeAutoObservable(this)
  }

  set value(url: string | undefined) {
    this._url = url
    this._file = undefined
  }

  set file(file: File | undefined) {
    this._url = undefined
    this._file = file
    if (file) void this.upload(file)
  }

  get value(): string | undefined {
    return this._url
  }

  get busy(): boolean {
    return !!this._file
  }

  get src(): string | undefined {
    if (this._file) return URL.createObjectURL(this._file)
    return this._url
  }

  private async upload(file: File | undefined) {
    try {
      const url = await api.uploadImage(file)
      if (this._file === file) {
        runInAction(() => {
          this._url = url
          this._file = undefined
        })
      }
    } catch (e) {
      console.error('upload failed', e)
      if (this._file === file) {
        notice.error(i18n('error.ImageUploadFailed'))
        runInAction(() => {
          this._file = undefined
        })
      }
    }
  }
}
