import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { CollectionTitle } from 'page/internal/products/CollectionTitle'
import { ProductFilters } from 'page/internal/products/ProductFilters'
import { ProductList } from 'page/internal/products/ProductList'
import { home } from 'store/home'
import { renderStubIcon } from 'util/render'

export const ProductsView = observer(() => {
  const items = home.collection.it.items.optional

  const present = items != null
  const none = !present
  const empty = present && items.length === 0
  const full = present && !empty

  useEffectOnce(() => {
    void home.collection.it.loadItems()
  })

  return <Vertical gap={16}>
    <CollectionTitle />
    <ProductFilters />
    {none && <Space height={400} center>
      {renderStubIcon(home.collection.it.items)}
    </Space>}
    {empty && <Typo size={14} bold secondary>
      {i18n('item.NoItems')}
    </Typo>}
    {full && <ProductList />}
  </Vertical>
})
