import { RadioGroup } from 'component/Radio/RadioGroup'
import { FormField } from 'form/FormField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'

export const GenderRadioGroup = observer(() => {
  const { gender } = modal.editCollectionModal.it

  const onChange = useLatestCallback((value: string) => {
    gender.value = value
  })

  const items = [
    { value: 'male', label: i18n('label.Male') },
    { value: 'female', label: i18n('label.Female') },
    { value: 'unisex', label: i18n('label.Unisex') },
  ]

  return <FormField field={gender} label={i18n('label.Gender')}>
    <RadioGroup value={gender.value} onChange={onChange} items={items} />
  </FormField>
})
