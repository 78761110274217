import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { IconName } from 'icon/types'
import { observer } from 'mobx-react-lite'
import { Sort } from 'page/demo-catalog/store/DemoCatalogFormStore'

export function getSortIconName(sort: Sort): IconName {
  switch (sort) {
    case 'popular':
      return 'sort'
    case 'cheap':
      return 'arrow_downward'
    case 'expensive':
      return 'arrow_upward'
  }
}
export function getSortTitle(sort: Sort) {
  switch (sort) {
    case 'popular':
      return i18n('demo.PopularSorting')
    case 'cheap':
      return i18n('demo.CheapSorting')
    case 'expensive':
      return i18n('demo.ExpensiveSorting')
  }
}

type Props = { value: Sort }

export const SortLabel = observer(({ value }: Props) => {
  return <Space gap={8}>
    <Icon name={getSortIconName(value)} size={16} secondary />
    <Typo size={11} medium upper>{getSortTitle(value)}</Typo>
  </Space>
})
