import { nil } from 'util/null'

export function parseFinite(text: string | null | undefined): number | undefined {
  if (!text) return undefined
  const num = parseFloat(text)
  return Number.isFinite(num) ? num : undefined
}

export function parseIntFinite(text: string | null | undefined): number | undefined {
  const num = parseFinite(text)
  if (num == null) return undefined
  return Number.isFinite(num) ? Math.trunc(num) : undefined
}

export function formatInt(value: number): string
export function formatInt(value: number | nil): string | undefined
export function formatInt(value: number | nil): string | undefined {
  if (value == null) return undefined
  const format = Intl.NumberFormat('en', { useGrouping: true, maximumFractionDigits: 0 })
  return format.format(value).replaceAll(',', ' ')
}
