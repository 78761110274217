import { LoadingPage } from 'feature/page/LoadingPage'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { ProductsView } from 'page/internal/products/ProductsView'
import { useState } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { Page } from 'saas/screen/Page'
import { saas } from 'saas/store/SaasController'
import { home } from 'store/home'
import { route } from 'store/route'

export const CollectionPage = observer(() => {
  const collectionId = route.unit(1)
  const [loading, setLoading] = useState(true)

  useEffectOnce(async () => {
    await saas.initCollection(collectionId)
    setLoading(false)
  })

  if (loading) return <LoadingPage />
  if (!home.collection.present) return <NotFoundPage />

  return <Page>
    <ProductsView />
  </Page>
})
