// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductPreview-root-6fa2{-webkit-user-select:none;user-select:none}.ProductPreview-left-6fa2{position:absolute;inset:0 50% 0 auto;width:auto}.ProductPreview-right-6fa2{position:absolute;inset:0 auto 0 50%;width:auto}.ProductPreview-image-6fa2 img{width:auto;height:calc(100vh - 68px);max-width:calc(50vw - 20px);margin-top:20px;object-fit:contain}.ProductPreview-left-6fa2 .ProductPreview-image-6fa2 img{object-position:right}.ProductPreview-right-6fa2 .ProductPreview-image-6fa2 img{object-position:left}.ProductPreview-nav-6fa2{position:absolute;inset:auto 0 8px 0;margin:auto;width:fit-content;border-radius:4px}.ProductPreview-count-6fa2{font-size:14px}.ProductPreview-count-6fa2>:nth-child(1){color:var(--ui-background-color);min-width:18px;text-align:right}.ProductPreview-count-6fa2>:nth-child(2){color:color-mix(in srgb, transparent, var(--ui-background-color) 30%);font-size:10px;margin-top:2px}.ProductPreview-count-6fa2>:nth-child(3){color:color-mix(in srgb, transparent, var(--ui-background-color) 30%);min-width:18px}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/ProductPreview.module.scss"],"names":[],"mappings":"AAAA,0BACE,wBAAA,CAAA,gBAAA,CAGF,0BACE,iBAAA,CACA,kBAAA,CACA,UAAA,CAGF,2BACE,iBAAA,CACA,kBAAA,CACA,UAAA,CAGF,+BACE,UAAA,CACA,yBAAA,CACA,2BAAA,CACA,eAAA,CACA,kBAAA,CAGF,yDACE,qBAAA,CAGF,0DACE,oBAAA,CAGF,yBACE,iBAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CAGF,2BACE,cAAA,CAEA,yCACE,gCAAA,CACA,cAAA,CACA,gBAAA,CAGF,yCACE,qEAAA,CACA,cAAA,CACA,cAAA,CAGF,yCACE,qEAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ProductPreview-root-6fa2`,
	"left": `ProductPreview-left-6fa2`,
	"right": `ProductPreview-right-6fa2`,
	"image": `ProductPreview-image-6fa2`,
	"nav": `ProductPreview-nav-6fa2`,
	"count": `ProductPreview-count-6fa2`
};
module.exports = ___CSS_LOADER_EXPORT___;
