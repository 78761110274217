// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileButton-profile-b035{display:flex;align-items:center;justify-content:center;width:42px;height:42px;background-color:var(--ui-background-color-soft);border-radius:var(--ui-border-radius);cursor:pointer;-webkit-user-select:none;user-select:none}`, "",{"version":3,"sources":["webpack://./src/section/Head/ProfileButton.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,gDAAA,CACA,qCAAA,CACA,cAAA,CACA,wBAAA,CAAA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `ProfileButton-profile-b035`
};
module.exports = ___CSS_LOADER_EXPORT___;
