// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterPopover-popover-7b85{max-height:100%;overflow:hidden}.FilterPopover-popover-7b85>*{flex:0 0 auto}.FilterPopover-header-7b85{border-bottom:1px solid #ebebeb}.FilterPopover-scroll-7b85{overflow:auto;flex:0 1 auto}.FilterPopover-footer-7b85{border-top:1px solid #ebebeb}.FilterPopover-checkbox-7b85>span>input:checked+span{background:#000 !important}.FilterPopover-checkbox-7b85>span>span{background:var(--ui-background-color-soft) !important;border-color:rgba(0,0,0,0) !important;border-radius:2px !important}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/FilterPopover/FilterPopover.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,eAAA,CAEA,8BACE,aAAA,CAIJ,2BACE,+BAAA,CAGF,2BACE,aAAA,CACA,aAAA,CAGF,2BACE,4BAAA,CAIA,qDACE,0BAAA,CAGF,uCACE,qDAAA,CACA,qCAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `FilterPopover-popover-7b85`,
	"header": `FilterPopover-header-7b85`,
	"scroll": `FilterPopover-scroll-7b85`,
	"footer": `FilterPopover-footer-7b85`,
	"checkbox": `FilterPopover-checkbox-7b85`
};
module.exports = ___CSS_LOADER_EXPORT___;
