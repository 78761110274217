// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./theme/github.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown blockquote{padding:0 8px;margin:0 8px;border-left:3px solid var(--ui-background-color-soft)}pre code.hljs{display:block;padding:4px 8px;border-radius:var(--ui-border-radius);color:#1f2328;background:#f6f8fa}`, "",{"version":3,"sources":["webpack://./src/saas/page/docs/ArticlePage.css"],"names":[],"mappings":"AAEA,qBACE,aAAA,CACA,YAAA,CACA,qDAAA,CAGF,cACE,aAAA,CACA,eAAA,CACA,qCAAA,CACA,aAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdown": `markdown`,
	"hljs": `hljs`
};
module.exports = ___CSS_LOADER_EXPORT___;
