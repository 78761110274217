import { Checkbox } from 'component/Checkbox'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const EmailCheckbox = observer(() => {
  const { on } = tryon.it.edit

  const onChange = useLatestCallback(() => {
    tryon.it.edit.on = !on
  })

  return <Checkbox checked={!!on} onChange={onChange}>
    {i18n('widget.EnableEmailsCollection')}
  </Checkbox>
})
