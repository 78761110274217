// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagerLogo-home-a2c0{cursor:pointer;transition:color 200ms ease-in-out;color:var(--ui-text-color)}.ManagerLogo-home-a2c0:hover{color:var(--ui-primary-color)}.ManagerLogo-sep-a2c0{width:1px;height:32px;background-color:currentColor;opacity:.1}.ManagerLogo-title-a2c0{font-size:13px;font-weight:700;line-height:16px;white-space:pre}@media(width <= 480px){.ManagerLogo-svg-a2c0{width:110px}.ManagerLogo-sep-a2c0{height:28px;margin:0 -2px}.ManagerLogo-title-a2c0{font-size:11px;line-height:14px}}@media(width <= 240px){.ManagerLogo-svg-a2c0{width:100px}.ManagerLogo-sep-a2c0{display:none}.ManagerLogo-title-a2c0{display:none}}@media(width <= 160px){.ManagerLogo-svg-a2c0{width:70px}}`, "",{"version":3,"sources":["webpack://./src/section/Head/manager/ManagerLogo.module.scss"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,kCAAA,CACA,0BAAA,CAGF,6BACE,6BAAA,CAGF,sBACE,SAAA,CACA,WAAA,CACA,6BAAA,CACA,UAAA,CAGF,wBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CAGF,uBACE,sBACE,WAAA,CAGF,sBACE,WAAA,CACA,aAAA,CAGF,wBACE,cAAA,CACA,gBAAA,CAAA,CAIJ,uBACE,sBACE,WAAA,CAGF,sBACE,YAAA,CAGF,wBACE,YAAA,CAAA,CAIJ,uBACE,sBACE,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `ManagerLogo-home-a2c0`,
	"sep": `ManagerLogo-sep-a2c0`,
	"title": `ManagerLogo-title-a2c0`,
	"svg": `ManagerLogo-svg-a2c0`
};
module.exports = ___CSS_LOADER_EXPORT___;
