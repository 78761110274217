import { makeAutoObservable } from 'mobx'
import { me } from 'store/me'
import { getRoleRights, MemberRight } from 'type/MemberRight'
import { extractMemberRoles, MemberRole } from 'type/MemberRole'
import { extractList } from 'util/string'

class CanController {
  constructor() {
    makeAutoObservable(this)
  }

  CreateCompany(): boolean {
    return me.admin
  }

  ManageProducts(company_id: string | undefined): boolean {
    return this.can(MemberRight.ManageProducts, company_id)
  }

  SystemAdmin(company_id: string | undefined) {
    if (me.admin) return true
    if (!company_id) return false
    return this.sys.has(company_id)
  }

  ViewCollectionsAndProducts(company_id: string | undefined): boolean {
    return this.can(MemberRight.ViewCollectionsAndItems, company_id)
  }

  ManageProductStatuses(company_id: string | undefined): boolean {
    return this.can(MemberRight.ManageItemStatuses, company_id)
  }

  TryOnWidget(company_id: string | undefined): boolean {
    return this.can(MemberRight.TryOnWidget, company_id)
  }

  AddAndEditProducts(company_id: string | undefined): boolean {
    return this.can(MemberRight.AddAndEditItems, company_id)
  }

  ViewCompanyInformation(company_id: string | undefined): boolean {
    return this.can(MemberRight.ViewCompanyInformation, company_id)
  }

  ViewStatistics(company_id: string | undefined): boolean {
    return this.can(MemberRight.ViewStatistics, company_id)
  }

  ViewApiKeys(company_id: string | undefined): boolean {
    return this.can(MemberRight.ViewApiKeys, company_id)
  }

  ManageApiKeys(company_id: string | undefined): boolean {
    return this.can(MemberRight.ManageApiKeys, company_id)
  }

  ViewDocumentation(company_id: string | undefined): boolean {
    return this.can(MemberRight.ViewDocumentation, company_id)
  }

  EditCompanyInformation(company_id: string | undefined): boolean {
    return this.can(MemberRight.EditCompanyInformation, company_id)
  }

  DeleteCompany(company_id: string | undefined): boolean {
    return this.can(MemberRight.DeleteCompany, company_id)
  }

  InviteUsers(company_id: string | undefined): boolean {
    return this.can(MemberRight.ManageUsersAndRoles, company_id)
  }

  ManageUsersAndRoles(company_id: string | undefined): boolean {
    return this.can(MemberRight.ManageUsersAndRoles, company_id)
  }

  DeleteMembers(company_id: string | undefined): boolean {
    return this.can(MemberRight.DeleteMembers, company_id)
  }

  CreateCollections(company_id: string | undefined): boolean {
    return this.can(MemberRight.CreateCollections, company_id)
  }

  EditCollectionInformation(company_id: string | undefined): boolean {
    return this.can(MemberRight.EditCollectionInformation, company_id)
  }

  DeleteCollections(company_id: string | undefined): boolean {
    return this.can(MemberRight.DeleteCollections, company_id)
  }

  DeleteProducts(company_id: string | undefined): boolean {
    return this.can(MemberRight.DeleteItems, company_id)
  }

  private can(right: MemberRight, company_id: string | undefined): boolean {
    if (!company_id) return false
    if (me.admin) return true
    if (this.admin.has(company_id)) return true
    const rights = this.rights(company_id)
    return rights ? rights.has(right) : false
  }

  private rights(company_id: string | undefined): Set<MemberRight> | undefined {
    if (!company_id) return undefined
    return this.companies[company_id] ?? undefined
  }

  private get companies(): Record<string, Set<MemberRight>> {
    const record: Record<string, Set<MemberRight>> = {}
    for (const company of me.permissions.companies) {
      const roles = extractMemberRoles(extractList(company.permissions))
      record[company.company_id] = new Set(roles.flatMap(role => getRoleRights(role)))
    }
    return record
  }

  private get admin(): Set<string> {
    const ids = new Set<string>()
    const ADMIN = [MemberRole.admin, 'system_admin']
    for (const company of me.permissions.companies) {
      const roles = extractList(company.permissions)
      const admin = roles.some(role => ADMIN.includes(role))
      if (admin) ids.add(company.company_id)
    }
    return ids
  }

  private get sys(): Set<string> {
    const ids = new Set<string>()
    const SYS = ['system_admin']
    for (const company of me.permissions.companies) {
      const roles = extractList(company.permissions)
      const sys = roles.some(role => SYS.includes(role))
      if (sys) ids.add(company.company_id)
    }
    return ids
  }
}

export const can = new CanController()
