import { observer } from 'mobx-react-lite'
import { DeleteCollectionModal } from 'modal/DeleteCollectionModal'
import { DeleteProductModal } from 'modal/DeleteProductModal'
import { DeleteSourceModal } from 'modal/DeleteSourceModal'
import { EditCollectionModal } from 'modal/EditCollectionModal'
import { modal } from 'modal/index'
import { InviteUsersModal } from 'modal/InviteUsersModal/InviteUsersModal'
import { ProfileModal } from 'modal/ProfileModal'
import { SupportModal } from 'modal/SupportModal'
import { TestItemModal } from 'modal/TestItemModal'
import { UploadCollectionModal } from 'modal/UploadCollectionModal/UploadCollectionModal'
import { me } from 'store/me'

export const Modals = observer(() => {
  if (!me.present) return null

  if (modal.deleteCollectionModal.present) return <DeleteCollectionModal />
  if (modal.deleteProductModal.present) return <DeleteProductModal />
  if (modal.deleteSourceModal.present) return <DeleteSourceModal />
  if (modal.profileModal.present) return <ProfileModal />
  if (modal.supportModal.present) return <SupportModal />
  if (modal.testItemModal.present) return <TestItemModal />
  if (modal.editCollectionModal.present) return <EditCollectionModal />
  if (modal.uploadCollectionModal.present) return <UploadCollectionModal />
  if (modal.inviteUsersModal.present) return <InviteUsersModal />

  return null
})
