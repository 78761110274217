import { FormStore } from 'form/store/FormStore'
import { ArrayStore } from 'store/base/ArrayStore'
import { ImageFile } from 'store/base/ImageFile'
import { ObjectStore } from 'store/base/ObjectStore'
import { StringStore } from 'store/base/StringStore'
import { ExternalStatus } from 'type/ExternalStatus'
import { InternalStatus } from 'type/InternalStatus'
import { Item } from 'type/Item'
import { split } from 'util/array'

export class ProductFormStore {
  readonly form = new FormStore()

  readonly images = this.form.field(new ArrayStore<ImageFile>([]))
  readonly product_name = this.form.field(new StringStore(''))
  readonly brand_name = this.form.field(new StringStore(''))
  readonly article = this.form.field(new StringStore(''))
  readonly sku = this.form.field(new StringStore(''))
  readonly description = this.form.field(new StringStore(''))
  readonly default_prompt = this.form.field(new StringStore(''))
  readonly background_prompt = this.form.field(new StringStore(''))
  readonly button_name = this.form.field(new StringStore(''))
  readonly button_url = this.form.field(new StringStore(''))
  readonly styles = this.form.field(new ArrayStore<string>([]))
  readonly types = this.form.field(new ArrayStore<string>([]))
  readonly seasons = this.form.field(new ArrayStore<string>([]))
  readonly gender = this.form.field(new ArrayStore<string>([]))
  readonly status = this.form.field(new ObjectStore<InternalStatus>())
  readonly review = this.form.field(new ObjectStore<ExternalStatus>())

  constructor(json: Item) {
    this.images.value = json.images?.map(image => ImageFile.fromUrl(image.url)) ?? []
    this.product_name.value = json.product_name ?? ''
    this.brand_name.value = json.brand_name ?? ''
    this.article.value = json.article ?? ''
    this.sku.value = json.external_item_id ?? ''
    this.description.value = json.item_descr ?? ''
    this.default_prompt.value = json.prompt ?? ''
    this.background_prompt.value = json.environment ?? ''
    this.button_name.value = json.links_name ?? ''
    this.button_url.value = json.links ?? ''
    this.styles.value = split(json.dress_styles)
    this.types.value = split(json.dress_types)
    this.seasons.value = split(json.dress_seasons)
    this.gender.value = split(json.dress_gender)
    this.status.value = json.internal_status || undefined
    this.review.value = json.external_status || undefined
  }

  get sources(): readonly string[] {
    return this.images.value.map(image => image.url)
  }
}
