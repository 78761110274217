import dayjs from 'dayjs'
import { nil } from 'util/null'

export function parseDate(text?: string | number | nil): Date | undefined {
  if (!text) return undefined
  try {
    return new Date(text)
  } catch (error) {
    console.error('parse date failed', text, error)
    return undefined
  }
}

export function parseDateFormat(text: string | number | nil, format: string): Date | undefined {
  if (!text || !format) return undefined
  try {
    return dayjs(text, format).toDate()
  } catch (error) {
    console.error('parse date failed', text, format, error)
    return undefined
  }
}

export function formatDate(date: Date, format?: string): string
export function formatDate(date: Date | nil, format?: string): undefined
export function formatDate(date: Date | nil, format = 'YYYY-MM-DD'): string | undefined {
  return date ? dayjs(date).format(format) : undefined
}

export function formatDateTime(date: Date): string
export function formatDateTime(date: Date | nil): string | undefined
export function formatDateTime(date: Date | nil): string | undefined {
  return formatDate(date, 'YYYY-MM-DD HH:mm')
}

export function formatDateSec(date: Date): string
export function formatDateSec(date: Date | nil): string | undefined
export function formatDateSec(date: Date | nil): string | undefined {
  return formatDate(date, 'YYYY-MM-DD HH:mm:ss')
}
