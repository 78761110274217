import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { KeyEditModal } from 'saas/page/company/modal/KeyEditModal/KeyEditModal'
import { KeySelect } from 'saas/page/product/tryon/ApiKey/KeySelect'
import { Origins } from 'saas/page/product/tryon/ApiKey/Origins'
import { ApiKeyModalStore, keyModal } from 'saas/store/key/ApiKeyModalStore'
import { tryon } from 'saas/store/product/ProductSaasController'
import { nbsp } from 'util/typo'

export const ApyKeyEdit = observer(() => {
  const { busy } = tryon.it
  const { company, keyEdit } = tryon.it
  const { key, canSave } = keyEdit!

  const onSave = useLatestCallback(() => {
    void tryon.it.saveKey()
  })

  const onCancel = useLatestCallback(() => {
    tryon.it.closeKeyEdit()
  })

  const onEdit = useLatestCallback(() => {
    keyModal.it = new ApiKeyModalStore(company, key)
  })

  const onCreate = useLatestCallback(() => {
    const modal = new ApiKeyModalStore(company, undefined)
    modal.attachOnCreate(key => keyEdit!.key = key)
    keyModal.it = modal
  })

  return <Vertical>
    <Space gap={16} opposite>
      <Typo size={14}>{i18n('company.ApiKey')}</Typo>
      <Space>
        <IconButton action={onSave} disabled={busy || !canSave}>
          <Icon name="check" size={16} />
        </IconButton>
        <IconButton action={onCancel}>
          <Icon name="close" size={16} />
        </IconButton>
      </Space>
    </Space>
    <Gap height={8} />
    <KeySelect />
    <Gap height={8} />
    <Space gap={16} opposite>
      {key ? <Button link compact action={onEdit}>
        {i18n('key.Edit')}
      </Button> : nbsp}
      <Button link compact action={onCreate}>
        {i18n('company.CreateApiKey')}
      </Button>
    </Space>
    {key && <Origins store={key} />}
    {keyModal.present && <KeyEditModal />}
  </Vertical>
})
