import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Glass, Sticker } from 'component/Glass'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CommentContent, getCommentContent } from 'page/internal/comments/CommentContent'
import { useEffect, useRef } from 'react'
import { api } from 'store/api'
import { commenting } from 'store/comment/CommentingStore'
import { CommentStore } from 'store/comment/CommentStore'
import { compact } from 'util/array'
import { copyText } from 'util/browser'
import { formatDateTime } from 'util/date'
import { emdash } from 'util/typo'
import css from './CommentItem.module.scss'

type Props = {
  comment: CommentStore
}

export const CommentItem = observer(({ comment }: Props) => {
  const store = commenting.comments
  const { chain } = store
  const { id, user_name, created, edited, canEdit, replyTo, error, scroll } = comment
  const sys = !!comment.like || !!comment.system

  const selected = comment === store.selected
  const exclude = chain.size && !chain.has(comment)
  const classes = clsx(css.comment, selected && css.selected, !id && css.weak, exclude && css.exclude)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      comment.scroll = undefined
    }
  }, [comment, scroll])

  const onClick = useLatestCallback(() => {
    store.selected = selected ? undefined : comment
  })

  const onClickCopy = useLatestCallback(() => {
    void copyText(getCommentContent(comment))
  })

  const onClickEdit = useLatestCallback(() => {
    store.editing = comment
  })

  const onClickDelete = useLatestCallback(async () => {
    if (comment.id) await api.deleteComment(comment.id)
    store.deleteComment(comment)
  })

  const onClickReply = useLatestCallback(() => {
    if (id) store.replyTo = comment
  })

  const items: DropdownItem[] = compact([
    canEdit && {
      key: 'edit',
      label: i18n('comment.Edit'),
      action: onClickEdit,
    },
    {
      key: 'Reply',
      label: i18n('comment.Reply'),
      action: onClickReply,
    },
    {
      key: 'copy',
      label: i18n('comment.Copy'),
      action: onClickCopy,
    },
    canEdit && {
      key: 'delete',
      label: i18n('comment.Delete'),
      action: onClickDelete,
    },
  ])

  return <Glass>
    <Vertical ref={ref} className={classes} gap={4} onClick={onClick}>
      <Space gap={[2, 4]} wrap>
        <Typo size={12} bold tertiary={!user_name}>
          {user_name || emdash}
        </Typo>
        <Space pad={[1, 0, 0, 0]}>
        <Typo size={10} secondary>
          {formatDateTime(created)}
        </Typo>
          </Space>
        {(sys || edited || replyTo) && <Space gap={4}>
          {sys && <Icon name="settings" size={12} tertiary />}
          {edited && <Icon name="edit" size={12} tertiary />}
          {replyTo && <Icon name="reply" size={12} tertiary />}
        </Space>}
      </Space>
      <CommentContent comment={comment} />
    </Vertical>

    {!error && id && <Sticker pin={[0, 0, null, null]} hidden>
      <Dropdown items={items} placement={'bottomRight'}>
        <IconButton>
          <Icon name="more_vert" size={16} />
        </IconButton>
      </Dropdown>
    </Sticker>}

    {error && <Sticker pin={[0, 0, 0, null]}>
      <Icon name="error" size={16} error />
    </Sticker>}
  </Glass>
})
