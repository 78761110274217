import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { RoleCheckbox } from 'saas/page/company/manage/modal/EditMemberModal/RoleCheckbox'
import { memberRoles } from 'type/MemberRole'

export const RolesSection = observer(() => {
  return <Vertical gap={8}>
    <Typo size={14} bold>
      {i18n('member.Permissions')}
    </Typo>
    <Vertical gap={4}>
      {memberRoles.map(role => <RoleCheckbox key={role} role={role} />)}
    </Vertical>
  </Vertical>
})
