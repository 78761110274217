import { makeAutoObservable } from 'mobx'
import { UploadItem } from 'modal/UploadCollectionModal/store/UploadItem'

export class ItemErrorsStore {
  readonly items: readonly  UploadItem[]
  private _skip = false

  constructor(items: UploadItem[]) {
    this.items = items
    makeAutoObservable(this, { items: false })
  }

  get skip(): boolean {
    return this._skip
  }

  set skip(value: boolean) {
    this._skip = value
  }

  get text(): string {
    return this.items
      .map(item => item.line.toFixed().padStart(3) + ': ' + item.text)
      .join('\n')
  }
}
