import { IconButton } from 'component/Button/IconButton'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const TitleEdit = observer(() => {
  const { busy, nameEdit } = productSaas.it
  const { name, canSave } = nameEdit!

  const onSave = useLatestCallback(async () => {
    void productSaas.it.saveName()
  })

  const onCancel = useLatestCallback(() => {
    productSaas.it.closeNameEdit()
  })

  return <Space gap={12} height={32} opposite wide>
    <Space flex="1">
      <Input lazy wide value={name.value} onChange={name.onChange} focus />
    </Space>
    <Space>
      <IconButton small action={onSave} disabled={busy || !canSave}>
        <Icon name="check" size={16} />
      </IconButton>
      <IconButton small action={onCancel}>
        <Icon name="close" size={16} />
      </IconButton>
    </Space>
  </Space>
})
