import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { StandProductStore } from 'saas/store/product/stand/StandProductStore'
import { TryOnProductStore } from 'saas/store/product/tryon/TryOnProductStore'
import { mx } from 'store/base/mx'
import { MxRef } from 'store/base/mx/MxRef'
import { Class } from 'util/type'

class ForwardTypedRef<Type, Store> {
  private readonly type: Class<Type>
  private readonly ref: MxRef<Store>

  constructor(type: Class<Type>, ref: MxRef<Store>) {
    this.type = type
    this.ref = ref
  }

  get it(): Type {
    const it = this.ref.it as object
    if (it instanceof this.type) return it

    const actual = it.constructor.name
    const expected = this.type.name
    throw new Error(actual + ' is not ' + expected)
  }
}

export const productSaas = mx.ref<ProductSaasStore>()
export const tryon = new ForwardTypedRef(TryOnProductStore, productSaas)
export const stand = new ForwardTypedRef(StandProductStore, productSaas)
