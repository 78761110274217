import { makeAutoObservable, reaction } from 'mobx'

export interface DataStore<Value> {
  value: Value
  readonly empty: boolean
}

export interface FieldOptions<Value = unknown, Data extends DataStore<Value> = DataStore<Value>> {
  required?: boolean
  fix?(value: Value, field: FieldStore<Value, Data>): Value
  validate?(value: Value, field: FieldStore<Value, Data>): boolean
}

export class FieldStore<Value = unknown, Data extends DataStore<Value> = DataStore<Value>> {
  private readonly _options: FieldOptions<Value>
  private readonly _data: Data
  private _valid = true
  private _touch = false
  private _focus = false

  constructor(data: Data, options?: FieldOptions<Value>) {
    this._data = data
    this._options = options ?? {}
    makeAutoObservable<this, '_options' | '_data'>(this, {
      _options: false,
      _data: false,
    })
    reaction(this.changes, this.changing, { fireImmediately: true })
  }

  reset(value: Value): void {
    this._data.value = value
    this._touch = false
  }

  get valid(): boolean {
    const { required, empty } = this
    if (required && empty) return false
    return this._valid
  }

  get required(): boolean {
    return !!this._options.required
  }

  get empty(): boolean {
    return this._data.empty
  }

  get error(): boolean {
    const { touch, valid } = this
    return touch && !valid
  }

  get data(): Data {
    return this._data
  }

  get value(): Value {
    return this._data.value
  }

  set value(value: Value) {
    this._data.value = value
  }

  get touch(): boolean {
    return this._touch
  }

  set touch(touch: boolean) {
    this._touch = touch
  }

  get focus(): boolean {
    return this._focus
  }

  set focus(focus: boolean) {
    this._focus = focus
  }

  readonly onFocus = (focus: boolean) => {
    this._focus = focus
    if (focus) this._touch = true
    const { fix } = this._options
    if (fix) {
      const after = fix(this.value, this)
      if (this.value !== after) this.value = after
    }
  }

  readonly onChange = (value: Value) => {
    this._data.value = value
    this._touch = true
  }

  private set valid(value: boolean) {
    this._valid = value
  }

  private changes = (): unknown[] => {
    return [this._data.value, this._touch, this._focus]
  }

  private changing = (): void => {
    const { validate } = this._options
    if (validate) {
      this.valid = this.empty || validate(this.value, this)
    } else this.valid = true
  }
}
