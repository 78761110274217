import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DemoButton } from 'page/demo/DemoButton'
import { demo } from 'page/demo/DemoStore'

type Props = {
  tab: string
}

function getTabTitle(tab: string) {
  if (!tab) return i18n('demo.GenderNone')
  if (tab === 'female') return i18n('demo.Female')
  if (tab === 'male') return i18n('demo.Male')
  return tab.toUpperCase()
}

export const DemoTab = observer(({ tab }: Props) => {
  const { itemsByTab } = demo
  const count = itemsByTab[tab]?.length || 0

  const onClick = useLatestCallback(() => {
    demo.setTab(tab)
  })

  return <DemoButton text active={demo.tab === tab} action={onClick}>
    <Space gap={8}>
      {getTabTitle(tab)}
      <Typo secondary>{count}</Typo>
    </Space>
  </DemoButton>
})
