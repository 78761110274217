import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productDeleteModal, ProductDeleteModalStore } from 'saas/store/product/ProductDeleteModalStore'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { formatDateTime, parseDate } from 'util/date'
import { emdash } from 'util/typo'

export const Info = observer(() => {
  const { busy } = productsSaas.it
  const { created_at, updated_at } = productSaas.it.json

  const created = parseDate(created_at)
  const updated = parseDate(updated_at)

  const onDelete = useLatestCallback(() => {
    productDeleteModal.open(new ProductDeleteModalStore(productSaas.it))
  })

  return <Vertical>
    <Space gap={[4, 4]} wrap oh opposite>
      <Typo size={12} ellipsis>{i18n('label.CreatedAt')}</Typo>
      <Typo size={12}>{formatDateTime(created) ?? emdash}</Typo>
    </Space>
    <Gap height={8} />
    <Space gap={[4, 4]} wrap oh opposite>
      <Typo size={12} ellipsis>{i18n('label.LastUpdate')}</Typo>
      <Typo size={12}>{formatDateTime(updated) ?? emdash}</Typo>
    </Space>
    <Gap height={24} />
    <Space>
      <Button error disabled={busy} action={onDelete}>
        {i18n('product.DeleteProduct')}
      </Button>
    </Space>
  </Vertical>
})
