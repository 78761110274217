// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Back-button-eed6{width:fit-content;height:40px;padding:0 12px;margin:-4px -12px;padding-right:24px}.Back-button-eed6 .ant-btn-icon{margin-right:2px !important}`, "",{"version":3,"sources":["webpack://./src/feature/back/Back.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,kBAAA,CAEA,gCACE,2BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Back-button-eed6`
};
module.exports = ___CSS_LOADER_EXPORT___;
