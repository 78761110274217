import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable, observable } from 'mobx'
import { StringStore } from 'store/base/StringStore'
import { fixText } from 'util/form'

type Json = {
  name?: string
}

export class NameEditStore {
  private readonly json: Json
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })

  constructor(json: Json) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this.json = json
    this.name.value = json.name?.trim() || ''
  }

  get changed(): boolean {
    return this.json.name !== this.name.value
  }

  get canSave(): boolean {
    return this.changed && !this.form.error
  }

  check() {
    this.form.check()
    return this.canSave
  }

  buildUpdate(): Json {
    return { name: this.name.value.trim() }
  }
}
