import { Button } from 'component/Button'
import { CopyIconButton } from 'component/Button/CopyIconButton'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ApyKeyEdit } from 'saas/page/product/tryon/ApiKey/ApyKeyEdit'
import { Origins } from 'saas/page/product/tryon/ApiKey/Origins'
import { tryon } from 'saas/store/product/ProductSaasController'
import { emdash } from 'util/typo'

export const ApiKey = observer(() => {
  const edit = !!tryon.it.keyEdit
  const { key } = tryon.it

  const onEdit = useLatestCallback(() => {
    tryon.it.openKeyEdit()
  })

  if (edit) return <ApyKeyEdit />

  return <Vertical>
    <Space gap={16} opposite>
      <Typo size={14}>{i18n('company.ApiKey')}</Typo>
      <Button link compact action={onEdit}>
        <Typo size={14}>{i18n('key.Change')}</Typo>
      </Button>
    </Space>
    <Gap height={8} />
    {!key && <Typo size={14} secondary>{emdash}</Typo>}
    {key && <Card pad={8} height={32}>
      <Space gap={16} wide opposite oh>
        <Typo size={14} mono ellipsis>{key.name}</Typo>
        <Space gap={8} oh flex="0 0 auto">
          <Typo size={14} mono>{key.valueText}</Typo>
          <CopyIconButton small data={key.value} />
        </Space>
      </Space>
    </Card>}
    {key && <Origins store={key} />}
  </Vertical>
})
