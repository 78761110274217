import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { UserButton } from 'saas/screen/head/UserButton'
import css from './Head.module.scss'

export const Head = observer(() => {
  return <Space className={css.head} pad={[0, 16]} opposite>
    <Space />
    <UserButton />
  </Space>
})
