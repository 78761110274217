import { LinkButton } from 'component/Button/LinkButton'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { managerSearchStore } from 'store/manager/search'
import { route } from 'store/route'

export const SearchButton = observer(() => {
  const companyId = home.company.optional?.company.company_id
  const path = managerSearchStore.formatCompanyLink(companyId)
  const active = route.pathname === '/search'

  return <LinkButton path={path} active={active}>
    <Typo size={16} ellipsis>{i18n('title.ItemSearch')}</Typo>
  </LinkButton>
})
