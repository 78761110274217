import { Button } from 'component/Button'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { Collections } from 'page/home/collections'
import { SectionStub } from 'page/home/stub/SectionStub'
import { can } from 'store/can'
import { home } from 'store/home'

export const LeftBoard = observer(() => {
  const { company_id } = home
  const { collections } = home.collections.it

  const onCreate = useLatestCallback(async () => {
    await modal.openCreateCollectionModal()
  })

  const onUpload = useLatestCallback(async () => {
    await modal.openUploadCollectionModal()
  })

  return <Vertical gap={32}>
    {collections.value.length ? <Collections /> : <SectionStub width={240} />}
    {can.CreateCollections(company_id) && <Vertical gap={8}>
      <Button text left action={onCreate} icon={<Icon name="add" size={14} />}>
        {i18n('header.CreateNewCollection')}
      </Button>
      {can.AddAndEditProducts(company_id) &&
        <Button text left action={onUpload} icon={<Icon name="upload" size={14} />}>
          {i18n('catalog.UploadCatalog')}
        </Button>}
    </Vertical>}
  </Vertical>
})
