import { mx } from 'store/base/mx'

type Callback<V> = () => Promise<V>
type Action<V> = () => Promise<V>

export class SingleProcessStore<V = void> {
  private readonly callback: Callback<V>
  private current?: Promise<V>
  private _busy = mx.ref(false)

  constructor(callback: Callback<V>) {
    this.callback = callback
  }

  get busy(): boolean {
    return this._busy.it
  }

  get action(): Action<V> | false {
    return this.busy ? false : this.call
  }

  readonly call = async (): Promise<V> => {
    if (this.current) return this.current

    this._busy.it = true
    this.current = this.callback()
    this.current.finally(this.end)
    return this.current
  }

  private readonly end = (): void => {
    this.current = undefined
    this._busy.it = false
  }
}
