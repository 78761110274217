import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Popover } from 'component/Popover'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { FiltersPopover } from 'page/internal/search/FiltersPopover'
import { QueryInput } from 'page/internal/search/QueryInput'
import { managerSearchStore } from 'store/manager/search'
import css from './SearchPanel.module.scss'

export const SearchPanel = observer(() => {
  const { busy, showFilterButton } = managerSearchStore

  const onSearch = useLatestCallback(async () => {
    await managerSearchStore.search()
  })

  return <Space className={css.panel} gap={[8, 12]}>
    {showFilterButton && <Popover space={8} pad={16} placement="bottomLeft" content={<FiltersPopover />}>
      <IconButton className={css.tune}>
        <Icon size={16} name="tune" />
      </IconButton>
    </Popover>}
    <Space className={css.query}>
      <QueryInput />
    </Space>
    <Button className={css.go} primary action={busy ? false : onSearch}>
      {i18n('search.Search')}
    </Button>
  </Space>
})
