import { DatePicker } from 'antd'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { isDayjs } from 'dayjs'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { statistics } from 'saas/store/stat/StatisticsStore'
import css from './IntervalSelect.module.scss'

const { RangePicker } = DatePicker

export const IntervalSelect = observer(() => {
  const { interval } = statistics
  const { value, week, month, quarter } = interval

  const onChange = useLatestCallback((values: unknown[] | null) => {
    const [start, end] = values ?? []
    interval.start = isDayjs(start) ? start : undefined
    interval.end = isDayjs(end) ? end : undefined
  })

  const onWeek = useLatestCallback(() => {
    interval.week = true
  })

  const onMonth = useLatestCallback(() => {
    interval.month = true
  })

  const onQuarter = useLatestCallback(() => {
    interval.quarter = true
  })

  return <Space gap={12}>
    <RangePicker className={css.input} popupClassName={css.dropdown} value={value} onChange={onChange} />
    <Space gap={8}>
      <Button compact link active={week} action={onWeek}>
        {i18n('interval.Week')}
      </Button>
      <Button compact link active={month} action={onMonth}>
        {i18n('interval.Month')}
      </Button>
      <Button compact link active={quarter} action={onQuarter}>
        {i18n('interval.Quarter')}
      </Button>
    </Space>
  </Space>
})
