import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore, SortField } from 'store/manager/search'

function formatSort(sort: [string, 'asc' | 'desc']): string {
  return sort?.join(':')
}

const LAST_UPDATE: SortField = ['updated_at', 'desc']
const CREATION_DATE: SortField = ['created_at', 'desc']

export const SortSelect = observer(() => {
  const sort = managerSearchStore.sort[0]

  const options: Option<SortField, string>[] = [{
    value: formatSort(LAST_UPDATE),
    label: i18n('sort.LastUpdate'),
    data: LAST_UPDATE,
  }, {
    value: formatSort(CREATION_DATE),
    label: i18n('sort.CreationDate'),
    data: CREATION_DATE,
  }]

  const option = sort && options.find(option => option.value === formatSort(sort))
  const value = option?.value || ''

  const onChange = useLatestCallback((_: string, option?: Option<SortField>) => {
    managerSearchStore.sort = option?.data ? [option.data] : []
  })

  return <Field label={i18n('search.Sorting')}>
    <Select wide value={value} options={options} onChange={onChange} allowClear={false} />
  </Field>
})
