import { mx } from 'store/base/mx'
import { ClientConfig } from 'type/ClientConfig'

type CfgRo = Readonly<ClientConfig>

class CfgStore {
  private _cfg = mx.ref<CfgRo>()

  get present(): boolean {
    return this._cfg.present
  }

  get cfg(): CfgRo {
    if (!this._cfg.present) throw new Error('no cfg')
    return this._cfg.it
  }

  set cfg(cfg: CfgRo) {
    this._cfg.it = cfg
  }

  clear(): void {
    this._cfg.it = undefined
  }
}

export const cfg = new CfgStore()
