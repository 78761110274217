import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { Breadcrumbs } from 'saas/page/product/common/Breadcrumbs/Breadcrumbs'
import { Info } from 'saas/page/product/common/Info/Info'
import { ProductDeleteModal } from 'saas/page/product/common/ProductDeleteModal/ProductDeleteModal'
import { Title } from 'saas/page/product/common/Title/Title'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'
import css from './View.module.scss'

type Props = {
  children: [main: ReactElement, panel: ReactElement]
}

export const View = observer(({ children }: Props) => {
  const [main, panel] = children

  return <Vertical tall>
    <Breadcrumbs />
    <Space top opposite tall>
      <Vertical gap={16} pad={16}>
        <Title />
        {main}
      </Vertical>
      <Vertical className={css.right} gap={16} pad={16} width={360} tall opposite>
        {panel}
        <Info />
      </Vertical>
    </Space>
    {productDeleteModal.present && <ProductDeleteModal />}
  </Vertical>
})
