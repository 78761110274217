import { Button } from 'component/Button'
import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { TextArea } from 'component/TextArea'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { ItemErrorsStore } from 'modal/UploadCollectionModal/store/ItemErrorsStore'

type Props = {
  store: ItemErrorsStore
  children: string
}

export const SkipStep = observer(({ store, children }: Props) => {
  const mod = modal.uploadCollectionModal.it
  const { skip, text, items } = store
  const rows = Math.min(12, items.length + 1)

  const onSkip = useLatestCallback((checked: boolean) => store.skip = checked)

  return <Vertical gap={24}>
    <Typo size={16} error>
      {i18n('catalog.Errors')}
    </Typo>

    <Vertical gap={12}>
      <Typo size={14}>
        {children}
      </Typo>

      <TextArea mono rows={rows} readonly value={text} />

      <Space gap={12}>
        <Checkbox checked={skip} onChange={onSkip}>
          {i18n('catalog.SkipAll')}
        </Checkbox>
      </Space>
    </Vertical>

    <Space gap={12} right>
      <Button action={mod.onBack}>
        {i18n('common.GoBack')}
      </Button>
      <Button primary disabled={!skip} action={mod.onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
