import { notice } from 'app/notice'
import { rest } from 'app/rest'
import { makeAutoObservable, runInAction } from 'mobx'
import { AUTH } from 'store/route'
import { Connection } from 'type/Connection'
import { notNil } from 'util/null'

const priorities = ['email', 'Username-Password-Authentication']

export class LoginStore {
  private _error = false
  private _connections?: Connection[]

  constructor() {
    makeAutoObservable(this)
    void this.fetch()
  }

  get error(): boolean {
    return this._error
  }

  get connections(): Connection[] | undefined {
    return this._connections
  }

  private async fetch() {
    const state = ''
    const auth = location.origin + AUTH
    try {
      const connections = await rest.get<Connection[]>('/api/auth/connections', { state, auth })
      this.done(connections)
    } catch (e) {
      console.error(e)
      notice.error()
      runInAction(() => {
        this._error = true
      })
    }
  }

  private done(connections: Connection[]) {
    const first = priorities.map(name => connections.find(connection => connection.name === name)).filter(notNil)
    const rest = connections.filter(connection => !priorities.includes(connection.name))
    const sorted = [...first, ...rest]
    this._connections = sorted
  }
}
