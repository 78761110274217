import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { ArrayStore } from 'store/base/ArrayStore'
import { StringStore } from 'store/base/StringStore'
import { Item } from 'type/Item'
import { split, uniq } from 'util/array'
import { fixText } from 'util/form'
import { equal } from 'util/object'

export class TagsFormStore {
  private readonly json: Item
  private readonly original: readonly string[]
  readonly form = new FormStore()
  readonly tags = this.form.field(new ArrayStore<string>())
  readonly text = this.form.field(new StringStore())
  private _busy = false

  constructor(json: Item) {
    makeAutoObservable(this)
    this.json = json
    this.original = uniq(split(this.json.item_tags || '').map(fixText).filter(tag => !!tag))
    this.reset()
  }

  get value() {
    const text = fixText(this.text.value)
    const tags = this.tags.value
    return text && !tags.includes(text) ? [...tags, text] : tags
  }

  get changed() {
    return !equal(this.original, this.value)
  }

  get busy(): boolean {
    return this._busy
  }

  set busy(value: boolean) {
    this._busy = value
  }

  undo() {
    this.reset()
  }

  removeLastTag() {
    const data = this.tags.data
    const len = data.length
    const last = data.value[len - 1]
    if (!last) return
    data.remove(last)
  }

  addTextTag() {
    const tag = fixText(this.text.value)
    this.text.value = ''
    if (!tag) return
    const data = this.tags.data
    if (data.value.includes(tag)) return
    data.add(tag)
  }

  private reset() {
    this.tags.value = this.original
    this.text.value = ''
  }
}
