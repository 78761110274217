// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar-sidebar-a602{--width: 240px;--time: 200ms}@media(width <= 480px){.Sidebar-sidebar-a602{--width: 100vw}}.Sidebar-sidebar-a602{z-index:99;flex:0 0 auto;position:relative;width:0;transition:width var(--time) ease-in-out;box-sizing:border-box}.Sidebar-fixed-a602{position:fixed;top:0;left:0;bottom:0;width:0;transition:width var(--time) ease-in-out}.Sidebar-over-a602{position:relative;width:100%;height:100%;overflow:hidden;background-color:var(--ui-background-color)}.Sidebar-content-a602{position:absolute;top:0;left:0;bottom:0;width:var(--width)}.Sidebar-open-a602{width:var(--width)}.Sidebar-open-a602 .Sidebar-fixed-a602{width:var(--width)}@media(width > 480px){.Sidebar-open-a602 .Sidebar-fixed-a602{border-right:1px solid var(--ui-border-color-tertiary)}}.Sidebar-menu-a602:not(.Sidebar-ready-a602){display:none}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,aAAA,CAEA,uBAJF,sBAKI,cAAA,CAAA,CAIJ,sBACE,UAAA,CACA,aAAA,CACA,iBAAA,CACA,OAAA,CACA,wCAAA,CACA,qBAAA,CAGF,oBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CACA,wCAAA,CAGF,mBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,2CAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,kBAAA,CAGF,mBACE,kBAAA,CAGF,uCACE,kBAAA,CAEA,sBAHF,uCAII,sDAAA,CAAA,CAIJ,4CACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar-sidebar-a602`,
	"fixed": `Sidebar-fixed-a602`,
	"over": `Sidebar-over-a602`,
	"content": `Sidebar-content-a602`,
	"open": `Sidebar-open-a602`,
	"menu": `Sidebar-menu-a602`,
	"ready": `Sidebar-ready-a602`
};
module.exports = ___CSS_LOADER_EXPORT___;
