import { development } from 'app/config/constants'
import { Icon } from 'icon/Icon'
import { memo } from 'react'
import css from './UserPicture.module.scss'

type Props = {
  picture: string | undefined | null
  size?: number
}

export const UserPicture = memo(({ picture, size }: Props) => {
  if (development) picture = undefined
  size ??= 24

  if (picture) return <img className={css.img} style={{ width: size, height: size }} src={picture} />
  return <Icon className={css.icon} name="account_circle" size={size} secondary />
})
