// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserPicture-img-7313{width:24px;height:24px;object-fit:cover;border-radius:50%;background-color:var(--ui-background-color)}.UserPicture-icon-7313{border-radius:50%;background-color:var(--ui-background-color)}`, "",{"version":3,"sources":["webpack://./src/section/Head/UserPicture.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,2CAAA,CAGF,uBACE,iBAAA,CACA,2CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `UserPicture-img-7313`,
	"icon": `UserPicture-icon-7313`
};
module.exports = ___CSS_LOADER_EXPORT___;
