import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { demo } from 'page/demo/DemoStore'
import { DemoTab } from 'page/demo/DemoTab'

export const DemoTabs = observer(() => {
  const { tabs } = demo

  if (tabs.length < 2) return null

  return <Space gap={12}>
    {tabs.map(tab => <DemoTab key={tab} tab={tab} />)}
  </Space>
})
