import { ErrorPage } from 'feature/page/ErrorPage'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { saasProductPageController } from 'saas/page/product/SaasProductPageController'
import { StandView } from 'saas/page/product/stand/StandView'
import { TryOnView } from 'saas/page/product/tryon/TryOnView'
import { Page } from 'saas/screen/Page'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { StandProductStore } from 'saas/store/product/stand/StandProductStore'
import { TryOnProductStore } from 'saas/store/product/tryon/TryOnProductStore'
import { usePageController } from 'store/base/page/usePageController'
import { route } from 'store/route'

function renderView(): ReactElement | undefined {
  const it = productSaas.it
  if (it instanceof TryOnProductStore) return <TryOnView />
  if (it instanceof StandProductStore) return <StandView />
  return undefined
}

export const SaasProductPage = observer(() => {
  const company_id = route.unit(1)
  const product_id = route.unit(3)

  const stub = usePageController(saasProductPageController, { company_id, product_id })
  if (stub) return stub

  const view = renderView()
  if (!view) return <ErrorPage text={i18n('product.UnknownProduct')} />

  return <Page pad={0}>
    {view}
  </Page>
})
