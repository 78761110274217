// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreatorLogo-home-0d2b{cursor:pointer;transition:color 200ms ease-in-out;color:var(--ui-text-color)}.CreatorLogo-home-0d2b:hover{color:var(--ui-primary-color)}.CreatorLogo-svg-0d2b{width:100px}.CreatorLogo-sep-0d2b{width:1px;height:32px;background-color:currentColor;opacity:.1}.CreatorLogo-title-0d2b{font-size:13px;font-weight:700;line-height:16px;white-space:pre}`, "",{"version":3,"sources":["webpack://./src/section/Head/creator/CreatorLogo.module.scss"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,kCAAA,CACA,0BAAA,CAGF,6BACE,6BAAA,CAGF,sBACE,WAAA,CAGF,sBACE,SAAA,CACA,WAAA,CACA,6BAAA,CACA,UAAA,CAGF,wBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `CreatorLogo-home-0d2b`,
	"svg": `CreatorLogo-svg-0d2b`,
	"sep": `CreatorLogo-sep-0d2b`,
	"title": `CreatorLogo-title-0d2b`
};
module.exports = ___CSS_LOADER_EXPORT___;
