import { loadJson, saveJson } from 'util/storage'

const COMPANY_ID = 'home.company.id'
const COLLECTION_ID = 'home.collection.id'
const PRODUCT_ID = 'home.product.id'

export class StorageController {
  loadCompanyId(): string | undefined {
    return loadJson<string>(COMPANY_ID)
  }

  loadCollectionId(): string | undefined {
    return loadJson<string>(COLLECTION_ID)
  }

  loadProductId(): string | undefined {
    return loadJson<string>(PRODUCT_ID)
  }

  saveCompanyId(companyId: string | undefined): void {
    saveJson(COMPANY_ID, companyId)
  }

  saveCollectionId(collectionId: string | undefined): void {
    saveJson(COLLECTION_ID, collectionId)
  }

  saveProductId(productId: string | undefined): void {
    saveJson(PRODUCT_ID, productId)
  }
}

export const storage = new StorageController()
