import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Glass.module.scss'

type Props = {
  children?: ReactNode
  className?: string
  fill?: boolean
  less?: boolean
}

export function Glass(props: Props) {
  const { className, children, fill, less } = props
  const classes = clsx(className, css.glass, fill && css.fill, less && css.less)

  return <div className={classes}>
    {children}
  </div>
}
