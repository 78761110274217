import { LinkButton } from 'component/Button/LinkButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import css from './UnknownPage.module.scss'

export const UnknownPage = () => {
  return <Vertical gap={12} className={css.error}>
    <Space gap={8}>
      <Icon name="error" size={20} error />
      <Typo size={14} error>{i18n('error.PageNotFound')}</Typo>
    </Space>
    <LinkButton path={'/'}>
      {i18n('common.GoHome')}
    </LinkButton>
  </Vertical>
}
