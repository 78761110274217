import { clsx } from 'clsx'
import { Button } from 'component/Button'
import { Input } from 'component/Input'
import { SearchSelect, TagsInput } from 'component/Select'
import { Select } from 'component/Select/Select'
import { TextArea } from 'component/TextArea'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react'
import css from './Field.module.scss'

type Props = {
  children: ReactNode
  className?: string
  wide?: boolean
  error?: boolean
  label: string
  onFocus?(focus: boolean): void
}

function getContentClass(props: Props) {
  const nodes = Children.toArray(props.children)
  const node = nodes.find(isValidElement)
  if (!isValidElement(node)) return

  const type = node.type
  if (type === Button) return css.button
  if (type === Input) return css.input
  if (type === TextArea) return css.area
  if (type === Select) return css.select
  if (type === SearchSelect) return css.select
  if (type === TagsInput) return css.select
  return css.content
}

export const Field = (props: Props) => {
  const { wide, error, label } = props

  const content = getContentClass(props)
  const className = clsx(props.className, css.field, wide && css.wide, error && css.error)

  const onFocus = useLatestCallback((focus: boolean) => {
    props.onFocus?.(focus)
  })

  const onRef = useLatestCallback((element: HTMLDivElement | null) => {
    if (!element) return
    element.addEventListener('focusin', () => onFocus(true))
    element.addEventListener('focusout', () => onFocus(false))
  })

  const children = Children.map(props.children, child => {
    if (isValidElement(child) && child.props?.error === undefined && props.error != undefined) {
      return cloneElement(child as ReactElement, { error: props.error })
    }
    return child
  })

  return <div className={className} ref={onRef}>
    <div className={content}>
      {children}
    </div>
    <div className={css.label} title={label}>
      {label}
    </div>
  </div>
}
