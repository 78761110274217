// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Link-link-c7fc{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.Link-secondary-c7fc{--ant-color-link: var(--ui-text-color-secondary);--ant-color-link-hover: var(--ui-text-color)}.Link-small-c7fc{font-size:12px}.Link-wide-c7fc{width:100%}.Link-wrap-c7fc{white-space:normal;word-break:break-word}`, "",{"version":3,"sources":["webpack://./src/component/Link/Link.module.scss"],"names":[],"mappings":"AAAA,gBACE,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,qBACE,gDAAA,CACA,4CAAA,CAGF,iBACE,cAAA,CAGF,gBACE,UAAA,CAGF,gBACE,kBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Link-link-c7fc`,
	"secondary": `Link-secondary-c7fc`,
	"small": `Link-small-c7fc`,
	"wide": `Link-wide-c7fc`,
	"wrap": `Link-wrap-c7fc`
};
module.exports = ___CSS_LOADER_EXPORT___;
