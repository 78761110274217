import { makeAutoObservable } from 'mobx'

export class WindowUiStore {
  width = 0
  height = 0
  scrollX = 0
  scrollY = 0

  constructor() {
    this.updateSize()
    this.updateScroll()
    window.addEventListener('resize', () => this.updateSize())
    window.addEventListener('scroll', () => this.updateScroll())
    makeAutoObservable(this)
  }

  get mobile() {
    return this.width < 760
  }

  private updateSize() {
    const root = document.documentElement
    this.width = root.clientWidth
    this.height = root.clientHeight
  }

  private updateScroll() {
    this.scrollX = window.scrollX
    this.scrollY = window.scrollY
  }
}
