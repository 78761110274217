import ImageBadSvg from 'asset/image-bad.svg'
import ImageGoodSvg from 'asset/image-good.svg'
import { Preview } from 'component/Image'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { MAX_IMAGE_SIZE_MB } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { AddImageCard } from 'page/home/product/description/AddImageCard'
import { ImageCard } from 'page/home/product/description/ImageCard'
import { home } from 'store/home'

export const ImagesBlock = observer(() => {
  const store = home.product.it.form
  const { images, sources } = store
  const { error } = images
  const control = usePreviewControl()

  return <Vertical gap={16}>
    <Space gap={8}>
      <Vertical>
        <Typo size={14} bold error={error}>
          {i18n('item.PreviewImages')}
        </Typo>
        <Typo size={12} secondary>
          {i18n('item.MaxWeightCountMbImageFormat', { count: MAX_IMAGE_SIZE_MB })}
        </Typo>
      </Vertical>
      <Typo tertiary>
        <ImageBadSvg />
      </Typo>
      <Typo primary>
        <ImageGoodSvg />
      </Typo>
    </Space>
    <Space gap={8} wrap>
      {images.value.map(image => <ImageCard key={image.id} image={image} control={control} />)}
      <Preview control={control} sources={sources} />
      <AddImageCard />
    </Space>
  </Vertical>
})
