// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RouteItem-item-b7ff{height:32px;width:100%;padding:0 8px;border-radius:var(--ui-border-radius)}.RouteItem-item-b7ff:hover{background-color:var(--ui-background-color-soft);color:var(--ui-text-color)}.RouteItem-active-b7ff{color:var(--ui-primary-color)}.RouteItem-icons-b7ff{flex:0 0 auto;position:relative;width:20px;height:20px}.RouteItem-icon-b7ff,.RouteItem-expand-b7ff,.RouteItem-collapse-b7ff{position:absolute;inset:0;width:20px;height:20px;opacity:0;transition:opacity 200ms ease-in-out}.RouteItem-item-b7ff:not(:hover) .RouteItem-icon-b7ff{opacity:1}.RouteItem-item-b7ff:hover .RouteItem-open-b7ff{opacity:1}.RouteItem-force-b7ff.RouteItem-open-b7ff{opacity:1}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/RouteItem.module.scss"],"names":[],"mappings":"AAAA,qBACE,WAAA,CACA,UAAA,CACA,aAAA,CACA,qCAAA,CAGF,2BACE,gDAAA,CACA,0BAAA,CAGF,uBACE,6BAAA,CAGF,sBACE,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CAGF,qEACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,oCAAA,CAGF,sDACE,SAAA,CAGF,gDACE,SAAA,CAGF,0CACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `RouteItem-item-b7ff`,
	"active": `RouteItem-active-b7ff`,
	"icons": `RouteItem-icons-b7ff`,
	"icon": `RouteItem-icon-b7ff`,
	"expand": `RouteItem-expand-b7ff`,
	"collapse": `RouteItem-collapse-b7ff`,
	"open": `RouteItem-open-b7ff`,
	"force": `RouteItem-force-b7ff`
};
module.exports = ___CSS_LOADER_EXPORT___;
