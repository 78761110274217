import { mx } from 'store/base/mx'

export class ArrayStore<Item> {
  private items = mx.box<readonly Item[]>([])

  constructor(items: Item[] = []) {
    this.items.it = [...items]
  }

  get value(): readonly Item[] {
    return this.items.it
  }

  set value(value: readonly Item[]) {
    if (this.items.it === value) return
    this.items.it = [...value]
  }

  get length(): number {
    return this.items.it.length
  }

  add(item: Item): void {
    this.items.it = [...this.items.it, item]
  }

  addFirst(item: Item): void {
    this.items.it = [item, ...this.items.it]
  }

  remove(item: Item): void {
    this.ensure(another => another !== item)
  }

  replace(from: Item, to: Item): void {
    this.update(item => item === from ? to : item)
  }

  update(predicate: (item: Item) => Item): void {
    let changed = false
    const items = this.items.it.map(item => {
      const update = predicate(item)
      if (item != update) changed = true
      return update
    })
    if (changed) this.items.it = items
  }

  ensure(filter: (item: Item) => boolean): void {
    const items = this.items.it.filter(filter)
    const changed = items.length !== this.items.it.length
    if (changed) this.items.it = items
  }

  find(predicate: (item: Item) => boolean): Item | undefined {
    return this.items.it.find(predicate)
  }

  filter(predicate: (item: Item) => boolean): Item[] {
    return this.items.it.filter(predicate)
  }

  map<T>(convert: (item: Item, index: number) => T): T[] {
    return this.items.it.map(convert)
  }

  each(callback: (item: Item, index: number) => unknown): void {
    this.items.it.forEach(callback)
  }

  get empty(): boolean {
    return !this.items.it.length
  }
}
