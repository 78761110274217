// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(width > 480px){.SearchPanel-panel-421c{flex:1 0 auto}.SearchPanel-tune-421c{flex-shrink:0}.SearchPanel-query-421c{flex-grow:1;max-width:420px;min-width:240px}.SearchPanel-go-421c{flex-shrink:0}}@media(width <= 480px){.SearchPanel-panel-421c{flex:1 1 auto;flex-wrap:wrap;justify-content:space-between}.SearchPanel-tune-421c{order:2}.SearchPanel-query-421c{order:1;width:100%}.SearchPanel-go-421c{order:3}}`, "",{"version":3,"sources":["webpack://./src/page/internal/search/SearchPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBACE,aAAA,CAGF,uBACE,aAAA,CAGF,wBACE,WAAA,CACA,eAAA,CACA,eAAA,CAGF,qBACE,aAAA,CAAA,CAIJ,uBACE,wBACE,aAAA,CACA,cAAA,CACA,6BAAA,CAGF,uBACE,OAAA,CAGF,wBACE,OAAA,CACA,UAAA,CAGF,qBACE,OAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `SearchPanel-panel-421c`,
	"tune": `SearchPanel-tune-421c`,
	"query": `SearchPanel-query-421c`,
	"go": `SearchPanel-go-421c`
};
module.exports = ___CSS_LOADER_EXPORT___;
