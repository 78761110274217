import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { FixedPortal } from 'component/Portal/FixedPortal'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { PreviewStore } from 'store/product/PreviewStore'
import { ProductItem } from 'store/product/ProductItem'
import css from './ProductPreview.module.scss'

type SectionProps = {
  className?: string
  store: PreviewStore
}

const Section = observer(({ className, store }: SectionProps) => {
  const { image, up, down, index, count } = store
  const many = count > 1

  const onUp = useLatestCallback(() => {
    --store.index
  })

  const onDown = useLatestCallback(() => {
    ++store.index
  })

  return <div className={className}>
    <div className={css.image}>
      {image && <img src={image} />}
    </div>
    {many && <Space className={css.nav} gap={2} center>
      <IconButton light action={onUp} disabled={!up}>
        <Icon size={20} name="keyboard_arrow_left" />
      </IconButton>
      <Space className={css.count} gap={4} height={32} center>
        <div>{index + 1}</div>
        <div>/</div>
        <div>{count}</div>
      </Space>
      <IconButton light action={onDown} disabled={!down}>
        <Icon size={20} name="keyboard_arrow_right" />
      </IconButton>
    </Space>}
  </div>
})

type Props = {
  className?: string
  item: ProductItem
}

export const ProductPreview = observer(({ className, item }: Props) => {
  const store = item.store.it.preview.it
  const { left, right } = store

  const onClose = useLatestCallback(() => {
    item.store.it.closePreview()
  })

  return <FixedPortal className={clsx(className, css.root)} onClose={onClose}>
    <Section className={css.left} store={left} />
    <Section className={css.right} store={right} />
  </FixedPortal>
})
