import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { observer } from 'mobx-react-lite'
import { CommentsSection } from 'page/internal/products/CommentsSection'
import { ProductInfoSection } from 'page/internal/products/ProductInfoSection'
import { ProductPreview } from 'page/internal/products/ProductPreview'
import { commenting } from 'store/comment/CommentingStore'
import { ProductItem } from 'store/product/ProductItem'
import css from './ProductCard.module.scss'

const HEIGHT = 480

type Props = {
  item: ProductItem
  num?: number
}

export const ProductCard = observer(({ item, num }: Props) => {
  if (item.store.present) return <Good item={item} num={num} />
  if (item.stub.present) return <Stub item={item} />
  return null
})

const Stub = observer(({ item }: Props) => {
  const { product_name } = item.stub.it

  return <Card className={css.card} center secondary>
    <Space className={css.single} height={HEIGHT} center>
      <Typo size={14} secondary>
        {product_name}
      </Typo>
    </Space>
  </Card>
})

const Good = observer(({ item, num }: Props) => {
  const product = item.store.it
  const comments = commenting.detached === product
  const { preview } = product

  return <Card className={css.card} secondary>
    <Space className={css.single} pad={12} top tall>
      <ProductInfoSection item={item} num={num} className={css.info} />
      {comments && <CommentsSection className={css.comments} />}
      {preview.present && <ProductPreview item={item} />}
    </Space>
  </Card>
})
