// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SharePanel-list-0ad3{display:flex;flex-wrap:wrap;justify-content:center;font-size:12px}.SharePanel-item-0ad3{text-align:center}.SharePanel-button-0ad3{width:80px;display:flex;flex-direction:column;align-items:center;cursor:pointer;margin:10px}.SharePanel-button-0ad3 svg{margin-bottom:6px;border-radius:4px;overflow:hidden}.SharePanel-copy-0ad3{width:48px;height:48px;display:flex;align-items:center;justify-content:center;background:rgba(0,0,0,.1);border-radius:4px;margin-bottom:6px}.SharePanel-copy-0ad3 svg{width:24px;height:24px;margin:0}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/SharePanel/SharePanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,sBAAA,CACA,cAAA,CAGF,sBACE,iBAAA,CAGF,wBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CAGF,4BACE,iBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,yBAAA,CACA,iBAAA,CACA,iBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `SharePanel-list-0ad3`,
	"item": `SharePanel-item-0ad3`,
	"button": `SharePanel-button-0ad3`,
	"copy": `SharePanel-copy-0ad3`
};
module.exports = ___CSS_LOADER_EXPORT___;
