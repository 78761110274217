// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card-card-3e77{--card-border-color: var(--ui-border-color);position:relative;border-radius:var(--ui-border-radius);box-shadow:0 0 0 1px var(--card-border-color);overflow:hidden;transition:box-shadow 200ms ease-in-out;background-color:var(--ui-background-color)}.Card-shadow-3e77{border:none;box-shadow:color-mix(in srgb, transparent, var(--card-border-color) 40%) 0 0 8px}.Card-secondary-3e77{--card-border-color: var(--ui-border-color-secondary)}.Card-tertiary-3e77{--card-border-color: var(--ui-border-color-tertiary)}.Card-overflow-3e77{overflow:visible}.Card-pic-3e77{aspect-ratio:var(--ui-aspect-ratio)}.Card-action-3e77:not(.Card-busy-3e77){cursor:pointer}.Card-action-3e77.Card-busy-3e77{cursor:wait;opacity:60%}.Card-card-3e77.Card-action-3e77:not(.Card-busy-3e77):hover{--card-border-color: var(--ui-primary-color)}.Card-card-3e77.Card-active-3e77{--card-border-color: var(--ui-primary-color)}`, "",{"version":3,"sources":["webpack://./src/component/Card/Card.module.scss"],"names":[],"mappings":"AAAA,gBACE,2CAAA,CACA,iBAAA,CACA,qCAAA,CACA,6CAAA,CACA,eAAA,CACA,uCAAA,CACA,2CAAA,CAGF,kBACE,WAAA,CACA,gFAAA,CAGF,qBACE,qDAAA,CAGF,oBACE,oDAAA,CAGF,oBACE,gBAAA,CAGF,eACE,mCAAA,CAGF,uCACE,cAAA,CAGF,iCACE,WAAA,CACA,WAAA,CAGF,4DACE,4CAAA,CAGF,iCACE,4CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card-card-3e77`,
	"shadow": `Card-shadow-3e77`,
	"secondary": `Card-secondary-3e77`,
	"tertiary": `Card-tertiary-3e77`,
	"overflow": `Card-overflow-3e77`,
	"pic": `Card-pic-3e77`,
	"action": `Card-action-3e77`,
	"busy": `Card-busy-3e77`,
	"active": `Card-active-3e77`
};
module.exports = ___CSS_LOADER_EXPORT___;
