import { Identity } from 'type/Identity'

export class IdentityStore {
  private readonly _json: Identity

  constructor(json: Identity) {
    this._json = json
  }

  get id(): string {
    return this._json.identity_id
  }

  get json(): Identity {
    return this._json
  }
}
