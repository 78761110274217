import { EffectCallback, useEffect } from 'react'

type AsyncAction = () => Promise<unknown>

export const useEffectOnce = (callback: EffectCallback | AsyncAction) => {
  useEffect(() => {
    const result = callback()
    if (result instanceof Promise) return undefined
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
