import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { TotalCard } from 'saas/page/statistics/total/TotalCard'
import { totals } from 'saas/store/stat/TotalsStore'

export const Totals = observer(() => {
  const { users, inferences, widgetOpen, share } = totals.totals ?? {}

  return <Space gap={16} spread width={1280}>
    <TotalCard num={users} unit={i18n('total.Users')} />
    <TotalCard num={inferences} unit={i18n('total.Generations')} />
    <TotalCard num={widgetOpen} unit={i18n('total.WidgetOpen')} />
    <TotalCard num={share} unit={i18n('total.Share')} />
  </Space>
})
