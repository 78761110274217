import { security } from 'app/controller/security'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { LOGIN, route } from 'store/route'
import css from './ErrorPage.module.scss'

type Props = {
  text?: string
}

export const ErrorPage = ({ text }: Props) => {
  const login = route.pathname === LOGIN

  const onRelogin = useLatestCallback(() => {
    security.logout()
  })

  const action = login ? i18n('common.Retry') : i18n('common.Relogin')

  return <Vertical gap={12} className={css.error}>
    <Space gap={8}>
      <Icon name="error" size={20} error />
      <Typo size={14} error>{text || i18n('common.Error')}</Typo>
    </Space>
    <Button link compact action={onRelogin}>
      {action}
    </Button>
  </Vertical>
}
