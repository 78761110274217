import { Card } from 'component/Card'
import { FitImage } from 'component/Image/FitImage'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { ProductItem } from 'store/product/ProductItem'
import css from './ProductImage.module.scss'

type Props = {
  className?: string
  item: ProductItem
  selected: boolean
}

export const ProductImage = observer(({ className, item, selected }: Props) => {
  const store = item.store.it
  const images = selected ? store.selectedImages : store.previewImages
  const source = images[0]
  const count = selected && images.length > 1 ? images.length.toFixed() : ''

  const onClick = useLatestCallback(() => {
    item.store.it.openPreview()
  })

  return <Card className={className} tertiary action={onClick}>
    {source && <FitImage src={source} />}
    {count && <Space className={css.count}>
      <Typo size={16} bold>{count}</Typo>
    </Space>}
  </Card>
})
