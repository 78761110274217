import { message } from 'antd'
import { setMessageApi } from 'app/notice'
import { memo } from 'react'

export const Notifications = memo(() => {
  const [messageApi, contextHolder] = message.useMessage()

  setMessageApi(messageApi)

  return <>
    {contextHolder}
  </>
})
