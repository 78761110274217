import { makeAutoObservable } from 'mobx'
import { extractPermissions, Member, MemberPreview, MemberUpdate } from 'type/Member'
import { extractMemberRoles, isMemberRole, MemberRole } from 'type/MemberRole'
import { uniq } from 'util/array'

export class MemberStore {
  user_id!: string
  user_name!: string

  created_at!: string
  last_update!: string
  last_request?: string

  user_email?: string
  user_phone?: string
  user_descr?: string
  user_pic?: string

  private permissions!: string[]

  static fromJson(json: Member): MemberStore {
    const store = new MemberStore()
    store.applyJson(json)
    return store
  }

  private constructor() {
    makeAutoObservable(this)
  }

  get roles(): MemberRole[] {
    return extractMemberRoles(this.permissions)
  }

  applyJson(json: Member) {
    this.user_id = json.user_id
    this.user_name = json.user_name
    this.created_at = json.created_at
    this.last_update = json.last_update
    this.last_request = json.last_request
    this.user_email = json.user_email
    this.user_phone = json.user_phone
    this.user_descr = json.user_descr
    this.user_pic = json.user_pic
    this.permissions = extractPermissions(json.permissions_list)
  }

  applyPreview(json: MemberPreview) {
    this.user_name = json.user_name
    this.user_email = json.user_email
    this.user_phone = json.user_phone
    this.user_descr = json.user_descr
    this.user_pic = json.user_pic
    this.permissions = extractPermissions(json.permissions_list)
  }

  applyUpdate(json: MemberUpdate) {
    this.user_name = json.user_name
    this.created_at = json.created_at
    this.last_update = json.last_update
    this.last_request = json.last_request
    this.user_email = json.user_email
    this.user_phone = json.user_phone
    this.user_descr = json.user_descr
    this.user_pic = json.user_pic
  }

  buildPermissions(roles: Iterable<MemberRole>): string {
    const keep = this.permissions.filter(r => !isMemberRole(r))
    return uniq([...keep, ...roles]).join(',')
  }
}
