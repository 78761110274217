import PlusSvg from 'asset/plus-circle.svg'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'

export const AddItemCard = observer(() => {
  const store = home.collection.it

  return <Glass>
    <Card action={store.createItem.action} center width={128} pic>
      <PlusSvg width={24} height={24} />
    </Card>
    <Sticker none pin={[null, 0, 8, 0]}>
      <Typo size={12} secondary pre center>
        {i18n('item.AddNewItem')}
      </Typo>
    </Sticker>
  </Glass>
})
