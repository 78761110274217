// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Head-head-fa62{height:73px;background-color:var(--ui-background-color);border-bottom:1px solid var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/saas/screen/head/Head.module.scss"],"names":[],"mappings":"AAAA,gBACE,WAAA,CACA,2CAAA,CACA,uDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `Head-head-fa62`
};
module.exports = ___CSS_LOADER_EXPORT___;
