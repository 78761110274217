import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { NameInput } from 'saas/page/product/common/ProductDeleteModal/NameInput'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

export const ProductDeleteModal = observer(() => {
  const { busy } = productsSaas.it
  const { product, canDelete } = productDeleteModal.it
  const { name } = product.json

  const onDelete = useLatestCallback(async () => {
    productDeleteModal.it.deleteProduct()
  })

  const onCancel = useLatestCallback(async () => {
    productDeleteModal.close()
  })

  return <Modal title={i18n('product.DeleteProduct')} onCancel={onCancel}>
    <Vertical gap={20}>
      <Typo size={12}>
        {i18n('product.YouAreAboutToDeleteNameProductEnterItsNameToConfirm', { name })}
      </Typo>
      <NameInput />
      <Space gap={12} right wrap>
        <Button link compact action={onCancel}>
          {i18n('common.Cancel')}
        </Button>
        <Button primary disabled={busy || !canDelete} action={onDelete}>
          {i18n('common.Delete')}
        </Button>
      </Space>
    </Vertical>
  </Modal>
})
