// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageInput-image-6dd3{overflow:hidden;min-width:48px;min-height:48px}.ImageInput-image-6dd3 img{width:auto;height:auto;max-width:200px;max-height:200px}.ImageInput-pale-6dd3{opacity:.2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/ImageInput/ImageInput.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CACA,cAAA,CACA,eAAA,CAEA,2BACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CAIJ,sBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `ImageInput-image-6dd3`,
	"pale": `ImageInput-pale-6dd3`
};
module.exports = ___CSS_LOADER_EXPORT___;
