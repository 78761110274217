import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'

export const FinalStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const count = mod.data.it.result.length

  const create = mod.catalogStep.create
  const name = mod.catalogStep.catalog_name.value
  const desc = mod.catalogStep.description.value
  const site = mod.catalogStep.website.value

  return <Vertical gap={24}>
    {create && <Vertical gap={8}>
      <Typo size={16}>
        {i18n('catalog.CreateNewCollection')}
      </Typo>
      {name && <Typo size={16}>
        {name}
      </Typo>}
      {desc && <Typo size={12} secondary>
        {desc}
      </Typo>}
      {site && <Space gap={8}>
        <Icon name="link" size={16} secondary />
        <Typo size={12}>
          {site}
        </Typo>
      </Space>}
    </Vertical>}

    <Typo size={14}>
      {i18n('catalog.UploadCountItems', { count })}
    </Typo>

    <Space gap={12} right>
      <Button action={mod.onBack}>
        {i18n('common.GoBack')}
      </Button>
      <Button primary action={mod.onUpload}>
        {create ? i18n('common.Create') : i18n('common.Upload')}
      </Button>
    </Space>
  </Vertical>
})
