import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { emdash } from 'util/typo'
import css from './CompanyItem.module.scss'

export const CompanyItem = observer(() => {
  const store = home.companies
  const current = home.company.optional
  const title = current?.title || emdash

  const items: DropdownItem[] = store.companies.map(company => {
    const json = company.company
    const id = json.company_id
    const selected = company === current
    const title = company.title
    const label = <Typo primary={selected}>{title}</Typo>
    return ({ key: id, label })
  })

  const onSelect = useLatestCallback((id: string) => {
    const company = store.findCompany(id)
    if (company) void home.openCompany(company)
  })

  return <Dropdown items={items} onClick={onSelect} placement="bottomLeft" space={8}>
    <Space className={css.item} gap={8} pad={[0, 8]} height={32} opposite>
      <Typo size={16} ellipsis>
        {title}
      </Typo>
      <Icon className={css.icon} name="keyboard_arrow_down" size={20} secondary />
    </Space>
  </Dropdown>
})
