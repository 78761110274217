// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DemoCatalogPage-header-5e4a{padding:20px 12px;border-bottom:1px solid #ebebeb}@media all and (min-width: 760px){.DemoCatalogPage-header-5e4a{padding:30px 32px}}.DemoCatalogPage-filters-5e4a{padding:16px 12px}@media all and (min-width: 760px){.DemoCatalogPage-filters-5e4a{padding:32px 32px 16px}}.DemoCatalogPage-list-5e4a{width:100%;display:grid;grid-template-columns:minmax(200px, 1fr) repeat(auto-fill, minmax(200px, 1fr));align-items:center;justify-items:stretch;grid-gap:40px 16px;padding:0 16px}@media(width <= 480px){.DemoCatalogPage-list-5e4a{grid-template-columns:repeat(2, minmax(100px, 1fr))}}@media(width <= 320px){.DemoCatalogPage-list-5e4a{grid-template-columns:1fr}}@media all and (min-width: 760px){.DemoCatalogPage-list-5e4a{padding:0 32px}}.DemoCatalogPage-headerButton-5e4a>span{display:none}@media all and (min-width: 760px){.DemoCatalogPage-headerButton-5e4a>span{display:block}}@media(width < 480px){.DemoCatalogPage-more-5e4a{width:100%}}@media(width >= 480px){.DemoCatalogPage-more-5e4a{min-width:320px}}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/DemoCatalogPage.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,+BAAA,CAEA,kCAJF,6BAKI,iBAAA,CAAA,CAIJ,8BACE,iBAAA,CAEA,kCAHF,8BAII,sBAAA,CAAA,CAIJ,2BACE,UAAA,CACA,YAAA,CACA,8EAAA,CACA,kBAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CAEA,uBATF,2BAUI,mDAAA,CAAA,CAGF,uBAbF,2BAcI,yBAAA,CAAA,CAGF,kCAjBF,2BAkBI,cAAA,CAAA,CAKF,wCACE,YAAA,CAGF,kCACE,wCACE,aAAA,CAAA,CAMJ,sBADF,2BAEI,UAAA,CAAA,CAGF,uBALF,2BAMI,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `DemoCatalogPage-header-5e4a`,
	"filters": `DemoCatalogPage-filters-5e4a`,
	"list": `DemoCatalogPage-list-5e4a`,
	"headerButton": `DemoCatalogPage-headerButton-5e4a`,
	"more": `DemoCatalogPage-more-5e4a`
};
module.exports = ___CSS_LOADER_EXPORT___;
