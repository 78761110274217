import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { StringStore } from 'store/base/StringStore'
import { fixText, validateEmail } from 'util/form'
import { getNextKey } from 'util/id'

export class UserFormStore {
  readonly key = getNextKey()
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true, fix: fixText })
  readonly email = this.form.field(new StringStore(''), { required: true, fix: fixText, validate: validateEmail })

  constructor() {
    makeAutoObservable(this)
  }
}
