type SearchPageRoute = {
  page: 'search'
}

type CompanyPageRoute = {
  page: 'company'
  companyId?: string
}

type CollectionPageRoute = {
  page: 'collection'
  collectionId: string
}

type ProductPageRoute = {
  page: 'product'
  productId: string
}

export type ManagerRoute = SearchPageRoute | CompanyPageRoute | CollectionPageRoute | ProductPageRoute

export function parseManagerRoute(pathname: string): ManagerRoute | undefined {
  const [type, id] = pathname.split('/').filter(part => !!part)

  if (!type) return { page: 'company' }
  if (type === 'search') return { page: 'search' }
  if (!type || !id) return undefined

  switch (type) {
    case 'item':
      return { page: 'product', productId: id }
    case 'collection':
      return { page: 'collection', collectionId: id }
    case 'company':
      return { page: 'company', companyId: id }
  }

  return undefined
}

export function formatManagerRoute(route: ManagerRoute): string {
  switch (route.page) {
    case 'search':
      return '/search'
    case 'company':
      return route.companyId ? '/company/' + route.companyId : '/'
    case 'collection':
      return '/collection/' + route.collectionId
    case 'product':
      return '/item/' + route.productId
    default:
      throw new Error('unknown route ' + JSON.stringify(route))
  }
}
