import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { FitImage } from 'component/Image/FitImage'
import { PreviewControl } from 'component/Image/PreviewControl'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ImageFile } from 'store/base/ImageFile'
import { home } from 'store/home'

type Props = {
  image: ImageFile
  control: PreviewControl
}

export const ImageCard = observer(({ image, control }: Props) => {
  const { images } = home.product.it.form

  const onClick = useLatestCallback(() => {
    control.setPreview(image.url)
  })

  const onDelete = useLatestCallback(() => {
    images.data.remove(image)
    images.touch = true
  })

  return <Glass>
    <Card width={100} pic action={onClick}>
      <FitImage src={image.url} />
    </Card>
    <Sticker pin={[null, null, 4, 4]} hidden vertical>
      <IconButton small action={onDelete}>
        <Icon name="delete" />
      </IconButton>
    </Sticker>
  </Glass>
})
