import { lang } from 'app/controller/language'
import { try_on } from 'feature/widget/TryOnStore'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { buyModal, BuyModalStore } from 'page/demo-catalog/store/BuyModalStore'
import { DemoCatalogFormStore, getLimitStep } from 'page/demo-catalog/store/DemoCatalogFormStore'
import { api } from 'store/api'
import { route } from 'store/route'
import { DemoCatalogProduct, extractBrands, extractGenders, extractStyles } from 'type/DemoCatalog'
import { StandProduct } from 'type/product/StandProduct'
import { onceAsync } from 'util/async'
import { parseFinite } from 'util/number'

type FilterOptions = {
  readonly brands: string[]
  readonly styles: string[]
  readonly genders: string[]
}

function getDemoCatalogProduct(): Partial<StandProduct> {
  return {
    brandFilter: true,
    brandNames: true,
    buyButtons: true,
    genderFilter: true,
    prices: true,
    shareCatalogButton: true,
    priceSorting: true,
    styleFilter: true,
  }
}

class DemoCatalogStore {
  ready = false
  error = false
  stand = false
  turn: number | undefined
  animated = false
  company_id?: string
  product: Partial<StandProduct> = {}
  items: DemoCatalogProduct[] = []
  options!: FilterOptions
  form = new DemoCatalogFormStore()

  constructor() {
    makeAutoObservable(this)
    const query = new URLSearchParams(window.location.search)
    this.stand = query.has('stand')
    this.turn = parseFinite(query.get('turn'))
  }

  get visibleItems() {
    return this.filteredItems.slice(0, this.form.limit)
  }

  get shopUrlDefault() {
    const item = this.visibleItems[0]
    const link = item?.links || ''
    if (!link) return ''
    try {
      return new URL(link).origin
    } catch {
      return ''
    }
  }

  more() {
    const limit = this.form.limit + getLimitStep()
    this.form.limit = Math.min(limit, this.filteredItems.length)
  }

  openBuyModal(item: DemoCatalogProduct) {
    buyModal.open(new BuyModalStore(item))
  }

  get canMore(): boolean {
    return this.filteredItems.length > this.form.limit
  }

  readonly init = onceAsync(async () => {
    try {
      const product = route.is('/stand/*')

      if (product) {
        const product_id = route.unit(1)
        if (!product_id) throw new Error('no product id')
        const { company_id, product, items } = await api.getStandDemoCatalog(product_id)
        this.company_id = company_id
        this.product = product
        this.setup(items)
        if (product.lang) await lang.change(product.lang)
      } else {
        const slug = route.unit(1)
        if (!slug) throw new Error('no slug')
        const company_id = route.unit(2)
        if (!company_id) throw new Error('no company id')
        this.company_id = company_id
        this.product = getDemoCatalogProduct()
        const { items } = await api.getDemoCatalog(slug)
        this.setup(items)
        const query = new URLSearchParams(location.search)
        if (query.has('lang')) await lang.change(query.get('lang')!)
      }

      const { stand, turn } = this
      if (this.company_id) void try_on.init(this.company_id, { stand, turn })
      this.animate()
      runInAction(() => this.ready = true)
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })

  private setup(items: DemoCatalogProduct[]) {
    const brands = extractBrands(items)
    const styles = extractStyles(items)
    const genders = extractGenders(items)

    runInAction(() => {
      this.items = items.reverse()
      this.options = { brands, styles, genders }
    })
  }

  private get filteredItems() {
    return this.sortedItems.filter(it => this.form.filter(it))
  }

  private get sortedItems() {
    const items = [...this.items]
    if (!this.form.comparator) return items
    return items.sort(this.form.comparator)
  }

  private animate() {
    this.animated = true
    reaction(() => this.visibleItems, () => this.animated = false)
  }
}

export const demo = new DemoCatalogStore()
