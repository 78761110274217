// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkButton-link-c508{margin:0 -4px;border-radius:var(--ui-border-radius)}.LinkButton-button-c508{width:fit-content;padding:0 4px}`, "",{"version":3,"sources":["webpack://./src/component/Button/LinkButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,qCAAA,CAGF,wBACE,iBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `LinkButton-link-c508`,
	"button": `LinkButton-button-c508`
};
module.exports = ___CSS_LOADER_EXPORT___;
