import { ArrayStore } from 'store/base/ArrayStore'
import { mx } from 'store/base/mx'
import { ImageAsset } from 'store/image/ImageAsset'
import { Collection, CollectionStats } from 'type/Collection'
import { Image } from 'type/Image'
import { split } from 'util/array'

export class CollectionDataStore {
  private _json = mx.ref<Collection>()
  private _assets = new ArrayStore<ImageAsset>([])
  private _stats = mx.ref<CollectionStats>()

  constructor(json: Collection) {
    this.update(json)
  }

  get json(): Collection {
    return this._json.it
  }

  get companyId(): string {
    return this._json.it.company_id
  }

  get collectionId(): string {
    return this._json.it.collection_id
  }

  get name(): string {
    return this._json.it.collection_name ?? ''
  }

  get images(): readonly string[] {
    return this._assets.value.map(image => image.url)
  }

  get assets(): readonly ImageAsset[] {
    return this._assets.value
  }

  get tags(): readonly string[] {
    return split(this._json.it.tags)
  }

  get demo(): string | undefined {
    const { modes, collection_id } = this.json
    const mode = !!modes?.includes('demo_public_view')
    if (!mode) return undefined

    const { origin } = location
    const slug = collection_id.substring(0, 8)
    return `${origin}/demo/${slug}`
  }

  get stats(): CollectionStats | undefined {
    return this._stats.optional
  }

  update(json: Collection) {
    this._json.it = json
    this._assets.value = json.images?.map(item => ImageAsset.fromItem('collection', item)) ?? []
    this._stats.it = json.stat
  }

  addImage(image: Image) {
    this._assets.add(ImageAsset.fromImage('collection', image))
  }
}
