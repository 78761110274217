import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { DescriptionTab } from 'page/home/product/description/DescriptionTab'
import { GenerationTabPreload } from 'page/home/product/generation/GenerationTabPreload'
import { ResultsTab } from 'page/home/product/result/ResultsTab'
import { Tabs } from 'page/home/product/Tabs'
import { home } from 'store/home'

export const ProductSection = observer(() => {
  const tab = home.ui.productTab

  return <Vertical gap={24} wide>
    <Tabs />
    {tab === 'description' && <DescriptionTab />}
    {tab === 'generation' && <GenerationTabPreload />}
    {tab === 'results' && <ResultsTab />}
  </Vertical>
})
