import { Space } from 'component/Space'
import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { UserFormStore } from 'page/invite/store/UserFormStore'

type Props = { user: UserFormStore }

export const UserRow = observer(({ user }: Props) => {
  const { name, email } = user
  return <Space gap={12}>
    <TextField field={name} label={i18n('label.Name')} />
    <TextField field={email} label={i18n('label.Email')} />
  </Space>
})
