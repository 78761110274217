import { makeAutoObservable } from 'mobx'
import { GroupStore } from 'saas/store/groups/GroupStore'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { onceAsync } from 'util/async'

export class GroupsStore {
  readonly company_id: string
  groups: GroupStore[] = []
  ready = false

  constructor(company_id: string) {
    makeAutoObservable(this)
    this.company_id = company_id
  }

  readonly load = onceAsync(async () => {
    const groups = await api.getItemGroups(this.company_id)
    this.groups = groups.map(group => new GroupStore(group))
  })
}

const cache = new Map<string, GroupsStore>()

export const groupsSaas = mx.ref<GroupsStore>()

export function getCompanyGroups(company_id: string) {
  const cached = cache.get(company_id)
  if (cached) return cached

  const store = new GroupsStore(company_id)
  cache.set(company_id, store)
  return store
}
