export type Listener<Event> = {
  (event: Event): void
}

export type Destructor = {
  (): void
}

export class Listeners<Event = void> {
  private listeners = new Set<Listener<Event>>()

  add(listener: Listener<Event>): Destructor {
    this.listeners.add(listener)
    return () => this.listeners.delete(listener)
  }

  remove(listener: Listener<Event>): void {
    this.listeners.delete(listener)
  }

  fire(event: Event) {
    for (let listener of this.listeners) {
      try {
        listener(event)
      } catch (e) {
        console.error('listener failed', e)
      }
    }
  }
}
