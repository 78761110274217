import { makeAutoObservable, reaction } from 'mobx'

export class PreviewStore {
  private _images: string[] = []
  private _index: number = 0

  constructor() {
    makeAutoObservable(this)
    reaction(() => this._images.length, () => this.setIndex(this.index))
  }

  get image(): string | undefined {
    return this._images[this._index]
  }

  get up(): boolean {
    return this._index > 0
  }

  get down(): boolean {
    return this._index < this._images.length - 1
  }

  get images(): string[] {
    return this._images
  }

  set images(value: string[]) {
    this._images = value
  }

  get index(): number {
    return this._index
  }

  set index(value: number) {
    this.setIndex(value)
  }

  get count(): number {
    return this._images.length
  }

  private setIndex(value: number) {
    const max = this._images.length - 1
    this._index = Math.max(0, Math.min(value, max))
  }
}
