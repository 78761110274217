import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { MouseEvent } from 'react'
import { RouteStore } from 'saas/route/RouteStore'
import css from './RouteItem.module.scss'

type Props = {
  route: RouteStore
}

export function renderRouteItems(routes: RouteStore[]) {
  return routes.map(route => <RouteItem key={route.path} route={route} />)
}

export const RouteItem = observer(({ route }: Props) => {
  const { icon, title, path, active, children, open } = route
  const empty = !children?.length
  const className = clsx(css.item, active && css.active)

  const onExpand = useLatestCallback((event: MouseEvent) => {
    const { button, altKey, ctrlKey, metaKey, shiftKey } = event
    const alt = altKey || ctrlKey || metaKey || shiftKey
    const click = button === 0 && !alt
    if (!click) return
    event.stopPropagation()
    event.preventDefault()
    route.open = !open

  })

  const button = empty ? (
    <Space className={className} gap={4}>
      {icon && <Icon name={icon} size={20} />}
      <Typo size={14}>{title}</Typo>
    </Space>
  ) : (
    <Space className={className} gap={4}>
      <div className={css.icons}>
        {icon && <Icon className={css.icon} name={icon} size={20} />}
        <IconButton small className={clsx(css.expand, open && css.open, !icon && css.force)} action={onExpand}>
          <Icon name="keyboard_arrow_down" size={20} />
        </IconButton>
        <IconButton className={clsx(css.collapse, !open && css.open, !icon && css.force)} action={onExpand}>
          <Icon name="keyboard_arrow_right" size={20} />
        </IconButton>
      </div>
      <Typo size={14}>{title}</Typo>
    </Space>
  )

  const link = <Link path={path}>{button}</Link>

  if (!children?.length) return link

  return <Vertical gap={8}>
    {link}
    {open && <Vertical pad={[0, 24]}>
      {renderRouteItems(children)}
    </Vertical>}
  </Vertical>
})
