import IconLink from 'asset/icon-link.svg'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share'
import { copyText } from 'util/browser'
import css from './SharePanel.module.scss'

export const SharePanel = () => {
  const url = location.href
  const title = document.title
  const iconSize = 48

  const onCopy = useLatestCallback(() => {
    void copyText(url)
  })

  return <div className={css.list}>
    <div className={css.item}>
      <div className={css.button} onClick={onCopy}>
        <div className={css.copy}>
          <IconLink />
        </div>
        {i18n('share.Copy')}
      </div>
    </div>
    <div className={css.item}>
      <FacebookShareButton className={css.button} url={url}>
        <FacebookIcon size={iconSize} />
        Facebook
      </FacebookShareButton>
    </div>
    <div className={css.item}>
      <TwitterShareButton className={css.button} url={url} title={title}>
        <XIcon size={iconSize} />X
      </TwitterShareButton>
    </div>
    <div className={css.item}>
      <PinterestShareButton className={css.button} url={url} description={title} media={url}>
        <PinterestIcon size={iconSize} />
        Pinterest
      </PinterestShareButton>
    </div>
    <div className={css.item}>
      <WhatsappShareButton className={css.button} url={url} title={title} separator=":: ">
        <WhatsappIcon size={iconSize} />
        WhatsApp
      </WhatsappShareButton>
    </div>
    <div className={css.item}>
      <TelegramShareButton className={css.button} url={url} title={title}>
        <TelegramIcon size={iconSize} />
        Telegram
      </TelegramShareButton>
    </div>
  </div>
}
