import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { TotalsRequest, TotalsResponse } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class TotalsStore {
  ready = false
  error = false
  totals?: TotalsResponse

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    this.ready = false
    this.error = false
    this.totals = undefined
  }

  readonly update = skipAsync(async (request: TotalsRequest) => {
    runInAction(() => {
      this.ready = false
      this.error = false
    })
    try {
      const totals = await api.getTotals(request)
      runInAction(() => {
        this.totals = totals
        this.ready = true
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })

}

export const totals = new TotalsStore()
