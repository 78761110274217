import { Checkbox } from 'component/Checkbox'
import { Radio } from 'component/Radio'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { key } from 'util/react'

export const ModesPopover = observer(() => {
  const { modes, modesCatalog, onCheckMode } = modal.editCollectionModal.it
  const { groups } = modesCatalog

  return <Space gap={16} top wrap>
    {groups.map((group, index) => (
      <Vertical key={key(index, group.title)} width={200} gap={8}>
        <Typo bold size={14}>{group.title}</Typo>
        {group.options.map((option, index) => {
          const { radio } = group
          const { value, label } = option
          const checked = modes.value.includes(value)

          if (radio) {
            return <Radio key={key(index, value)} checked={checked} data={value}
              allowUncheck onChange={onCheckMode}>
              {label}
            </Radio>
          } else {
            return <Checkbox key={key(index, value)} checked={checked} data={value}
              onChange={onCheckMode}>
              {label}
            </Checkbox>
          }
        })}
      </Vertical>
    ))}
  </Space>
})
