import { ForbiddenPage } from 'feature/page/ForbiddenPage/ForbiddenPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { CollectionPage } from 'saas/page/collection/CollectionPage'
import { CompaniesPage } from 'saas/page/companies/CompaniesPage'
import { CompanyPage } from 'saas/page/company/CompanyPage'
import { CompanyCreatePage } from 'saas/page/company/create/CompanyCreatePage'
import { CompanyManagePage } from 'saas/page/company/manage/CompanyManagePage'
import { ArticlePage } from 'saas/page/docs/ArticlePage'
import { DocsPage } from 'saas/page/docs/DocsPage'
import { ProductPage } from 'saas/page/item/ProductPage'
import { SaasProductPage } from 'saas/page/product/SaasProductPage'
import { SaasProductsPage } from 'saas/page/products/SaasProductsPage'
import { SearchPage } from 'saas/page/search/SearchPage'
import { StatisticsPage } from 'saas/page/statistics/StatisticsPage'
import { saas } from 'saas/store/SaasController'
import { can } from 'store/can'
import { route } from 'store/route'

function products() {
  return can.ManageProducts(route.unit(1))
}

export const SaasPageRouting = observer(() => {
  const { pathname } = route

  if (!saas.ready) return <LoadingPage />

  if (pathname === '/') return <StatisticsPage />
  if (pathname === '/companies') return <CompaniesPage />
  if (route.is('/companies/create') && can.CreateCompany()) return <CompanyCreatePage />
  if (route.is('/company/*/manage')) return <CompanyManagePage />
  if (route.is('/company/*/products')) return products() ? <SaasProductsPage /> : <ForbiddenPage />
  if (route.is('/company/*/product/*')) return products() ? <SaasProductPage /> : <ForbiddenPage />
  if (pathname === '/search') return <SearchPage />
  if (pathname === '/statistics') return <StatisticsPage />
  if (route.is('/company/*')) return <CompanyPage />
  if (route.is('/collection/*')) return <CollectionPage />
  if (route.is('/item/*')) return <ProductPage />
  if (route.is('/docs')) return <DocsPage />
  if (route.is('/docs/*')) return <ArticlePage />

  return <NotFoundPage />
})
