// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination-limit-3104{position:absolute;inset:0 auto auto 0}@media(width <= 480px){.Pagination-root-3104{padding-top:40px}}`, "",{"version":3,"sources":["webpack://./src/page/internal/search/Pagination.module.scss"],"names":[],"mappings":"AAAA,uBACE,iBAAA,CACA,mBAAA,CAGF,uBACE,sBACE,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limit": `Pagination-limit-3104`,
	"root": `Pagination-root-3104`
};
module.exports = ___CSS_LOADER_EXPORT___;
