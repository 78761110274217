import { getAppTitle } from 'app/config/title'
import IconShare from 'asset/icon-share.svg'
import { Modal } from 'component/Modal'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { SharePanel } from 'page/demo-catalog/SharePanel/SharePanel'
import { useState } from 'react'
import { ui } from 'store/home/ui/HomeUiStore'
import css from './ShareButton.module.scss'

export const ShareButton = observer(() => {
  const { mobile } = ui.window
  const [open, setOpen] = useState(false)

  const onShare = useLatestCallback(async () => {
    try {
      const title = getAppTitle()
      const text = ''
      const url = location.href
      const shareData: ShareData = { title, text, url }
      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData)
      }
    } catch (e) {
      console.error('share failed', e)
    }
  })

  const onClick = useLatestCallback(() => {
    if (mobile) void onShare()
    else setOpen(true)
  })

  const onClose = useLatestCallback(() => {
    setOpen(false)
  })

  return <>
    <DemoCatalogButton className={css.button} text xl icon={<IconShare />} action={onClick}>
      {i18n('share.Share')}
    </DemoCatalogButton>
    {open && <Modal width={360} onCancel={onClose}>
      <Vertical>
        <Typo size={16} medium center>{i18n('share.Share')}</Typo>
        <Gap height={16} />
        <SharePanel />
      </Vertical>
    </Modal>}
  </>
})
