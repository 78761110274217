import { makeAutoObservable, observable } from 'mobx'
import { api } from 'store/api'
import { CompanyConfig } from 'type/CompanyConfig'
import { skipAsync } from 'util/async'

export class CompanyConfigStore {
  readonly company_id: string
  json: CompanyConfig

  constructor(company_id: string, json: CompanyConfig) {
    makeAutoObservable(this, { company_id: false, json: observable.ref })
    this.company_id = company_id
    this.json = json
  }

  async save(update: CompanyConfig) {
    this.json = update
    await this.saveInner()
  }

  private readonly saveInner = skipAsync(async () => {
    await api.setCompanyConfig(this.company_id, this.json)
  })
}
