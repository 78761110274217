const KEY_ID = '6LcIxeQpAAAAAGpMNV7SPvbfwi9-nTlfBTFq0ysK'

class CaptchaController {
  private _ready?: Promise<void>

  async render(container: HTMLElement, callback: (token: string) => void) {
    await this.ready
    grecaptcha.enterprise.render(container, {
      sitekey: KEY_ID,
      theme: 'light',
      callback,
    })
  }

  private get ready(): Promise<void> {
    if (this._ready) return this._ready
    return this._ready = new Promise(resolve => {
      const onload = () => {
        grecaptcha.enterprise.ready(() => resolve())
      }
      const src = `https://www.google.com/recaptcha/enterprise.js`
      const element = document.createElement('script')
      element.src = src
      element.async = true
      element.onload = onload
      document.head.appendChild(element)
    })
  }
}

export const captcha = new CaptchaController()
