import { production } from 'app/config/constants'
import { Button } from 'component/Button'
import { Link } from 'component/Link'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Page } from 'saas/screen/Page'

export const DocsPage = observer(() => {
  const icon = <Icon name="article" size={20} />

  return <Page>
    <Vertical gap={16}>
      <Typo size={18} bold>
        {i18n('title.Docs')}
      </Typo>
      <Vertical gap={12}>
        <Link path="/docs/api">
          <Button icon={icon} width={200} large left>
            {i18n('title.Api')}
          </Button>
        </Link>
        <Link path="/docs/widget">
          <Button icon={icon} width={200} large left>
            {i18n('title.Widget')}
          </Button>
        </Link>
        {!production && <Link path="/docs/new.api">
          <Button icon={icon} width={200} large left>
            {i18n('title.NewApi')}
          </Button>
        </Link>}
      </Vertical>
    </Vertical>
  </Page>
})
