import { Product } from 'type/product/Product'
import { ProductsConfig, UnknownProduct } from 'type/product/ProductsConfig'
import { STAND, TRY_ON } from 'type/product/ProductType'
import { StandProduct } from 'type/product/StandProduct'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { notNil } from 'util/null'

export function extractProduct(json: UnknownProduct): Product | undefined {
  const { company_id, product_id, type } = json
  if (!company_id || !product_id || !type) {
    console.error('bad product', json)
    return undefined
  }
  switch (type) {
    case TRY_ON:
      return json as TryOnProduct
    case STAND:
      return json as StandProduct
    default:
      console.error('unknown product', json)
      return undefined
  }
}

export function extractProducts(products: ProductsConfig): Product[] {
  if (!products.length) return []
  return products.map(extractProduct).filter(notNil)
}

export function addProduct(products: ProductsConfig, product: Product): ProductsConfig {
  return [product, ...products]
}

export function updateProduct(products: ProductsConfig, product: Product): ProductsConfig {
  const index = products.findIndex(p => p.product_id === product.product_id)
  if (index < 0) {
    console.error('no product', product.product_id)
    return products
  }
  products = [...products]
  products[index] = product
  return products
}

export function deleteProduct(products: ProductsConfig, product: Product): ProductsConfig {
  const index = products.findIndex(p => p.product_id === product.product_id)
  if (index < 0) {
    console.error('no product', product.product_id)
    return products
  }
  products = [...products]
  products.splice(index, 1)
  return products
}
