import { reaction, runInAction } from 'mobx'
import { chart } from 'saas/store/stat/ChartStore'
import { statistics } from 'saas/store/stat/StatisticsStore'
import { totals } from 'saas/store/stat/TotalsStore'
import { home } from 'store/home'
import { me } from 'store/me'
import { TotalsRequest } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class StatisticsController {
  company_id?: string

  constructor() {
    reaction(this.buildRequest, this.update)
  }

  init = skipAsync(async () => {
    const company_id = home.company.optional?.company_id
    if (this.company_id === company_id) return
    this.company_id = company_id

    runInAction(() => {
      totals.reset()
      chart.reset()
      statistics.reset(company_id)
    })

    await statistics.load(company_id)
  })

  private readonly buildRequest = (): TotalsRequest | undefined => {
    if (!me.present) return undefined
    const { user_id } = me.user
    const { company_id, interval } = statistics
    if (!company_id) return undefined
    const from = interval.start.format('YYYY-MM-DD')
    const to = interval.end.format('YYYY-MM-DD')
    return { user_id, company_id, from, to }
  }

  private readonly update = skipAsync(async (request: TotalsRequest | undefined) => {
    if (!request) return

    const _chart = chart.update(request)
    const _totals = totals.update(request)
    await _chart
    await _totals
  })
}

export const statisticsController = new StatisticsController()
