import { CopyButton } from 'component/Button/CopyButton'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { StatusSwitch } from 'saas/page/product/stand/Details/StatusSwitch'
import { stand } from 'saas/store/product/ProductSaasController'

export const Details = observer(() => {
  const { product_id, json } = stand.it
  const { published } = json
  const linkFull = location.origin + `/stand/${product_id}`
  const linkShort = location.origin + `/stand/${product_id.substring(0, 8)}`

  return <Vertical>
    <StatusSwitch />
    <Gap height={16} />
    <Typo size={14}>{i18n('label.Link')}</Typo>
    <Gap height={4} />
    <Space gap={8}>
      <Link href={linkFull} disabled={!published}>{linkFull}</Link>
      <CopyButton data={linkFull} />
    </Space>
    <Gap height={16} />
    <Typo size={14}>{i18n('label.ShortLink')}</Typo>
    <Gap height={4} />
    <Space gap={8}>
      <Link href={linkShort} disabled={!published}>{linkShort}</Link>
      <CopyButton data={linkShort} />
    </Space>
    <Gap height={4} />
    <Typo size={12} secondary>
      {i18n('stand.ShortLinkCanHaveConflicts')}
    </Typo>
  </Vertical>
})
