import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { Preview } from 'component/Image'
import { FitImage } from 'component/Image/FitImage'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { can } from 'store/can'
import { home } from 'store/home'
import { ProductItem } from 'store/product/ProductItem'
import css from './ItemCard.module.scss'

type Props = {
  item: ProductItem
}

export const ItemCard = observer(({ item }: Props) => {
  if (item.store.present) return <Good item={item} />
  if (item.stub.present) return <Stub item={item} />
  return null
})

const Stub = observer(({ item }: Props) => {
  const { product_name } = item.stub.it

  return <Card center width={128} pic className={css.card}>
    <div className={css.title}>
      <Typo size={12} ellipsis title={product_name}>
        {product_name}
      </Typo>
    </div>
  </Card>
})

const Good = observer(({ item }: Props) => {
  const store = item.store.it

  const { companyId, visible } = store
  const { images, product_name } = store.data

  const image = images[0]
  const active = store === home.product.optional

  const control = usePreviewControl()

  const onOpen = useLatestCallback(async () => {
    await home.openProduct(store)
  })

  const onPreview = useLatestCallback(() => {
    control.setPreview(image)
  })

  const onDelete = useLatestCallback(async () => {
    if (modal.openDeleteProductModal(item)) return
    await home.deleteProduct(item)
  })

  return <Card center width={128} pic active={active} action={onOpen} className={css.card}>
    <Glass fill>
      {image && <FitImage src={image} />}
      <Sticker pin={[4, null, null, 4]} highlight>
        <Space width={23} height={23} center>
          <Icon size={12} name={visible ? 'visibility' : 'visibility_off'} />
        </Space>
      </Sticker>
      <Sticker pin={[4, 4, null, null]} hidden vertical>
        <IconButton small action={onPreview} disabled={images.length === 0}>
          <Icon name="open_in_full" />
        </IconButton>
        {can.DeleteProducts(companyId) && <IconButton small action={onDelete}>
          <Icon name="delete" />
        </IconButton>}
      </Sticker>
    </Glass>
    <Preview control={control} sources={images} />
    <div className={css.title}>
      <Typo size={12} ellipsis title={product_name}>
        {product_name}
      </Typo>
    </div>
  </Card>
})
