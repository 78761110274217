type Callback = () => void

type Transform<From, To> = (from: From) => To | PromiseLike<To>

export interface Cancellable<Value = unknown> extends Promise<Value> {
  readonly canceled: boolean
  cancel(): void
  canceling(callback: Callback): this
  final(callback: Callback): Cancellable<Value>
  process<Another>(transform: Transform<Value, Another>): Cancellable<Another>
  error<Another>(transform: Transform<unknown, Another>): Cancellable<Another>
}

export function cancelable<Value>(promise: Promise<Value>): Cancellable<Value> {
  if ('cancel' in promise) return promise as Cancellable<Value>

  // fields
  const cancels: Callback[] = []
  const finals: Callback[] = []
  let done = false
  let canceled = false

  // this
  const self = new Promise((resolve, reject) => {
    promise.finally(() => {
      done = true
      if (!canceled) fireFinal()
    }).then(
      (data) => {
        if (!canceled) resolve(data)
      },
      (error) => {
        if (!canceled) reject(error)
      },
    )
  }) as Cancellable<Value>

  // functions
  function fireCancel() {
    for (const callback of cancels) {
      callback()
    }
  }
  function fireFinal() {
    for (const callback of finals) {
      callback()
    }
  }

  // methods
  function cancel(): void {
    if (canceled || done) return
    canceled = true
    fireCancel()
    fireFinal()
  }
  function canceling(callback: Callback): Cancellable<Value> {
    cancels.push(callback)
    return self
  }
  function final(callback: Callback): Cancellable<Value> {
    finals.push(callback)
    return self
  }
  function process<Another>(transform: Transform<Value, Another>): Cancellable<Another> {
    const promise = self.then(transform)
    return cancelable(promise).canceling(() => self.cancel())
  }
  function error<Another>(transform: Transform<unknown, Another>): Cancellable {
    const promise = self.catch(transform)
    return cancelable(promise).canceling(() => self.cancel())
  }

  // public
  Object.defineProperty(self, 'canceled', { get: () => canceled })
  Object.defineProperty(self, 'cancel', { get: () => cancel })
  Object.defineProperty(self, 'canceling', { get: () => canceling })
  Object.defineProperty(self, 'final', { get: () => final })
  Object.defineProperty(self, 'process', { get: () => process })
  Object.defineProperty(self, 'error', { get: () => error })

  return self
}
