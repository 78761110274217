import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { IconInput } from 'saas/page/product/stand/IconInput/IconInput'
import { LogoInput } from 'saas/page/product/stand/LogoInput/LogoInput'
import { ShopButtonInput } from 'saas/page/product/stand/SettingsCard/ShopButtonInput'
import { ShopUrlInput } from 'saas/page/product/stand/SettingsCard/ShopUrlInput'
import { stand } from 'saas/store/product/ProductSaasController'

export const PageSettings = observer(() => {
  const { edit } = stand.it
  const { title } = edit

  return <>
    <Typo size={14} bold>{i18n('stand.Page')}</Typo>
    <Gap height={12} />
    <TextField field={title} label={i18n('stand.Title')} placeholder="" />
    <Gap height={12} />
    <ShopUrlInput />
    <Gap height={12} />
    <ShopButtonInput />
    <Gap height={8} />
    <LogoInput />
    <Gap height={8} />
    <IconInput />
  </>
})
