// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Captcha-captcha-8bff{position:relative;box-sizing:border-box;width:300px;max-width:calc(100vw - 64px);height:72px;margin:auto;border-radius:4px;border:1px solid var(--ui-border-color);overflow:hidden}.Captcha-captcha-8bff>div{margin:-2px}.Captcha-captcha-8bff.Captcha-error-8bff{border-color:var(--ui-border-color-error)}`, "",{"version":3,"sources":["webpack://./src/component/Captcha/Captcha.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,WAAA,CACA,4BAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CACA,uCAAA,CACA,eAAA,CAEA,0BACE,WAAA,CAIJ,yCACE,yCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"captcha": `Captcha-captcha-8bff`,
	"error": `Captcha-error-8bff`
};
module.exports = ___CSS_LOADER_EXPORT___;
