import { CopyButton } from 'component/Button/CopyButton'
import { IconButton } from 'component/Button/IconButton'
import { Image } from 'component/Image'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { memberEdit, MemberEditStore } from 'saas/store/MemberEditStore'
import { MemberStore } from 'saas/store/MemberStore'
import { can } from 'store/can'
import { getMemberRoleLabel } from 'type/MemberRole'
import { compact } from 'util/array'
import { formatDate, parseDate } from 'util/date'
import { delay } from 'util/promise'
import { emdash, space } from 'util/typo'
import css from './MemberRow.module.scss'

type Props = {
  member: MemberStore
}

export const MemberRow = observer(({ member }: Props) => {
  const { company } = companyEdit.it
  const { company_id } = company
  const {
    user_name, created_at, last_update, last_request,
    user_email, user_phone, user_descr, user_pic, roles,
  } = member

  const invite = parseDate(created_at)
  const sign_up = parseDate(last_update)
  const sign_in = parseDate(last_request)

  const canEdit = can.ManageUsersAndRoles(company_id)
  const canDelete = can.DeleteMembers(company_id)
  const more = canEdit || canDelete

  const actions: DropdownItem[] = compact([
    canEdit && {
      key: 'open',
      label: i18n('member.Edit'),
      async action() {
        await delay(100)
        memberEdit.it = new MemberEditStore(company, member)
      },
    },
    canDelete && {
      key: 'delete',
      label: i18n('member.Delete'),
      async action() {
        await delay(100)
        companyEdit.it.memberDeleting = member
      },
    },
  ])

  return <tr>
    <td>
      {user_pic && <Space width={64}>
        <Space className={css.picture}>
          <Image src={user_pic} />
        </Space>
      </Space>}
      {!user_pic && <div className={css.blank} />}
    </td>
    <td>
      <Vertical gap={8}>
        <Vertical>
          <Typo size={16}>{user_name}</Typo>
          {user_email && <Typo size={14} secondary>
            <Icon name="mail" />
            {space}
            {user_email}
            {space}
            <CopyButton data={user_email} />
          </Typo>}
          {user_phone && <Typo size={14} secondary>
            <Icon name="call" />
            {space}
            {user_phone}
            {space}
            <CopyButton data={user_phone} />
          </Typo>}
        </Vertical>
        <Typo size={12}>{user_descr}</Typo>
      </Vertical>
    </td>
    <td>
      <Vertical gap={2}>
        <Typo size={14}>{formatDate(invite, 'YYYY-MM-DD')}</Typo>
        <Typo size={12} secondary>{formatDate(invite, 'HH:mm:ss')}</Typo>
      </Vertical>
    </td>
    <td>
      <Vertical gap={2}>
        <Typo size={14}>{formatDate(sign_up, 'YYYY-MM-DD')}</Typo>
        <Typo size={12} secondary>{formatDate(sign_up, 'HH:mm:ss')}</Typo>
      </Vertical>
    </td>
    <td>
      <Vertical gap={2}>
        <Typo size={14}>{formatDate(sign_in, 'YYYY-MM-DD')}</Typo>
        <Typo size={12} secondary>{formatDate(sign_in, 'HH:mm:ss')}</Typo>
      </Vertical>
    </td>
    <td>
      <Space top opposite>
        <Vertical gap={4} wide>
          {!roles.length && <Typo size={14} secondary>
            {emdash}
          </Typo>}
          {roles.map(role => <Typo key={role} size={14}>
            {getMemberRoleLabel(role)}
          </Typo>)}
        </Vertical>
        {more && <Space margin={[-4, 0, 0, 0]}>
          <Dropdown items={actions} placement="bottomRight">
            <IconButton><Icon name="more_horiz" /></IconButton>
          </Dropdown>
        </Space>}
      </Space>
    </td>
  </tr>
})
