import { Button } from 'component/Button'
import { ClickAction } from 'hook/useOnClickAction'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import css from './Back.module.scss'

type Props = {
  action?: ClickAction | false
}

export const Back = ({ action }: Props) => {
  return <Button className={css.button} link
    action={action} icon={<Icon name="arrow_back" />}>
    {i18n('common.Back')}
  </Button>
}
