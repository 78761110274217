import { action, makeObservable, observable } from 'mobx'

export class MxBox<V> {
  private value: V

  constructor(value: V) {
    this.value = value
    makeObservable<this, 'value'>(this, {
      value: observable.ref,
    })
  }

  get it(): V {
    return this.value
  }

  set it(value: V) {
    this.set(value)
  }

  private set = action((value: V) => {
    this.value = value
  })
}
