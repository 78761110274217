import { useLatestCallback } from 'hook/useLatestCallback'
import { useState } from 'react'

type Props = {
  onFocus?(focus: boolean): unknown
}

type Focus = {
  focus: boolean
  onFocus(): void
  onBlur(): void
}

export const useFocus = (props: Props): Focus => {
  const [focus, setFocus] = useState(false)

  const onFocus = useLatestCallback(() => {
    setFocus(true)
    props.onFocus?.(true)
  })

  const onBlur = useLatestCallback(() => {
    setFocus(false)
    props.onFocus?.(false)
  })

  return { focus, onFocus, onBlur }
}
