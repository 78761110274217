import PlusSvg from 'asset/plus-circle.svg'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { CreatorLogo } from 'section/Head/creator/CreatorLogo'
import { ProfileButton } from 'section/Head/ProfileButton'
import { can } from 'store/can'
import { home } from 'store/home'

export const CreatorHead = observer(() => {
  const { company_id } = home

  const onCreate = useLatestCallback(async () => {
    await modal.openCreateCollectionModal()
  })

  return <Space gap={16} opposite>
    <Space gap={16}>
      <Space width={240}>
        <CreatorLogo />
      </Space>

      {can.CreateCollections(company_id) && <Button large action={onCreate}
        icon={<PlusSvg width={24} height={24} />}>
        <Typo size={14} bold>{i18n('header.CreateNewCollection')}</Typo>
      </Button>}

      <Typo size={12}>
        {i18n('header.GeneratedCountOfTotalPhotos', {
          count: 0,
          total: 1000,
          c: <Typo size={16} bold />,
          t: <Typo size={12} bold />,
        })}
      </Typo>
    </Space>

    <ProfileButton />
  </Space>
})
