type Call<V> = () => Promise<V>
type CallArg<V, Args extends unknown[]> = (...args: Args) => Promise<V>

export function minAsync<V = void>(call: Call<V>): Call<V> {
  let promise: Promise<V> | undefined

  return () => {
    if (!promise) {
      promise = call()
      promise.finally(() => promise = undefined)
    }
    return promise
  }
}

export function strongAsync<V = void>(call: Call<V>): Call<V> {
  let current: Promise<V> | undefined
  let next: Promise<V> | undefined

  function go() {
    if (!current) {
      next = undefined
      current = call()
      current.finally(() => current = undefined)
      return current
    }
    if (!next) next = current.finally(() => go())
    return next
  }

  return go
}

export function onceAsync<V = void>(call: Call<V>): Call<V> {
  let promise: Promise<V> | undefined

  return () => {
    if (!promise) promise = call()
    return promise
  }
}

export function skipAsync<V, Args extends unknown[]>(call: CallArg<V, Args>): CallArg<V, Args> {
  let current: Promise<V> | undefined
  let next: Promise<V> | undefined
  let latest: Args | undefined

  function go(...args: Args) {
    if (!current) {
      next = undefined
      latest = undefined
      current = call(...args)
      current.finally(() => current = undefined)
      return current
    }
    if (!next) next = current.finally(() => latest && go(...latest))
    latest = args
    return next
  }

  return go
}
