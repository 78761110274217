// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnknownPage-error-5eb1{display:flex;align-items:center;justify-content:center;width:100%;height:100%;animation:1s ease-in-out UnknownPage-appearance-5eb1}@keyframes UnknownPage-appearance-5eb1{from{opacity:0}to{opacity:1}}`, "",{"version":3,"sources":["webpack://./src/feature/page/UnknownPage/UnknownPage.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,oDAAA,CAGF,uCACE,KACE,SAAA,CAGF,GACE,SAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `UnknownPage-error-5eb1`,
	"appearance": `UnknownPage-appearance-5eb1`
};
module.exports = ___CSS_LOADER_EXPORT___;
