import { FormStore } from 'form/store/FormStore'
import { ImageFile } from 'store/base/ImageFile'
import { mx } from 'store/base/mx'
import { ObjectStore } from 'store/base/ObjectStore'
import { StringStore } from 'store/base/StringStore'
import { ShapeType } from 'type/Source'

export class GenerationForm {
  private _open = mx.box(false)

  readonly form = new FormStore()

  readonly image = this.form.field(new ObjectStore<ImageFile>(), { required: true })
  readonly shape = this.form.field(new ObjectStore<ShapeType>('full'), { required: true })
  readonly prompt = this.form.field(new StringStore(''))

  get open(): boolean {
    return this._open.it
  }

  set open(value: boolean) {
    this._open.it = value
  }
}
