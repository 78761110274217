import { notice } from 'app/notice'
import { IconButton } from 'component/Button/IconButton'
import { Field } from 'component/Field'
import { Glass, Sticker } from 'component/Glass'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { copyText } from 'util/browser'

interface UidInputProps {
  uid: string
}

export const UidInput = observer(({ uid }: UidInputProps) => {
  const onCopy = useLatestCallback(async () => {
    await copyText(uid)
    notice.success(i18n('message.Copied'))
  })

  return <Glass>
    <Field label={i18n('label.Id')}>
      <Input value={uid} readonly />
    </Field>
    <Sticker pin={[8, 8, 0, null]}>
      <IconButton small action={onCopy}>
        <Icon name="content_copy" secondary />
      </IconButton>
    </Sticker>
  </Glass>
})
