import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import css from 'saas/screen/sidebar/MenuButton.module.scss'
import { sidebar } from 'saas/store/SidebarStore'

export const MenuButton = observer(() => {
  const { open, start } = sidebar
  const className = clsx(css.button, open ? css.open : css.close, start ? css.right : css.left)

  const onMenu = useLatestCallback(() => {
    sidebar.open = !open
  })

  return <IconButton className={className} link secondary action={onMenu}>
    <Icon className={css.icon} name="menu" size={24} />
    <Icon className={clsx(css.expand, open && css.show)}
      name="keyboard_double_arrow_left" size={24} />
    <Icon className={clsx(css.collapse, !open && css.show)}
      name="keyboard_double_arrow_right" size={24} />
  </IconButton>
})
