export type CompanyConfig = {
  webhook?: string
}

export type WebhookUpdate = {
  webhook?: string
}

export function applyWebhookUpdate(config: CompanyConfig, update: WebhookUpdate): CompanyConfig {
  return { ...config, ...update }
}
