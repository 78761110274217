import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { route } from 'store/route'
import { DemoCollection, DemoCompany, DemoProduct } from 'type/Demo'
import { onceAsync } from 'util/async'
import { comparingKnownStrings } from 'util/string'

const compareTabs = comparingKnownStrings(['female', 'male'], [''])

function extractTab(it: DemoProduct): string {
  return it.dress_gender?.toLowerCase().trim() || ''
}

class DemoStore {
  ready = false
  error = false
  tabs: string[] = []
  tab: string = 'female'
  company?: DemoCompany
  collection?: DemoCollection
  items: DemoProduct[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setTab(tab: string) {
    this.tab = tab
  }

  get itemsByTab(): Record<string, DemoProduct[]> {
    const record: Record<string, DemoProduct[]> = {}
    for (const it of this.items) {
      const tab = extractTab(it)
      const value = record[tab] ??= []
      value.push(it)
    }
    return record
  }

  readonly init = onceAsync(async () => {
    try {
      const slug = route.unit(1)
      if (!slug) throw new Error('no slug')
      await this.load(slug)
      runInAction(() => this.ready = true)
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })

  private async load(slug: string) {
    const json = await api.getDemo(slug)
    const { company, collection, items } = json
    const tabs = Array.from(new Set(items.map(it => extractTab(it)).sort(compareTabs)))
    const tab = tabs[0] ?? ''
    runInAction(() => {
      this.company = company
      this.collection = collection
      this.items = items.reverse()
      this.tabs = tabs
      this.tab = tab
    })
  }
}

export const demo = new DemoStore()
