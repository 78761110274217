import { mx } from 'store/base/mx'
import { can } from 'store/can'
import { CommentsStore } from 'store/comment/CommentsStore'
import { home } from 'store/home'
import { ProductStore } from 'store/product'

class CommentingStore {
  private readonly _product = mx.ref<ProductStore>()

  async open(product: ProductStore) {
    this._product.it = product
  }

  close() {
    this._product.it = undefined
  }

  get inline(): boolean {
    const { company_id } = home
    // show comments inline
    return !this.detached && !can.AddAndEditProducts(company_id)
  }

  get detached(): ProductStore | undefined {
    return home.product.optional ? undefined : this._product.optional
  }

  get collapsable(): boolean {
    return !this.detached && this.comments.small
  }

  get product(): ProductStore {
    return home.product.optional ?? this._product.it
  }

  get comments(): CommentsStore {
    return this.product.comments
  }

  active(product: ProductStore) {
    return this._product.optional === product
  }
}

export const commenting = new CommentingStore()
