import { Image as ImageAntd } from 'antd'
import { clsx } from 'clsx'
import { Preview } from 'component/Image/Preview'
import { PreviewControl, usePreviewControl } from 'component/Image/PreviewControl'
import { useLatestCallback } from 'hook/useLatestCallback'
import { mutable } from 'util/type'
import css from './Image.module.scss'

type Props = {
  className?: string
  contain?: boolean
  sources?: readonly string[]
  src: string
  control?: PreviewControl
}

export const Image = (props: Props) => {
  const { className, contain, sources, src } = props
  const pointer = !!sources || !!props.control
  const classes = clsx(css.image, contain && css.contain, pointer && css.pointer)
  const control = usePreviewControl()
  const inner = sources && !props.control

  const onClick = useLatestCallback(() => {
    if (props.control) props.control.setPreview(src)
    else if (sources) control.setPreview(src)
  })

  return <>
    <ImageAntd wrapperClassName={classes} className={className} src={src} preview={false} onClick={onClick} />
    {inner && <Preview control={control} sources={mutable(sources)} />}
  </>
}
