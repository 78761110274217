import { makeAutoObservable } from 'mobx'

export class ObjectStore<Value> {
  private _value: Value | undefined

  constructor(value?: Value) {
    this._value = value
    makeAutoObservable(this)
  }

  get value(): Value | undefined {
    return this._value
  }

  set value(value: Value | undefined) {
    this._value = value
  }

  get empty(): boolean {
    return this._value == null
  }

  readonly onChange = (value: Value | null | undefined) => {
    this._value = value ?? undefined
  }
}
