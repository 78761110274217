// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton-button-fea4{position:relative;padding:0;line-height:0;width:32px;font-size:16px}.IconButton-button-fea4:not(:disabled):focus-visible{outline-width:1px;outline-offset:-1px}.IconButton-small-fea4{width:24px;font-size:12px}.IconButton-large-fea4{width:40px;font-size:20px}.IconButton-button-fea4.ant-btn-loading{cursor:wait}.IconButton-button-fea4.IconButton-light-fea4{--ant-color-text: var(--ui-background-color);--ant-color-text-disabled: color-mix(in srgb, transparent, var(--ui-background-color) 10%);--ant-button-text-hover-bg: color-mix(in srgb, transparent, var(--ui-background-color) 6%)}.IconButton-tertiary-fea4{--ant-color-text: var(--ui-text-color-tertiary)}.IconButton-secondary-fea4{--ant-color-text: var(--ui-text-color-secondary)}.IconButton-primary-fea4{--ant-color-text: var(--ui-primary-color)}.IconButton-success-fea4{--ant-color-text: var(--ui-text-color-success)}.IconButton-error-fea4{--ant-color-text: var(--ui-text-color-error)}`, "",{"version":3,"sources":["webpack://./src/component/Button/IconButton.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,SAAA,CACA,aAAA,CACA,UAAA,CACA,cAAA,CAGF,qDACE,iBAAA,CACA,mBAAA,CAGF,uBACE,UAAA,CACA,cAAA,CAGF,uBACE,UAAA,CACA,cAAA,CAGF,wCACE,WAAA,CAGF,8CACE,4CAAA,CACA,0FAAA,CACA,0FAAA,CAGF,0BACE,+CAAA,CAGF,2BACE,gDAAA,CAGF,yBACE,yCAAA,CAGF,yBACE,8CAAA,CAGF,uBACE,4CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `IconButton-button-fea4`,
	"small": `IconButton-small-fea4`,
	"large": `IconButton-large-fea4`,
	"light": `IconButton-light-fea4`,
	"tertiary": `IconButton-tertiary-fea4`,
	"secondary": `IconButton-secondary-fea4`,
	"primary": `IconButton-primary-fea4`,
	"success": `IconButton-success-fea4`,
	"error": `IconButton-error-fea4`
};
module.exports = ___CSS_LOADER_EXPORT___;
