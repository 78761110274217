import { clsx } from 'clsx'
import { CopyButton } from 'component/Button/CopyButton'
import { Image, Preview } from 'component/Image'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { InferenceImageStore } from 'store/inference/InferenceImageStore'
import { InferenceItem } from 'store/inference/InferenceItem'
import { InferenceImageTags } from 'type/Inference'
import { compact } from 'util/array'
import css from './InferenceImageToolbar.module.scss'

type Props = {
  inference: InferenceItem
  image: InferenceImageStore
}

function getSources(tags: InferenceImageTags | undefined): string[] {
  const source = tags?.info?.source_url
  const pose = tags?.info?.pose_url
  const id = tags?.data?.identity_id
  const identity = home.profile.optional?.identities?.find(i => i.id === id)
  const face = identity?.json?.face1_url ?? identity?.json?.face2_url
  return compact([source, pose, face])
}

export const InferenceImageToolbar = observer(({ image }: Props) => {
  const tags = image.json?.showoff_tags
  const prompt = tags?.tags?.dress_prompt ?? ''
  const json = JSON.stringify(tags, null, 2)
  const sources = getSources(tags)
  const control = usePreviewControl()

  useEffectOnce(() => {
    void home.loadProfile()
  })

  return <>
    <div className={clsx(css.card, css.prompt)}>
      <Space opposite gap={16}>
        <Typo size={14} bold>Prompt</Typo>
        <CopyButton data={prompt} />
      </Space>
      <div className={css.text}>{prompt}</div>
    </div>

    <div className={clsx(css.card, css.tags)}>
      <Space opposite gap={16}>
        <Typo size={14} bold>Tags</Typo>
        <CopyButton data={json} />
      </Space>
      <div className={css.pre}>{json}</div>
    </div>

    <div className={css.sources}>
      {sources.map((source, index) => <div key={index} className={css.source}>
        <Image src={source} control={control} />
      </div>)}
      <Preview control={control} sources={sources} />
    </div>
  </>
})
