import { IconButton } from 'component/Button/IconButton'
import { Glass, Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { getCommentContent } from 'page/internal/comments/CommentContent'
import { commenting } from 'store/comment/CommentingStore'
import { CommentStore, ScrollStamp } from 'store/comment/CommentStore'
import { emdash } from 'util/typo'
import css from './CommentPreview.module.scss'

type Props = {
  type: 'reply' | 'edit'
  comment: CommentStore
}

export const CommentPreview = observer(({ comment, type }: Props) => {
  const store = commenting.comments
  const content = getCommentContent(comment)

  const onSelect = useLatestCallback(() => {
    store.selected = comment
    comment.scroll = new ScrollStamp()
  })

  const onCancel = useLatestCallback(() => {
    if (store.editing) store.editing = undefined
    else store.replyTo = undefined
  })

  return <Glass>
    <Space className={css.preview} gap={8} pad={[0, 12]} height={32} onClick={onSelect}>
      <Icon size={12} name={type} primary />
      <Typo size={12} bold>{comment.user_name ?? emdash}</Typo>
      <Typo size={12} ellipsis>{content}</Typo>
    </Space>
    <Sticker pin={[0, 4, 0, null]} hidden>
      <IconButton link small action={onCancel}>
        <Icon name="close" size={16} secondary />
      </IconButton>
    </Sticker>
  </Glass>
})
