// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemsList-list-0fcd{transition:opacity 300ms ease-in-out}.ItemsList-busy-0fcd{opacity:0;transition:opacity 1s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/page/internal/search/ItemsList.module.scss"],"names":[],"mappings":"AAAA,qBACE,oCAAA,CAGF,qBACE,SAAA,CACA,iCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `ItemsList-list-0fcd`,
	"busy": `ItemsList-busy-0fcd`
};
module.exports = ___CSS_LOADER_EXPORT___;
