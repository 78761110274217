import { makeObservable } from 'mobx'
import { DuplicateErrorsStore } from 'modal/UploadCollectionModal/store/DuplicateErrorsStore'
import { ItemErrorsStore } from 'modal/UploadCollectionModal/store/ItemErrorsStore'
import { UploadItem } from 'modal/UploadCollectionModal/store/UploadItem'
import { UploadParser } from 'modal/UploadCollectionModal/store/UploadParser'

export class UploadDataStore {
  readonly no_item_id: ItemErrorsStore
  readonly existing: ItemErrorsStore
  readonly no_url: ItemErrorsStore
  readonly bad_url: ItemErrorsStore
  readonly duplicates: DuplicateErrorsStore
  readonly good: readonly  UploadItem[]

  constructor(data: UploadParser) {
    this.no_item_id = new ItemErrorsStore(data.no_item_id)
    this.existing = new ItemErrorsStore(data.existing)
    this.no_url = new ItemErrorsStore(data.no_url)
    this.bad_url = new ItemErrorsStore(data.bad_url)
    this.duplicates = new DuplicateErrorsStore(data)
    this.good = data.good
    makeObservable(this, { result: true })
  }

  get error(): boolean {
    const bad = this.no_item_id.items.length + this.existing.items.length
      + this.no_url.items.length + +this.bad_url.items.length + this.duplicates.items.length
    return bad > 0
  }

  get empty(): boolean {
    return !this.error && !this.good.length
  }

  get result(): UploadItem[] {
    return [...this.duplicates.result, ...this.good]
  }
}
