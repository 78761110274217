// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagerHead-top-2c9f{flex-direction:row}.ManagerHead-left-2c9f{flex:0 1 auto}.ManagerHead-right-2c9f{flex:1 1 auto;flex-direction:row-reverse}.ManagerHead-tab-2c9f{max-width:calc(100vw - 48px)}`, "",{"version":3,"sources":["webpack://./src/section/Head/manager/ManagerHead.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,uBACE,aAAA,CAGF,wBACE,aAAA,CACA,0BAAA,CAGF,sBACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": `ManagerHead-top-2c9f`,
	"left": `ManagerHead-left-2c9f`,
	"right": `ManagerHead-right-2c9f`,
	"tab": `ManagerHead-tab-2c9f`
};
module.exports = ___CSS_LOADER_EXPORT___;
