import { FormStore } from 'form/store/FormStore'
import { mx } from 'store/base/mx'
import { StringStore } from 'store/base/StringStore'
import { CollectionStore } from 'store/collection'
import { home } from 'store/home'

export const DESCRIPTION_LIMIT = 250

export class CatalogStepStore {
  readonly form = new FormStore()

  readonly catalog_name = this.form.field(new StringStore(''), { required: true })
  readonly website = this.form.field(new StringStore(''))
  readonly description = this.form.field(new StringStore(''))

  private _create = mx.box(false)
  private _collection = mx.box<CollectionStore>()

  constructor() {
    this._create.it = !this.collections.length
  }

  async next() {
    if (this.create) {
      this.form.check()
    } else {
      await this.collection?.loadItems()
    }
  }

  get done(): boolean {
    if (this.create) return !this.form.error
    else return !!this.collection?.items.present
  }

  get collections(): readonly CollectionStore[] {
    return home.collections.optional?.collections.value ?? []
  }

  get create(): boolean {
    return this._create.it
  }

  set create(value: boolean) {
    this._create.it = value
  }

  get collection(): CollectionStore | undefined {
    return this._collection.it
  }

  set collection(value: CollectionStore | undefined) {
    this._collection.it = value
  }
}
