import { useEffect, useRef } from 'react'

const isTouchDevice = () => {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch {
    return false
  }
}

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    const type = isTouchDevice() ? 'touchend' : 'mouseup'
    document.addEventListener(type, handleClickOutside)
    return () => document.removeEventListener(type, handleClickOutside)
  }, [callback])

  return ref
}
