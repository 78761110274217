import PlusSvg from 'asset/plus-circle.svg'
import { Glass, Sticker } from 'component/Glass'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageFile } from 'store/base/ImageFile'
import { home } from 'store/home'

export const AddImageCard = observer(() => {
  const { images } = home.product.it.form
  const { error } = images

  const onFile = useLatestCallback((file: File) => {
    images.data.add(ImageFile.fromFile(file))
    images.touch = true
  })

  return <Glass>
    <Upload accept="image" multiple width={100} pic error={error} onFile={onFile}>
      <PlusSvg width={24} height={24} />
    </Upload>
    <Sticker none pin={[null, 0, 8, 0]}>
      <Typo size={12} secondary pre center>
        {i18n('item.AddImage')}
      </Typo>
    </Sticker>
  </Glass>
})
