import { IconButton } from 'component/Button/IconButton'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'
import { emdash } from 'util/typo'

export const WebhookSection = observer(() => {
  const { company_id, company, webhook } = companyEdit.it
  const data = company.config.json.webhook
  const form = webhook.present
  const canEdit = can.ManageApiKeys(company_id)

  const onChange = useLatestCallback((value: string) => {
    webhook.it.webhook = value
  })

  const onEdit = useLatestCallback(() => {
    companyEdit.it.openWebhookEdit()
  })

  const onSave = useLatestCallback(async () => {
    await companyEdit.it.saveWebhook()
  })

  const onCancel = useLatestCallback(() => {
    companyEdit.it.closeWebhookEdit()
  })

  return <Vertical gap={16} wide>
    <Typo size={16} bold>
      {i18n('company.Webhook')}
    </Typo>
    {!form && <Space gap={16} height={32}>
      <Space flex="1" oh>
        <Typo size={14} ellipsis secondary={!data}>
          {data || emdash}
        </Typo>
      </Space>
      <Space>
        {canEdit && <IconButton small action={onEdit}>
          <Icon name="edit" size={16} />
        </IconButton>}
      </Space>
    </Space>}
    {form && <Space gap={12} height={32} opposite>
      <Space flex="1">
        <Input wide value={webhook.it.webhook} onChange={onChange} />
      </Space>
      <Space>
        <IconButton small action={onSave}>
          <Icon name="check" size={16} />
        </IconButton>
        <IconButton small action={onCancel}>
          <Icon name="close" size={16} />
        </IconButton>
      </Space>
    </Space>}
  </Vertical>
})
