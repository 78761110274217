import { UploadDataStore } from 'modal/UploadCollectionModal/store/UploadDataStore'
import { UploadItem } from 'modal/UploadCollectionModal/store/UploadItem'

function isGoodUrl(url: string): boolean {
  try {
    if (!url.startsWith('https:')) return false
    new URL(url)
    return true
  } catch {
    return false
  }
}

export class UploadParser {
  private readonly text: string
  private readonly old: string[]
  private readonly unused: UploadItem[] = []

  readonly no_item_id: UploadItem[] = []
  readonly existing: UploadItem[] = []
  readonly no_url: UploadItem[] = []
  readonly bad_url: UploadItem[] = []
  readonly duplicates: UploadItem[][] = []
  readonly good: UploadItem[] = []

  constructor(text: string, existingItemIds: string[]) {
    this.text = text
    this.old = existingItemIds
    this.parse()
    this.validate()
  }

  buildData(): UploadDataStore {
    return new UploadDataStore(this)
  }

  private parse() {
    const rows = this.text.split(/\r?\n/)

    let line = 0
    for (const row of rows) {
      ++line
      if (line == 1) continue
      if (!row.trim()) continue

      const columns = row.split(',').map(row => row.trim())
      const [url, item_id, item_name] = columns
      const item = new UploadItem(line, row, url, item_id, item_name)

      if (!item_id) {
        this.no_item_id.push(item)
      } else if (this.old.includes(item_id)) {
        this.existing.push(item)
      } else if (!url) {
        this.no_url.push(item)
      } else if (!isGoodUrl(url)) {
        this.bad_url.push(item)
      } else {
        this.unused.push(item)
      }
    }
  }

  private validate(): void {
    const grouped = new Map<string, UploadItem[]>()

    for (const item of this.unused) {
      const { item_id } = item
      const items = grouped.get(item_id)

      if (items) items.push(item)
      else grouped.set(item_id, [item])
    }

    this.unused.length = 0

    for (const items of grouped.values()) {
      const item = items[0]
      if (item && items.length === 1) this.good.push(item)
      else this.duplicates.push(items)
    }
  }
}
