// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FixedPortal-root-b138{z-index:999999;position:fixed;inset:0}.FixedPortal-background-b138{position:absolute;inset:0;background-color:var(--ui-text-color);opacity:90%}.FixedPortal-container-b138{position:absolute;inset:0}.FixedPortal-close-b138{position:absolute;inset:8px 8px auto auto}`, "",{"version":3,"sources":["webpack://./src/component/Portal/FixedPortal.module.scss"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,cAAA,CACA,OAAA,CAGF,6BACE,iBAAA,CACA,OAAA,CACA,qCAAA,CACA,WAAA,CAGF,4BACE,iBAAA,CACA,OAAA,CAGF,wBACE,iBAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FixedPortal-root-b138`,
	"background": `FixedPortal-background-b138`,
	"container": `FixedPortal-container-b138`,
	"close": `FixedPortal-close-b138`
};
module.exports = ___CSS_LOADER_EXPORT___;
