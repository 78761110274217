import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { Preview } from 'saas/page/product/tryon/Preview/Preview'
import { SettingsCard } from 'saas/page/product/tryon/SettingsCard/SettingsCard'
import { Texts } from 'saas/page/product/tryon/SettingsCard/Texts'
import { tryon } from 'saas/store/product/ProductSaasController'

export const Main = observer(() => {
  const { texts } = tryon.it
  return <Space gap={16} parallel>
    {texts ? <Texts /> : <SettingsCard />}
    <Preview />
  </Space>
})
