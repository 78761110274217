import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { useBodyScrollLock } from 'hook/useBodyScrollLock'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ReactNode, useMemo } from 'react'
import { createPortal } from 'react-dom'
import css from './FixedPortal.module.scss'

type Props = {
  className?: string
  children: ReactNode
  onClose?(): void
}

export const FixedPortal = observer(({ className, children, onClose }: Props) => {
  const container = useMemo(() => document.createElement('div'), [])

  useEffectOnce(() => {
    document.body.appendChild(container)
    return () => void document.body.removeChild(container)
  })

  useBodyScrollLock(true)

  const onKeyDown = useLatestCallback((event: KeyboardEvent) => {
    const esc = event.key === 'Escape'
    if (esc && onClose) onClose()
  })

  useEffectOnce(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  })

  const root = <div className={css.root}>
    <div className={css.background} />
    <div className={clsx(className, css.container)}>
      {children}
    </div>
    {onClose && <IconButton className={css.close} light action={onClose}>
      <Icon size={20} name="close" />
    </IconButton>}
  </div>

  return createPortal(root, container)
})
