import { useLatestCallback } from 'hook/useLatestCallback'
import { RefCallback, useState } from 'react'

export function useRefState<T>(): [T | undefined, RefCallback<T>] {
  const [element, setElement] = useState<T>()

  const onRef = useLatestCallback((element: T | null | undefined) => {
    setElement(element ?? undefined)
  })

  return [element, onRef]
}
