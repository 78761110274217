// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal-modal-477f{top:min(100px,10vh);min-width:min-content;padding-bottom:min(100px,10vh);margin:0 auto !important}.Modal-modal-477f .ant-modal-content{padding:24px}.Modal-modal-477f .ant-modal-close{position:fixed !important;top:8px;right:8px;border-radius:var(--ui-border-radius);width:32px;height:32px;color:var(--ui-background-color-active) !important;animation:600ms ease-in Modal-appear-477f}@keyframes Modal-appear-477f{0%{opacity:0}50%{opacity:0}100%{opacity:1}}.Modal-modal-477f .ant-modal-close:hover,.Modal-modal-477f .ant-modal-close:focus{color:var(--ui-background-color) !important}.Modal-modal-477f .ant-modal-header{margin-bottom:24px}.Modal-modal-477f .ant-modal-title{font-size:16px;font-weight:700}.Modal-modal-477f .ant-modal-body{display:flex;flex-direction:column;gap:24px}.ant-modal-wrap{-ms-overflow-style:none;scrollbar-width:none}.ant-modal-wrap::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/component/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA,kBACE,mBAAA,CACA,qBAAA,CACA,8BAAA,CACA,wBAAA,CAEA,qCACE,YAAA,CAGF,mCACE,yBAAA,CACA,OAAA,CACA,SAAA,CACA,qCAAA,CACA,UAAA,CACA,WAAA,CACA,kDAAA,CACA,yCAAA,CAEA,6BACE,GACE,SAAA,CAEF,IACE,SAAA,CAEF,KACE,SAAA,CAAA,CAIJ,kFACE,2CAAA,CAIJ,oCACE,kBAAA,CAGF,mCACE,cAAA,CACA,eAAA,CAGF,kCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAIJ,gBACE,uBAAA,CACA,oBAAA,CAGF,mCACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal-modal-477f`,
	"appear": `Modal-appear-477f`
};
module.exports = ___CSS_LOADER_EXPORT___;
