export function saveJson<V>(key: string, value: V | undefined): void {
  try {
    if (value === undefined) localStorage.removeItem(key)
    else localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.error('save failed', key, e)
  }
}

export function loadJson<V>(key: string): V | undefined {
  try {
    const text = localStorage.getItem(key)
    return text ? JSON.parse(text) : undefined
  } catch (e) {
    console.error('load failed', key, e)
    return undefined
  }
}
