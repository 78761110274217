// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoutItem-item-5bff{height:40px;width:100%;padding:0 8px;border-radius:var(--ui-border-radius);color:var(--ui-text-color-secondary)}.LogoutItem-item-5bff:hover{color:var(--ui-primary-color)}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/LogoutItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,aAAA,CACA,qCAAA,CACA,oCAAA,CAGF,4BACE,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `LogoutItem-item-5bff`
};
module.exports = ___CSS_LOADER_EXPORT___;
