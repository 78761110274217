import { makeAutoObservable } from 'mobx'
import { ui } from 'store/home/ui/HomeUiStore'
import { DemoCatalogProduct, extractPrice, fixGender } from 'type/DemoCatalog'
import { split } from 'util/array'
import { nil } from 'util/null'

export const sortValues = ['popular', 'cheap', 'expensive'] as const
export type Sort = typeof sortValues[number]

export function getLimitStep() {
  return ui.window.mobile ? 10 : 14
}

export class DemoCatalogFormStore {
  private _brands: string[] = []
  private _styles: string[] = []
  private _gender: string = ''
  private _sort: Sort = 'popular'
  private _limit: number = getLimitStep()

  constructor() {
    makeAutoObservable(this)
  }

  get brands(): string[] {
    return this._brands
  }

  set brands(value: string[]) {
    this._brands = value
    this.change()
  }

  get styles(): string[] {
    return this._styles
  }

  set styles(value: string[]) {
    this._styles = value
    this.change()
  }

  get gender(): string {
    return this._gender
  }

  set gender(value: string | nil) {
    this._gender = value ?? ''
    this.change()
  }

  get sort(): Sort {
    return this._sort
  }

  set sort(value: Sort) {
    this._sort = value
    this.change()
  }

  get limit(): number {
    return this._limit
  }

  set limit(value: number) {
    this._limit = value
  }

  get comparator() {
    if (this.sort == 'popular') return undefined
    return this.compare
  }

  filter(item: DemoCatalogProduct): boolean {
    const brand = item.brand_name ?? ''
    const styles = split(item.dress_styles)
    const gender = fixGender(item.dress_gender)

    let found = true
    if (this.brands.length > 0 && !this.brands.includes(brand)) found = false
    if (this.styles.length > 0 && !this.styles.some(style => styles.includes(style))) found = false
    if (this.gender && this.gender !== gender) found = false
    return found
  }

  private change() {
    this.limit = getLimitStep()
  }

  private readonly compare = (a: DemoCatalogProduct, b: DemoCatalogProduct): number => {
    const ap = extractPrice(a) ?? 0
    const bp = extractPrice(b) ?? 0
    const desc = this.sort === 'expensive'
    return desc ? bp - ap : ap - bp
  }
}
