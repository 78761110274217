import { Button } from 'component/Button'
import { SearchInput } from 'component/Input/SearchInput'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { CollectionCard } from 'page/internal/collections/CollectionCard'
import { can } from 'store/can'
import { home } from 'store/home'
import { ui } from 'store/home/ui/HomeUiStore'
import { renderStubIcon } from 'util/render'

export const CollectionsView = observer(() => {
  useEffectOnce(() => {
    void home.company.it.loadCollections()
  })

  return home.collections.present ? <Actual /> : <Stub />
})

const Title = observer(() => {
  if (!ui.saas) return null

  return <Typo size={18} bold>
    {i18n('collection.Collections')}
  </Typo>
})

const Stub = observer(() => {
  return <Vertical gap={24}>
    <Title />

    <Space height={400} center>
      {renderStubIcon(home.collections)}
    </Space>
  </Vertical>
})

const Actual = observer(() => {
  const { company_id } = home
  const { collections, collectionsVisible, search } = home.collections.it
  const none = collections.length === 0

  useEffectOnce(() => {
    window.scroll(0, 0)
  })

  const onCreate = useLatestCallback(async () => {
    await modal.openCreateCollectionModal()
  })

  if (none) {
    return <Vertical gap={24}>
      <Title />

      <Typo size={14} bold secondary>
        {i18n('collection.NoCollections')}
      </Typo>

      {can.CreateCollections(company_id) && <Space>
        <Button action={onCreate}>
          {i18n('collection.CreateNew')}
        </Button>
      </Space>}
    </Vertical>
  }

  return <Vertical gap={24}>
    <Title />

    <Space gap={[12, 16]} opposite wrap>
      <Space flex="1 1 200px">
        <SearchInput width={420} placeholder={i18n('placeholder.SearchCollections')}
          value={search.value} onChange={search.onChange} />
      </Space>

      {can.CreateCollections(company_id) && <Button action={onCreate}>
        {i18n('collection.CreateNew')}
      </Button>}
    </Space>

    {!collectionsVisible.length && <Typo size={14} bold secondary>
      {i18n('collection.NoCollections')}
    </Typo>}

    {collectionsVisible.length > 0 && <Space gap={16} top wrap>
      {collectionsVisible.map(collection => (
        <CollectionCard key={collection.collectionId} collection={collection} />
      ))}
    </Space>}
  </Vertical>
})
