import { Space } from 'component/Space'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { IdentityCard } from 'modal/TestItemModal/IdentityCard'
import { home } from 'store/home'
import { renderStubIcon } from 'util/render'

export const ProfileSelect = observer(() => {
  useEffectOnce(() => void home.loadProfile())

  return home.profile.present ? <Actual /> : <Stub />
})

const Stub = observer(() => {
  return <Space height={150} wide center>
    {renderStubIcon(home.profile)}
  </Space>
})

const Actual = observer(() => {
  return <Space gap={8} wrap>
    {home.profile.it.identities.map(identity => {
      return <IdentityCard key={identity.id} identity={identity} />
    })}
  </Space>
})
