import { observer } from 'mobx-react-lite'
import { View } from 'saas/page/product/common/View/View'
import { Details } from 'saas/page/product/stand/Details/Details'
import { Main } from 'saas/page/product/stand/Main'

export const StandView = observer(() => {
  return <View>
    <Main />
    <Details />
  </View>
})
