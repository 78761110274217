import clsx from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import css from './GenderButton.module.scss'

type Props = {
  gender: string
}

function getGenderTitle(gender: string) {
  if (!gender) return i18n('demo.GenderAll')
  if (gender === 'female') return i18n('demo.Female')
  if (gender === 'male') return i18n('demo.Male')
  if (gender === 'none') return i18n('demo.GenderNone')
  return gender.toUpperCase()
}

export const GenderButton = observer(({ gender }: Props) => {
  const { form } = demo
  const className = clsx(css.buttonGender, form.gender === gender && css.active)

  const onClick = useLatestCallback(() => {
    form.gender = gender
  })

  return <DemoCatalogButton className={className} action={onClick}>
    {getGenderTitle(gender)}
  </DemoCatalogButton>

})
