// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentsSection-title-5c1e{border-bottom:1px solid var(--ui-border-color-tertiary)}.CommentsSection-main-5c1e{flex:1 1 0;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/CommentsSection.module.scss"],"names":[],"mappings":"AAAA,4BACE,uDAAA,CAGF,2BACE,UAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `CommentsSection-title-5c1e`,
	"main": `CommentsSection-main-5c1e`
};
module.exports = ___CSS_LOADER_EXPORT___;
