import { api } from 'store/api'
import { MosaicSocket, UpdateInferenceDataListener } from 'store/socket/event'
import { InferenceFallback, InferenceListener } from 'store/socket/listener'
import { nil } from 'util/null'
import { delay } from 'util/promise'

type O = { inference_id: string }
type S = MosaicSocket
type E = UpdateInferenceDataListener
type L = InferenceListener
type F = InferenceFallback

const ACK_TIMEOUT = 10/*sec*/ * 1000
const POLL_DELAY = 10/*sec*/ * 1000
const POLL_TIMEOUT = 10/*min*/ * 60/*sec*/ * 1000

export function openInferenceChannel(socket: S | nil, options: O, listener: L, fallback: F): void {
  const startFallback = async () => {
    const start = Date.now()
    while (true) {
      await delay(POLL_DELAY)
      const expired = Date.now() - start > POLL_TIMEOUT
      if (expired) return
      const json = await api.getInference(options.inference_id)
      fallback(json)
      if (json.status === 'complete') return
    }
  }

  if (!socket) {
    console.error('no socket')
    return void startFallback()
  }

  const close = () => {
    socket.off('disconnect', onDisconnect)
    socket.off('update-inference-data', onEvent)
  }

  const onDisconnect = (): void => {
    console.error('socket disconnected')
    close()
    void startFallback()
  }

  const onEvent: E = (inference_id, status, images): void => {
    if (options.inference_id !== inference_id) return
    if (status === 'complete') close()
    images = images ?? []
    listener({ inference_id, status, images })
  }

  const open = async (): Promise<void> => {
    try {
      await socket.timeout(ACK_TIMEOUT).emitWithAck('inference_id', options.inference_id)
      socket.on('disconnect', onDisconnect)
      socket.on('update-inference-data', onEvent)
    } catch (e) {
      console.error('socket emit failed', e)
      return void startFallback()
    }
  }

  void open()
}
