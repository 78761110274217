// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Upload-upload-020b{width:fit-content}.Upload-upload-020b .ant-upload-wrapper{display:block}.Upload-box-020b{height:auto}`, "",{"version":3,"sources":["webpack://./src/component/Upload/Upload.module.scss"],"names":[],"mappings":"AAAA,oBACE,iBAAA,CAEA,wCACE,aAAA,CAIJ,iBACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `Upload-upload-020b`,
	"box": `Upload-box-020b`
};
module.exports = ___CSS_LOADER_EXPORT___;
