// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyCard-image-a65a{width:fit-content;border-radius:var(--ui-border-radius);overflow:hidden}.CompanyCard-image-a65a img{height:120px !important}.CompanyCard-blank-a65a{width:120px;height:120px;background-color:var(--ui-background-color-weak);border-radius:var(--ui-border-radius)}`, "",{"version":3,"sources":["webpack://./src/saas/page/companies/CompanyCard.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,qCAAA,CACA,eAAA,CAEA,4BACE,uBAAA,CAIJ,wBACE,WAAA,CACA,YAAA,CACA,gDAAA,CACA,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `CompanyCard-image-a65a`,
	"blank": `CompanyCard-blank-a65a`
};
module.exports = ___CSS_LOADER_EXPORT___;
