import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'

export const ArticleInput = observer(() => {
  const value = managerSearchStore.article

  const onChange = useLatestCallback((value: string) => {
    managerSearchStore.article = value
  })

  return <Field label={i18n('label.Article')}>
    <Input value={value} onChange={onChange} placeholder={i18n('placeholder.Article')} />
  </Field>
})
