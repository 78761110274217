import { language } from 'app/config/constants'
import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { skipAsync } from 'util/async'

const cache: Record<string, string> = {}

async function getDoc(path: string) {
  const cached = cache[path]
  if (cached) return cached

  const content = await api.getDoc(path + '.' + language + '.md')
  cache[path] = content
  return content
}

export class ArticleStore {
  loading = true
  content = ''

  constructor() {
    makeAutoObservable(this)
  }

  readonly update = skipAsync(async (path: string) => {
    this.loading = true
    this.content = ''
    try {
      const content = await getDoc(path)
      runInAction(() => this.content = content)
    } catch (e) {
      console.error('article loading failed', e)
    } finally {
      runInAction(() => this.loading = false)
    }
  })
}
