// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconInput-icon-c0c3{overflow:hidden;min-width:48px;min-height:48px}.IconInput-icon-c0c3 img{width:20px;height:20px}.IconInput-pale-c0c3{opacity:.2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/IconInput/IconInput.module.scss"],"names":[],"mappings":"AAAA,qBACE,eAAA,CACA,cAAA,CACA,eAAA,CAEA,yBACE,UAAA,CACA,WAAA,CAIJ,qBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `IconInput-icon-c0c3`,
	"pale": `IconInput-pale-c0c3`
};
module.exports = ___CSS_LOADER_EXPORT___;
