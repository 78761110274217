import { Button } from 'component/Button'
import { Input } from 'component/Input'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { OriginRow } from 'saas/page/company/modal/KeyEditModal/OriginRow'
import { keyModal } from 'saas/store/key/ApiKeyModalStore'

export const KeyEditModal = observer(() => {
  const { creating, canSave, name, origins } = keyModal.it
  const ref = useRef<HTMLInputElement | null>(null)

  useEffectOnce(() => {
    ref.current?.focus()
  })

  const onAddOrigin = useLatestCallback(async () => {
    keyModal.it.addOrigin()
  })

  const onSave = useLatestCallback(async () => {
    await keyModal.it.saveKey()
  })

  const onCancel = useLatestCallback(async () => {
    keyModal.close()
  })

  return <Modal stable title={i18n('company.CreateApiKey')} onCancel={onCancel}>
    <Vertical gap={20}>
      <Input ref={ref} mono value={name.value} error={name.error}
        placeholder={i18n('label.Name')} onChange={name.onChange} />
      <Vertical gap={12}>
        <Typo size={14}>{i18n('label.Origins')}</Typo>
        {origins.map(origin => <OriginRow key={origin.key} origin={origin} />)}
      </Vertical>
      <Space gap={[8, 16]} opposite wrap>
        <Button link compact action={onAddOrigin}>
          {i18n('company.AddOrigin')}
        </Button>
        <Space gap={[4, 12]} wrap>
          <Button link compact action={onCancel}>
            {i18n('common.Cancel')}
          </Button>
          <Button disabled={!canSave} action={onSave}>
            {creating ? i18n('common.Create') : i18n('common.Save')}
          </Button>
        </Space>
      </Space>
    </Vertical>
  </Modal>
})
