// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentsList-scroll-c2e0{padding:12px}`, "",{"version":3,"sources":["webpack://./src/page/internal/comments/CommentsList.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": `CommentsList-scroll-c2e0`
};
module.exports = ___CSS_LOADER_EXPORT___;
