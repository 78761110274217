import { action, makeObservable, observable } from 'mobx'

export class MxRef<V> {
  private value: V | undefined

  constructor(value?: V) {
    this.value = value
    makeObservable<this, 'value'>(this, {
      value: observable.ref,
    })
  }

  get present(): boolean {
    return this.value != null
  }

  get it(): V {
    if (this.value == null) throw new Error('no it')
    return this.value
  }

  get optional(): V | undefined {
    return this.value
  }

  set it(value: V | undefined) {
    this.set(value)
  }

  open(value: V) {
    this.set(value)
  }

  close() {
    this.set(undefined)
  }

  private set = action((value: V | undefined) => {
    this.value = value
  })
}
