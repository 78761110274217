import { Field } from 'component/Field'
import { SearchSelect, SelectOption } from 'component/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'
import { getReviewStatusLabel, reviewStatusValues } from 'type/ExternalStatus'

export const ReviewSelect = observer(() => {
  const value = managerSearchStore.external_status

  const options: SelectOption[] = [
    { value: '', label: getReviewStatusLabel('') },
    ...reviewStatusValues.map(status => ({
      value: status,
      label: getReviewStatusLabel(status),
    })),
  ]

  const onChange = useLatestCallback((value: string[]) => {
    managerSearchStore.external_status = value
  })

  return <Field label={i18n('label.ReviewStatus')}>
    <SearchSelect wide multiple value={value} options={options} onChange={onChange} />
  </Field>
})
