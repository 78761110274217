import { Card } from 'component/Card'
import { Preview } from 'component/Image'
import { FitImage } from 'component/Image/FitImage'
import { PreviewControl, usePreviewControl } from 'component/Image/PreviewControl'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { MouseEvent } from 'react'
import { key } from 'util/react'
import css from './ImageStack.module.scss'

type ImgProps = {
  control: PreviewControl
  source: string,
}

const Img = ({ control, source }: ImgProps) => {
  const onClick = useLatestCallback((event: MouseEvent) => {
    event.stopPropagation()
    control.setPreview(source)
  })

  return <Card className={css.image} width={32} height={48} action={onClick}>
    <FitImage src={source} />
  </Card>
}

type Props = {
  sources: readonly string[]
}

export const ImageStack = observer(({ sources }: Props) => {
  const control = usePreviewControl()

  return <div className={css.stack}>
    {!sources.length && <Card width={32} height={48} center secondary>
      <Icon name="image" size={12} tertiary />
    </Card>}
    {sources.slice(0, 3).reverse().map((image, index) => (
      <Img key={key(index, image)} source={image} control={control} />
    ))}
    <Preview sources={sources} control={control} />
  </div>
})
