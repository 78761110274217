import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { useMemo } from 'react'

let locks = 0
let unlock: Function | undefined

function lock() {
  const top = document.documentElement.scrollTop
  const left = document.documentElement.scrollLeft
  document.body.style.overflow = 'hidden'
  document.body.scrollTop = top
  document.body.scrollLeft = left

  unlock = () => {
    document.body.style.overflow = ''
    document.documentElement.scrollTop = top
    document.documentElement.scrollLeft = left
    unlock = undefined
  }
}

export const useBodyScrollLock = (active: boolean): void => {
  const state = useMemo(() => ({ used: false }), [])

  const start = useLatestCallback(() => {
    if (!state.used) {
      state.used = true
      ++locks
      if (locks === 1) lock()
    }
  })

  const end = useLatestCallback(() => {
    if (state.used) {
      state.used = false
      --locks
      if (locks === 0) {
        if (unlock) unlock()
        else console.error('no unlock')
      }
    }
  })

  useMemo(() => {
    if (active) start()
    else end()
  }, [start, end, active])

  useEffectOnce(() => {
    return () => end()
  })
}
