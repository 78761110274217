import { Tag } from 'component/Tag'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'

export const AllTag = observer(() => {
  const { selectedTags } = home.collections.it
  const selected = selectedTags.empty

  const onClick = useLatestCallback(() => selectedTags.clear())

  return <Tag solid primary={selected} action={onClick}>
    {i18n('collection.AllTag')}
  </Tag>
})
