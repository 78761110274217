import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { getPaddingStyle, Padding, styles } from 'util/style'
import css from './Page.module.scss'

type Props = {
  children: ReactNode,
  pad?: Padding
}

export const Page = observer((props: Props) => {
  const { children } = props
  const pad = getPaddingStyle(props.pad)
  const style = styles({ ...pad })

  return <Vertical className={css.page} style={style}>
    {children}
  </Vertical>
})
