import { io } from 'socket.io-client'
import { cfg } from 'store/cfg'
import { me } from 'store/me'
import { openInferenceChannel } from 'store/socket/channel/inference'
import { MosaicSocket } from 'store/socket/event'
import { InferenceFallback, InferenceListener } from 'store/socket/listener'
import { delay } from 'util/promise'

class SocketController {
  private socket?: MosaicSocket

  attachInference(inference_id: string, listener: InferenceListener, fallback: InferenceFallback): void {
    openInferenceChannel(this.socket, { inference_id }, listener, fallback)
  }

  connect() {
    this.close()

    const user_id = me.user.user_id
    const ws = cfg.cfg.ws

    const url = `${ws}/${user_id}`
    this.socket = io(url, {
      autoConnect: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 10000,
      transports: ['websocket', 'polling'],
      withCredentials: true,
    })

    this.socket.connect()
    // this.socket.on('connect', () => console.info('socket connect'))
    // this.socket.on('disconnect', (reason: string) => console.info('socket disconnect', reason))
    this.socket.on('error', (error: Error) => console.error('socket error', error))
    this.socket.on('reconnect_error', (error: Error) => console.error('reconnect error', error))
    this.socket.on('reconnect_failed', () => console.error('reconnect failed'))
    this.socket.on('connect_error', async (error: Error) => {
      console.error('connect error', error)
      const socket = this.socket
      if (!socket || socket?.active) return

      await delay(10000)
      if (socket === this.socket) socket?.connect()
    })
  }

  close(): void {
    this.socket?.close()
    this.socket = undefined
  }
}

export const socket = new SocketController()
