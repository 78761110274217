import { makeAutoObservable } from 'mobx'
import { ProductStore } from 'store/product'

export class TestItemModalStore {
  readonly product: ProductStore
  private _identity_id: string | undefined

  constructor(product: ProductStore) {
    this.product = product
    makeAutoObservable(this)
  }

  get identity_id(): string | undefined {
    return this._identity_id
  }

  set identity_id(value: string | undefined) {
    this._identity_id = value
  }

  get canTest(): boolean {
    return this.identity_id != null
  }

  readonly testItem = async () => {
    if (!this.identity_id) throw new Error('no identity')
    await this.product.testItem(this.identity_id)
  }
}
