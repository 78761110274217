import { Button } from 'component/Button'
import { Checkbox } from 'component/Checkbox'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'

export const DeleteProductModal = observer(() => {
  const store = modal.deleteProductModal.it
  const { product_name } = store.item.store.it.data

  const onChangeSkip = useLatestCallback((value: boolean) => {
    store.skip = value
  })

  const onCancel = useLatestCallback(() => {
    modal.deleteProductModal.close()
  })

  const onDelete = useLatestCallback(async () => {
    modal.deleteProductModal.close()
    await store.deleteItem()
  })

  return <Modal title={i18n('item.DeleteItem')} onCancel={onCancel}>
    <Vertical gap={12}>
      <Typo size={14}>
        {i18n('item.YouAreAboutToDeleteNameItem', { n: <Typo size={14} bold />, name: product_name })}
      </Typo>
      <Checkbox checked={store.skip} onChange={onChangeSkip}>{i18n('text.DontAskForMinute')}</Checkbox>
    </Vertical>
    <Space spread gap={12}>
      <Button primary action={onDelete}>{i18n('common.Delete')}</Button>
      <Button action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
