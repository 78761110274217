import { Auth } from 'type/Auth'
import { loadJson, saveJson } from 'util/storage'

type AuthRo = Readonly<Auth>

function loadAuth(): Auth | undefined {
  return loadJson('auth')
}

function saveAuth(auth: AuthRo | undefined): void {
  saveJson('auth', auth)
}

class AuthStore {
  private _auth?: AuthRo

  constructor() {
    this._auth = loadAuth()
  }

  get token(): string | undefined {
    return this._auth?.id_token
  }

  get present(): boolean {
    return !!this._auth
  }

  get auth(): AuthRo {
    if (!this._auth) throw new Error('no auth')
    return this._auth
  }

  set auth(auth: AuthRo) {
    saveAuth(auth)
    this._auth = auth
  }

  clear() {
    saveAuth(undefined)
    this._auth = undefined
  }
}

export const auth = new AuthStore()
