import { makeAutoObservable, observable } from 'mobx'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'

type Data = {
  readonly key: ApiKeyStore | undefined
}

export class KeyEditStore {
  private readonly data: Data
  private _key: ApiKeyStore | undefined

  constructor(data: Data) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this.data = data
    this._key = data.key
  }

  get key(): ApiKeyStore | undefined {
    return this._key
  }

  set key(value: ApiKeyStore | undefined) {
    this._key = value
  }

  get changed(): boolean {
    return this.data.key?.value !== this._key?.value
  }

  get canSave(): boolean {
    return this.changed && this.goodKey
  }

  get goodKey(): boolean {
    if (!this._key) return true
    return !!this._key.value
  }

  check() {
    return this.canSave
  }

  buildUpdate() {
    return { access: this.key?.value }
  }
}
