import { makeAutoObservable } from 'mobx'
import { api } from 'store/api'
import { LazyStore } from 'store/base/LazyStore'
import { CollectionListStore } from 'store/collection'
import { Collection } from 'type/Collection'
import { Company } from 'type/Company'

export class CompanyStore {
  readonly company: Company
  readonly collections = new LazyStore<CollectionListStore>()

  constructor(company: Company) {
    this.company = company
    makeAutoObservable(this)
  }

  get company_id() {
    return this.company.company_id
  }

  get title() {
    return this.company.company_name ?? this.company.company_id
  }

  async loadCollections() {
    const company_id = this.company.company_id
    await this.collections.open(async () => {
      const response = await api.getCompanyCollections(company_id)
      return new CollectionListStore(response)
    })
  }

  setCollections(collections: Collection[]) {
    this.collections.it = new CollectionListStore(collections)
  }

  private get roles(): string[] {
    return this.company.permissions?.split(',') ?? []
  }
}
