// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentsCard-root-1f5e{position:relative}.CommentsCard-ref-1f5e{position:absolute;inset:-24px -12px}.CommentsCard-card-1f5e{width:100%;max-height:max(200px,min(800px,100vh - 100px));background-color:var(--ui-background-color)}@media(width <= 720px){.CommentsCard-card-1f5e{box-shadow:0 0 8px var(--ui-border-color)}}@media(width <= 480px){.CommentsCard-card-1f5e{max-width:calc(100vw - 48px)}}.CommentsCard-title-1f5e{border-bottom:1px solid var(--ui-border-color-secondary)}.CommentsCard-collapse-1f5e .CommentsCard-title-1f5e{border:none}`, "",{"version":3,"sources":["webpack://./src/page/internal/comments/CommentsCard.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CAGF,uBACE,iBAAA,CACA,iBAAA,CAGF,wBACE,UAAA,CACA,8CAAA,CACA,2CAAA,CAEA,uBALF,wBAMI,yCAAA,CAAA,CAGF,uBATF,wBAUI,4BAAA,CAAA,CAIJ,yBACE,wDAAA,CAGF,qDACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CommentsCard-root-1f5e`,
	"ref": `CommentsCard-ref-1f5e`,
	"card": `CommentsCard-card-1f5e`,
	"title": `CommentsCard-title-1f5e`,
	"collapse": `CommentsCard-collapse-1f5e`
};
module.exports = ___CSS_LOADER_EXPORT___;
