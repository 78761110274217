import { i18n } from 'i18n'

export enum MemberRole {
  reviewer = 'access',
  developer = 'edit',
  admin = 'admin',
}

export const memberRoles: MemberRole[] = [
  MemberRole.reviewer,
  MemberRole.developer,
  MemberRole.admin,
]

export function isMemberRole(role: string): role is MemberRole {
  return memberRoles.includes(role as MemberRole)
}

export function extractMemberRoles(permissions: string[] | null | undefined): MemberRole[] {
  if (!permissions) return []
  return memberRoles.filter(r => permissions.includes(r))
}

export function getMemberRoleLabel(role: MemberRole): string {
  switch (role) {
    case MemberRole.reviewer:
      return i18n('role.Reviewer')
    case MemberRole.developer:
      return i18n('role.Developer')
    case MemberRole.admin:
      return i18n('role.Admin')
    default:
      return ('' + role).toUpperCase()
  }
}
