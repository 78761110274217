import { reaction } from 'mobx'
import { PreviewStore } from 'store/product/PreviewStore'
import { ProductStore } from 'store/product/ProductStore'

export class ProductPreviewStore {
  private readonly product: ProductStore
  readonly left = new PreviewStore()
  readonly right = new PreviewStore()

  constructor(product: ProductStore) {
    this.product = product
    reaction(() => this.product.previewImages, images =>
      this.left.images = images, { fireImmediately: true })
    reaction(() => this.product.selectedImages, images =>
      this.right.images = images, { fireImmediately: true })
  }
}
