import { Checkbox } from 'component/Checkbox'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const UiControls = observer(() => {
  const { edit } = stand.it
  const { brandFilter, styleFilter, genderFilter, priceSorting, shareCatalogButton, prices, buyButtons, brandNames } = edit

  const onChangeBrandFilter = useLatestCallback((value: boolean) => edit.brandFilter = value)
  const onChangeStyleFilter = useLatestCallback((value: boolean) => edit.styleFilter = value)
  const onChangeGenderFilter = useLatestCallback((value: boolean) => edit.genderFilter = value)
  const onChangePriceSorting = useLatestCallback((value: boolean) => edit.priceSorting = value)
  const onChangeShareCatalogButton = useLatestCallback((value: boolean) => edit.shareCatalogButton = value)
  const onChangePrices = useLatestCallback((value: boolean) => edit.prices = value)
  const onChangeBuyButtons = useLatestCallback((value: boolean) => edit.buyButtons = value)
  const onChangeBrandNames = useLatestCallback((value: boolean) => edit.brandNames = value)

  return <>
    <Typo size={14} bold>{i18n('stand.UiControls')}</Typo>
    <Gap height={12} />
    <Checkbox checked={brandFilter} onChange={onChangeBrandFilter}>
      {i18n('stand.BrandFilter')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={styleFilter} onChange={onChangeStyleFilter}>
      {i18n('stand.StyleFilter')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={genderFilter} onChange={onChangeGenderFilter}>
      {i18n('stand.GenderFilter')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={priceSorting} onChange={onChangePriceSorting}>
      {i18n('stand.PriceSorting')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={shareCatalogButton} onChange={onChangeShareCatalogButton}>
      {i18n('stand.ShareCatalogButton')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={prices} onChange={onChangePrices}>
      {i18n('stand.Prices')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={buyButtons} onChange={onChangeBuyButtons}>
      {i18n('stand.BuyButtons')}
    </Checkbox>
    <Gap height={8} />
    <Checkbox checked={brandNames} onChange={onChangeBrandNames}>
      {i18n('stand.BrandNames')}
    </Checkbox>
  </>
})
