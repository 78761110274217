// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DemoCatalogButton-control-158d{padding:0;font:inherit;font-size:16px;background:none;border:none;cursor:pointer;color:#1f1f1f}.DemoCatalogButton-button-158d{display:flex;align-items:center;justify-content:center;padding:0 16px;height:32px;border-radius:4px;transition:all 100ms ease-in-out;border:none;font-size:11px;text-transform:uppercase;font-weight:500}.DemoCatalogButton-button-158d>span{line-height:32px}.DemoCatalogButton-active-158d{background:#1f1f1f;color:#fff}.DemoCatalogButton-button-158d:disabled{opacity:60%}.DemoCatalogButton-xl-158d{height:40px}.DemoCatalogButton-text-158d{color:var(--ui-text-color);background-color:#f5f5f5}.DemoCatalogButton-text-158d:hover{background-color:var(--ui-background-color-soft)}.DemoCatalogButton-text-158d.DemoCatalogButton-active-158d{color:var(--ui-primary-color)}.DemoCatalogButton-outline-158d{color:var(--ui-text-color);background-color:var(--ui-background-color);border:1px solid var(--ui-border-color)}.DemoCatalogButton-outline-158d.DemoCatalogButton-active-158d{color:var(--ui-primary-color);border-color:var(--ui-primary-color)}.DemoCatalogButton-invert-158d{color:var(--ui-background-color);background-color:var(--ui-text-color)}.DemoCatalogButton-withIcon-158d{display:flex}.DemoCatalogButton-withIcon-158d>span{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/DemoCatalogButton/DemoCatalogButton.module.scss"],"names":[],"mappings":"AAAA,gCACE,SAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,aAAA,CAGF,+BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,gCAAA,CACA,WAAA,CACA,cAAA,CACA,wBAAA,CACA,eAAA,CAEA,oCACE,gBAAA,CAIJ,+BACE,kBAAA,CACA,UAAA,CAGF,wCACE,WAAA,CAGF,2BACE,WAAA,CAGF,6BACE,0BAAA,CACA,wBAAA,CAGF,mCACE,gDAAA,CAGF,2DACE,6BAAA,CAGF,gCACE,0BAAA,CACA,2CAAA,CACA,uCAAA,CAGF,8DACE,6BAAA,CACA,oCAAA,CAGF,+BACE,gCAAA,CACA,qCAAA,CAGF,iCACE,YAAA,CAEA,sCACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `DemoCatalogButton-control-158d`,
	"button": `DemoCatalogButton-button-158d`,
	"active": `DemoCatalogButton-active-158d`,
	"xl": `DemoCatalogButton-xl-158d`,
	"text": `DemoCatalogButton-text-158d`,
	"outline": `DemoCatalogButton-outline-158d`,
	"invert": `DemoCatalogButton-invert-158d`,
	"withIcon": `DemoCatalogButton-withIcon-158d`
};
module.exports = ___CSS_LOADER_EXPORT___;
