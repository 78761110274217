import { makeAutoObservable } from 'mobx'

type Err = {
  text?: string
}

class PageStore {
  private _ready = false
  private _loading = false
  private _error?: Err

  constructor() {
    makeAutoObservable(this)
  }

  async process(process: () => Promise<unknown>): Promise<void> {
    try {
      this.loading = true
      await process()
    } catch (e) {
      console.error('init failed', e)
      this.error = this.error || {}
    } finally {
      this.loading = false
    }
  }

  get ready(): boolean {
    return this._ready
  }

  set ready(value: boolean) {
    this._ready = value
  }

  get loading(): boolean {
    return this._loading
  }

  set loading(value: boolean) {
    this._loading = value
  }

  get error(): Err | undefined {
    return this._error
  }

  set error(value: Err | undefined) {
    this._error = value
  }
}

export const pageStore = new PageStore()
