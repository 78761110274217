// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalCard-num-1489{opacity:1;transition:opacity 200ms ease-in-out}.TotalCard-hide-1489{opacity:.1;transition-duration:1000ms}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/total/TotalCard.module.scss"],"names":[],"mappings":"AAAA,oBACE,SAAA,CACA,oCAAA,CAGF,qBACE,UAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"num": `TotalCard-num-1489`,
	"hide": `TotalCard-hide-1489`
};
module.exports = ___CSS_LOADER_EXPORT___;
