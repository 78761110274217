// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MemberRow-picture-045c{border-radius:var(--ui-border-radius);overflow:hidden}.MemberRow-picture-045c img{max-width:64px !important;max-height:64px !important}.MemberRow-blank-045c{width:64px;height:64px;background-color:var(--ui-background-color-weak);border-radius:var(--ui-border-radius)}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/manage/MemberRow.module.scss"],"names":[],"mappings":"AAAA,wBACE,qCAAA,CACA,eAAA,CAEA,4BACE,yBAAA,CACA,0BAAA,CAIJ,sBACE,UAAA,CACA,WAAA,CACA,gDAAA,CACA,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picture": `MemberRow-picture-045c`,
	"blank": `MemberRow-blank-045c`
};
module.exports = ___CSS_LOADER_EXPORT___;
