// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductInfoSection-section-46e3{opacity:1}.ProductInfoSection-select-46e3{width:120px;max-width:calc(100vw - 48px);overflow:hidden;flex:1 1 auto}.ProductInfoSection-image-46e3{flex:1 1 0;aspect-ratio:var(--ui-aspect-ratio)}.ProductInfoSection-text-46e3{flex:1 1 120px;min-width:0}.ProductInfoSection-num-46e3{display:inline-block;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/ProductInfoSection.module.scss"],"names":[],"mappings":"AAAA,iCACE,SAAA,CAGF,gCACE,WAAA,CACA,4BAAA,CACA,eAAA,CACA,aAAA,CAGF,+BACE,UAAA,CACA,mCAAA,CAGF,8BACE,cAAA,CACA,WAAA,CAGF,6BACE,oBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `ProductInfoSection-section-46e3`,
	"select": `ProductInfoSection-select-46e3`,
	"image": `ProductInfoSection-image-46e3`,
	"text": `ProductInfoSection-text-46e3`,
	"num": `ProductInfoSection-num-46e3`
};
module.exports = ___CSS_LOADER_EXPORT___;
