export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

type Resolve<T> = (value: Promise<T> | T) => void
type Reject = (reason?: unknown) => void

type Executor<T> = (resolve: Resolve<T>, reject: Reject) => void

export class OpenPromise<T> extends Promise<T> {
  private readonly _resolve!: Resolve<T>
  private readonly _reject!: Reject

  constructor(executor?: Executor<T>) {
    let _resolve!: Resolve<T>
    let _reject!: Reject
    super((resolve, reject) => {
      _resolve = resolve
      _reject = reject
      executor?.(resolve, reject)
    })
    this._resolve = _resolve
    this._reject = _reject
  }

  readonly resolve = (value: T): void => {
    this._resolve(value)
  }

  readonly reject = (reason?: unknown): void => {
    this._reject(reason)
  }

  exec(process: () => PromiseLike<T> | T): Promise<T> {
    Promise.resolve(process()).then(this.resolve, this.reject)
    return this
  }
}
