// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorStep-users-3514{width:100%;max-height:400px}`, "",{"version":3,"sources":["webpack://./src/page/invite/ErrorStep.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"users": `ErrorStep-users-3514`
};
module.exports = ___CSS_LOADER_EXPORT___;
