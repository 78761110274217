import { IconButton } from 'component/Button/IconButton'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { keyModal } from 'saas/store/key/ApiKeyModalStore'
import { OriginStore } from 'saas/store/key/OriginStore'

type Props = {
  origin: OriginStore
}

export const OriginRow = observer(({ origin }: Props) => {
  const { value, error, onChange, onFocus } = origin.host

  const onRemove = useLatestCallback(() => {
    keyModal.it.removeOrigin(origin)
  })

  return <Space gap={8}>
    <Input value={value} error={error} before="https://" placeholder="example.com"
      onChange={onChange} onFocus={onFocus} />
    <IconButton action={onRemove}>
      <Icon name="delete" />
    </IconButton>
  </Space>
})
