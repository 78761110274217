// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanySelect-root-a6d9{flex:1 1 auto;max-width:calc(100vw - 48px);overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/section/Head/manager/CompanySelect.module.scss"],"names":[],"mappings":"AAAA,yBACE,aAAA,CACA,4BAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CompanySelect-root-a6d9`
};
module.exports = ___CSS_LOADER_EXPORT___;
