import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { MetaSettings } from 'saas/page/product/stand/SettingsCard/MetaSettings'
import { PageSettings } from 'saas/page/product/stand/SettingsCard/PageSettings'
import { stand } from 'saas/store/product/ProductSaasController'

export const AppearanceCard = observer(() => {
  const { busy, edit } = stand.it
  const { changed, canSave } = edit

  const onSettings = useLatestCallback(() => {
    edit.tab = 'settings'
  })

  const onReset = useLatestCallback(() => {
    void stand.it.edit.reset()
  })

  const onSave = useLatestCallback(() => {
    void stand.it.saveSettings()
  })

  return <Card vertical gap={24} pad={16} width={400} opposite secondary>
    <Vertical>
      <Space gap={16} opposite>
        <Typo size={16} bold>{i18n('stand.Appearance')}</Typo>
        <Button link compact action={onSettings}>
          {i18n('label.Settings')}
        </Button>
      </Space>
      <Gap height={24} />
      <PageSettings />
      <Gap height={24} />
      <MetaSettings />
    </Vertical>
    <Space gap={8} right>
      <Button text disabled={!changed} action={onReset}>
        {i18n('form.Reset')}
      </Button>
      <Button primary disabled={busy || !canSave} action={onSave}>
        {i18n('common.Save')}
      </Button>
    </Space>
  </Card>
})
