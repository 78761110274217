import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageInput } from 'saas/page/product/stand/ImageInput/ImageInput'
import { stand } from 'saas/store/product/ProductSaasController'

export const MetaSettings = observer(() => {
  const { edit } = stand.it
  const { description } = edit

  return <>
    <Typo size={14} bold>{i18n('stand.Meta')}</Typo>
    <Gap height={12} />
    <TextField field={description} label={i18n('stand.MetaDescription')} placeholder="" />
    <Gap height={8} />
    <ImageInput />
  </>
})
