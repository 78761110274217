import { Card } from 'component/Card'
import { observer } from 'mobx-react-lite'
import { LazyStore } from 'store/base/LazyStore'
import { renderStubIcon } from 'util/render'

type Props<F> = {
  width: number
  form?: LazyStore<F> | undefined
}

export const SectionStub = observer(<F, >({ width, form }: Props<F>) => {
  return <Card width={width} height={400} center tertiary>
    {renderStubIcon(form)}
  </Card>
})
