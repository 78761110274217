import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { LazyStore } from 'store/base/LazyStore'
import { SingleProcessStore } from 'store/base/process/SingleProcessStore'
import { CommentStore } from 'store/comment/CommentStore'
import { home } from 'store/home'
import { me } from 'store/me'
import { Comment, CreateCommentRequest, LikeState, UpdateCommentRequest } from 'type/Comment'
import { Item } from 'type/Item'
import { compact, last } from 'util/array'
import { minAsync } from 'util/async'
import { by } from 'util/sort'

export class FocusStamp {
}

function createCommentStores(comments: Comment[] | undefined) {
  const items = (comments ?? [])
    .sort(by(comment => comment.created_at))
    .map(comment => CommentStore.fromComment(comment))
  return items
}

export class CommentsStore {
  private readonly collectionId: string
  private readonly itemId: string
  readonly comments = new LazyStore<ArrayStore<CommentStore>>()
  private _focus?: FocusStamp
  private _message: string = ''
  private _replyTo?: CommentStore
  private _selected?: CommentStore
  private _editing?: CommentStore
  private _collapse = false
  private _loading: boolean = false

  constructor(json: Item) {
    this.collectionId = json.collection_id
    this.itemId = json.item_id
    this.comments.it = new ArrayStore(createCommentStores(json.comments))
    makeAutoObservable(this)
  }

  readonly reload = minAsync(async () => {
    runInAction(() => this._loading = true)
    const response = await api.getComments(this.collectionId, this.itemId)
    const comments = createCommentStores(response.comments)
    for (const comment of this.comments.it.value) {
      if (!comment.id) comments.push(comment)
    }
    this.comments.it = new ArrayStore(comments)
    runInAction(() => {
      this._replyTo = this.fix(this._replyTo)
      this._selected = this.fix(this._selected)
      this._editing = this.fix(this._editing)
      this._loading = false
    })
  })

  get loading(): boolean {
    return this._loading
  }

  get count(): string {
    return this.comments.optional?.length?.toFixed() ?? ''
  }

  getMyLikeComments() {
    const { user_id } = me.user
    return this.comments.it.filter(comment => comment.user_id === user_id && comment.like !== 0)
  }

  get like(): LikeState {
    const like = last(this.getMyLikeComments())
    return like ? like.like : 0
  }

  get likes(): number {
    return this.comments.it.filter(comment => comment.like === 1).length
  }

  get dislikes(): number {
    return this.comments.it.filter(comment => comment.like === 2).length
  }

  get small(): boolean {
    return home.ui.window.width <= 720
  }

  get collapse(): boolean {
    return this._collapse
  }

  set collapse(value: boolean) {
    this._collapse = value
  }

  async open() {
    this._focus = new FocusStamp()
  }

  get ready(): boolean {
    return !!this.comments.optional
  }

  get focus(): FocusStamp | undefined {
    return this._focus
  }

  set focus(value: FocusStamp | undefined) {
    this._focus = value
  }

  get message(): string {
    return this._message
  }

  set message(value: string) {
    this._message = value
  }

  get replyTo(): CommentStore | undefined {
    return this._replyTo
  }

  set replyTo(value: CommentStore | undefined) {
    if (this._editing) {
      // full reset
      this.editing = undefined
    }
    this._replyTo = value
    this._focus = new FocusStamp()
  }

  get selected(): CommentStore | undefined {
    return this._selected
  }

  set selected(value: CommentStore | undefined) {
    this._selected = value
  }

  get editing(): CommentStore | undefined {
    return this._editing
  }

  set editing(comment: CommentStore | undefined) {
    if (comment) {
      this._message = comment.message ?? ''
      this._replyTo = this.comments.optional?.find(another => comment.replyTo === another.id)
      this._editing = comment
    } else {
      this._message = ''
      this._replyTo = undefined
      this._editing = undefined
    }
    this._focus = new FocusStamp()
  }

  get chain(): Set<CommentStore> {
    const chain = new Set<CommentStore>()
    const comments = this.comments.optional ?? []

    // parents
    let comment = this._selected
    while (comment) {
      chain.add(comment)
      const id = comment.replyTo
      comment = comments.find(another => another.id === id)
    }

    // children
    let parents = compact([this._selected?.id])
    while (parents.length) {
      const replies = comments.filter(comment => !!comment.replyTo && parents.includes(comment.replyTo))
      replies.forEach(reply => chain.add(reply))
      parents = compact(replies.map(comment => comment.id))
    }

    return chain
  }

  get canSend(): boolean {
    return this.ready && this._message.trim().length > 0
  }

  addComment(json: Comment) {
    const comment = CommentStore.fromComment(json)
    this.comments.optional?.add(comment)
  }

  deleteComment(comment: CommentStore) {
    this.comments.optional?.remove(comment)
  }

  readonly send = new SingleProcessStore(async (): Promise<void> => {
    if (!this.canSend) return

    if (this.editing) await this.updateComment(this.editing)
    else await this.createComment()
  })

  private async createComment() {
    const comment = CommentStore.fromRequest(this.message, this.replyTo?.id)
    this.comments.optional?.add(comment)

    const request = this.buildCreateRequest()
    this.afterRequest()

    try {
      const response = await api.createComment(request)
      comment.afterResponse(response)
    } catch (e) {
      console.error('create comment fail', e)
      comment.afterError()
    }
  }

  private async updateComment(comment: CommentStore) {
    const request = this.buildUpdateRequest(comment)
    this.afterRequest()

    try {
      const response = await api.updateComment(request)
      comment.afterResponse(response)
    } catch (e) {
      console.error('update comment fail', e)
      comment.afterError()
    }
  }

  private buildCreateRequest(): CreateCommentRequest {
    return {
      collection_id: this.collectionId,
      user_id: me.user.user_id,
      item_id: this.itemId,
      type: 'public',
      message: this._message,
      reply_to: this._replyTo?.id ?? '',
    }
  }

  private buildUpdateRequest(comment: CommentStore): UpdateCommentRequest {
    if (!comment.id) throw new Error('no id')
    return {
      collection_id: this.collectionId,
      user_id: me.user.user_id,
      item_id: this.itemId,
      type: 'public',
      comment_id: comment.id,
      message: this._message,
    }
  }

  private afterRequest() {
    this._message = ''
    this._replyTo = undefined
    this._editing = undefined
  }

  private fix(comment: CommentStore | undefined): CommentStore | undefined {
    return comment && this.comments.it.find(another => another.id === comment.id)
  }
}
