import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { AllTag } from 'page/home/collections/TagsFilter/AllTag'
import { FilterTag } from 'page/home/collections/TagsFilter/FilterTag'
import { home } from 'store/home'

export const TagsFilter = observer(() => {
  const { tags } = home.collections.it

  return <Vertical gap={8}>
    <Typo size={14} bold secondary>
      {i18n('collection.FiltrationByTags')}
    </Typo>
    <Space gap={8} wrap>
      {tags.length === 0 && <Typo size={14} bold tertiary>
        {i18n('collection.NoTags')}
      </Typo>}
      {tags.length > 0 && <AllTag />}
      {tags.map(tag => (
        <FilterTag key={tag.toLowerCase()} tag={tag} />
      ))}
    </Space>
  </Vertical>
})
