import { development } from 'app/config/constants'
import { notice } from 'app/notice'
import { Button } from 'component/Button'
import { Captcha } from 'component/Captcha'
import { Checkbox } from 'component/Checkbox'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'

export const SupportModal = observer(() => {
  const { form, name, email, phone, details, consent, captcha } = modal.supportModal.it

  const onCancel = useLatestCallback(() => {
    modal.supportModal.close()
  })

  const onChangeConsent = useLatestCallback((value: boolean) => {
    consent.touch = true
    consent.value = value
  })

  const onCaptchaSuccess = useLatestCallback(() => {
    captcha.touch = true
    captcha.value = true
  })

  const onSend = useLatestCallback(async () => {
    if (!form.check()) return
    notice.success('Send')
  })

  return <Modal title={i18n('label.Support')} onCancel={onCancel}>
    <Vertical gap={12}>
      <TextField field={name} label={i18n('label.Name')} />
      <TextField field={email} label={i18n('label.Email')} />
      <TextField field={phone} label={i18n('label.Phone')} />
      <TextField field={details} label={i18n('support.DescribeYourProblem')} rows={4} />
      <Checkbox checked={consent.value} onChange={onChangeConsent} error={consent.error}>
        {i18n('support.ConsentToTheProcessingOfPersonalData')}
      </Checkbox>
    </Vertical>
    {development && <Captcha onSuccess={onCaptchaSuccess} error={captcha.error} />}
    <Space spread gap={12}>
      <Button primary disabled={form.error} action={onSend}>{i18n('common.Send')}</Button>
      <Button action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
