import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { UserRow } from 'page/invite/UserRow'
import { ui } from 'store/home/ui/HomeUiStore'

export const AddUsersStep = observer(() => {
  const { company, users, hasUsersDone, onAddUser, onRemoveUser, onBack, onNext } = inviteUsers.it
  const title = company?.title

  return <Vertical gap={24}>
    <Typo size={16} secondary>
      {i18n('invite.AddUsers')}
    </Typo>

    <Space gap={8}>
      <Typo size={12} secondary>
        {i18n('label.Company')}
      </Typo>
      <Typo size={12}>
        {title}
      </Typo>
    </Space>

    <Vertical gap={12}>
      {users.map(user => <UserRow key={user.key} user={user} />)}
    </Vertical>

    <Space gap={24} opposite wrap>
      <Space gap={[4, 12]} wrap>
        <Button link compact action={onAddUser}>
          {i18n('invite.AddUser')}
        </Button>
        {users.length > 1 && <Button link compact action={onRemoveUser}>
          {i18n('invite.RemoveUser')}
        </Button>}
      </Space>

      <Space gap={12} right wrap>
        {ui.manager && <Button action={onBack}>
          {i18n('common.GoBack')}
        </Button>}
        <Button primary disabled={!hasUsersDone} action={onNext}>
          {i18n('common.GoNext')}
        </Button>
      </Space>
    </Space>
  </Vertical>
})
