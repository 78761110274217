import { Button } from 'component/Button'
import { CopyButton } from 'component/Button/CopyButton'
import { IconButton } from 'component/Button/IconButton'
import { Link } from 'component/Link'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Back } from 'feature/back/Back'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { can } from 'store/can'
import { home } from 'store/home'
import { compact } from 'util/array'
import { formatDate, parseDate } from 'util/date'
import { emdash } from 'util/typo'
import css from './CollectionTitle.module.scss'

export const CollectionTitle = observer(() => {
  const { company_id } = home
  const collection = home.collection.it
  const { name, demo, json } = collection.data
  const { links, created_at, updated_at } = json

  const created = parseDate(created_at)
  const updated = parseDate(updated_at)

  const canEdit = can.EditCollectionInformation(company_id)
  const canAdd = can.AddAndEditProducts(company_id)
  const more = canEdit || canAdd

  const items: DropdownItem[] = compact([
    canAdd && {
      key: 'add',
      label: i18n('item.AddNewItem'),
      action: collection.createItem.action,
    },
    canAdd && {
      key: 'upload',
      label: i18n('catalog.UploadCatalog'),
      action() {
        void modal.openUploadCollectionModal(collection)
      },
    },
  ])

  const onBack = useLatestCallback(() => {
    home.closeCollection()
  })

  const onEdit = useLatestCallback(async () => {
    void modal.openEditCollectionModal(collection)
  })

  return <Vertical className={css.root} gap={8}>
    <Back action={onBack} />

    <Vertical gap={8}>
      <Space gap={[8, 16]} opposite wrap>
        <Space>
          <Typo size={18} bold wrap>{name}</Typo>
        </Space>

        {more && <Space className={css.edit} gap={8}>
          {canEdit && <Button action={onEdit}>
            {i18n('collection.Edit')}
          </Button>}
          <Dropdown items={items} placement={'bottomRight'}>
            <IconButton><Icon name="more_horiz" /></IconButton>
          </Dropdown>
        </Space>}
      </Space>

      <Space gap={[8, 16]} wrap>
        <Space gap={[4, 4]} wrap oh>
          <Space gap={2} oh>
            <Icon size={14} name="calendar_today" secondary />
            <Typo size={12} secondary ellipsis>{i18n('label.CreatedAt')}</Typo>
          </Space>
          <Typo size={12}>{formatDate(created) ?? emdash}</Typo>
        </Space>
        <Space gap={[4, 4]} wrap oh>
          <Space gap={2} oh>
            <Icon size={14} name="calendar_today" secondary />
            <Typo size={12} secondary ellipsis>{i18n('label.LastUpdate')}</Typo>
          </Space>
          <Typo size={12}>{formatDate(updated) ?? emdash}</Typo>
        </Space>
        {links && <Space gap={4} maxWidth={400}>
          <Icon size={14} name="link" secondary />
          <Link small href={links}>{links}</Link>
        </Space>}
        {demo && <Space gap={4} maxWidth={400}>
          <Typo size={12} secondary>{i18n('company.Demo')}</Typo>
          <Link small href={demo}>{demo}</Link>
          <CopyButton data={demo} />
        </Space>}
      </Space>
    </Vertical>
  </Vertical>
})
