import { makeAutoObservable } from 'mobx'
import { api } from 'store/api'
import { InferenceImage, InferenceImageEvent } from 'type/Inference'

type Args = {
  id: number
  like: boolean
  url: string
  json: InferenceImage | undefined
}

export class InferenceImageStore {
  private _id: number
  private _like = false
  private _url: string
  private _changed = false

  readonly json: InferenceImage | undefined

  static fromJson(json: InferenceImage) {
    return new InferenceImageStore({
      id: json.image_id,
      like: json.like_mode === 1,
      url: json.image_url,
      json,
    })
  }

  static fromEvent(event: InferenceImageEvent) {
    return new InferenceImageStore({
      id: event.int_id,
      like: event.like_mode === 1,
      url: event.url,
      json: undefined,
    })
  }

  private constructor(args: Args) {
    this._id = args.id
    this._like = args.like
    this._url = args.url
    this.json = args.json
    makeAutoObservable(this, {
      json: false,
    })
  }

  get id(): number {
    return this._id
  }

  get like(): boolean {
    return this._like
  }

  get url(): string {
    return this._url
  }

  async setLike(like: boolean) {
    this._changed = true
    this._like = like
    await api.setInferenceImageLikeMode(this._id, like ? 1 : 0)
  }

  update(update: InferenceImageStore): boolean {
    if (this._id !== update._id) return false

    this._id = update._id
    if (!this._changed) this._like = update._like
    this._url = update._url
    return true
  }
}
