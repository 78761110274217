import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { LazyStore } from 'store/base/LazyStore'
import { Inference } from 'type/Inference'
import { onceAsync } from 'util/async'

export class InferencesStore {
  readonly inferences = new LazyStore<ArrayStore<Inference>>()

  async getItemInferences(item_id: string): Promise<Inference[]> {
    await this.load()
    return this.inferences.it.filter(inference => {
      const data = inference.generated?.[0]?.showoff_tags?.data?.custom_collection_data
      return data?.item === item_id
    })
  }

  load = onceAsync(async (): Promise<void> => {
    await this.inferences.open(async () => {
      const inferences = await api.getInferences()
      return new ArrayStore(inferences)
    })
  })
}

export const inferencesStore = new InferencesStore()
