import { security } from 'app/controller/security'
import { clsx } from 'clsx'
import { Connection } from 'type/Connection'
import css from './ConnectButton.module.scss'

type Props = {
  connection: Connection
}

function getIconClass(name: string) {
  switch (name) {
    case 'apple' :
      return css.apple
    case 'discord' :
      return css.discord
    case 'email' :
      return css.email
    case 'Username-Password-Authentication' :
      return css.email
    case 'facebook' :
      return css.facebook
    case 'google-oauth2' :
      return css.google
    default:
      return css.unknown
  }
}

function getTitle(connection: Connection): string {
  switch (connection.name) {
    case 'email' :
      return 'Code'
    case 'Username-Password-Authentication' :
      return 'Password'
    default:
      return connection.title
  }
}

export function ConnectButton({ connection }: Props) {
  const { name, url } = connection

  const onClick = () => {
    security.login(url)
  }

  return <div className={clsx(css.button, getIconClass(name))} onClick={onClick}>
    <span className={css.icon} />
    <span className={css.title}>{getTitle(connection)}</span>
  </div>
}
