import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageUpload } from 'saas/page/company/common/ImageUpload'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'

export const DataSection = observer(() => {
  const { company_id, company, name, description, link, logo, canSave } = companyEdit.it
  const canEdit = can.EditCompanyInformation(company_id)
  const canDelete = can.DeleteCompany(company_id)

  const onDelete = useLatestCallback(async () => {
    companyEdit.it.companyDeleting = true
  })

  const onSave = useLatestCallback(async () => {
    await companyEdit.it.save()
  })

  return <Vertical gap={16} wide>
    <Space gap={16} top>
      <ImageUpload field={logo} disabled={!canEdit}
        original={company.json.logo_url} title={i18n('label.Logo')} />
      <Vertical gap={12} wide>
        <TextField field={name} label={i18n('label.Name')} disabled={!canEdit} />
        <TextField field={link} label={i18n('label.Website')} disabled={!canEdit} />
      </Vertical>
    </Space>
    <TextField rows={4} field={description} label={i18n('label.Description')} disabled={!canEdit} />
    <Space opposite>
      {canEdit && <Button primary disabled={!canSave} action={onSave}>
        {i18n('common.Save')}
      </Button>}
      {canDelete && <Button link compact error onClick={onDelete}>
        {i18n('company.DeleteCompany')}
      </Button>}
    </Space>
  </Vertical>
})
