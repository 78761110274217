import { i18n } from 'i18n'
import { SeriesResponse, StatValue } from 'type/Statistics'
import { parseDate } from 'util/date'

export type ChartSerieId = 'users' | 'inferences' | 'widgetOpen' | 'share' | string

export type ChartData = {
  x: number
  y: number
}

export type ChartSerie = {
  id: ChartSerieId
  color: string
  data: ChartData[]
}

function extractX(v: StatValue): number {
  return parseDate(v.date)?.getTime() ?? 0
}

function extractXx(response: SeriesResponse): number[] {
  const xx = new Set<number>()
  for (const [, values] of Object.entries(response)) {
    for (const value of values) {
      xx.add(extractX(value))
    }
  }
  return Array.from(xx).sort()
}

function createData(v: StatValue): ChartData {
  const x = extractX(v)
  const y = v.count
  return { x, y }
}

function extractDataMap(values: StatValue[]): Map<number, ChartData> {
  const map = new Map<number, ChartData>()
  for (const value of values) {
    map.set(extractX(value), createData(value))
  }
  return map
}

function createSerie(id: ChartSerieId, values: StatValue[], xx: number[]): ChartSerie {
  const color = getSerieColor(id)
  const dd = extractDataMap(values)
  const data = xx.map(x => dd.get(x) ?? { x, y: 0 })
  return { id, color, data }
}

export function createSeries(response: SeriesResponse): ChartSerie[] {
  const series: ChartSerie[] = []
  const xx = extractXx(response)
  for (const [key, values] of Object.entries(response)) {
    const serie = createSerie(key, values, xx)
    if (serie.data.length) series.push(serie)
  }
  return series
}

export function getSerieTitle(id: ChartSerieId): string {
  switch (id) {
    case 'users':
      return i18n('chart.Users')
    case 'inferences':
      return i18n('chart.Generations')
    case 'widgetOpen':
      return i18n('chart.WidgetOpen')
    case 'share':
      return i18n('chart.Share')
    default:
      return id.toUpperCase()
  }
}

export function getSerieColor(id: ChartSerieId) {
  switch (id) {
    case 'widgetOpen':
      return '#fff7bc'
    case 'inferences':
      return '#fee391'
    case 'users':
      return '#fec44f'
    case 'share':
      return '#ec7014'
    default:
      return '#f0f0f0'
  }
}
