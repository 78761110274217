import { clsx } from 'clsx'
import { Button, ButtonProps } from 'component/Button'
import { Link } from 'component/Link'
import { observer } from 'mobx-react-lite'
import css from './LinkButton.module.scss'

type Props = ButtonProps & {
  path: string
}

export const LinkButton = observer((_props: Props) => {
  const { path, className, ...props } = _props

  return <Link path={path} className={css.link}>
    <Button link className={clsx(className, css.button)} {...props} />
  </Link>
})
