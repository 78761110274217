import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import './ArticlePage.css'
import { home } from 'store/home'

export const Settings = observer(() => {
  return <Vertical gap={12}>
    <Typo size={16} bold>{i18n('title.Settings')}</Typo>
    <Space gap={12}>
      <Typo size={14}>{i18n('label.CompanyId')}</Typo>
      <Typo size={14} mono>{home.company.optional?.company_id}</Typo>
    </Space>
  </Vertical>
})
