import clsx from 'clsx'
import { useBodyScrollLock } from 'hook/useBodyScrollLock'
import { useOutsideClick } from 'hook/useClickOutside'
import { useLatestCallback } from 'hook/useLatestCallback'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { FilterPopover } from 'page/demo-catalog/FilterPopover/FilterPopover'
import { ReactNode, useState } from 'react'
import { ui } from 'store/home/ui/HomeUiStore'
import css from './FilterButton.module.scss'

type Props = {
  children: string | ReactNode
  title: string
  list: string[]
  value: string[]
  onChange: (value: string[]) => void
  onOpened?: (opened: boolean) => void
}

export const FilterButton = ({ children, title, list, value, onChange, onOpened }: Props) => {
  const { mobile } = ui.window
  const [opened, setOpenedRaw] = useState(false)

  const setOpened = useLatestCallback((value: boolean) => {
    setOpenedRaw(value)
    if (onOpened) onOpened(value)
  })

  const handleAction = useLatestCallback(() => {
    setOpened(!opened)
  })

  const ref = useOutsideClick(() => {
    handleClose()
  })

  const handleClose = useLatestCallback(() => {
    setOpened(false)
  })

  useBodyScrollLock(mobile && opened)

  return <div className={css.container} ref={ref}>
    <DemoCatalogButton className={css.button} action={handleAction} invert={opened}>
      {children}
    </DemoCatalogButton>
    <div className={clsx(css.wrapper, opened && css.opened)}>
      <div className={css.bg} onClick={handleClose} />
      <div className={css.inner}>
        <FilterPopover title={title} value={value} list={list} onChange={onChange} onClose={handleClose} />
      </div>
    </div>
  </div>
}
