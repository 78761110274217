import { app_domain } from 'app/config/constants'
import DressCodeSvg from 'asset/dress-code.svg'
import ShowoffSvg from 'asset/showoff.svg'
import { observer } from 'mobx-react-lite'
import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  h?: number
  w?: number
}

export const AppSvg = observer((p: Props) => {
  const dresscode = app_domain === 'dresscode'
  const { h, w, ...props } = p
  const height = h == null ? undefined : dresscode ? h / 2 : h
  const width = w == null ? undefined : dresscode ? w : w / 2

  const Svg = dresscode ? DressCodeSvg : ShowoffSvg
  return <Svg height={height} width={width} {...props} />
})
