import { makeAutoObservable } from 'mobx'
import { home } from 'store/home'
import { ProductItem } from 'store/product/ProductItem'

let hide = 0

export class DeleteProductModalStore {
  readonly item: ProductItem
  private _skip: boolean

  constructor(item: ProductItem) {
    this.item = item
    this._skip = !!hide && hide - Date.now() > 0
    makeAutoObservable(this)
  }

  get skip(): boolean {
    return this._skip
  }

  set skip(skip: boolean) {
    this._skip = skip
  }

  readonly deleteItem = async () => {
    hide = this.skip ? Date.now() + 60 * 1000 : 0
    await home.deleteProduct(this.item)
  }
}
