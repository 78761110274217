import { makeAutoObservable, runInAction } from 'mobx'
import { can } from 'store/can'
import { CompanyStore } from 'store/company/CompanyStore'
import { me } from 'store/me'
import { onceAsync } from 'util/async'

export class CompaniesStore {
  companies: CompanyStore[] = []

  constructor() {
    makeAutoObservable(this)
  }

  load = onceAsync(async () => {
    const companies = me.permissions.companies
      .filter(co => can.ViewCompanyInformation(co.company_id))
      .map(co => new CompanyStore(co))
    runInAction(() => {
      this.companies = companies
    })
  })

  findCompany(companyId: string | undefined): CompanyStore | undefined {
    if (companyId == null) return undefined
    return this.companies.find(company => company.company.company_id === companyId)
  }

  findRoute(companyId: string | undefined): CompanyStore | undefined {
    return this.findCompany(companyId) ?? this.companies[0]
  }
}
