import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { managerSearchStore } from 'store/manager/search'
import { nil } from 'util/null'

export const CompanySelect = observer(() => {
  const value = managerSearchStore.company_id

  const companies = home.companies.companies

  const options: Option[] = companies?.map(company => ({
    value: company.company.company_id,
    label: company.title,
  }))

  const onChange = useLatestCallback((value: string | nil) => {
    managerSearchStore.company_id = value || ''
  })

  return <Field label={i18n('label.Company')}>
    <Select wide value={value} options={options} onChange={onChange} allowClear={false} />
  </Field>
})
