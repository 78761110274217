import { Affix } from 'antd'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { CommentTitle } from 'page/internal/comments/CommentTitle'
import { commenting } from 'store/comment/CommentingStore'
import { CommentEditor } from './CommentEditor'
import { CommentsList } from './CommentsList'

export const InlineComments = observer(() => {
  const store = commenting.comments
  const { ready } = store

  return <Vertical gap={12}>
    <CommentTitle />
    {!ready && <Space height={200} center>
      <Spinner />
    </Space>}
    {ready && <CommentsList />}
    {ready && <Affix offsetBottom={24}>
      <CommentEditor />
    </Affix>}
  </Vertical>
})
