import IconBag from 'asset/icon-bag.svg'
import { clsx } from 'clsx'
import { Image } from 'component/Image'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on } from 'feature/widget/TryOnStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import { DemoCatalogProduct, formatPrice } from 'type/DemoCatalog'
import css from './DemoCatalogItem.module.scss'

type Props = {
  item: DemoCatalogProduct
}

export const DemoCatalogItem = observer(({ item }: Props) => {
  const { company_id, stand, animated } = demo
  const { brandNames, buyButtons, prices } = demo.product
  const { item_id, product_name, brand_name, images, links } = item

  const sources = images ?? []
  const source = sources[0]
  const price = formatPrice(item)

  const onTry = useLatestCallback(async () => {
    if (company_id) await try_on.open(company_id, item_id)
  })

  const onBuy = useLatestCallback(async () => {
    demo.openBuyModal(item)
  })

  return <Vertical className={clsx(css.item, animated && css.animated)}>
    <Space className={css.image}>
      {source && <Image src={source} sources={sources} />}
    </Space>
    <Gap height={8} />
    <Vertical oh>
      {brandNames && brand_name && <Typo size={10} upper secondary>{brand_name}</Typo>}
      {brandNames && brand_name && <Gap height={2} />}
      <Typo size={11} medium upper>{product_name}</Typo>
      {prices && price && <Gap height={4} />}
      {prices && price && <Typo size={11} medium>{price}</Typo>}
    </Vertical>
    <Gap height={8} spring />
    <div className={css.actions}>
      <DemoCatalogButton invert action={onTry}>
        <Space gap={8}>
          <Icon name="photo_camera" size={16} style={{ marginTop: -1 }} />
          <Typo ellipsis>{i18n('demo.TryOn')}</Typo>
        </Space>
      </DemoCatalogButton>
      {buyButtons && links && <Link className={css.link} href={links} action={stand ? onBuy : undefined}>
        <DemoCatalogButton text>
          <Space gap={8}>
            <IconBag />
            <Typo>{i18n('demo.Buy')}</Typo>
          </Space>
        </DemoCatalogButton>
      </Link>}
    </div>
  </Vertical>
})
