import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'

export const NameInput = observer(() => {
  const { name, canDelete } = productDeleteModal.it

  const onChange = useLatestCallback((value: string) => {
    productDeleteModal.it.name = value
  })

  return <Input value={name} onChange={onChange} error={!canDelete} focus />
})
