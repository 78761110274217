import { Option, Select } from 'component/Select/Select'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productCreate } from 'saas/store/product/ProductCreateStore'
import { getProductTypeTitle, ProductType, STAND, TRY_ON } from 'type/product/ProductType'

export const TypeSelect = observer(() => {
  const { value, error, onChange } = productCreate.it.type

  const options: Option<never, ProductType>[] = [{
    value: TRY_ON,
    label: getProductTypeTitle(TRY_ON),
  }, {
    value: STAND,
    label: getProductTypeTitle(STAND),
  }, {
    label: i18n('product.InStoreShowcase'),
    disabled: true,
  }, {
    label: i18n('product.API'),
    disabled: true,
  }]

  return <Vertical gap={4}>
    <Typo size={12}>{i18n('product.ProductType')}</Typo>
    <Select value={value} options={options} onChange={onChange} error={error} wide allowClear={false} />
  </Vertical>
})
