import { Space } from 'component/Space'
import { Page } from 'feature/page/Page/Page'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { Products } from 'page/home/items'
import { LeftBoard } from 'page/home/LeftBoard'
import { ProductSectionCard } from 'page/home/product/ProductSectionCard'
import { SectionStub } from 'page/home/stub/SectionStub'
import { useEffect } from 'react'
import { home } from 'store/home'

export const CreatorHome = observer(() => {
  const items = home.collection.optional?.items
  const { product } = home
  const collectionId = home.collection.optional?.collectionId
  const itemId = product.optional?.itemId

  useEffectOnce(() => void home.start())

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [collectionId, itemId])

  return <Page content={() => {
    return <Space gap={16} top>
      <LeftBoard />
      {items?.present ? <Products /> : <SectionStub width={590} form={items} />}
      {product.present ? <ProductSectionCard /> : <SectionStub width={480} />}
    </Space>
  }} />
})
