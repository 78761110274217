import { Radio as RadioAntd } from 'antd'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Radio.module.scss'

type Props<Data> = {
  allowUncheck?: boolean
  checked: boolean
  children: ReactNode
  data?: Data
  error?: boolean
  onChange(checked: boolean, data: Data): void
  onFocus?(focus: boolean): void
}

export const Radio = <Data = void, >(props: Props<Data>) => {
  const { allowUncheck, checked, children, data, error } = props
  const classes = clsx(css.radio, error && css.error)

  const onClick = () => {
    if (!checked || allowUncheck) props.onChange(!checked, data!)
  }

  return <RadioAntd className={classes} checked={checked} onClick={onClick}>
    {children}
  </RadioAntd>
}
