import { development } from 'app/config/constants'
import { security } from 'app/controller/security'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { UserPicture } from 'section/Head/UserPicture'
import { can } from 'store/can'
import { home } from 'store/home'
import { me } from 'store/me'
import { compact } from 'util/array'
import css from './ProfileButton.module.scss'

export const ProfileButton = observer(() => {
  const company = home.company.optional
  const canInvite = can.InviteUsers(company?.company_id)

  const items: DropdownItem[] = compact([
    {
      key: 'profile',
      label: i18n('common.Profile'),
      action: () => modal.openProfileModal(),
    },
    development && {
      key: 'support',
      label: i18n('common.Support'),
      action: () => modal.openSupportModal(),
    },
    canInvite && { divider: true },
    canInvite && {
      key: 'invite',
      label: i18n('invite.Invite'),
      action: () => {
        const company = home.company.optional
        modal.openInviteUsersModal(company)
      },
    },
    { divider: true },
    {
      key: 'logout',
      label: i18n('label.Logout'),
      action: () => security.logout(),
    },
  ])

  return <Dropdown items={items} placement="leftTop" space={8}>
    <div className={css.profile}>
      <UserPicture picture={me.user.picture} />
    </div>
  </Dropdown>
})
