import { QRCode } from 'antd'
import { Modal } from 'component/Modal'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { buyModal } from 'page/demo-catalog/store/BuyModalStore'
import { ui } from 'store/home/ui/HomeUiStore'

export const BuyModal = observer(() => {
  const { product_name, links } = buyModal.it.item
  const { width, height } = ui.window
  const size = Math.min(width - 64, height - 64, 320)

  const onClose = useLatestCallback(async () => {
    buyModal.close()
  })

  return <Modal width={360} onCancel={onClose}>
    <Vertical center>
      <Typo size={14} center semi upper>{product_name}</Typo>
      <Gap height={16} />
      <QRCode value={links ?? ''} size={size} bordered={false} />
      <Gap height={12} />
      <Typo size={12} center medium upper pre>{i18n('demo.ScanQrCodeAndOpenShopSite')}</Typo>
    </Vertical>
  </Modal>
})
