import { LoadingPage } from 'feature/page/LoadingPage'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { ProductView } from 'page/internal/product/ProductView'
import { useState } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { Page } from 'saas/screen/Page'
import { saas } from 'saas/store/SaasController'
import { home } from 'store/home'
import { route } from 'store/route'

export const ProductPage = observer(() => {
  const productId = route.unit(1)
  const [loading, setLoading] = useState(true)

  useEffectOnce(async () => {
    await saas.initProduct(productId)
    setLoading(false)
  })

  if (loading) return <LoadingPage />
  if (!home.product.present) return <NotFoundPage />

  return <Page>
    <ProductView />
  </Page>
})
