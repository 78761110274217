import { observer } from 'mobx-react-lite'
import { AddUsersStep } from 'page/invite/AddUsersStep'
import { ErrorStep } from 'page/invite/ErrorStep'
import { FinalStep } from 'page/invite/FinalStep'
import { SelectCompanyStep } from 'page/invite/SelectCompanyStep'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { SuccessStep } from 'page/invite/SuccessStep'

export const InviteUsersView = observer(() => {
  const { step } = inviteUsers.it

  if (step === 'company') return <SelectCompanyStep />
  if (step === 'users') return <AddUsersStep />
  if (step === 'final') return <FinalStep />
  if (step === 'success') return <SuccessStep />
  if (step === 'error') return <ErrorStep />
  return null
})
