import { useCallback, useRef } from 'react'

export const useLatestCallback = <Callback extends Function>(callback: Callback): Callback => {
  const latest = useRef(callback)
  latest.current = callback

  const immutable = useCallback<Function>((...args: unknown[]) => {
    return latest.current(...args)
  }, [])

  return immutable as Callback
}
