import { Checkbox as CheckboxAntd } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Checkbox.module.scss'

type Props<Data> = {
  checked: boolean
  children: ReactNode
  data?: Data
  disabled?: boolean
  error?: boolean
  indeterminate?: boolean
  onChange(checked: boolean, data: Data): void
  className?: string
}

export const Checkbox = <Data = void, >(props: Props<Data>) => {
  const { checked, children, data, disabled, error, indeterminate, className } = props
  const classes = clsx(className, css.checkbox, error && css.error)

  const onChange = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    props.onChange(checked, data!)
  }

  return <CheckboxAntd className={classes} checked={checked} indeterminate={indeterminate}
    disabled={disabled} onChange={onChange}>
    {children}
  </CheckboxAntd>
}
