import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ChartView } from 'saas/page/statistics/chart/ChartView'
import { ItemsTab } from 'saas/page/statistics/ItemsTab'
import { Parameters } from 'saas/page/statistics/Parameters'
import { Title } from 'saas/page/statistics/Title'
import { Totals } from 'saas/page/statistics/total/Totals'
import { statistics } from 'saas/store/stat/StatisticsStore'

export const StatisticsView = observer(() => {
  const { tab } = statistics

  return <Vertical gap={16}>
    <Title />
    <Parameters />
    <Totals />
    {tab === 'items' && <ItemsTab />}
    {tab === 'chart' && <ChartView />}
  </Vertical>
})
