import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Text } from 'component/Typo/Text'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { StatsSection } from 'page/internal/collections/StatsSection'
import { CollectionStore } from 'store/collection'
import { home } from 'store/home'
import { formatDate, parseDate } from 'util/date'
import { emdash, space } from 'util/typo'
import css from './CollectionCard.module.scss'

type Props = {
  collection: CollectionStore
}

export const CollectionCard = observer(({ collection }: Props) => {
  const { name, json, stats } = collection.data
  const updated = parseDate(json.updated_at)

  const onOpen = useLatestCallback(async () => {
    await home.openCollection(collection)
  })

  return <Card vertical pad={24} className={css.card} secondary action={onOpen}>
    <Vertical gap={16} minHeight={240}>
      <Vertical gap={4}>
        <Space height={48} top>
          <Text rows={2}>
            <Typo size={16} bold>
              {name}
            </Typo>
          </Text>
        </Space>
        <Space gap={4} wrap>
          <Icon size={14} name="calendar_today" secondary />
          <Typo size={12} secondary>{i18n('label.LastUpdate')}</Typo>
          {space}
          <Typo size={12}>{formatDate(updated) ?? emdash}</Typo>
        </Space>
      </Vertical>
      <Vertical gap={16} minHeight={154} opposite>
        <Text rows={stats ? 6 : 8}>
          <Typo size={14}>
            {json.description}
          </Typo>
        </Text>
        {stats && <StatsSection stats={stats} />}
      </Vertical>
    </Vertical>
  </Card>
})
