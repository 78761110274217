import { mx } from 'store/base/mx'

type Action<V> = () => Promise<V>

export class SerialProcessStore {
  private last = mx.ref<Promise<unknown>>()

  get busy(): boolean {
    return this.last.present
  }

  async call<V>(action: Action<V>): Promise<V> {
    const last = this.last.optional
    // noinspection ES6MissingAwait
    const promise = last ? last.then(() => action()) : action()
    this.last.it = promise
    promise.finally(() => {
      if (this.last.optional === promise) {
        this.last.it = undefined
      }
    })
    return promise
  }
}
