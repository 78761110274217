import { clsx } from 'clsx'
import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { pageStore } from 'feature/page/PageStore'
import { UnknownPage } from 'feature/page/UnknownPage/UnknownPage'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { ReactElement, useState } from 'react'
import { Head } from 'section/Head/Head'
import css from './Page.module.scss'

type Props = {
  content(): ReactElement | null | undefined,
  unknown?: boolean
}

let _show = false

export const Page = observer(({ content, unknown }: Props) => {
  const { ready, loading, error } = pageStore
  const [show, setShow] = useState(_show)

  useEffectOnce(() => {
    _show = true
    setShow(true)
  })

  if (error) return <ErrorPage text={error.text} />
  if (!ready) return <LoadingPage />
  if (unknown) return <UnknownPage />

  const children = content()
  if (!children) return <LoadingPage />

  const className = clsx(css.page, show && css.show, loading && css.loading)
  return <div className={className}>
    <div className={css.head}><Head /></div>
    <div className={undefined}>{children}</div>
    <div className={css.background} />
  </div>
})
