import { mx } from 'store/base/mx'
import { cancelable, Cancellable } from 'util/cancelable'

export class LazyStore<Store> {
  private readonly ref = mx.box<Store>()
  private readonly process = mx.box<Cancellable>()
  private readonly error = mx.box(false)

  constructor(store?: Store) {
    this.ref.it = store
  }

  get idle(): boolean {
    return this.ref.it == null && this.process.it == null
  }

  get present(): boolean {
    return this.ref.it != null
  }

  get opening(): boolean {
    return this.process.it != null
  }

  get fail(): boolean {
    return this.error.it
  }

  get it(): Store {
    const it = this.ref.it
    if (it == null) throw new Error('no it')
    return it
  }

  get optional(): Store | undefined {
    return this.ref.it
  }

  set it(store: Store) {
    this.close()
    this.ref.it = store
  }

  async open(load: () => Promise<Store>, reload?: boolean): Promise<void> {
    if (this.idle || reload) await this.reopen(load)
    await this.process.it
  }

  async reopen(load: () => Promise<Store>): Promise<void> {
    this.close()
    this.process.it = cancelable(load())
      .process(it => this.ref.it = it)
      .error(e => {
        console.error('open error', e)
        return this.error.it = true
      })
      .final(() => this.process.it = undefined)
    await this.process.it
  }

  close(): void {
    this.ref.it = undefined
    this.process.it?.cancel()
    this.error.it = false
  }
}
