import { security } from 'app/controller/security'
import { Button } from 'component/Button'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { UserPicture } from 'section/Head/UserPicture'
import { can } from 'store/can'
import { home } from 'store/home'
import { me } from 'store/me'
import { compact } from 'util/array'

export const UserButton = observer(() => {
  const company = home.company.optional

  const items: DropdownItem[] = compact([
    {
      key: 'profile',
      label: i18n('common.Profile'),
      action: () => modal.openProfileModal(),
    },
    can.InviteUsers(company?.company_id) && {
      key: 'invite',
      label: i18n('invite.Invite'),
      action: () => modal.openInviteUsersModal(company),
    },
    { divider: true },
    {
      key: 'logout',
      label: i18n('label.Logout'),
      action: () => security.logout(),
    },
  ])

  return <Dropdown items={items} placement="bottomRight" space={8}>
    <Button text compact pad={[0, 8]} height={40}>
      <Space gap={4}>
        <UserPicture picture={me.user.picture} />
        <Typo size={12} secondary nowrap>{me.user.name}</Typo>
        <Icon name="keyboard_arrow_down" size={16} secondary />
      </Space>
    </Button>
  </Dropdown>
})
