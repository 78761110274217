import { useState } from 'react'

export type PreviewControl = {
  setPreview(source: string | undefined): void
  preview: string | undefined
}

export const usePreviewControl = (): PreviewControl => {
  const [preview, setPreview] = useState<string | undefined>(undefined)

  return { preview, setPreview }
}
