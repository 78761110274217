import { makeAutoObservable } from 'mobx'

export class SetStore<Item> {
  private items = new Set<Item>()

  constructor(items: Iterable<Item> = []) {
    for (const item of items) {
      this.items.add(item)
    }
    makeAutoObservable(this)
  }

  get value(): ReadonlySet<Item> {
    return this.items
  }

  set value(items: Iterable<Item>) {
    this.items.clear()
    for (const item of items) {
      this.items.add(item)
    }
  }

  has(item: Item): boolean {
    return this.items.has(item)
  }

  add(item: Item): void {
    this.items.add(item)
  }

  remove(item: Item): void {
    this.items.delete(item)
  }

  toggle(item: Item): void {
    if (this.items.has(item)) this.items.delete(item)
    else this.items.add(item)
  }

  clear(): void {
    this.items.clear()
  }

  get empty(): boolean {
    return !this.items.size
  }

  find(predicate: (item: Item) => boolean): Item | undefined {
    for (const item of this.items) {
      if (predicate(item)) return item
    }
    return undefined
  }

  map<T>(convert: (item: Item, index: number) => T): T[] {
    const array: T[] = []
    let index = 0
    for (const item of this.items) {
      array.push(convert(item, index))
      ++index
    }
    return array
  }

  filter(predicate: (item: Item) => boolean): Item[] {
    const array: Item[] = []
    for (const item of this.items) {
      if (predicate(item)) array.push(item)
    }
    return array
  }
}
