import { makeAutoObservable } from 'mobx'
import { mx } from 'store/base/mx'
import { DemoCatalogProduct } from 'type/DemoCatalog'

export class BuyModalStore {
  readonly item: DemoCatalogProduct

  constructor(item: DemoCatalogProduct) {
    makeAutoObservable(this)
    this.item = item
  }
}

export const buyModal = mx.ref<BuyModalStore>()
