import { app_name } from 'app/config/constants'
import { makeAutoObservable } from 'mobx'
import { ProductTabKey } from 'store/home/ui/ProductTabKey'
import { WindowUiStore } from 'store/home/ui/WindowUiStore'

export class HomeUiStore {
  private _productTab: ProductTabKey = 'description'
  readonly window = new WindowUiStore()

  constructor() {
    makeAutoObservable(this)
  }

  get productTab(): ProductTabKey {
    return this._productTab
  }

  set productTab(tab: ProductTabKey) {
    this._productTab = tab
  }

  get creator(): boolean {
    return app_name === 'CREATE'
  }

  get manager(): boolean {
    return app_name === 'MANAGE'
  }

  get saas(): boolean {
    return app_name === 'SAAS'
  }
}

export const ui = new HomeUiStore()
