import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { api } from 'store/api'
import { LazyStore } from 'store/base/LazyStore'
import { mx } from 'store/base/mx'
import { SingleProcessStore } from 'store/base/process/SingleProcessStore'
import { CommentsStore } from 'store/comment/CommentsStore'
import { SourcesStore } from 'store/generation/SourcesStore'
import { InferenceItem } from 'store/inference/InferenceItem'
import { InferenceListStore } from 'store/inference/InferenceListStore'
import { inferencesStore } from 'store/inference/InferencesStore'
import { InferenceStore } from 'store/inference/InferenceStore'
import { me } from 'store/me'
import { ProductDataStore } from 'store/product/ProductDataStore'
import { ProductFormStore } from 'store/product/ProductFormStore'
import { ProductPreviewStore } from 'store/product/ProductPreviewStore'
import { SaveProductProcess } from 'store/product/SaveProductProcess'
import { TagsFormStore } from 'store/product/TagsFormStore'
import {
  CreateLikeCommentRequest,
  CreateStatusCommentRequest,
  ExternalStatusChange,
  InternalStatusChange,
  LikeState,
} from 'type/Comment'
import { getReviewStatusLabel } from 'type/ExternalStatus'
import { getItemStatusLabel } from 'type/InternalStatus'
import { Item, UpdateProductRequest, UpdateProductStatusRequest } from 'type/Item'
import { minAsync } from 'util/async'
import { emdash } from 'util/typo'

function getChange<V>(prev: V | '' | null | undefined, next: V | '' | null | undefined): V | '' {
  next ||= ''
  prev ||= ''
  return prev === next ? '' : next
}

export const productStoreCache: Record<string, ProductStore | undefined> = {}

function fixStatusJson(next: Item, prev: Item) {
  const { images, source_images, selected_images } = prev
  return { ...next, images, source_images, selected_images }
}

export class ProductStore {
  readonly companyId: string | undefined
  readonly collectionId: string
  readonly itemId: string
  private readonly _data = mx.ref<ProductDataStore>()
  private readonly _form = mx.ref<ProductFormStore>()
  private readonly _tags = mx.ref<TagsFormStore>()
  private readonly _like = mx.box<LikeState>(0)
  private likeProcess?: Promise<unknown>
  readonly sources = new LazyStore<SourcesStore>()
  readonly inferences = new LazyStore<InferenceListStore>()
  readonly comments: CommentsStore
  readonly preview = new LazyStore<ProductPreviewStore>()

  constructor(json: Item, companyId?: string) {
    this.companyId = companyId || undefined
    this.collectionId = json.collection_id
    this.itemId = json.item_id
    this.afterResponse(json)
    this.comments = new CommentsStore(json)
    productStoreCache[this.itemId] = this
  }

  get visible(): boolean {
    return true
  }

  get data(): ProductDataStore {
    return this._data.it
  }

  get form(): ProductFormStore {
    return this._form.it
  }

  get tags(): TagsFormStore {
    return this._tags.it
  }

  get previewImages(): string[] {
    const previews = this.data.json.images?.map(image => image.url) ?? []
    const sources = this.data.json.source_images?.map(image => image.url) ?? []
    return [...previews, ...sources]
  }

  get selectedImages(): string[] {
    return this.data.json.selected_images?.map(image => image.url) ?? []
  }

  openPreview() {
    this.preview.it = new ProductPreviewStore(this)
  }

  closePreview() {
    this.preview.close()
  }

  readonly undo = (): void => {
    this._form.it = new ProductFormStore(this.data.json)
  }

  readonly saveStatuses = minAsync(async () => {
    const product = this.buildUpdateProductStatusRequest()
    const status = this.buildCreateStatusCommentRequest()
    const json = await api.updateProductStatus(product)
    this.afterResponse(fixStatusJson(json, this.data.json))
    const comment = await api.createStatusComment(status)
    this.comments.addComment(comment)
  })

  readonly saveTags = minAsync(async () => {
    this.tags.busy = true
    try {
      this.tags.addTextTag()
      const product = this.buildUpdateTagsRequest()
      const json = await api.updateProduct(product)
      this.afterResponse(fixStatusJson(json, this.data.json))
    } finally {
      this.tags.busy = false
    }
  })

  readonly setLike = async (like: LikeState): Promise<void> => {
    while (this.likeProcess) {
      await this.likeProcess
    }
    try {
      this.likeProcess = this.setLikeInner(like)
      await this.likeProcess
    } finally {
      this.likeProcess = undefined
    }
  }

  private async setLikeInner(like: LikeState) {
    this._like.it = like

    const comments = this.comments.getMyLikeComments()
    for (const comment of comments) {
      if (comment.id) await api.deleteComment(comment.id)
      this.comments.deleteComment(comment)
    }

    if (like) {
      const request = this.buildCreateLikeCommentRequest(like)
      const comment = await api.createLikeComment(request)
      this.comments.addComment(comment)
    }
  }

  readonly save = new SingleProcessStore(async (): Promise<void> => {
    if (!this.form.form.check()) return
    const process = new SaveProductProcess(this)
    const json = await process.process()
    this.afterResponse(json)
    notice.success(i18n('item.ItemUpdated'))
  })

  private afterResponse(json: Item) {
    this._data.it = new ProductDataStore(json)
    this._form.it = new ProductFormStore(json)
    this._tags.it = new TagsFormStore(json)
  }

  loadSources = () => this.sources.open(async () => {
    const response = await api.getSourceImages(this.itemId)
    return new SourcesStore(this.itemId, response)
  })

  loadInferences = () => this.inferences.open(async () => {
    const inferences = await inferencesStore.getItemInferences(this.itemId)
    return new InferenceListStore(inferences)
  })

  async testItem(identityId: string) {
    await this.loadInferences()

    const inference = new InferenceItem()
    this.inferences.it.addInference(inference)

    await inference.store.open(async () => {
      try {
        const response = await api.testProduct(identityId, this.itemId)
        return InferenceStore.fromResponse(response)
      } catch (e) {
        console.error(e)
        this.inferences.it.removeInference(inference)
        throw e
      }
    })
  }

  private buildUpdateProductStatusRequest(): Required<UpdateProductStatusRequest> {
    const user_id = me.user.user_id
    const collection_id = this.collectionId
    const item_id = this.itemId
    const json = this._data.it.json
    const form = this._form.it
    const internal_status = getChange(json.internal_status, form.status.value) ?? ''
    const external_status = getChange(json.external_status, form.review.value) ?? ''
    return {
      user_id,
      collection_id,
      item_id,
      internal_status,
      external_status,
    }
  }

  private buildUpdateTagsRequest(): UpdateProductRequest {
    const user_id = me.user.user_id
    const collection_id = this.collectionId
    const item_id = this.itemId
    const item_tags = this.tags.value.join(',') || ' '
    return {
      user_id,
      collection_id,
      item_id,
      item_tags,
    }
  }

  private getStatusMessage(int_status: InternalStatusChange | undefined, ext_status: ExternalStatusChange | undefined) {
    const status = int_status && '' +
      i18n('label.ItemStatus') +
      ': ' +
      (getItemStatusLabel(int_status.from) ?? emdash) +
      ' \u2192 ' +
      (getItemStatusLabel(int_status.to) ?? emdash)

    const review = ext_status && '' +
      i18n('label.ReviewStatus') +
      ': ' +
      (getReviewStatusLabel(ext_status.from) ?? emdash) +
      ' \u2192 ' +
      (getReviewStatusLabel(ext_status.to) ?? emdash)

    return [status, review].join('\n') || emdash
  }

  private buildCreateStatusCommentRequest(): CreateStatusCommentRequest {
    const user_id = me.user.user_id
    const collection_id = this.collectionId
    const item_id = this.itemId
    const json = this._data.it.json
    const form = this._form.it
    const internal_status = getChange(json.internal_status, form.status.value) ?? ''
    const external_status = getChange(json.external_status, form.review.value) ?? ''
    const change_int_status: InternalStatusChange | undefined = internal_status ? {
      from: json.internal_status || '',
      to: form.status.value || '',
    } : undefined
    const change_ext_status: ExternalStatusChange | undefined = external_status ? {
      from: json.external_status || '',
      to: form.review.value || '',
    } : undefined
    const message = this.getStatusMessage(change_int_status, change_ext_status)
    return {
      user_id,
      collection_id,
      item_id,
      message,
      is_system: 1,
      change_int_status,
      change_ext_status,
    }
  }

  private buildCreateLikeCommentRequest(is_like: 1 | 2): CreateLikeCommentRequest {
    const user_id = me.user.user_id
    const collection_id = this.collectionId
    const item_id = this.itemId
    const message = is_like === 1 ? 'Like' : is_like === 2 ? 'Dislike' : emdash
    return {
      user_id,
      collection_id,
      item_id,
      message,
      is_like,
    }
  }
}
