// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusSwitch-button-aa95{flex:1 1 0;border-radius:0}.StatusSwitch-button-aa95:first-child{border-top-left-radius:var(--ui-border-radius);border-bottom-left-radius:var(--ui-border-radius)}.StatusSwitch-button-aa95:last-child{border-top-right-radius:var(--ui-border-radius);border-bottom-right-radius:var(--ui-border-radius)}.StatusSwitch-button-aa95:not(:first-child){margin-left:-1px}.StatusSwitch-button-aa95:hover,.StatusSwitch-button-aa95:active,.StatusSwitch-button-aa95:focus{z-index:1}.StatusSwitch-button-aa95.StatusSwitch-active-aa95{z-index:2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/tryon/Details/StatusSwitch.module.scss"],"names":[],"mappings":"AAAA,0BACE,UAAA,CACA,eAAA,CAEA,sCACE,8CAAA,CACA,iDAAA,CAGF,qCACE,+CAAA,CACA,kDAAA,CAGF,4CACE,gBAAA,CAGF,iGACE,SAAA,CAGF,mDACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StatusSwitch-button-aa95`,
	"active": `StatusSwitch-active-aa95`
};
module.exports = ___CSS_LOADER_EXPORT___;
