import { security } from 'app/controller/security'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import css from './LogoutItem.module.scss'

export const LogoutItem = observer(() => {
  const onClick = useLatestCallback(() => {
    security.logout()
  })

  return <Space className={css.item} gap={4} wide onClick={onClick}>
    <Icon name="logout" size={20} />
    <Typo size={12}>{i18n('label.Logout')}</Typo>
  </Space>
})
