import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import css from 'page/invite/ErrorStep.module.scss'
import { closeInviteUsers, inviteUsers } from 'page/invite/store/InviteUsersStore'

export const ErrorStep = observer(() => {
  const { fails } = inviteUsers.it

  const onClose = useLatestCallback(() => {
    closeInviteUsers()
  })

  return <Vertical gap={24}>
    <Typo size={16} secondary>
      {i18n('common.Error')}
    </Typo>

    <Typo size={12}>
      {i18n('invite.SomeInvitationsFailed')}
    </Typo>

    {fails.length && <Card pad={8} secondary maxWidth={400}>
      <OverlayScrollbarsComponent className={css.users}>
        <Vertical gap={8}>
          {fails.map(user => <Space key={user.key} gap={12}>
            <Typo size={12} ellipsis>{user.name.value}</Typo>
            <Typo size={12} ellipsis secondary>{user.email.value}</Typo>
          </Space>)}
        </Vertical>
      </OverlayScrollbarsComponent>
    </Card>}

    <Space gap={12} right>
      <Button action={onClose}>
        {i18n('common.Close')}
      </Button>
    </Space>
  </Vertical>
})
