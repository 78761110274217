// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popover-popover-f5aa{box-shadow:var(--ant-box-shadow-secondary);border-radius:var(--ui-border-radius);overflow:hidden}.Popover-popover-f5aa .ant-popover-inner{width:fit-content;max-width:calc(100vw - 48px);overflow:auto !important}.Popover-child-f5aa{display:flex}`, "",{"version":3,"sources":["webpack://./src/component/Popover/Popover.module.scss"],"names":[],"mappings":"AAAA,sBACE,0CAAA,CACA,qCAAA,CACA,eAAA,CAEA,yCACE,iBAAA,CACA,4BAAA,CACA,wBAAA,CAIJ,oBACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `Popover-popover-f5aa`,
	"child": `Popover-child-f5aa`
};
module.exports = ___CSS_LOADER_EXPORT___;
