import { makeAutoObservable } from 'mobx'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { WebhookUpdate } from 'type/CompanyConfig'

export class WebhookFormStore {
  private _webhook = ''

  constructor(company: CompanySaasStore) {
    makeAutoObservable(this)
    this._webhook = company.config.json.webhook?.trim() || ''
  }

  get webhook(): string {
    return this._webhook
  }

  set webhook(value: string) {
    this._webhook = value
  }

  buildUpdate(): WebhookUpdate {
    const webhook = this._webhook.trim() || undefined
    return { webhook }
  }
}
