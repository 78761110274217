import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ArticleInput } from 'page/internal/search/ArticleInput'
import { BrandSelect } from 'page/internal/search/BrandSelect'
import { CollectionSelect } from 'page/internal/search/CollectionSelect'
import { CompanySelect } from 'page/internal/search/CompanySelect'
import { ExternalIdInput } from 'page/internal/search/ExternalIdInput'
import { ItemIdInput } from 'page/internal/search/ItemIdInput'
import { ReviewSelect } from 'page/internal/search/ReviewSelect'
import { SortSelect } from 'page/internal/search/SortSelect'
import { StatusSelect } from 'page/internal/search/StatusSelect'
import { managerSearchStore } from 'store/manager/search'

export const Filters = observer(() => {
  const { pin } = managerSearchStore

  const onPin = useLatestCallback(() => {
    managerSearchStore.pin = !pin
  })

  const onReset = useLatestCallback(() => {
    managerSearchStore.reset()
  })

  const onApply = useLatestCallback(async () => {
    await managerSearchStore.search()
  })

  return <Vertical gap={16} width={320}>
    <Space gap={16} opposite>
      <Space gap={8}>
        <Icon size={16} name="tune" />
        <Typo size={16} bold>
          {i18n('search.Filters')}
        </Typo>
      </Space>
      <IconButton action={onPin}>
        <Icon size={16} name="keep" secondary primary={pin} />
      </IconButton>
    </Space>
    <Vertical gap={8}>
      <CompanySelect />
      <CollectionSelect />
      <BrandSelect />
      <StatusSelect />
      <ReviewSelect />
      <ItemIdInput />
      <ExternalIdInput />
      <ArticleInput />
      <SortSelect />
      <Space opposite>
        <Button link compact action={onReset}>
          {i18n('form.Reset')}
        </Button>
        <Button primary action={onApply}>
          {i18n('search.Apply')}
        </Button>
      </Space>
    </Vertical>
  </Vertical>
})
