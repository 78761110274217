// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingPage-loading-0af0{display:flex;align-items:center;justify-content:center;width:100%;height:100%;opacity:0;transition:opacity 3s ease-in}.LoadingPage-show-0af0{opacity:1}`, "",{"version":3,"sources":["webpack://./src/feature/page/LoadingPage/LoadingPage.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,6BAAA,CAGF,uBACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `LoadingPage-loading-0af0`,
	"show": `LoadingPage-show-0af0`
};
module.exports = ___CSS_LOADER_EXPORT___;
