import { clsx } from 'clsx'
import { Image } from 'component/Image/Image'
import { useEffect, useRef, useState } from 'react'
import { getFitSrc } from 'util/image'
import css from './FitImage.module.scss'

type Props = {
  className?: string
  src: string
}

export const FitImage = (props: Props) => {
  const { className, src } = props
  const [resized, setResized] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const root = ref.current
    if (!root) return

    const { width, height } = root.getBoundingClientRect()
    setResized(getFitSrc(src, width, height, false))
  }, [src])

  return <div ref={ref} className={clsx(className, css.root)}>
    {resized && <Image src={resized} />}
  </div>
}
