import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { captcha } from 'store/captcha'
import css from './Captcha.module.scss'

type Props = {
  error?: boolean
  onSuccess(): void
}

export const Captcha = ({ error, onSuccess }: Props) => {
  const onRef = useLatestCallback((container: HTMLElement | null) => {
    if (container) void captcha.render(container, onSuccess)
  })

  return <div className={clsx(css.captcha, error && css.error)}>
    <div ref={onRef} />
  </div>
}
