// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Field-field-54e7{position:relative;padding-top:8px}@media(width <= 320px){.Field-field-54e7{width:100%}}.Field-wide-54e7{width:100%}.Field-label-54e7{z-index:1;position:absolute;top:0;left:8px;max-width:calc(100% - 16px);padding:0 4px;line-height:16px;color:var(--ui-text-color-secondary);background-color:var(--ui-background-color);border-radius:var(--ui-border-radius);overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px}.Field-text-54e7{z-index:1;position:relative}.Field-button-54e7>*{width:100%;height:auto;min-height:42px;padding:9px !important}.Field-input-54e7>*{height:42px}.Field-area-54e7>textarea{padding-block:9px}.Field-select-54e7>.ant-select{min-height:42px}.Field-select-54e7 .ant-select-selector{padding-top:6px;padding-bottom:6px;padding-left:8px}.Field-wide-54e7 .Field-select-54e7>.ant-select{width:100%}.Field-content-54e7{padding:10px;border:1px solid var(--ui-border-color);border-radius:var(--ui-border-radius);transition:border 200ms ease-in-out}.Field-error-54e7 .Field-content-54e7{border-color:var(--ui-border-color-error)}`, "",{"version":3,"sources":["webpack://./src/component/Field/Field.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,eAAA,CAGF,uBACE,kBACE,UAAA,CAAA,CAIJ,iBACE,UAAA,CAGF,kBACE,SAAA,CACA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,2BAAA,CACA,aAAA,CACA,gBAAA,CACA,oCAAA,CACA,2CAAA,CACA,qCAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAGF,iBACE,SAAA,CACA,iBAAA,CAGF,qBACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,sBAAA,CAGF,oBACE,WAAA,CAGF,0BACE,iBAAA,CAIA,+BACE,eAAA,CAGF,wCACE,eAAA,CACA,kBAAA,CACA,gBAAA,CAKF,gDACE,UAAA,CAIJ,oBACE,YAAA,CACA,uCAAA,CACA,qCAAA,CACA,mCAAA,CAGF,sCACE,yCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `Field-field-54e7`,
	"wide": `Field-wide-54e7`,
	"label": `Field-label-54e7`,
	"text": `Field-text-54e7`,
	"button": `Field-button-54e7`,
	"input": `Field-input-54e7`,
	"area": `Field-area-54e7`,
	"select": `Field-select-54e7`,
	"content": `Field-content-54e7`,
	"error": `Field-error-54e7`
};
module.exports = ___CSS_LOADER_EXPORT___;
