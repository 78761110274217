// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SortButton-button-6f31{display:flex;font-size:12px}@media all and (min-width: 760px){.SortButton-button-6f31{font-size:11px}}.SortButton-button-6f31 svg{margin-right:11px;transition:transform .3s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/SortButton/SortButton.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,cAAA,CAEA,kCAJF,wBAKI,cAAA,CAAA,CAGF,4BACE,iBAAA,CACA,oCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `SortButton-button-6f31`
};
module.exports = ___CSS_LOADER_EXPORT___;
