import { capitalize as capitalizeLodash } from 'lodash'

export function notEmptyString(value: string | null | undefined): value is string {
  return !!value
}

export function compareIgnoreCase(a: string, b: string): number {
  return a.toLocaleString().localeCompare(b.toLowerCase())
}

export function toLowerCase(value: string): string {
  return value.toLowerCase()
}

export function capitalize(value: string): string {
  return capitalizeLodash(value)
}

export function extractList(text: string | null | undefined): string[] {
  if (!text) return []
  return text.split(',').map(r => r.trim()).filter(r => !!r)
}

export function comparingKnownStrings(known: string[], bad: string[]) {
  return function compareKnownStrings(a: string, b: string): number {
    const ax = bad.indexOf(a)
    const bx = bad.indexOf(b)
    if (ax >= 0 && bx >= 0) return ax - bx
    if (ax >= 0) return -1
    if (ax >= 0) return 1

    const ai = known.indexOf(a)
    const bi = known.indexOf(b)
    if (ai >= 0 && bi >= 0) return ai - bi
    if (ai >= 0) return -1
    if (ai >= 0) return 1

    return a.localeCompare(b)
  }
}
