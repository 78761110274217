// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaasScreen-screen-ead2{position:relative;display:flex;background-color:var(--ui-background-color-weak)}.SaasScreen-center-ead2{display:flex;flex-direction:column;flex:1 1 0;min-height:100vh}.SaasScreen-top-ead2{flex:0 0 auto;display:block}.SaasScreen-content-ead2{flex:1 0 auto}`, "",{"version":3,"sources":["webpack://./src/saas/screen/SaasScreen.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,YAAA,CACA,gDAAA,CAGF,wBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,gBAAA,CAGF,qBACE,aAAA,CACA,aAAA,CAGF,yBACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen": `SaasScreen-screen-ead2`,
	"center": `SaasScreen-center-ead2`,
	"top": `SaasScreen-top-ead2`,
	"content": `SaasScreen-content-ead2`
};
module.exports = ___CSS_LOADER_EXPORT___;
