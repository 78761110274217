// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Glass-glass-0123{position:relative}.Glass-fill-0123{width:100%;height:100%}.Glass-less-0123{width:fit-content;height:fit-content}`, "",{"version":3,"sources":["webpack://./src/component/Glass/Glass.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CAGF,iBACE,UAAA,CACA,WAAA,CAGF,iBACE,iBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"glass": `Glass-glass-0123`,
	"fill": `Glass-fill-0123`,
	"less": `Glass-less-0123`
};
module.exports = ___CSS_LOADER_EXPORT___;
