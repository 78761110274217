// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollectionCard-card-f321{width:320px}@media(width <= 480px){.CollectionCard-card-f321{width:calc(100vw - 48px)}}`, "",{"version":3,"sources":["webpack://./src/page/internal/collections/CollectionCard.module.scss"],"names":[],"mappings":"AAAA,0BACE,WAAA,CAEA,uBAHF,0BAII,wBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CollectionCard-card-f321`
};
module.exports = ___CSS_LOADER_EXPORT___;
