import { reaction } from 'mobx'
import { LazyStore } from 'store/base/LazyStore'
import { mx } from 'store/base/mx'
import { getNextKey } from 'util/id'

export class ItemStore<Stub, Store> {
  readonly key = getNextKey()
  readonly stub = mx.ref<Stub>()
  readonly store: LazyStore<Store>

  constructor(stub?: Stub, store?: Store) {
    this.stub.it = stub
    this.store = new LazyStore(store)
    reaction(() => this.store.present, present => present && this.stub.close())
  }
}
