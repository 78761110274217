import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'
import { api } from 'store/api'
import { cfg } from 'store/cfg'
import { me } from 'store/me'
import { MasksSourceResponse, ShapeType, Source, UploadSourceRequest, UploadSourceResponse } from 'type/Source'
import { minAsync } from 'util/async'
import { getNextId } from 'util/id'

type SourceImages = [string | undefined, string | undefined, string | undefined, string | undefined]

type Args = {
  item_id: string
  source_image_id: string | undefined
  image_id: string | undefined
  image_url: string | undefined
  shape_type: ShapeType
  prompt: string
  images: SourceImages
}

export class SourceStore {
  readonly id = getNextId()
  item_id: string
  source_image_id?: string
  image_id?: string
  image_url?: string
  shape_type: ShapeType
  prompt: string
  promptOriginal: string
  images: SourceImages

  static fromSource(source: Source): SourceStore {
    const { images_source, images_webp, images_alpha, images_mask } = source
    return new SourceStore({
      item_id: source.item_id,
      source_image_id: source.source_image_id,
      image_id: images_source[0]?.id,
      image_url: images_source[0]?.url,
      shape_type: source.shape_type,
      prompt: source.prompt ?? '',
      images: [
        images_source[0]?.url,
        images_webp[0]?.url,
        images_alpha[0]?.url,
        images_mask[0]?.url,
      ],
    })
  }

  static fromRequest(request: Required<UploadSourceRequest>, image?: string): SourceStore {
    return new SourceStore({
      item_id: request.item_id,
      source_image_id: undefined,
      image_id: undefined,
      image_url: undefined,
      shape_type: request.shape_type,
      prompt: request.prompt,
      images: image ? [image, undefined, undefined, undefined] : [undefined, undefined, undefined, undefined],
    })
  }

  private constructor(args: Args) {
    this.item_id = args.item_id
    this.source_image_id = args.source_image_id
    this.image_id = args.image_id
    this.image_url = args.image_url
    this.shape_type = args.shape_type
    this.prompt = args.prompt
    this.promptOriginal = args.prompt
    this.images = args.images
    makeAutoObservable(this)
  }

  get manualLink(): string | undefined {
    try {
      const { gradio, mosaica_api } = cfg.cfg
      if (!gradio || !mosaica_api) return undefined

      const id = this.source_image_id ?? ''
      const image = this.image_url ?? ''

      const url = new URL(gradio)
      const params = url.searchParams

      params.append('segment_image_url', image)
      params.append('segment_save_url', mosaica_api + '/set_gradio_segments_nt')
      params.append('segment_save_param_type', 'item_ipa_mask')
      params.append('segment_save_param_id', id)
      params.append('segment_bucket_path', 'item_ipa_mask/' + id + '/')
      params.append('tab_name', 'Segment')

      return url.toString()
    } catch (e) {
      console.error('cannot create gradio link', e)
      return undefined
    }
  }

  get canEdit(): boolean {
    return this.source_image_id != null
  }

  get canSave(): boolean {
    return this.source_image_id != null && this.prompt != this.promptOriginal
  }

  get canDelete(): boolean {
    return this.image_id != null && this.source_image_id != null
  }

  get canAutomatic(): boolean {
    return this.source_image_id != null && this.image_url != null
  }

  setPrompt(value: string) {
    this.prompt = value
  }

  readonly save = minAsync(async () => {
    const request = this.buildUpdateRequest()
    await api.updateSourceImage(request)
    this.promptOriginal = request.prompt
  })

  readonly runAutomatic = minAsync(async () => {
    const update = this.buildUpdateRequest()

    const _prompt = this.generatePrompt()
    const _masks = this.calculateMasks()

    const prompt = await _prompt
    await api.updateSourceImage({ ...update, prompt })
    this.afterPrompt(prompt)
    notice.success(i18n('source.PromptGenerated'))

    const masks = await _masks
    this.afterMasks(masks)
    notice.success(i18n('source.MasksCreated'))
  })

  afterUpload(response: UploadSourceResponse) {
    const item = response.item_image
    const source = response.source_image

    this.item_id = item.item_id
    this.source_image_id = item.source_image_id
    this.image_id = source.asset_id
    this.image_url = source.url
    this.shape_type = item.shape_type
    this.prompt = item.prompt
    this.promptOriginal = item.prompt
    this.images = [source.url, undefined, undefined, undefined]
  }

  private buildUpdateRequest() {
    const user_id = me.user.user_id
    const { item_id, shape_type, prompt } = this
    const source_id = this.source_image_id

    if (!source_id) throw new Error('no source id')

    const request = { user_id, item_id, source_id, shape_type, prompt }
    return request
  }

  private async generatePrompt() {
    const user_id = me.user.user_id
    const url = this.image_url

    if (!url) throw new Error('no url')

    const request = { user_id, url }
    return await api.generateSourcePrompt(request)
  }

  private async calculateMasks() {
    const user_id = me.user.user_id
    const source_id = this.source_image_id
    const url = this.image_url

    if (!source_id) throw new Error('no source id')
    if (!url) throw new Error('no url')

    const request = { user_id, source_id, url }
    return await api.calculateSourceMasks(request)
  }

  private afterPrompt(prompt: string) {
    this.prompt = prompt
    this.promptOriginal = prompt
  }

  private afterMasks(masks: MasksSourceResponse) {
    const first = this.images[0]
    const { webp, skin, alpha } = masks
    this.images = [first, webp, alpha, skin]
  }
}
