import { language } from 'app/config/constants'
import { LanguageDetectorModule } from 'i18next'

export class LanguageDetector implements LanguageDetectorModule {
  readonly type = 'languageDetector' as const

  detect() {
    return language
  }
}
