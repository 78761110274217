// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-tag-7a74{margin:0;border-radius:12px;font-size:10px}.Tag-action-7a74:not(.Tag-busy-7a74){cursor:pointer}.Tag-action-7a74.Tag-busy-7a74{cursor:wait}.Tag-action-7a74:not(.Tag-busy-7a74):hover{border-color:var(--ui-primary-color)}`, "",{"version":3,"sources":["webpack://./src/component/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA,cACE,QAAA,CACA,kBAAA,CACA,cAAA,CAGF,qCACE,cAAA,CAGF,+BACE,WAAA,CAGF,2CACE,oCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag-tag-7a74`,
	"action": `Tag-action-7a74`,
	"busy": `Tag-busy-7a74`
};
module.exports = ___CSS_LOADER_EXPORT___;
