import { uniq } from 'util/array'
import { extractList } from 'util/string'

export type Member = {
  user_id: string
  user_name: string

  created_at: string
  last_update: string
  last_request?: string

  user_email?: string
  user_phone?: string
  user_descr?: string
  user_pic?: string

  permissions_list?: string

  app_name?: string
  access?: 0 | 1
  admin?: 0 | 1

  is_deleted?: 0 | 1
  is_external?: 0 | 1
}

export type MemberPreview = {
  user_name: string
  user_email: string
  user_phone: string
  user_descr: string
  user_pic?: string
  permissions_list: string
}

export type MemberUpdate = {
  user_id: string
  user_name: string

  created_at: string
  last_update: string
  last_request?: string

  user_email?: string
  user_phone?: string
  user_descr?: string
  user_pic?: string
}

export type UpdateMemberRequest = {
  user_id: string
  member_id: string
  name: string
  email?: string
  phone?: string
  descr?: string
  pic?: File
}

export type UpdateMemberPermissionsRequest = {
  company_id: string
  user_id: string
  member_id: string
  permissions: string
}

export function extractPermissions(permissions_list: string | undefined): string[] {
  return uniq(extractList(permissions_list)).sort()
}
