import { AppSvg } from 'app/config/icon'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import css from './CreatorLogo.module.scss'

export const CreatorLogo = observer(() => {
  const onHome = useLatestCallback(() => home.goHome())

  return <Link path={'/'}>
    <Space height={42} gap={8} fit onClick={onHome}>
      <AppSvg className={css.svg} />
      <div className={css.sep} />
      <div className={css.title}>
        {i18n('header.CreatorStudio')}
      </div>
    </Space>
  </Link>
})
