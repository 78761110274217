// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage-error-4f6e{display:flex;align-items:center;justify-content:center;width:100%;height:100%;animation:1s ease-in-out ErrorPage-appearance-4f6e}@keyframes ErrorPage-appearance-4f6e{from{opacity:0}to{opacity:1}}`, "",{"version":3,"sources":["webpack://./src/feature/page/ErrorPage/ErrorPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,kDAAA,CAGF,qCACE,KACE,SAAA,CAGF,GACE,SAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `ErrorPage-error-4f6e`,
	"appearance": `ErrorPage-appearance-4f6e`
};
module.exports = ___CSS_LOADER_EXPORT___;
