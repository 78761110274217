import { Button as ButtonAntd } from 'antd'
import { clsx } from 'clsx'
import { ClickAction, useOnClickAction } from 'hook/useOnClickAction'
import { ReactNode } from 'react'
import css from './IconButton.module.scss'

export type IconButtonProps = {
  action?: ClickAction | false
  children?: ReactNode
  className?: string
  disabled?: boolean
  large?: boolean
  light?: boolean
  link?: boolean
  size?: 'small' | 'middle' | 'large'
  small?: boolean
  title?: string
  success?: boolean
  error?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
}

export function IconButton(props: IconButtonProps) {
  const { children, disabled, large, light, link, small, title, success, error, primary, secondary, tertiary } = props
  const { busy, onClick } = useOnClickAction(props)

  const type = link ? 'link' : 'text'
  const size = props.size ? props.size : large ? 'large' : small ? 'small' : undefined
  const sizeCn = large ? css.large : small ? css.small : undefined
  const color = error ? css.error : success ? css.success : primary ? css.primary :
    secondary ? css.secondary : tertiary ? css.tertiary : light ? css.light : undefined
  const className = clsx(props.className, css.button, sizeCn, color, busy && 'ant-btn-loading')

  return <ButtonAntd type={type} size={size} disabled={disabled || busy} title={title}
    className={className} onClick={onClick}>
    {children}
  </ButtonAntd>
}
