import { TagsInput } from 'component/Select'
import { FormField } from 'form/FormField'
import { FieldStore } from 'form/store/FieldStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'

type Props = {
  field: FieldStore<readonly string[]>
  label: string
  placeholder?: string
}

export const TagsField = observer(({ field, label, placeholder }: Props) => {
  const { value } = field

  const onChange = useLatestCallback((value: readonly string[]) => {
    field.value = value
  })

  return <FormField field={field} label={label}>
    <TagsInput value={value} placeholder={placeholder} onChange={onChange} />
  </FormField>
})
