export class UploadItem {
  constructor(
    readonly line: number,
    readonly text: string,
    readonly url: string = '',
    readonly item_id: string = '',
    readonly item_name: string = '',
  ) {
  }
}
