import { observer } from 'mobx-react-lite'
import { CreatorHead } from 'section/Head/creator/CreatorHead'
import { ManagerHead } from 'section/Head/manager/ManagerHead'
import { home } from 'store/home'

export const Head = observer(() => {
  const manager = home.ui.manager

  if (manager) return <ManagerHead />
  else return <CreatorHead />
})
