import { language } from 'app/config/constants'
import { makeAutoObservable } from 'mobx'
import { api } from 'store/api'
import { DemoWidget } from 'type/Demo'
import { skipAsync } from 'util/async'
import { delay } from 'util/promise'

function extractHost(src: string): string | undefined {
  try {
    return new URL(src).host
  } catch {
    return undefined
  }
}

type Tune = {
  saas?: boolean
  stand?: boolean
  turn?: number
}

class TryOnStore {
  private _ready = false
  private _error = false
  private company_id = ''
  private tune: Tune = {}
  private _config?: DemoWidget
  private element?: HTMLScriptElement

  constructor() {
    makeAutoObservable(this)
  }

  get ready(): boolean {
    return this._ready
  }

  get error(): boolean {
    return this._error
  }

  readonly open = skipAsync(async (company_id: string, item?: string, tune?: Tune) => {
    await this.update(company_id, tune)
    if (!this.ready) return
    if (!window.showoff?.open) return
    window.showoff.open({ item })
  })

  async close() {
    await this.update(undefined)
  }

  readonly init = async (company_id: string | undefined, tune?: Tune) => this.update(company_id, tune)

  private readonly update = skipAsync(async (company_id: string | undefined, tune?: Tune) => {
    if (this.company_id === company_id) return
    try {
      this.destroy()
      this._ready = false
      this._error = false
      this.company_id = company_id ?? ''
      this.tune = tune ?? {}
      if (!company_id) return
      await this.prepare()
      await this.create()
      this._ready = true
    } catch (e) {
      console.error('init failed', e)
      this._error = true
    }
  })

  private set config(value: DemoWidget) {
    this._config = value
  }

  private async prepare() {
    this.config = await api.getWidget(this.company_id, this.tune?.saas)
  }

  private async create() {
    if (!this._config) throw new Error('no config')

    const { stand, turn } = this.tune
    const { src, client, company, access } = this._config
    const lang = language
    const options = { client, company, access, lang, stand, turn }
    window.showoff = { options }

    const element = document.createElement('script')
    element.src = src
    element.async = true
    document.head.appendChild(element)

    this.element = element

    while (!window.showoff?.open) {
      await delay(100)
    }
  }

  private destroy() {
    if (!this.element) return

    const host = extractHost(this.element.src)
    for (const element of document.querySelectorAll('script')) {
      if (host && host === extractHost(element.src)) {
        element.remove()
      }
    }
    document.querySelector('[class|=showoff-widget-widget]')?.remove()
    this.element = undefined
  }
}

export const try_on = new TryOnStore()
