// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DemoButton-control-a401{padding:0;font:inherit;font-size:16px;background:none;border:none;cursor:pointer}.DemoButton-button-a401{display:flex;align-items:center;justify-content:center;padding:0 16px;height:42px;border-radius:8px;transition:opacity 100ms ease-in-out}.DemoButton-button-a401:active{opacity:80%}.DemoButton-button-a401:disabled{opacity:60%}.DemoButton-text-a401{color:var(--ui-text-color);background-color:var(--ui-background-color-weak)}.DemoButton-text-a401:hover{background-color:var(--ui-background-color-soft)}.DemoButton-text-a401.DemoButton-active-a401{color:var(--ui-primary-color)}.DemoButton-outline-a401{color:var(--ui-text-color);background-color:var(--ui-background-color);border:1px solid var(--ui-border-color)}.DemoButton-outline-a401.DemoButton-active-a401{color:var(--ui-primary-color);border-color:var(--ui-primary-color)}.DemoButton-invert-a401{color:var(--ui-background-color);background-color:var(--ui-text-color)}`, "",{"version":3,"sources":["webpack://./src/page/demo/DemoButton.module.scss"],"names":[],"mappings":"AAAA,yBACE,SAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CAGF,wBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,oCAAA,CAGF,+BACE,WAAA,CAGF,iCACE,WAAA,CAGF,sBACE,0BAAA,CACA,gDAAA,CAGF,4BACE,gDAAA,CAGF,6CACE,6BAAA,CAGF,yBACE,0BAAA,CACA,2CAAA,CACA,uCAAA,CAGF,gDACE,6BAAA,CACA,oCAAA,CAGF,wBACE,gCAAA,CACA,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `DemoButton-control-a401`,
	"button": `DemoButton-button-a401`,
	"text": `DemoButton-text-a401`,
	"active": `DemoButton-active-a401`,
	"outline": `DemoButton-outline-a401`,
	"invert": `DemoButton-invert-a401`
};
module.exports = ___CSS_LOADER_EXPORT___;
