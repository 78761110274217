import { app_domain } from 'app/config/constants'
import { getAppLink } from 'app/config/title'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import css from './DemoCatalogLogo.module.scss'
import LogoDresscodeSvg from './logo-dresscode.svg'
import LogoShowoffSvg from './logo-showoff.svg'

export const DemoCatalogLogo = observer(() => {
  const { logo } = demo.product
  const link = getAppLink()
  const dresscode = app_domain === 'dresscode'

  return <Link href={link}>
    <Space className={css.logo}>
      {logo ? <img src={logo} /> : dresscode ? <LogoDresscodeSvg /> : <LogoShowoffSvg />}
    </Space>
  </Link>
})
