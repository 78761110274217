import { AxisTickProps } from '@nivo/axes'
import { formatDate } from 'util/date'
import css from './DateTick.module.scss'

export function renderDateTick(props: AxisTickProps<number>) {
  const { x, y, textX, textY, textAnchor, textBaseline, value } = props
  const transform = `translate(${textX}, ${textY}) rotate(0)`
  const date = formatDate(new Date(value), 'DD MMM')
  const year = formatDate(new Date(value), 'YYYY')

  return <g className={css.tick} transform={`translate(${x}, ${y})`}>
    <text dominantBaseline={textBaseline} textAnchor={textAnchor} transform={transform}>
      <tspan className={css.date} x={0} dy={0}>{date}</tspan>
      <tspan className={css.year} x={0} dy={16}>{year}</tspan>
    </text>
  </g>
}
