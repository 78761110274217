import { getMarginStyle, Margin, styles } from 'util/style'

type Props = {
  height?: number
  width?: number
  spring?: boolean
  margin?: Margin
}

export function Gap(props: Props) {
  const { width, height, spring } = props
  const flex = spring ? '1 1 auto' : undefined
  const margin = getMarginStyle(props.margin)
  const style = styles({ width, height, flex, ...margin })
  return <div style={style} />
}
