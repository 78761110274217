type Value = string | null | undefined

export function matchSearch(search: string, values: Value[]): boolean {
  const parts = search.toLowerCase().split(/\s+/).filter(s => !!s)
  return values.some(value => {
    if (value == null) return false
    const text = value.toLowerCase()
    return parts.every(part => text.includes(part))
  })
}
