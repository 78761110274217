import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'

export const Title = observer(() => {

  return <Space>
    <Typo size={18} bold>
      {i18n('title.Dashboard')}
    </Typo>
  </Space>
})
