import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { totals } from 'saas/store/stat/TotalsStore'
import css from './TotalCard.module.scss'

type Props = {
  num: number | undefined
  unit: string
}

export const TotalCard = observer(({ num, unit }: Props) => {
  const { ready, error } = totals
  const hide = !ready || error

  return <Card minWidth={200} height={100} center error={error}>
    <Vertical gap={4} center>
      <Typo className={clsx(css.num, hide && css.hide)} size={24} bold>
        {num ?? 0}
      </Typo>
      <Typo size={12}>{unit}</Typo>
    </Vertical>
  </Card>
})
