import { FormStore } from 'form/store/FormStore'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'

export class ProfileModalStore {
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly phone = this.form.field(new StringStore(''))

  constructor() {
    this.name.value = me.user.name ?? ''
  }
}
