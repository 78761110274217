import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { useEffectOnce } from 'hook/useEffectOnce'
import { Icon } from 'icon/Icon'
import { memo, useRef } from 'react'
import css from './Deleting.module.scss'

type Props = {
  delay: number
  undo: () => void
}

function blur(element: Element | null): void {
  if (element instanceof HTMLElement) element.blur()
}

export const Deleting = memo(({ delay, undo }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const animationDuration = `${delay}ms`

  useEffectOnce(() => {
    blur(document.activeElement)
  })

  useEffectOnce(() => {
    const box = ref.current
    const parent = box?.parentElement
    if (!parent) return

    const listener = (event: FocusEvent) => {
      const target = event.target
      if (target instanceof Element && !box.contains(target)) {
        blur(target)
      }
    }

    parent.addEventListener('focusin', listener)
    return () => parent.removeEventListener('focusin', listener)
  })

  return <div className={css.box} ref={ref}>
    <div className={clsx(css.shadow)} style={{}} />
    <IconButton small action={undo} className={css.undo}>
      <Icon name="undo" size={14} />
    </IconButton>
    <svg className={css.svg} viewBox="22 22 44 44">
      <circle cx="44" cy="44" r="20" fill="none" strokeWidth="4" style={{ animationDuration }} />
    </svg>
  </div>
})
