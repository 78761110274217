import { notice } from 'app/notice'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Sticker } from 'component/Glass'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import hljs from 'highlight.js'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useMemo } from 'react'
import { tryon } from 'saas/store/product/ProductSaasController'
import { copyText } from 'util/browser'

export const EmbedCode = observer(() => {
  const { code } = tryon.it
  const high = useMemo(() => {
    return hljs.highlight(code, { language: 'html' }).value
  }, [code])

  const onCopy = useLatestCallback(async () => {
    await copyText(code)
    notice.success(i18n('message.Copied'))
  })

  return <Vertical>
    <Space gap={16} opposite>
      <Typo size={14}>{i18n('product.EmbedCode')}</Typo>
      <Link href="/docs/widget">
        {i18n('label.Documentation')}
      </Link>
    </Space>
    <Gap height={8} />
    <Card minHeight={200} top>
      <Sticker pin={[4, 4, null, null]}>
        <IconButton small action={onCopy}>
          <Icon name="content_copy" secondary />
        </IconButton>
      </Sticker>
      <OverlayScrollbarsComponent>
        <Space>
          <Space pad={[4, 12, 16, 8]}>
            <Typo size={12} mono code>
              <div dangerouslySetInnerHTML={{ __html: high }} />
            </Typo>
          </Space>
        </Space>
      </OverlayScrollbarsComponent>
    </Card>
    <Gap height={8} />
  </Vertical>
})
