import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { TabButton } from 'page/home/product/TabButton'

export const Tabs = observer(() => {
  return <Space gap={8} wrap>
    <TabButton tab="description">
      {i18n('item.ItemDescription')}
    </TabButton>
    <TabButton tab="generation">
      {i18n('item.AiGeneration')}
    </TabButton>
    <TabButton tab="results">
      {i18n('item.TestResults')}
    </TabButton>
  </Space>
})
