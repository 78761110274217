import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { parseIntFinite } from 'util/number'

export const DelayInput = observer(() => {
  const { delay } = tryon.it.edit
  const value = delay?.toFixed() ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.delay = parseIntFinite(value)
  })

  return <>
    <Typo size={14}>{i18n('widget.DelayForOpeningTheEmailPopupOnResultPage')}</Typo>
    <Gap height={0} />
    <Typo size={12} secondary>{i18n('widget.InSeconds')}</Typo>
    <Gap height={4} />
    <Input lazy value={value} placeholder="0" onChange={onChange} />
  </>
})
