import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs } from 'section/Head/manager/Breadcrumbs'
import { CompanySelect } from 'section/Head/manager/CompanySelect'
import { HomeButton } from 'section/Head/manager/HomeButton'
import { ManagerLogo } from 'section/Head/manager/ManagerLogo'
import { SearchButton } from 'section/Head/manager/SearchButton'
import { ProfileButton } from 'section/Head/ProfileButton'
import { route } from 'store/route'
import css from './ManagerHead.module.scss'

export const ManagerHead = observer(() => {
  const search = route.pathname === '/search'

  return <Vertical gap={8}>
    <Space className={css.top} gap={[8, 16]} oh top opposite wrap>
      <Space className={css.left} gap={[0, 16]} wrap>
        <Space height={42}>
          <ManagerLogo />
        </Space>
        {!search && <CompanySelect />}
      </Space>

      <Space className={css.right} gap={[8, 16]} wrap>
        <ProfileButton />
        <Space gap={[0, 12]} right wrap>
          <Space className={css.tab}>
            <HomeButton />
          </Space>
          <Space className={css.tab}>
            <SearchButton />
          </Space>
        </Space>
      </Space>
    </Space>

    <Breadcrumbs />
  </Vertical>
})
