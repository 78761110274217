// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShopButton-header-4573{padding:20px 12px;border-bottom:1px solid #ebebeb}@media all and (min-width: 760px){.ShopButton-header-4573{padding:30px 32px}}.ShopButton-filters-4573{padding:16px 12px}@media all and (min-width: 760px){.ShopButton-filters-4573{padding:32px 32px 16px}}.ShopButton-list-4573{width:100%;display:grid;grid-template-columns:minmax(200px, 1fr) repeat(auto-fill, minmax(200px, 1fr));align-items:center;justify-items:stretch;grid-gap:40px 16px;padding:0 16px}@media(width <= 480px){.ShopButton-list-4573{grid-template-columns:repeat(2, minmax(100px, 1fr))}}@media(width <= 320px){.ShopButton-list-4573{grid-template-columns:1fr}}@media all and (min-width: 760px){.ShopButton-list-4573{padding:0 32px}}.ShopButton-headerButton-4573>span{display:none}@media all and (min-width: 760px){.ShopButton-headerButton-4573>span{display:block}}@media(width < 480px){.ShopButton-more-4573{width:100%}}@media(width >= 480px){.ShopButton-more-4573{min-width:320px}}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/ShopButton/ShopButton.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,+BAAA,CAEA,kCAJF,wBAKI,iBAAA,CAAA,CAIJ,yBACE,iBAAA,CAEA,kCAHF,yBAII,sBAAA,CAAA,CAIJ,sBACE,UAAA,CACA,YAAA,CACA,8EAAA,CACA,kBAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CAEA,uBATF,sBAUI,mDAAA,CAAA,CAGF,uBAbF,sBAcI,yBAAA,CAAA,CAGF,kCAjBF,sBAkBI,cAAA,CAAA,CAKF,mCACE,YAAA,CAGF,kCACE,mCACE,aAAA,CAAA,CAMJ,sBADF,sBAEI,UAAA,CAAA,CAGF,uBALF,sBAMI,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ShopButton-header-4573`,
	"filters": `ShopButton-filters-4573`,
	"list": `ShopButton-list-4573`,
	"headerButton": `ShopButton-headerButton-4573`,
	"more": `ShopButton-more-4573`
};
module.exports = ___CSS_LOADER_EXPORT___;
