import { Card } from 'component/Card'
import { Spinner } from 'component/Spinner'
import { observer } from 'mobx-react-lite'
import { GenerationTab } from 'page/home/product/generation/GenerationTab'
import { useEffect } from 'react'
import { home } from 'store/home'

export const GenerationTabPreload = observer(() => {
  const store = home.product.it
  const { sources } = store

  useEffect(() => {
    void store.loadSources()
  }, [store])

  if (!sources.present) {
    return <Card height={300} center tertiary>
      <Spinner />
    </Card>
  }

  return <GenerationTab />
})
