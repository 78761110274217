import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const BackgroundColorInput = observer(() => {
  const { background } = tryon.it.edit
  const value = background ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.background = value.trim()
  })

  return <>
    <Typo size={14}>{i18n('widget.BackgroundColor')}</Typo>
    <Gap height={4} />
    <Input mono lazy value={value} placeholder="#ffffff" onChange={onChange} />
  </>
})
