import { ArrayStore } from 'store/base/ArrayStore'
import { IdentityStore } from 'store/profile/IdentityStore'
import { Identity } from 'type/Identity'

export class ProfileStore {
  readonly identities = new ArrayStore<IdentityStore>([])

  constructor(identities: Identity[]) {
    for (const identity of identities) {
      this.identities.add(new IdentityStore(identity))
    }
  }
}
