import { makeAutoObservable } from 'mobx'
import { UploadItem } from 'modal/UploadCollectionModal/store/UploadItem'
import { UploadParser } from 'modal/UploadCollectionModal/store/UploadParser'
import { last } from 'util/array'

export class DuplicateItem {
  readonly items: readonly  UploadItem[]

  constructor(items: readonly UploadItem[]) {
    this.items = items
  }
}

type DuplicatesDecision = 'skip' | 'first' | 'last'

export class DuplicateErrorsStore {
  readonly items: readonly  DuplicateItem[]
  private _decision?: DuplicatesDecision

  constructor(data: UploadParser) {
    this.items = data.duplicates.map(items => new DuplicateItem(items))
    makeAutoObservable(this, { items: false })
  }

  get decision(): DuplicatesDecision | undefined {
    return this._decision
  }

  set decision(value: DuplicatesDecision | undefined) {
    this._decision = value
  }

  get lines(): number {
    return this.items.reduce((count, item) => count + item.items.length, 0)
  }

  get text(): string {
    return this.items
      .flatMap(item => item.items)
      .map(item => item.line.toFixed().padStart(3) + ': ' + item.text)
      .join('\n')
  }

  get result(): UploadItem[] {
    switch (this._decision) {
      case 'skip':
        return []
      case 'first':
        return this.items.map(item => item.items[0]!)
      case 'last':
        return this.items.map(item => last(item.items)!)
      default:
        return []
    }
  }
}
