import { Button } from 'component/Button'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'

export const OpenButton = observer(() => {
  const form = home.product.it.sources.it.form

  const action = useLatestCallback(() => {
    form.open = true
  })

  return <Button action={action} primary>
    {i18n('source.AddSource')}
  </Button>
})
