import { ItemTag } from 'component/Tag'
import { useLatestCallback } from 'hook/useLatestCallback'
import { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { MouseEvent } from 'react'

export function renderSelectTag(props: CustomTagProps) {
  return <SelectTag {...props} />
}

export function SelectTag(props: CustomTagProps) {
  const { label } = props

  const onClose = useLatestCallback((event: MouseEvent<HTMLElement>) => {
    props.onClose(event)
  })

  return <ItemTag data={undefined} onClose={onClose}>
    {label}
  </ItemTag>
}
