import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { companyEdit } from 'saas/store/CompanyEditStore'

export const MemberDeleteModal = observer(() => {
  const { user_name } = companyEdit.it.memberDeleting!

  const onCancel = useLatestCallback(() => {
    companyEdit.it.memberDeleting = undefined
  })

  const onDelete = useLatestCallback(async () => {
    await companyEdit.it.deleteMember()
  })

  return <Modal title={i18n('member.DeleteMember')} onCancel={onCancel}>
    <Vertical gap={12}>
      <Typo size={14}>
        {i18n('member.YouAreAboutToDeleteName', { n: <Typo size={14} bold />, name: user_name })}
      </Typo>
    </Vertical>
    <Space spread gap={12}>
      <Button primary action={onDelete}>{i18n('common.Delete')}</Button>
      <Button action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
