import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { ReactElement } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { PageState } from 'store/base/page/PageState'

export function renderPageState(state: PageState): ReactElement | undefined {
  switch (state) {
    case 'none':
      return <LoadingPage />
    case 'unknown':
      return <NotFoundPage />
    case 'ready':
      return undefined
    case 'error':
      return <ErrorPage />
    default:
      console.error('unknown state', state)
      return undefined
  }
}
