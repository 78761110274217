import { Field } from 'component/Field'
import { SearchSelect, SelectOption } from 'component/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'
import { getItemStatusLabel, itemStatusValues } from 'type/InternalStatus'

export const StatusSelect = observer(() => {
  const value = managerSearchStore.internal_status

  const options: SelectOption[] = [
    { value: '', label: getItemStatusLabel('') },
    ...itemStatusValues.map(status => ({
      value: status,
      label: getItemStatusLabel(status),
    })),
  ]

  const onChange = useLatestCallback((value: string []) => {
    managerSearchStore.internal_status = value
  })

  return <Field label={i18n('label.ItemStatus')}>
    <SearchSelect wide multiple value={value} options={options} onChange={onChange} />
  </Field>
})
