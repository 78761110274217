import { SearchInput } from 'component/Input/SearchInput'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Collection } from 'page/home/collections/Collection'
import { TagsFilter } from 'page/home/collections/TagsFilter/TagsFilter'
import { home } from 'store/home'

export const Collections = observer(() => {
  const { collectionsVisible, search } = home.collections.it

  const onChangeSearch = useLatestCallback((value: string) => search.value = value)

  return <Vertical gap={16} width={240}>
    <Vertical gap={8}>
      <Vertical gap={12}>
        <Typo size={14} bold secondary>
          {i18n('collection.MyCollections')}
        </Typo>
        <SearchInput wide placeholder={i18n('placeholder.SearchCollections')}
          value={search.value} onChange={onChangeSearch} />
      </Vertical>
    </Vertical>
    <TagsFilter />
    <Vertical gap={8}>
      {!collectionsVisible.length && <Typo size={14} bold secondary>
        {i18n('collection.NoCollections')}
      </Typo>}
      {collectionsVisible.map(collection => (
        <Collection key={collection.collectionId} collection={collection} />
      ))}
    </Vertical>
  </Vertical>
})
