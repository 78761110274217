import { clsx } from 'clsx'
import { Select } from 'component/Select/Select'
import { renderSelectTag } from 'component/Select/SelectTag'
import css from './TagsInput.module.scss'

type Props = {
  className?: string,
  error?: boolean,
  onChange(value: readonly string[]): void,
  placeholder?: string | undefined
  value: readonly string[],
}

export function TagsInput(props: Props) {
  const { className, error, onChange, placeholder, value } = props
  const status = error ? 'error' : undefined
  const separators = [' ', ',', ';']
  const classes = clsx(className, css.select)

  return <Select className={classes} mode="tags" status={status} value={value}
    placeholder={placeholder} tokenSeparators={separators} notFoundContent={false}
    onChange={onChange} tagRender={renderSelectTag} />
}
