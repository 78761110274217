// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select-select-eb44{width:100%;overflow:hidden}.Select-select-eb44.ant-select-single.ant-select-show-arrow .ant-select-selection-item{padding-inline-end:8px}.Select-select-eb44.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{padding-inline-end:8px}.Select-select-eb44 .ant-select-selector{padding-right:24px !important}.Select-select-eb44 .ant-select-selection-overflow{gap:4px}.Select-select-eb44 .ant-select-selection-search{min-width:32px;margin-left:0 !important}.Select-select-eb44 .ant-select-arrow{right:12px;color:var(--ui-text-color-secondary)}.Select-select-eb44 .ant-select-clear{top:0;right:12px;bottom:0;width:auto;height:fit-content;margin-top:auto;margin-bottom:auto;line-height:0;color:var(--ui-text-color-secondary)}.Select-select-eb44 input:not(:read-only){cursor:text !important}.Select-fit-eb44{width:200px;max-width:min(100%,100vw - 48px)}@media(width <= 320px){.Select-fit-eb44{width:100%}}.Select-popup-eb44 .ant-select-item-group{font-size:14px;font-weight:700;color:var(--ui-text-color)}.Select-popup-eb44 .ant-select-item-option-selected{font-weight:400 !important}.Select-popup-eb44 .ant-select-item-option-active{background-color:var(--ui-background-color-hover) !important}`, "",{"version":3,"sources":["webpack://./src/component/Select/Select.module.scss"],"names":[],"mappings":"AAAA,oBACE,UAAA,CACA,eAAA,CAEA,uFACE,sBAAA,CAGF,8FACE,sBAAA,CAGF,yCACE,6BAAA,CAGF,mDACE,OAAA,CAGF,iDACE,cAAA,CACA,wBAAA,CAGF,sCACE,UAAA,CACA,oCAAA,CAGF,sCACE,KAAA,CACA,UAAA,CACA,QAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA,CACA,oCAAA,CAGF,0CACE,sBAAA,CAIJ,iBACE,WAAA,CACA,gCAAA,CAEA,uBAJF,iBAKI,UAAA,CAAA,CAIJ,0CACE,cAAA,CACA,eAAA,CACA,0BAAA,CAGF,oDACE,0BAAA,CAGF,kDACE,4DAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `Select-select-eb44`,
	"fit": `Select-fit-eb44`,
	"popup": `Select-popup-eb44`
};
module.exports = ___CSS_LOADER_EXPORT___;
