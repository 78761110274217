import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { LoadingPage } from 'feature/page/LoadingPage'
import { useEffectOnce } from 'hook/useEffectOnce'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DemoCatalogPage } from 'page/demo-catalog/DemoCatalogPage'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'

const ErrorPage = () => {
  return <Space gap={8} tall center>
    <Icon name="error" size={20} error />
    <Typo size={14} error>
      {i18n('error.PageUnavailable')}
    </Typo>
  </Space>
}

export const DemoCatalog = observer(() => {
  useEffectOnce(() => {
    void demo.init()
  })

  if (demo.error) return <ErrorPage />
  if (!demo.ready) return <LoadingPage />

  return <DemoCatalogPage />
})
