import { observer } from 'mobx-react-lite'
import { CreatorHome } from 'page/home'
import { route } from 'store/route'

export const CreatorRouting = observer(() => {
  if (route.pathname !== '/') {
    route.push('/')
    return null
  }

  return <CreatorHome />
})
