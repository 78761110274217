import { useLatestCallback } from 'hook/useLatestCallback'
import { useMemo, useState } from 'react'

export type PopoverControl = {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
}

export const usePopoverControl = (): PopoverControl => {
  const [open, setOpenRaw] = useState(false)

  const setOpen = useLatestCallback((open: boolean) => {
    setOpenRaw(open)
  })

  return useMemo(() => ({ open, setOpen }), [open, setOpen])
}
