import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GroupSelect } from 'saas/page/product/stand/SettingsCard/GroupSelect'
import { LanguageSelect } from 'saas/page/product/stand/SettingsCard/LanguageSelect'
import { UiControls } from 'saas/page/product/stand/SettingsCard/UiControls'
import { stand } from 'saas/store/product/ProductSaasController'

export const SettingsCard = observer(() => {
  const { busy, edit } = stand.it
  const { changed, canSave } = edit

  const onAppearance = useLatestCallback(() => {
    edit.tab = 'appearance'
  })

  const onReset = useLatestCallback(() => {
    void stand.it.edit.reset()
  })

  const onSave = useLatestCallback(() => {
    void stand.it.saveSettings()
  })

  return <Card vertical gap={24} pad={16} width={400} opposite secondary>
    <Vertical>
      <Space gap={16} opposite>
        <Typo size={16} bold>{i18n('label.Settings')}</Typo>
        <Button link compact action={onAppearance}>
          {i18n('stand.Appearance')}
        </Button>
      </Space>
      <Gap height={24} />
      <LanguageSelect />
      <Gap height={24} />
      <GroupSelect />
      <Gap height={24} />
      <UiControls />
    </Vertical>
    <Space gap={8} right>
      <Button text disabled={!changed} action={onReset}>
        {i18n('form.Reset')}
      </Button>
      <Button primary disabled={busy || !canSave} action={onSave}>
        {i18n('common.Save')}
      </Button>
    </Space>
  </Card>
})
