import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { LoadingPage } from 'feature/page/LoadingPage'
import { try_on } from 'feature/widget/TryOnStore'
import { useEffectOnce } from 'hook/useEffectOnce'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DemoPage } from 'page/demo/DemoPage'
import { demo } from 'page/demo/DemoStore'
import { useEffect } from 'react'

const ErrorPage = () => {
  return <Space gap={8} tall center>
    <Icon name="error" size={20} error />
    <Typo size={14} error>{i18n('error.PageNotFound')}</Typo>
  </Space>
}

export const Demo = observer(() => {
  const company_id = demo.company?.company_id

  useEffectOnce(() => {
    void demo.init()
  })

  useEffect(() => {
    void try_on.init(company_id)
  }, [company_id])

  if (demo.error) return <ErrorPage />
  if (!demo.ready) return <LoadingPage />

  return <DemoPage />
})
