import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { CompanyManageView } from 'saas/page/company/manage/CompanyManageView'
import { Page } from 'saas/screen/Page'
import { useCompanyEditLoadingStore } from 'saas/store/CompanyEditLoadingStore'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { route } from 'store/route'

export const CompanyManagePage = observer(() => {
  const company_id = route.unit(1)
  const { loading, error } = useCompanyEditLoadingStore(company_id)

  if (loading) return <LoadingPage />
  if (error) return <ErrorPage />
  if (!companyEdit.present) return <NotFoundPage />

  return <Page>
    <CompanyManageView />
  </Page>
})
