// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page-page-8d53{padding:16px 16px;height:100%}`, "",{"version":3,"sources":["webpack://./src/saas/screen/Page.module.scss"],"names":[],"mappings":"AAAA,gBACE,iBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Page-page-8d53`
};
module.exports = ___CSS_LOADER_EXPORT___;
