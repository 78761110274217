// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Material Symbols Outlined";font-style:normal;font-weight:100 700;src:url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v183/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2) format("woff2");font-display:block}.Icon-icon-574e{font-family:"Material Symbols Outlined";font-weight:normal;font-style:normal;font-size:24px;line-height:1;letter-spacing:normal;text-transform:none;display:inline-block;white-space:nowrap;word-wrap:normal;direction:ltr;-webkit-font-feature-settings:"liga";-webkit-font-smoothing:antialiased}.Icon-icon-574e{width:1em;height:1em;font-size:1em;line-height:1;font-variation-settings:"FILL" 0,"wght" 400,"GRAD" 0,"opsz" 20;vertical-align:middle;-webkit-user-select:none;user-select:none}.Icon-fill-574e{font-variation-settings:"FILL" 1}.Icon-primary-574e{color:var(--ui-primary-color)}.Icon-secondary-574e{color:var(--ui-text-color-secondary)}.Icon-tertiary-574e{color:var(--ui-text-color-tertiary)}.Icon-error-574e{color:var(--ui-text-color-error)}.Icon-size20-574e{font-variation-settings:"FILL" 0,"wght" 300,"GRAD" 0,"opsz" 20}.Icon-size24-574e{font-variation-settings:"FILL" 0,"wght" 300,"GRAD" 0,"opsz" 24}.Icon-size40-574e{font-variation-settings:"FILL" 0,"wght" 200,"GRAD" 0,"opsz" 40}.Icon-size48-574e{font-variation-settings:"FILL" 0,"wght" 200,"GRAD" 0,"opsz" 48}`, "",{"version":3,"sources":["webpack://./src/icon/Icon.module.scss"],"names":[],"mappings":"AAEA,WACE,uCAAA,CACA,iBAAA,CACA,mBAAA,CACA,mIAAA,CACA,kBAAA,CAGF,gBAEE,uCAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,aAAA,CACA,qBAAA,CACA,mBAAA,CACA,oBAAA,CACA,kBAAA,CACA,gBAAA,CACA,aAAA,CACA,oCAAA,CACA,kCAAA,CAGF,gBACE,SAAA,CACA,UAAA,CACA,aAAA,CACA,aAAA,CACA,8DAAA,CACA,qBAAA,CACA,wBAAA,CAAA,gBAAA,CAGF,gBACE,gCAAA,CAGF,mBACE,6BAAA,CAGF,qBACE,oCAAA,CAGF,oBACE,mCAAA,CAGF,iBACE,gCAAA,CAGF,kBACE,8DAAA,CAGF,kBACE,8DAAA,CAGF,kBACE,8DAAA,CAGF,kBACE,8DAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Icon-icon-574e`,
	"fill": `Icon-fill-574e`,
	"primary": `Icon-primary-574e`,
	"secondary": `Icon-secondary-574e`,
	"tertiary": `Icon-tertiary-574e`,
	"error": `Icon-error-574e`,
	"size20": `Icon-size20-574e`,
	"size24": `Icon-size24-574e`,
	"size40": `Icon-size40-574e`,
	"size48": `Icon-size48-574e`
};
module.exports = ___CSS_LOADER_EXPORT___;
