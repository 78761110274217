import { LanguageDetector } from 'i18n/config/LanguageDetector'
import i18next from 'i18next'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export async function initI18next(): Promise<void> {
  await i18next
    .use(HttpBackend)
    .use(new LanguageDetector())
    .use(initReactI18next)
    .init({
      ns: ['default'],
      defaultNS: 'default',
      supportedLngs: ['en', 'ru'],
      backend: { loadPath: `/api/i18n/translations?lng={{lng}}&ns={{ns}}` },
      react: { useSuspense: false },
    })
}
