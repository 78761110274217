// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenderButton-buttonGender-0b27{height:28px;background:rgba(0,0,0,0);flex:1}@media all and (min-width: 760px){.GenderButton-buttonGender-0b27{height:20px}}.GenderButton-buttonGender-0b27.GenderButton-active-0b27{background:#1f1f1f;color:#fff}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/GenderButton/GenderButton.module.scss"],"names":[],"mappings":"AAAA,gCACE,WAAA,CACA,wBAAA,CACA,MAAA,CAEA,kCALF,gCAMI,WAAA,CAAA,CAGF,yDACE,kBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGender": `GenderButton-buttonGender-0b27`,
	"active": `GenderButton-active-0b27`
};
module.exports = ___CSS_LOADER_EXPORT___;
