import { Tag as TagAntd } from 'antd'
import { clsx } from 'clsx'
import { ClickAction, useOnClickAction } from 'hook/useOnClickAction'
import { ReactNode } from 'react'
import css from './Tag.module.scss'

type Props = {
  action?: ClickAction | false
  children?: ReactNode
  error?: boolean
  info?: boolean
  primary?: boolean
  solid?: boolean
  success?: boolean
  warning?: boolean
}

export function Tag(props: Props) {
  const { action, children, error, info, primary, solid, success, warning } = props
  const { busy, onClick } = useOnClickAction(props)

  const color = error ? 'error' : warning ? 'warning' : info ? 'info' : success ? 'success' :
    primary ? 'gold' : undefined
  const bordered = solid ? false : undefined

  const className = clsx(css.tag, action != null && css.action, busy && css.busy)

  return <TagAntd className={className} color={color} bordered={bordered} onClick={onClick}>
    {children}
  </TagAntd>
}
