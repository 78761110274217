import en from 'i18n/en.json'
import { t } from 'i18next'
import { isValidElement, ReactElement } from 'react'
import { Trans } from 'react-i18next'

type RawOptions = Record<string, string | number | ReactElement | undefined>
type RawTextOptions = Record<string, string | number | undefined>

type Options = {
  values: Record<string, string | number>
  components: Record<string, ReactElement>
}

function parseOptions(raw: RawOptions | undefined): Options {
  const options: Options = {
    values: {},
    components: {},
  }
  for (const [key, value] of Object.entries(raw ?? {})) {
    if (isValidElement(value)) {
      options.components[key] = value as ReactElement
    } else if (typeof value === 'string') {
      options.values[key] = value
    } else if (typeof value === 'number') {
      options.values[key] = value
    } else if (value == null) {
      // skip
    } else if (value) {
      options.values[key] = value.toString()
    }
  }
  return options
}

export type I18nKey = keyof typeof en

export function i18n(key: I18nKey): string
export function i18n(key: I18nKey, options?: RawTextOptions): string
export function i18n(key: I18nKey, options?: RawOptions): string | ReactElement
export function i18n(key: I18nKey, options?: RawOptions): string | ReactElement {
  const defaultValue = en[key]

  const { values, components } = parseOptions(options)
  const simple = Object.keys(components).length === 0

  if (simple) return t(key, { ...values, defaultValue })
  return <Trans i18nKey={key as string} values={values} components={components} defaults={defaultValue} />
}
