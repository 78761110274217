import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on } from 'feature/widget/TryOnStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DemoButton } from 'page/demo/DemoButton'
import { demo } from 'page/demo/DemoStore'
import { DemoProduct } from 'type/Demo'
import css from './DemoItem.module.scss'

type Props = {
  item: DemoProduct
}

export const DemoItem = observer(({ item }: Props) => {
  const company_id = demo.company?.company_id
  const { item_id, product_name, brand_name, images } = item
  const sources = images ?? []
  const source = sources[0]

  const onTry = useLatestCallback(async () => {
    if (company_id) await try_on.open(company_id, item_id)
  })

  return <Vertical className={css.item}>
    <Space className={css.image}>
      {source && <Image src={source} sources={sources} />}
    </Space>
    <Gap height={16} />
    <Vertical gap={4} pad={[0, 12]} center>
      <Typo size={16} center balance>
        {product_name}
      </Typo>
      <Typo size={12} center balance>
        {brand_name}
      </Typo>
    </Vertical>
    <Gap height={16} spring />
    <DemoButton invert action={onTry}>
      <Space gap={8}>
        <Icon name="photo_camera" size={20} style={{ marginTop: -1 }} />
        {i18n('demo.TryOn')}
      </Space>
    </DemoButton>
  </Vertical>
})
