import { Button } from 'component/Button'
import { SelectOption } from 'component/Select'
import { Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { TextArea } from 'component/TextArea'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { AddSourceCard } from 'page/home/product/generation/AddSourceCard'
import { useMemo } from 'react'
import { home } from 'store/home'
import { ShapeType } from 'type/Source'

export const FormSection = observer(() => {
  const store = home.product.it.sources.it
  const { shape, prompt } = store.form
  const canSave = !store.form.form.error

  const shapes = useMemo<SelectOption<ShapeType>[]>(() => [{
    value: 'top',
    label: i18n('label.TopBody'),
  }, {
    value: 'bottom',
    label: i18n('label.BottomBody'),
  }, {
    value: 'full',
    label: i18n('label.FullBody'),
  }], [])

  return <Vertical gap={8}>
    <Space gap={8} top wide>
      <AddSourceCard />
      <Vertical gap={8} wide>
        <Select value={shape.value} options={shapes} onChange={value => shape.value = value}
          error={shape.error} placeholder={i18n('label.ClothSection')} />
        <TextArea rows={6} value={prompt.value} onChange={value => prompt.value = value}
          error={prompt.error} placeholder={i18n('label.DefaultPrompt')} />
      </Vertical>
    </Space>
    <Space gap={16}>
      <Button primary style={{ flex: '1 0 0' }} action={store.createSource} disabled={!canSave}>
        {i18n('common.Create')}
      </Button>
      {store.sources.length > 0 && <Button text action={store.closeForm}>
        {i18n('common.Cancel')}
      </Button>}
    </Space>
  </Vertical>
})
