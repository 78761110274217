import { Card } from 'component/Card'
import { observer } from 'mobx-react-lite'
import { IntervalSelect } from 'saas/page/statistics/IntervalSelect'

export const Parameters = observer(() => {
  return <Card gap={16} pad={16} vertical width={1280}>
    <IntervalSelect />
    {/*<Space gap={[4, 12]} wrap>
      <ApiKeySelect />
      <CollectionSelect />
      <BrandSelect />
      <GenderSelect />
    </Space>*/}
  </Card>
})
