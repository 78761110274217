import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'
import { nil } from 'util/null'

export const CollectionSelect = observer(() => {
  const value = managerSearchStore.collection_id

  const options: Option[] = managerSearchStore.collections.map(collection => ({
    value: collection.id,
    label: collection.name,
  }))

  const onChange = useLatestCallback((value: string | nil) => {
    managerSearchStore.collection_id = value || ''
  })

  return <Field label={i18n('collection.Collection')}>
    <Select wide value={value} options={options} onChange={onChange} />
  </Field>
})
