import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Back } from 'feature/back/Back'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { ImageUpload } from 'saas/page/company/common/ImageUpload'
import { Page } from 'saas/screen/Page'
import { companyCreate, CompanyCreateStore } from 'saas/store/CompanyCreateStore'
import { can } from 'store/can'
import { route } from 'store/route'

export const CompanyCreatePage = observer(() => {
  useMemo(() => companyCreate.it = new CompanyCreateStore(), [])
  const { name, description, link, logo, canCreate } = companyCreate.it

  const onBack = useLatestCallback(() => {
    route.push('/companies')
  })

  const onCreate = useLatestCallback(async () => {
    await companyCreate.it.create()
  })

  return <Page>
    <Vertical gap={24}>
      <Vertical gap={8}>
        <Back action={onBack} />
        <Typo size={18} bold>
          {i18n('company.CreateNewCompany')}
        </Typo>
      </Vertical>
      <Vertical gap={16} width={480}>
        <Space gap={16} top>
          <ImageUpload field={logo} title={i18n('label.Logo')} />
          <Vertical gap={12} wide>
            <TextField field={name} label={i18n('label.Name')} />
            <TextField field={link} label={i18n('label.Website')} />
          </Vertical>
        </Space>
        <TextField rows={4} field={description} label={i18n('label.Description')} />
      </Vertical>
      <Space>
        {can.CreateCompany() && <Button primary disabled={!canCreate} action={onCreate}>
          {i18n('common.Create')}
        </Button>}
      </Space>
    </Vertical>
  </Page>
})
