import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { InferenceImageStore } from 'store/inference/InferenceImageStore'
import { InferenceItem } from 'store/inference/InferenceItem'
import { InferenceStore } from 'store/inference/InferenceStore'
import { Inference } from 'type/Inference'

export class InferenceListStore {
  private _inferences: ArrayStore<InferenceItem>

  constructor(inferences: Inference[]) {
    const items = inferences.map(inference => new InferenceItem(InferenceStore.fromJson(inference)))
    this._inferences = new ArrayStore(items)
  }

  get inferences(): ReadonlyArray<InferenceItem> {
    return this._inferences.value
  }

  addInference(inference: InferenceItem): void {
    this._inferences.addFirst(inference)
  }

  removeInference(inference: InferenceItem): void {
    this._inferences.remove(inference)
  }

  async deleteInferenceImage(inference: InferenceItem, image: InferenceImageStore) {
    if (!inference.store.present) throw new Error('no inference')

    inference.store.it.deleteImage(image)
    if (inference.store.it.images.length === 0) {
      this._inferences.remove(inference)
    }

    await api.setInferenceImageLikeMode(image.id, 2)
  }
}
