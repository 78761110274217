import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { ProductCreateModal } from 'saas/page/products/ProductCreateModal'
import { SaasProductsView } from 'saas/page/products/SaasProductsView'
import { Page } from 'saas/screen/Page'
import { productCreate } from 'saas/store/product/ProductCreateStore'
import { closeProductsSaas, productsSaas } from 'saas/store/products/ProductsSaasController'
import { home } from 'store/home'
import { route } from 'store/route'
import { renderAsyncState } from 'util/render'

export const SaasProductsPage = observer(() => {
  const company_id = route.unit(1)
  const company = home.companies.companies.find(co => co.company_id === company_id)

  useMemo(() => {
    if (company) home.company.it = company
    if (company_id) productsSaas.select(company_id)
    else closeProductsSaas()
  }, [company, company_id])

  useEffectOnce(() => () => closeProductsSaas())

  const state = renderAsyncState(productsSaas)
  if (state) return state

  return <Page>
    <SaasProductsView />
    {productCreate.present && <ProductCreateModal />}
  </Page>
})
