import { IconButton, IconButtonProps } from 'component/Button/IconButton'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { useState } from 'react'
import { copyText } from 'util/browser'
import { delay } from 'util/promise'

type Props = IconButtonProps & {
  data: string | null | undefined
  action?: never
  children?: never
}

export function CopyIconButton(all: Props) {
  const { data, ...props } = all
  const [done, setDone] = useState(false)
  const name = done ? 'check' : 'content_copy'

  const copy = useLatestCallback(async () => {
    if (done) return
    await copyText(data)
    setDone(true)
    await delay(1000)
    setDone(false)
  })

  const onClick = useLatestCallback(() => void copy())

  return <IconButton {...props} action={onClick} success={done}>
    <Icon name={name} size={16} />
  </IconButton>
}
