import { i18n } from 'i18n'
import { capitalize } from 'lodash'

export const itemStatusValues = ['in_progress', 'check', 'disabled', 'edit', 'done'] as const

export type InternalStatus = typeof itemStatusValues[number]

export function fixItemStatus(status: InternalStatus | string | null | undefined): InternalStatus | undefined {
  if (!status) return undefined
  if (['null', 'none', 'not set'].includes(status)) return undefined
  if (itemStatusValues.includes(status as InternalStatus)) return status as InternalStatus
  return undefined
}

export function getItemStatusLabel(status: InternalStatus | '' | null | undefined): string {
  status = fixItemStatus(status)

  if (!status) return i18n('moderation.None')

  switch (status) {
    case 'in_progress':
      return i18n('moderation.InProgress')
    case 'check':
      return i18n('moderation.Check')
    case 'disabled':
      return i18n('moderation.Disabled')
    case 'edit':
      return i18n('moderation.Edit')
    case 'done':
      return i18n('moderation.Done')
    default:
      return capitalize(status).replaceAll('_', ' ')
  }
}

export const buildInternalStatusOptions = () => itemStatusValues.map(status => ({
  value: status,
  label: getItemStatusLabel(status),
}))
