import { nil } from 'util/null'
import { parseFinite } from 'util/number'

export function formatPrice(price: number): string
export function formatPrice(price: number | nil): string | undefined
export function formatPrice(price: number | nil): string | undefined {
  if (price == null) return undefined

  const options = { useGrouping: true, maximumFractionDigits: 2 }
  return Intl.NumberFormat('en', options).format(price).replaceAll(',', ' ')
}

export function formatCurrency(price: string | number, currency: string | nil): string
export function formatCurrency(price: string | number | nil, currency: string | nil): string | undefined
export function formatCurrency(price: string | number | nil, currency: string | nil): string | undefined {
  if (price == null) return undefined

  currency = currency?.trim() ?? ''
  const parsed = typeof price === 'string' ? parseFinite(price) : price
  const num = formatPrice(parsed)

  if (!currency) return num + ' ' + '₽'
  if (currency === 'RUB') return num + ' ' + '₽'
  if (currency === 'USD') return '$' + num
  return num + ' ' + currency
}
