import clsx from 'clsx'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { getSortIconName, getSortTitle, SortLabel } from 'page/demo-catalog/SortButton/SortLabel'
import { Sort, sortValues } from 'page/demo-catalog/store/DemoCatalogFormStore'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import css from './SortButton.module.scss'

export const SortButton = observer(() => {
  const sort = demo.form.sort

  const onClick = useLatestCallback((value: Sort) => {
    demo.form.sort = value
  })

  const items: DropdownItem[] = sortValues.map(value => ({
    key: value,
    label: <SortLabel value={value} />,
  }))

  return <Dropdown items={items} placement="bottomRight" onClick={onClick}>
    <DemoCatalogButton className={clsx(css.button)}>
      <Space gap={8}>
        <Typo medium upper>{getSortTitle(sort)}</Typo>
        <Icon name={getSortIconName(sort)} size={16} secondary />
      </Space>
    </DemoCatalogButton>
  </Dropdown>
})
