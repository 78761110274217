import IconExternalLink from 'asset/icon-external-link.svg'
import { Link } from 'component/Link'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import css from './ShopButton.module.scss'

export const ShopButton = observer(() => {
  const { shopUrlDefault } = demo
  const { shopUrl, shopButton } = demo.product

  return <Link href={shopUrl || shopUrlDefault}>
    <DemoCatalogButton className={css.headerButton} text xl icon={<IconExternalLink />}>
      <Typo>
        {shopButton || i18n('demo.ToTheFullSite')}
      </Typo>
    </DemoCatalogButton>
  </Link>
})
