import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CommentTitle } from 'page/internal/comments/CommentTitle'
import { useRef } from 'react'
import { commenting } from 'store/comment/CommentingStore'
import { CommentEditor } from './CommentEditor'
import css from './CommentsCard.module.scss'
import { CommentsList } from './CommentsList'

export const CommentsCard = observer(() => {
  const store = commenting.comments
  const { detached, collapsable } = commenting
  const { collapse, ready } = store
  const ref = useRef<HTMLDivElement>(null)

  const onCollapse = useLatestCallback(() => {
    store.collapse = !store.collapse
  })

  const onClose = useLatestCallback(() => {
    commenting.close()
  })

  return <div className={clsx(css.root, collapse && css.collapse)}>
    <div ref={ref} className={css.ref} />
    <Card className={css.card} vertical secondary>
      <Space className={css.title} gap={16} pad={12} opposite>
        <CommentTitle />
        <Space gap={8}>
          {collapsable && <IconButton small action={onCollapse}>
            <Icon name={collapse ? 'expand_all' : 'collapse_all'} />
          </IconButton>}
          {detached && <IconButton small action={onClose}>
            <Icon name="close" />
          </IconButton>}
        </Space>
      </Space>
      {!ready && !collapse && <Space height={200} center>
        <Spinner />
      </Space>}
      {ready && !collapse && <CommentsList />}
      <CommentEditor />
    </Card>
  </div>
})
