import { clsx } from 'clsx'
import { Spinner } from 'component/Spinner'
import { useEffect, useState } from 'react'
import css from './LoadingPage.module.scss'

// небольшой промежуток времени, чтобы лоадер не моргал
// при быстром unmount + mount
const timeout = 100

// время последнего unmount
// или 0, если unmount не успел произойти
let unmounted = 1

export const LoadingPage = () => {
  const [stale, setStale] = useState(() => unmounted > 0 && Date.now() - unmounted > timeout)

  useEffect(() => {
    unmounted = 0
    return () => {
      unmounted = Date.now()
    }
  }, [])

  useEffect(() => {
    if (stale) setStale(false)
  }, [stale])

  return <div className={clsx(css.loading, !stale && css.show)}>
    <Spinner />
  </div>
}
