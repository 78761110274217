export function matchPath(pathname: string, pattern: string): boolean {
  let mask = pattern
  // escape all regex symbols except asterisk (*)
  mask = mask.replace(/[.+?^${}()|[\]\\]/g, '\\$&')
  // match any directory
  mask = mask.replaceAll('*', '[^\\/]+')
  // allow optional ending slash
  mask = mask + '[/]?'
  // full match only
  mask = '^' + mask + '$'
  return !!pathname.match(new RegExp(mask))
}
