import { makeAutoObservable, runInAction } from 'mobx'
import { IntervalStore } from 'saas/store/IntervalStore'
import { api } from 'store/api'
import { ApiKey } from 'type/ApiKey'
import { CollectionId } from 'type/Search'
import { skipAsync } from 'util/async'

type Tab = 'items' | 'chart'

export class StatisticsStore {
  ready = false
  error = false
  company_id?: string

  private _tab: Tab = 'chart'
  readonly interval = new IntervalStore()

  keys: ApiKey[] = []
  collections: CollectionId[] = []
  brands: string[] = []

  private _key: ApiKey | undefined
  private _collection: CollectionId | undefined
  private _brand: string | undefined
  private _gender: string | undefined

  constructor() {
    makeAutoObservable(this)
  }

  get tab(): Tab {
    return this._tab
  }

  set tab(value: Tab) {
    this._tab = value
  }

  get key(): ApiKey | undefined {
    return this._key
  }

  set key(value: ApiKey | undefined) {
    this._key = value
  }

  get collection(): CollectionId | undefined {
    return this._collection
  }

  set collection(value: CollectionId | undefined) {
    this._collection = value
  }

  get brand(): string | undefined {
    return this._brand
  }

  set brand(value: string | undefined) {
    this._brand = value
  }

  get gender(): string | undefined {
    return this._gender
  }

  set gender(value: string | undefined) {
    this._gender = value
  }

  reset(company_id: string | undefined) {
    this.ready = false
    this.error = false
    this.company_id = company_id
    this.collections = []
    this.brands = []
    this.keys = []
    this._key = undefined
    this._collection = undefined
    this._brand = undefined
    this._gender = undefined
  }

  load = skipAsync(async (company_id: string | undefined) => {
    runInAction(() => {
      this.ready = false
      this.error = false
      this.company_id = company_id
    })
    if (!company_id) return
    try {
      const _keys = api.getApiKeys(company_id)
      const _config = api.getSearchConfig(company_id)
      const keys = await _keys
      const { brands, collections } = await _config
      runInAction(() => {
        this.collections = collections ?? []
        this.brands = brands ?? []
        this.keys = keys
        this.ready = true
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })
}

export const statistics = new StatisticsStore()
