import { SearchInput } from 'component/Input/SearchInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'

export const QueryInput = observer(() => {
  const store = managerSearchStore
  const onChange = useLatestCallback((value: string | null | undefined) => {
    store.query = value ?? ''
  })

  return <SearchInput wide value={store.query} onChange={onChange}
    placeholder={i18n('placeholder.Search')} />
})
