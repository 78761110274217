import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { useState } from 'react'
import { copyText } from 'util/browser'
import { delay } from 'util/promise'
import css from './CopyButton.module.scss'

type Props = {
  className?: string
  data: string | null | undefined
  small?: boolean
}

export function CopyButton(props: Props) {
  const { data, small } = props
  const [done, setDone] = useState(false)
  const className = clsx(props.className, css.copy, small && css.small, done && css.done)
  const name = done ? 'check' : 'content_copy'

  const onClick = useLatestCallback(async () => {
    await copyText(data)
    setDone(true)
    await delay(1000)
    setDone(false)
  })

  return <div className={className}>
    <Icon name={name} onClick={onClick} />
  </div>
}
