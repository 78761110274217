import { Button } from 'component/Button'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { home } from 'store/home'
import { ProductTabKey } from 'store/home/ui/ProductTabKey'

type Props = {
  tab: ProductTabKey
  children: ReactNode
}

export const TabButton = observer(({ tab, children }: Props) => {
  const current = home.ui.productTab
  const type = tab === current ? 'primary' : 'default'
  const action = useLatestCallback(() => home.ui.productTab = tab)

  return <Button type={type} action={action}>
    {children}
  </Button>
})
