import { clsx } from 'clsx'
import { CSSProperties, ForwardedRef, forwardRef, MouseEvent, ReactNode } from 'react'
import { Gap, getGapStyle, getMarginStyle, getPaddingStyle, Margin, Padding, styles } from 'util/style'
import css from './Space.module.scss'

export type SpaceProps = {
  bottom?: boolean
  center?: boolean
  children?: ReactNode
  className?: string
  fit?: boolean
  flex?: string
  gap?: Gap
  height?: number | string
  margin?: Margin
  maxHeight?: number | string
  maxWidth?: number | string
  minHeight?: number | string
  minWidth?: number | string
  oh?: boolean
  onClick?(event: MouseEvent): unknown
  opposite?: boolean
  pad?: Padding
  parallel?: boolean
  right?: boolean
  round?: boolean
  shrink?: boolean
  spread?: boolean
  stable?: boolean
  style?: CSSProperties
  tall?: boolean
  title?: string
  top?: boolean
  vertical?: boolean
  wide?: boolean
  width?: number | string
  wrap?: boolean
}

function buildStyle(props: SpaceProps) {
  const { flex, height, maxHeight, maxWidth, minHeight, minWidth, pad, style, width } = props
  const gap = getGapStyle(props.gap)
  const padding = getPaddingStyle(pad)
  const margin = getMarginStyle(props.margin)
  return styles({
    flex, width, height, minWidth, minHeight, maxWidth, maxHeight,
    ...gap, ...padding, ...margin, ...style,
  })
}

export const Space = forwardRef((props: SpaceProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    bottom, center, children, fit, onClick, oh, opposite, parallel, right, round,
    shrink, spread, stable, tall, title, top, vertical, wide, wrap,
  } = props

  const style = buildStyle(props)
  const className = clsx(
    props.className,
    vertical ? css.column : css.row,
    !!onClick && css.pointer,
    top && css.top,
    bottom && css.bottom,
    center && css.center,
    right && css.right,
    oh && css.oh,
    opposite && css.opposite,
    parallel && css.parallel,
    shrink && css.shrink,
    spread && css.spread,
    stable && css.stable,
    fit && css.fit,
    wide && css.wide,
    tall && css.tall,
    round && css.round,
    wrap && css.wrap,
  )

  return <div ref={ref} className={className} style={style} title={title} onClick={onClick}>
    {children}
  </div>
})
