import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { OriginStore } from 'saas/store/key/OriginStore'
import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { mx } from 'store/base/mx'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'
import { CreateApiKeyRequest, UpdateApiKeyRequest } from 'type/ApiKey'
import { Listener, Listeners } from 'util/listener'

export class ApiKeyModalStore {
  readonly company: CompanySaasStore
  readonly key: ApiKeyStore | undefined
  form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly origins = new ArrayStore<OriginStore>([])
  private listenersOnCreate = new Listeners<ApiKeyStore>()

  constructor(company: CompanySaasStore, key: ApiKeyStore | undefined) {
    makeAutoObservable(this)
    this.company = company
    this.key = key
    if (key) this.applyKey(key)
    if (!this.origins.length) this.addOrigin()
  }

  attachOnCreate(listener: Listener<ApiKeyStore>) {
    return this.listenersOnCreate.add(listener)
  }

  get company_id() {
    return this.company.company_id
  }

  get creating(): boolean {
    return !this.key
  }

  get canSave(): boolean {
    return !this.form.error && !this.unchanged
  }

  check() {
    return this.form.check()
  }

  addOrigin() {
    this.origins.add(new OriginStore(this.form))
  }

  removeOrigin(origin: OriginStore) {
    this.form.remove(origin.host)
    this.origins.remove(origin)
    if (!this.origins.length) this.addOrigin()
  }

  async saveKey() {
    if (!this?.check()) return
    if (this.key) await this.updateKey()
    else await this.createKey()
  }

  private async createKey() {
    const request = this.buildCreateKeyRequest()
    const key = ApiKeyStore.fromStub(request)
    this.company.keys.add(key)
    keyModal.close()
    this.listenersOnCreate.fire(key)
    const response = await api.createApiKey(request)
    key.applyCreateResponse(response)
  }

  private async updateKey() {
    const request = this.buildUpdateKeyRequest()
    const key = this.key
    if (!key) throw new Error('no key')
    key.applyForm(this.name.value, this.originsArray)
    keyModal.close()
    const response = await api.updateApiKey(request)
    key.applyUpdateResponse(response)
  }

  private get originsArray(): string[] {
    return this.origins.map(o => o.origin).filter(o => !!o)
  }

  private get originsValue(): string {
    return this.originsArray.join(',')
  }

  private get unchanged(): boolean {
    if (!this.key) return false
    return this.name.value === this.key.name
      && this.originsValue === this.key.origins.join(',')
  }

  private applyKey(key: ApiKeyStore) {
    this.name.value = key.name
    this.origins.value = key.origins.map(o => new OriginStore(this.form, o))
  }

  private buildCreateKeyRequest(): CreateApiKeyRequest {
    const user_id = me.user.user_id
    const company_id = this.company_id
    const name = this.name.value
    const origins = this.originsValue
    return { user_id, company_id, name, origins }
  }

  private buildUpdateKeyRequest(): UpdateApiKeyRequest {
    const user_id = me.user.user_id
    const company_id = this.company_id
    const access_key = this.key?.value
    const name = this.name.value
    const origins = this.originsValue
    if (!access_key) throw new Error('no key')
    return { user_id, company_id, access_key, name, origins }
  }
}

export const keyModal = mx.ref<ApiKeyModalStore>()
