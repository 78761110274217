import { makeAutoObservable } from 'mobx'
import { matchPath } from 'util/route'

export const LOGIN = '/login'
export const AUTH = '/auth'

function fixPath(pathname?: string): string {
  return pathname?.trim() || '/'
}

class RouteController {
  private _pathname: string = location.pathname
  private _state: object = {}

  constructor() {
    makeAutoObservable(this)
    window.addEventListener('popstate', this.pop)
  }

  unit(index: number): string | undefined {
    return this.directories[index]
  }

  is(pattern: string): boolean {
    return matchPath(this.pathname, pattern)
  }

  login(url: string) {
    location.assign(url)
  }

  start(creator: boolean) {
    if (creator) {
      this.replace('/')
      for (let n = 1; n <= 3; ++n) history.pushState({}, '', '/')
    } else {
      if (this.open) this.replace('/')
    }
  }

  logout() {
    const returnTo = location.origin + LOGIN
    location.assign(`/api/auth/logout?returnTo=${returnTo}`)
  }

  get pathname(): string {
    return this._pathname
  }

  get state(): unknown {
    return this._state
  }

  get open(): boolean {
    const path = this._pathname
    return [LOGIN, AUTH].includes(path) || this.is('/demo/*') || this.is('/demo-catalog/*/*')
  }

  replace(pathname: string, state: object = {}) {
    history.replaceState(state, '', fixPath(pathname))
    this._pathname = location.pathname
    this._state = state
  }

  push(pathname: string, state: object = {}) {
    history.pushState(state, '', fixPath(pathname))
    this._pathname = location.pathname
    this._state = state
  }

  private get directories(): string[] {
    return this.pathname.split('/').filter(d => !!d)
  }

  private readonly pop = (event: PopStateEvent): void => {
    const { pathname } = location
    this._pathname = pathname
    this._state = event.state || {}
  }
}

export const route = new RouteController()
