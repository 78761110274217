import { Input } from 'antd'
import { TextAreaRef } from 'antd/es/input/TextArea'
import { clsx } from 'clsx'
import { useFocus } from 'hook/useFocus'
import { useLatestCallback } from 'hook/useLatestCallback'
import { ChangeEvent, CSSProperties, ForwardedRef, forwardRef, KeyboardEvent } from 'react'
import { setRef } from 'util/react'
import css from './TextArea.module.scss'

type Props = {
  className?: string
  disabled?: boolean
  error?: boolean
  fix?: boolean
  limit?: number
  mono?: boolean
  onChange?(value: string): void
  onFocus?(focus: boolean): void
  onKeyDown?(event: KeyboardEvent<HTMLTextAreaElement>): void
  placeholder?: string
  readonly?: boolean
  rows?: number
  style?: CSSProperties
  value: string
}

export const TextArea = forwardRef((props: Props, ref: ForwardedRef<HTMLTextAreaElement | HTMLElement>) => {
  const { disabled, error, fix, limit, mono, onKeyDown, placeholder, readonly, rows = 4, style, value } = props
  const focus = useFocus(props)
  const status = error ? 'error' : undefined
  const className = clsx(props.className, css.area, fix && css.fix, mono && css.mono)

  const onChange = useLatestCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange?.(event.target.value)
  })

  const onRef = useLatestCallback((input: TextAreaRef | null) => {
    setRef(ref, input?.resizableTextArea?.textArea)
  })

  return <Input.TextArea ref={onRef} className={className} style={style} status={status} value={value}
    placeholder={placeholder} disabled={disabled} readOnly={readonly} rows={rows} maxLength={limit}
    onKeyDown={onKeyDown} onChange={onChange} onFocus={focus.onFocus} onBlur={focus.onBlur} />
})
