import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { ImageStack } from 'page/home/collections/ImageStack'
import { can } from 'store/can'
import { CollectionStore } from 'store/collection'
import { home } from 'store/home'
import { compact } from 'util/array'

type Props = { collection: CollectionStore }

export const Collection = observer(({ collection }: Props) => {
  const { name, images } = collection.data
  const { company_id } = collection.data.json
  const active = collection === home.collection.optional

  const canEdit = can.EditCollectionInformation(company_id)
  const canDelete = can.DeleteCollections(company_id)
  const more = canEdit || canDelete

  const onOpen = useLatestCallback(async () => {
    void home.openCollection(collection)
  })

  const items: DropdownItem[] = compact([
    canEdit && {
      key: 'edit',
      label: i18n('collection.Edit'),
      action() {
        void modal.openEditCollectionModal(collection)
      },
    },
    canDelete && {
      key: 'delete',
      label: i18n('collection.Delete'),
      action() {
        void modal.openDeleteCollectionModal(collection)
      },
    },
  ])

  return <Card gap={8} pad={4} opposite action={onOpen} active={active}>
    <Glass fill>
      <Space gap={8} shrink>
        <ImageStack sources={images} />
        <Typo size={14} ellipsis>{name}</Typo>
      </Space>
      {more && <Sticker pin={[2, 2, null, null]} hidden>
        <Dropdown items={items} placement="rightTop">
          <IconButton small>
            <Icon name="more_vert" secondary />
          </IconButton>
        </Dropdown>
      </Sticker>}
    </Glass>
  </Card>
})
