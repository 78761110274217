import { IconName } from 'icon/types'
import { makeAutoObservable } from 'mobx'
import { route } from 'store/route'
import { matchPath } from 'util/route'
import { loadJson, saveJson } from 'util/storage'

type Data = {
  open?: boolean
}

export type RouteConfig = {
  icon?: IconName
  title: string
  path: string
  open?: boolean
  patterns?: string[]
  active?(path: string): boolean
  children?: RouteStore[]
}

export class RouteStore {
  private readonly config: RouteConfig
  private _open: boolean

  constructor(config: RouteConfig) {
    this.config = config
    this._open = this.load().open ?? !!config.open
    makeAutoObservable(this)
  }

  get open(): boolean {
    return !!this.children?.length && this._open
  }

  set open(open: boolean) {
    this._open = open
    this.save()
  }

  get icon(): IconName | undefined {
    return this.config.icon
  }

  get title(): string {
    return this.config.title
  }

  get path(): string {
    return this.config.path
  }

  get active(): boolean {
    const pathname = route.pathname
    const { path, patterns, active } = this.config

    if (active) return active(pathname)
    if (patterns) return patterns.some(pattern => matchPath(pathname, pattern))
    return pathname === path
  }

  get children(): RouteStore[] | undefined {
    return this.config.children
  }

  private key() {
    let key = this.config.path
    key = key.replace(/^\//g, '')
    key = key.replace(/\/$/g, '')
    key = key.replace(/\//g, '.')
    return 'route.' + key
  }

  private load(): Data {
    return loadJson(this.key()) ?? {}
  }

  private save() {
    const { open } = this
    saveJson(this.key(), { open })
  }
}
