import { makeAutoObservable } from 'mobx'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { mx } from 'store/base/mx'
import { route } from 'store/route'

export class ProductDeleteModalStore {
  readonly product: ProductSaasStore
  private _name = ''

  constructor(product: ProductSaasStore) {
    makeAutoObservable(this)
    this.product = product
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get canDelete(): boolean {
    return this.name === this.product.json.name
  }

  deleteProduct() {
    if (!this.canDelete) return
    const { company_id } = this.product
    productDeleteModal.close()
    route.push(`/company/${company_id}/products`)
    void productsSaas.it.deleteProduct(this.product)
  }
}

export const productDeleteModal = mx.ref<ProductDeleteModalStore>()
