import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const ShopUrlInput = observer(() => {
  const { shopUrl } = stand.it.edit

  return <TextField field={shopUrl} label={i18n('stand.ShopUrl')}
    placeholder={i18n('stand.ShopUrlPlaceholder')} />
})
