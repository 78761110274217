// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemCard-card-f930{background-color:#fffff8}.ItemCard-title-f930{z-index:0;position:absolute;inset:0;top:auto;padding:8px;padding-top:24px;pointer-events:none}.ItemCard-title-f930:before{z-index:-1;content:"";position:absolute;inset:0;background-image:linear-gradient(to top, var(--ui-background-color) 16px, transparent);opacity:60%;transition:opacity 200ms ease-in-out}.ItemCard-card-f930:hover .ItemCard-title-f930:before{opacity:80%}`, "",{"version":3,"sources":["webpack://./src/page/home/items/ItemCard/ItemCard.module.scss"],"names":[],"mappings":"AAAA,oBACE,wBAAA,CAGF,qBACE,SAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,gBAAA,CACA,mBAAA,CAGF,4BACE,UAAA,CACA,UAAA,CACA,iBAAA,CACA,OAAA,CACA,sFAAA,CAGA,WAAA,CACA,oCAAA,CAGF,sDACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ItemCard-card-f930`,
	"title": `ItemCard-title-f930`
};
module.exports = ___CSS_LOADER_EXPORT___;
