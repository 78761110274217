// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-message-top{top:4px !important}.Notice-notice-be54{margin:-4px 0}.Notice-notice-be54 .ant-message-notice-content{padding:8px 16px !important}@media(width <= 480px){.Notice-notice-be54 .ant-message-notice-content{width:calc(100vw - 24px)}}.Notice-info-be54 .ant-message-notice-content{border:1px solid var(--ui-primary-color)}.Notice-success-be54 .ant-message-notice-content{background-color:#f6ffed !important;border:1px solid #b7eb8f}.Notice-error-be54 .ant-message-notice-content{background-color:#fff1f0 !important;border:1px solid #ffa39e}`, "",{"version":3,"sources":["webpack://./src/app/notice/Notice.module.scss"],"names":[],"mappings":"AAAA,iBACE,kBAAA,CAGF,oBACE,aAAA,CAEA,gDACE,2BAAA,CAGF,uBACE,gDACE,wBAAA,CAAA,CAMJ,8CACE,wCAAA,CAKF,iDACE,mCAAA,CACA,wBAAA,CAKF,+CACE,mCAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notice": `Notice-notice-be54`,
	"info": `Notice-info-be54`,
	"success": `Notice-success-be54`,
	"error": `Notice-error-be54`
};
module.exports = ___CSS_LOADER_EXPORT___;
