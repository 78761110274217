import { ResponsiveLine } from '@nivo/line'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { renderDateTick } from 'saas/page/statistics/chart/DateTick'
import { Tooltip } from 'saas/page/statistics/chart/Tooltip'
import { chart } from 'saas/store/stat/ChartStore'

export const Chart = observer(() => {
  const { error, ready, series } = chart

  if (error) return <Space width={1200} height={600} center>
    <Typo size={12} error>
      {i18n('common.Error')}
    </Typo>
  </Space>

  if (!ready) return <Space width={1200} height={600} center>
    <Typo size={12} secondary>
      {i18n('chart.Loading')}
    </Typo>
  </Space>

  if (!series.length) return <Space width={1200} height={600} center>
    <Typo size={12} secondary>
      {i18n('chart.NoData')}
    </Typo>
  </Space>

  return <Space width={1280} height={600}>
    <ResponsiveLine
      data={series}
      margin={{ top: 20, right: 40, bottom: 60, left: 60 }}
      xScale={{
        type: 'point',
      }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        nice: true,
        stacked: false,
      }}
      curve="monotoneX"
      tooltip={Tooltip}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 16,
        renderTick: renderDateTick,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 16,
      }}
      pointSize={12}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      useMesh={true}
      enableCrosshair={true}
      enableTouchCrosshair={true}
      legends={[]}
      animate={false}
      crosshairType={'cross'}
      colors={serie => serie.color}
      theme={{
        background: 'var(--ui-background-color)',
        text: {
          fontFamily: 'inherit',
          fontSize: 12,
        },
        grid: {
          line: {
            stroke: 'var(--ui-text-color)',
            strokeOpacity: .1,
          },
        },
        crosshair: {
          line: {
            stroke: 'var(--ui-text-color)',
            strokeOpacity: .4,
            strokeDasharray: '4,4',
          },
        },
      }}
    />
  </Space>
})
