import { clsx } from 'clsx'
import { Field } from 'component/Field'
import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { IconName } from 'icon/types'
import { observer } from 'mobx-react-lite'
import { InferenceImageCard } from 'page/home/product/result/InferenceCard/InferenceImageCard'
import { InferenceItem } from 'store/inference/InferenceItem'
import { key } from 'util/react'
import { renderStubIcon } from 'util/render'
import css from './InferenceCard.module.scss'

type Props = {
  item: InferenceItem
}

export const InferenceCard = observer(({ item }: Props) => {
  if (item.store.present) return <Good item={item} />
  if (item.stub.present) return <Stub item={item} />
  return null
})

const Stub = observer(({ item }: Props) => {
  const { title } = item.stub.it

  return <Field className={css.field} label={title}>
    <Space height={90} wide center>
      {renderStubIcon(item.store)}
    </Space>
    <div className={css.status}>
      {i18n('inference.CreatingStatus')}
      <Icon className={css.icon} name="progress_activity" size={16} />
    </div>
  </Field>
})

const Good = observer(({ item }: Props) => {
  const { title, status, images } = item.store.it
  const completed = status === 'complete'
  const icon: IconName = completed ? 'check_circle' : 'qr_code'

  return <Field className={css.field} label={title}>
    <Space gap={4} wrap>
      {images.map((image, index) => (
        <InferenceImageCard key={key(index, image?.id)} inference={item} image={image} />
      ))}
    </Space>
    <div className={clsx(css.status, completed ? css.completed : css.process)}>
      {completed ? i18n('inference.CompletedStatus') : i18n('inference.InProcessStatus')}
      <Icon className={css.icon} name={icon} size={16} />
    </div>
  </Field>
})
