import { times } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { InferenceImageStore } from 'store/inference/InferenceImageStore'
import { InferenceEvent } from 'store/socket/listener'
import { socket } from 'store/socket/socket'
import { Inference, InferenceStatus, NewInference } from 'type/Inference'
import { formatDateTime, parseDate } from 'util/date'
import { notNil } from 'util/null'

export class InferenceStore {
  private _inference_id!: string
  private _title!: string
  private _status!: InferenceStatus
  private _images: (InferenceImageStore | undefined)[] = []

  static fromJson(json: Inference): InferenceStore {
    return new InferenceStore(json, undefined)
  }

  static fromResponse(response: NewInference): InferenceStore {
    return new InferenceStore(undefined, response)
  }

  private constructor(json?: Inference, response?: NewInference) {
    if (json) this.useJson(json)
    if (response) this.useResponse(response)
    if (this._status !== 'complete') void this.update()
    makeAutoObservable(this)
  }

  get title(): string {
    return this._title
  }

  get status(): string {
    return this._status
  }

  get canDeleteImage(): boolean {
    return this._status === 'complete'
  }

  get images(): readonly (InferenceImageStore | undefined)[] {
    return this._images
  }

  get sources(): readonly string[] {
    return this._images.filter(notNil).map(image => image.url)
  }

  deleteImage(image: InferenceImageStore) {
    this._images = this._images.filter(another => another !== image)
  }

  private async update(): Promise<void> {
    socket.attachInference(this._inference_id,
      event => this.useEvent(event),
      json => this.useJson(json))
  }

  private useEvent(event: InferenceEvent): void {
    runInAction(() => this._status = event.status)
    this.updateImages(event.images.map(InferenceImageStore.fromEvent))
  }

  private useJson(json: Inference): void {
    const date = parseDate(json.generated?.[0]?.created_at)
    this._inference_id = json.inference_id
    this._title = formatDateTime(date) ?? json.inference_id
    this._status = json.status
    this.updateImages(json.generated?.map(InferenceImageStore.fromJson) ?? [])
  }

  private useResponse(response: NewInference): void {
    this._inference_id = response.inference_id
    this._title = this._title || formatDateTime(new Date())
    this._status = response.status
    this.updateImages(times(response.items_cnt, () => undefined))
  }

  private updateImages(updates: (InferenceImageStore | undefined)[]) {
    while (this._images.length < updates.length) this._images.push(undefined)

    this._images = this._images.map((store, index) => {
      const update = updates[index]
      if (!update) return store
      if (!store) return update
      if (store.update(update)) return store
      return update
    })
  }
}
