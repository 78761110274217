import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { MouseEvent, ReactNode, useState } from 'react'
import css from './DemoButton.module.scss'

type Props = {
  children: ReactNode
  disabled?: boolean
  action(): unknown
  text?: boolean
  invert?: boolean
  active?: boolean
}

export const DemoButton = ({ children, disabled, action, text, invert, active }: Props) => {
  const type = text ? css.text : invert ? css.invert : css.outline
  const className = clsx(css.control, css.button, type,
    active === true && css.active, active === false && css.inactive)

  const [busy, setBusy] = useState(false)

  const onClick = useLatestCallback(async (event: MouseEvent) => {
    if (event.button === 0 && event.detail === 1) {
      const promise = action()
      if (promise instanceof Promise) {
        setBusy(true)
        await promise
        setBusy(false)
      }
    }
  })

  return <button className={className} disabled={disabled || busy} onClick={onClick}>
    {children}
  </button>
}
