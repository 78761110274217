import { makeAutoObservable } from 'mobx'
import { ItemGroup } from 'type/ItemGroup'

export class GroupStore {
  readonly json: ItemGroup

  constructor(json: ItemGroup) {
    makeAutoObservable(this)
    this.json = json
  }

  get company_id(): string {
    return this.json.company_id
  }

  get group_id(): string {
    return this.json.group_id
  }
}
