import { makeAutoObservable } from 'mobx'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { createProduct } from 'saas/store/product/utils'
import { productsConfig, ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { Product } from 'type/product/Product'
import { extractProducts } from 'type/product/utils'
import { notNil } from 'util/null'

export function createProducts(company_id: string, config: ProductsConfigStore): ProductSaasStore[] {
  const products = extractProducts(config.json)
  return products.map(json => createProduct(company_id, json)).filter(notNil)
}

export class ProductsSaasStore {
  readonly company_id: string
  readonly products: ProductSaasStore[]

  constructor(company_id: string, config: ProductsConfigStore) {
    makeAutoObservable(this)
    this.company_id = company_id
    this.products = createProducts(company_id, config)
  }

  get config(): ProductsConfigStore {
    return productsConfig.get(this.company_id)
  }

  get busy(): boolean {
    return this.config.busy
  }

  async addProduct(product: Product) {
    const store = createProduct(this.company_id, product)
    if (!store) throw new Error('no product')
    this.products.unshift(store)
    await this.config.addProduct(product)
  }

  async deleteProduct(product: ProductSaasStore) {
    const index = this.products.indexOf(product)
    if (index < 0) throw new Error('unknown product')
    this.products.splice(index, 1)
    const config = productsConfig.get(this.company_id)
    await config.deleteProduct(product.json)
  }
}
