// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollectionTitle-root-936c{max-width:calc(100vw - 64px)}@media(width <= 320px){.CollectionTitle-edit-936c{width:calc(100vw - 48px)}.CollectionTitle-edit-936c :first-child{flex:1 1 0}.CollectionTitle-edit-936c :last-child{flex:0 0 auto}}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/CollectionTitle.module.scss"],"names":[],"mappings":"AAAA,2BACE,4BAAA,CAIA,uBADF,2BAEI,wBAAA,CAEA,wCACE,UAAA,CAGF,uCACE,aAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CollectionTitle-root-936c`,
	"edit": `CollectionTitle-edit-936c`
};
module.exports = ___CSS_LOADER_EXPORT___;
