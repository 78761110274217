import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { productCreate } from 'saas/store/product/ProductCreateStore'
import { productsConfig } from 'saas/store/products/ProductsConfigStore'
import { ProductsSaasStore } from 'saas/store/products/ProductsSaasStore'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'

async function preload(company_id: string) {
  await companiesSaas.load()
  const company = companiesSaas.companies.find(c => c.company_id === company_id)
  if (company) await company.load()
}

export async function factory(company_id: string): Promise<ProductsSaasStore | undefined> {
  void preload(company_id)
  const config = await productsConfig.load(company_id).load()
  if (!config) return undefined
  return new ProductsSaasStore(company_id, config)
}

export const productsSaas = new AsyncStoreController(factory)

export function closeProductsSaas() {
  if (!productsSaas.optional) return
  productCreate.close()
}
