import { notice } from 'app/notice'
import { pageStore } from 'feature/page/PageStore'
import { i18n } from 'i18n'
import { reaction, runInAction } from 'mobx'
import { api } from 'store/api'
import { CollectionStore, collectionStoreCache } from 'store/collection'
import { CollectionDataStore } from 'store/collection/CollectionDataStore'
import { commenting } from 'store/comment/CommentingStore'
import { home } from 'store/home'
import { parseManagerRoute } from 'store/manager/routes'
import { managerSearchStore } from 'store/manager/search'
import { managerStore } from 'store/manager/store'
import { ProductStore, productStoreCache } from 'store/product'
import { route } from 'store/route'
import { onceAsync, skipAsync } from 'util/async'

class ManagerController {
  attachManger(): () => void {
    void this.init()
    return reaction(() => route.pathname, this.update, { fireImmediately: true })
  }

  private readonly init = onceAsync(async () => {
    void home.companies.load()

    reaction(() => home.collection.optional, () => commenting.close())
    reaction(() => home.product.optional, () => commenting.close())
  })

  private readonly update = skipAsync(async (pathname: string) => {
    const success = await this.syncRoute(pathname)
    if (!success) managerStore.page = undefined
    pageStore.ready = true
  })

  private async syncRoute(pathname: string): Promise<boolean> {
    const route = parseManagerRoute(pathname)
    if (!route) return false

    const { page } = route
    if (page === 'search') return this.syncSearch()
    if (page === 'product') return this.syncProduct(route.productId)
    if (page === 'collection') return this.syncCollection(route.collectionId)
    if (page === 'company') return this.syncCompany(route.companyId)

    return false
  }

  private async syncSearch() {
    const _companyId = managerSearchStore.parseCompanyId() ?? home.company.optional?.company.company_id
    const company = await this.getCompany(_companyId)
    const companyId = company.company.company_id
    runInAction(() => {
      home.product.close()
      home.collection.close()
      home.company.it = company
      void company.loadCollections()
      managerSearchStore.init(companyId)
      managerStore.page = 'search'
    })
    return true
  }

  private findProduct(productId: string): ProductStore | undefined {
    return productStoreCache[productId]
  }

  private async fetchProduct(productId: string) {
    try {
      return await api.getProduct(productId)
    } catch {
      return undefined
    }
  }

  private async syncProduct(productId: string) {
    const product = this.findProduct(productId)
    if (product) {
      runInAction(() => {
        home.product.it = product
        managerStore.page = 'product'
      })
      return true
    }
    return await this.startProduct(productId)
  }

  private async startProduct(productId: string) {
    const { collection, item } = await this.fetchProduct(productId) ?? {}
    if (!collection || !item) return false
    const companyId = collection.company_id
    const company = await this.getCompany(companyId)
    const col = this.findCollection(collection.collection_id)
    const product = this.findProduct(productId)
    runInAction(() => {
      home.company.it = company
      home.collection.it = col ?? new CollectionStore(new CollectionDataStore(collection))
      home.product.it = product ?? new ProductStore(item, companyId)
      managerStore.page = 'product'
    })
    return true
  }

  private findCollection(collectionId: string): CollectionStore | undefined {
    return collectionStoreCache[collectionId]
  }

  private async fetchCollection(collectionId: string) {
    try {
      return await api.getProducts(collectionId)
    } catch {
      return undefined
    }
  }

  private async syncCollection(collectionId: string) {
    const collection = this.findCollection(collectionId)
    if (collection) {
      runInAction(() => {
        home.product.close()
        home.collection.it = collection
        void collection.loadItems()
        managerStore.page = 'collection'
      })
      return true
    }
    return await this.startCollection(collectionId)
  }

  private async startCollection(collectionId: string) {
    const { collection, items } = await this.fetchCollection(collectionId) ?? {}
    if (!collection || !items) return false
    const companyId = collection.company_id
    const company = await this.getCompany(companyId)
    const col = this.findCollection(collectionId)
    runInAction(() => {
      home.company.it = company
      home.collection.it = col ?? new CollectionStore(new CollectionDataStore(collection))
      if (!col) home.collection.it.setItems(items)
      managerStore.page = 'collection'
    })
    return true
  }

  private async syncCompany(companyId: string | undefined) {
    const company = await this.getCompany(companyId)
    runInAction(() => {
      home.product.close()
      home.collection.close()
      home.company.it = company
      void company.loadCollections()
      managerStore.page = 'company'
    })
    return true
  }

  private async getCompany(companyId: string | undefined) {
    await home.companies.load()
    const company = home.companies.findRoute(companyId)
    if (company) return company

    notice.error(i18n('error.NoAccessToCompany'))
    pageStore.error = { text: i18n('error.AccessDenied') }
    throw new Error('no companies')
  }
}

export const managerController = new ManagerController()
