// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Deleting-box-c994{z-index:2;position:absolute;inset:0}.Deleting-shadow-c994{position:absolute;inset:0;background-color:var(--ui-background-color);animation:300ms linear Deleting-appear-c994;opacity:1}@keyframes Deleting-appear-c994{0%{opacity:0}100%{opacity:1}}.Deleting-undo-c994{position:absolute;inset:0;width:40px;height:40px;margin:auto;border-radius:50%;transition:background-color 200ms ease-in-out;background-color:color-mix(in srgb, var(--ui-background-color), transparent 60%)}.Deleting-box-c994:hover .Deleting-undo-c994{background-color:color-mix(in srgb, var(--ui-background-color), transparent 20%)}.Deleting-box-c994 .Deleting-undo-c994:hover{background-color:var(--ui-background-color)}.Deleting-svg-c994{position:absolute;inset:0;width:48px;height:48px;margin:auto;transform:rotate(-90deg);pointer-events:none}.Deleting-svg-c994 circle{stroke:var(--ui-primary-color);stroke-dasharray:126.92;stroke-dashoffset:0;animation:Deleting-progress-c994 1s linear}@keyframes Deleting-progress-c994{0%{stroke-dashoffset:126.92}100%{stroke-dashoffset:0}}`, "",{"version":3,"sources":["webpack://./src/feature/deleting/Deleting.module.scss"],"names":[],"mappings":"AAAA,mBACE,SAAA,CACA,iBAAA,CACA,OAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,2CAAA,CACA,2CAAA,CACA,SAAA,CAGF,gCACE,GACE,SAAA,CAEF,KACE,SAAA,CAAA,CAIJ,oBACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CACA,6CAAA,CACA,gFAAA,CAGF,6CACE,gFAAA,CAGF,6CACE,2CAAA,CAGF,mBACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,wBAAA,CACA,mBAAA,CAGF,0BACE,8BAAA,CACA,uBAAA,CACA,mBAAA,CACA,0CAAA,CAGF,kCACE,GACE,wBAAA,CAEF,KACE,mBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `Deleting-box-c994`,
	"shadow": `Deleting-shadow-c994`,
	"appear": `Deleting-appear-c994`,
	"undo": `Deleting-undo-c994`,
	"svg": `Deleting-svg-c994`,
	"progress": `Deleting-progress-c994`
};
module.exports = ___CSS_LOADER_EXPORT___;
