import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { ManagerHome } from 'page/internal'
import { managerController } from 'store/manager/controller'

export const ManagerRouting = observer(() => {
  useEffectOnce(() => managerController.attachManger())

  return <ManagerHome />
})
