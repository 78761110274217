import { useLatestCallback } from 'hook/useLatestCallback'
import { MouseEvent, useState } from 'react'

export type ClickAction = (event: MouseEvent) => unknown

type Props = {
  action?: ClickAction | false
  onClick?: ClickAction
}

type State = {
  busy: boolean
  onClick?(event: MouseEvent): unknown
}

export const useOnClickAction = (props: Props): State => {
  const { action } = props
  const [waiting, setWaiting] = useState(false)

  const busy = waiting || action === false

  const onClickRaw = useLatestCallback(async (event: MouseEvent) => {
    if (busy) return
    props.onClick?.(event)
    if (action) {
      event.stopPropagation()
      const result = action(event)
      if (result instanceof Promise) {
        setWaiting(true)
        try {
          await result
        } catch (e) {
          console.error('action failed', e)
        }
        setWaiting(false)
      }
    }
  })

  const onClick = action || props.onClick ? onClickRaw : undefined

  return { busy, onClick }
}
