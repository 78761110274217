// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageStack-stack-6d50{display:flex;flex-direction:row-reverse;width:64px;justify-content:left}.ImageStack-image-6d50:not(:last-child){margin-left:-16px}`, "",{"version":3,"sources":["webpack://./src/page/home/collections/ImageStack/ImageStack.module.scss"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,0BAAA,CACA,UAAA,CACA,oBAAA,CAGF,wCACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stack": `ImageStack-stack-6d50`,
	"image": `ImageStack-image-6d50`
};
module.exports = ___CSS_LOADER_EXPORT___;
