import { IconButton } from 'component/Button/IconButton'
import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { memberEdit } from 'saas/store/MemberEditStore'
import { getRoleRightLabels } from 'type/MemberRight'
import { getMemberRoleLabel, MemberRole } from 'type/MemberRole'

type Props = {
  role: MemberRole
}

export const RoleCheckbox = observer(({ role }: Props) => {
  const { roles } = memberEdit.it
  const rights = getRoleRightLabels(role)
  const included = role !== MemberRole.admin && roles.value.has(MemberRole.admin)
  const checked = included || roles.value.has(role)
  const [open, setOpen] = useState(false)

  const onChange = useLatestCallback((checked: boolean) => {
    memberEdit.it.checkRole(checked, role)
  })

  const onOpen = useLatestCallback(() => {
    setOpen(!open)
  })

  return <Vertical gap={4}>
    <Space gap={16} opposite>
      <Checkbox checked={checked} disabled={included} onChange={onChange}>
        {getMemberRoleLabel(role)}
      </Checkbox>
      <IconButton small action={onOpen}>
        <Icon name={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={16} />
      </IconButton>
    </Space>
    {open && <Vertical>
      {rights.map((right, index) => <Typo key={index} size={12}>
        {right}
      </Typo>)}
    </Vertical>}
    {open && <Gap height={4} />}
  </Vertical>

})
