import { Vertical } from 'component/Vertical'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { ArticleStore } from 'saas/page/docs/ArticleStore'
import { Settings } from 'saas/page/docs/Settings'
import { Page } from 'saas/screen/Page'
import './ArticlePage.css'
import { route } from 'store/route'

export const ArticlePage = observer(() => {
  const path = route.pathname
  const article = useMemo(() => new ArticleStore(), [])
  const { loading, content } = article

  useEffect(() => {
    void article.update(path)
  }, [article, path])

  if (loading) return <LoadingPage />
  if (!content) return <NotFoundPage />

  return <Page>
    <Vertical gap={16}>
      <Settings />
      <div className="markdown" dangerouslySetInnerHTML={{ __html: content }} />
    </Vertical>
  </Page>
})
