import { makeAutoObservable } from 'mobx'

export type ManagerPage = 'search' | 'product' | 'collection' | 'company'

class ManagerStore {
  private _page?: ManagerPage

  constructor() {
    makeAutoObservable(this)
  }

  get page(): ManagerPage | undefined {
    return this._page
  }

  set page(value: ManagerPage | undefined) {
    this._page = value
  }
}

export const managerStore = new ManagerStore()
