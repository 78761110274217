// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../asset/background.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page-page-c026{min-height:100%;padding:16px 24px;padding-bottom:48px;opacity:0;transition:opacity 300ms ease-in-out}.Page-show-c026{opacity:1}.Page-loading-c026{transition:opacity 300ms 1000ms ease-in-out;opacity:0}.Page-head-c026{margin-bottom:24px}.Page-background-c026{position:fixed;z-index:-1;inset:0;margin:auto;min-width:100%;height:100%;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center;background-size:cover;opacity:15%}`, "",{"version":3,"sources":["webpack://./src/feature/page/Page/Page.module.scss"],"names":[],"mappings":"AAAA,gBACE,eAAA,CACA,iBAAA,CACA,mBAAA,CACA,SAAA,CACA,oCAAA,CAGF,gBACE,SAAA,CAGF,mBACE,2CAAA,CACA,SAAA,CAGF,gBACE,kBAAA,CAGF,sBACE,cAAA,CACA,UAAA,CACA,OAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,gEAAA,CACA,qBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Page-page-c026`,
	"show": `Page-show-c026`,
	"loading": `Page-loading-c026`,
	"head": `Page-head-c026`,
	"background": `Page-background-c026`
};
module.exports = ___CSS_LOADER_EXPORT___;
