import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const PrimaryColorInput = observer(() => {
  const { primary } = tryon.it.edit
  const value = primary ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.primary = value.trim()
  })

  return <>
    <Typo size={14}>{i18n('widget.PrimaryColor')}</Typo>
    <Gap height={4} />
    <Input mono lazy value={value} placeholder="#000000" onChange={onChange} />
  </>
})
