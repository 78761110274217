import { makeAutoObservable } from 'mobx'
import { SourceStore } from 'store/generation/SourceStore'
import { ProductStore } from 'store/product'

export class DeleteSourceModalStore {
  private readonly product: ProductStore
  private readonly source: SourceStore

  constructor(product: ProductStore, source: SourceStore) {
    this.product = product
    this.source = source
    makeAutoObservable(this)
  }

  readonly deleteSource = async () => {
    await this.product.sources.it.deleteSource(this.source)
  }
}
