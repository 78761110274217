import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { MouseEvent, ReactNode, useState } from 'react'
import css from './DemoCatalogButton.module.scss'

type Props = {
  children: ReactNode
  disabled?: boolean
  action?(): unknown
  text?: boolean
  invert?: boolean
  active?: boolean
  className?: string
  xl?: boolean
  icon?: ReactNode
}

export const DemoCatalogButton = (props: Props) => {
  const { children, disabled, action, text, invert, active, xl, icon } = props
  const [busy, setBusy] = useState(false)
  const className = clsx(
    css.control,
    css.button,
    text ? css.text : invert ? css.invert : '',
    active === true && css.active,
    active === false && css.inactive,
    xl && css.xl,
    icon && css.withIcon,
    props.className,
  )

  const onClick = useLatestCallback(async (event: MouseEvent) => {
    if (action && event.button === 0 && event.detail === 1) {
      const promise = action()
      if (promise instanceof Promise) {
        setBusy(true)
        await promise
        setBusy(false)
      }
    }
  })

  return <button className={className} disabled={disabled || busy} onClick={onClick}>
    {icon}
    {icon ? <span>{children}</span> : children}
  </button>
}
