// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagsSpace-card-46a2{border:1px solid var(--ui-border-color);border-radius:var(--ui-border-radius);cursor:text;padding:3px 3px;gap:3px}.TagsSpace-card-46a2.TagsSpace-disabled-46a2{cursor:not-allowed}.TagsSpace-pane-46a2{flex:1 1 auto;max-width:100%}.TagsSpace-input-46a2{height:24px;min-width:40px;box-sizing:content-box;border-width:0;transition:none;box-shadow:none !important;flex:1 1 auto;padding:0 4px;border-radius:0}.TagsSpace-ghost-46a2{position:fixed;visibility:hidden}.TagsSpace-tag-46a2{height:24px}.TagsSpace-button-46a2{width:24px;height:24px}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/tags/TagsSpace.module.scss"],"names":[],"mappings":"AAAA,qBACE,uCAAA,CACA,qCAAA,CACA,WAAA,CACA,eAAA,CACA,OAAA,CAGF,6CACE,kBAAA,CAGF,qBACE,aAAA,CACA,cAAA,CAGF,sBACE,WAAA,CACA,cAAA,CACA,sBAAA,CACA,cAAA,CACA,eAAA,CACA,0BAAA,CACA,aAAA,CACA,aAAA,CACA,eAAA,CAGF,sBACE,cAAA,CACA,iBAAA,CAGF,oBACE,WAAA,CAGF,uBACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `TagsSpace-card-46a2`,
	"disabled": `TagsSpace-disabled-46a2`,
	"pane": `TagsSpace-pane-46a2`,
	"input": `TagsSpace-input-46a2`,
	"ghost": `TagsSpace-ghost-46a2`,
	"tag": `TagsSpace-tag-46a2`,
	"button": `TagsSpace-button-46a2`
};
module.exports = ___CSS_LOADER_EXPORT___;
