import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { TagsField } from 'form/TagsField'
import { TextField } from 'form/TextField'
import { UidInput } from 'form/UidInput'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImagesBlock } from 'page/home/product/description/ImagesBlock'
import { home } from 'store/home'

export const DescriptionTab = observer(() => {
  const store = home.product.it
  const { itemId } = store
  const {
    product_name, brand_name, article, sku, description, default_prompt, background_prompt,
    button_name, button_url, styles, types, seasons, gender,
  } = store.form
  const canSave = !store.form.form.error

  return <Vertical gap={16}>
    <ImagesBlock />
    <UidInput uid={itemId} />
    <TextField field={product_name} label={i18n('label.ItemName')}
      placeholder={i18n('placeholder.ItemName')} />
    <TextField field={brand_name} label={i18n('label.BrandName')}
      placeholder={i18n('placeholder.BrandName')} />
    <TextField field={article} label={i18n('label.Article')}
      placeholder={i18n('placeholder.Article')} />
    <TextField field={sku} label={i18n('label.Sku')}
      placeholder={i18n('placeholder.Sku')} />
    <TextField field={description} rows={4} label={i18n('label.Description')}
      placeholder={i18n('placeholder.Description')} />
    <TextField field={default_prompt} rows={4} label={i18n('label.DefaultPrompt')}
      placeholder={i18n('placeholder.DefaultPrompt')} />
    <TextField field={background_prompt} rows={4} label={i18n('label.BackgroundPrompt')}
      placeholder={i18n('placeholder.BackgroundPrompt')} />
    <TextField field={button_name} label={i18n('label.ButtonName')}
      placeholder={i18n('placeholder.ButtonName')} />
    <TextField field={button_url} label={i18n('label.ButtonUrl')}
      placeholder={i18n('placeholder.ButtonUrl')} />
    <TagsField field={styles} label={i18n('label.Styles')}
      placeholder={i18n('placeholder.Styles')} />
    <TagsField field={types} label={i18n('label.Types')}
      placeholder={i18n('placeholder.Types')} />
    <TagsField field={seasons} label={i18n('label.Seasons')}
      placeholder={i18n('placeholder.Seasons')} />
    <TagsField field={gender} label={i18n('label.Gender')}
      placeholder={i18n('placeholder.Gender')} />
    <Space gap={16}>
      <Button primary style={{ flex: '1 0 0' }} action={store.save.action} disabled={!canSave}>
        {i18n('common.SaveChanges')}
      </Button>
      <Button text action={store.undo}>
        {i18n('common.UndoAll')}
      </Button>
    </Space>
  </Vertical>
})
