import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { Chart } from 'saas/page/statistics/chart/Chart'
import { Legend } from 'saas/page/statistics/chart/Legend'
import { chart } from 'saas/store/stat/ChartStore'
import css from './ChartView.module.scss'

export const ChartView = observer(() => {
  const { ready } = chart
  const hide = !ready

  return <Card pad={[16, 0]} fit overflow width={1280}>
    <Vertical className={clsx(css.view, hide && css.hide)} gap={8} fit>
      <Chart />
      <Legend />
    </Vertical>
  </Card>
})
