import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const ShopButtonInput = observer(() => {
  const { shopButton } = stand.it.edit

  return <TextField field={shopButton} label={i18n('stand.ShopButton')}
    placeholder={i18n('stand.ShopButtonPlaceholder')} />
})
