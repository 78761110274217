import { AppSvg } from 'app/config/icon'
import { getAppLink } from 'app/config/title'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on } from 'feature/widget/TryOnStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DemoButton } from 'page/demo/DemoButton'
import { DemoItem } from 'page/demo/DemoItem'
import { demo } from 'page/demo/DemoStore'
import { DemoTabs } from 'page/demo/DemoTabs'

export const DemoPage = observer(() => {
  const { company, collection, tab, itemsByTab } = demo
  const company_id = company?.company_id
  const tabItems = itemsByTab[tab]
  const title = collection?.collection_title || i18n('collection.Collection')
  const link = getAppLink()

  const onOutfits = useLatestCallback(async () => {
    if (company_id) await try_on.open(company_id)
  })

  return <Vertical>
    <Space gap={20} pad={[0, 24]} opposite wrap>
      <Space gap={[0, 24]} wrap>
        <Link href={link}>
          <Space height={64}>
            <AppSvg h={24} />
          </Space>
        </Link>
        <Typo size={16}>
          {i18n('demo.VirtualFittingWidget')}
        </Typo>
      </Space>
      <DemoButton text action={onOutfits}>
        {i18n('demo.MyOutfits')}
      </DemoButton>
    </Space>
    <Gap height={32} />
    <Space gap={40} pad={[0, 24]} wrap>
      <Typo style={{ fontSize: 32 }}>
        {title}
      </Typo>
      <DemoTabs />
    </Space>
    <Gap height={32} />
    <Space gap={[64, 32]} pad={[0, 24]} top wrap>
      {!tabItems?.length && <Space height={400} wide center>
        <Typo size={14} secondary>{i18n('item.NoItems')}</Typo>
      </Space>}
      {tabItems?.map(it => <DemoItem key={it.item_id} item={it} />)}
    </Space>
    <Gap height={32} />
    <Space pad={[0, 24]} height={64} center>
      <Link href={link}>
        <Space height={64}>
          <AppSvg h={24} />
        </Space>
      </Link>
    </Space>
    <Gap height={32} />
  </Vertical>
})
