export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

type Value = Record<string, unknown> | readonly unknown[] | string | number | boolean | Blob | null | undefined

export type Data = Record<string, Value>

export function buildGetQuery(path: string, data?: Data) {
  const split = path.split('?')
  const pathname = split[0]
  const params = new URLSearchParams(split[1])

  if (data) {
    for (const key of Object.keys(data)) {
      const value = data[key]
      if (value != null) params.append(key, value.toString())
    }
  }

  const search = params.size === 0 ? '' : '?' + params.toString()
  return pathname + search
}

export function toFormData(data: Data) {
  const form = new FormData()
  for (const [key, value] of Object.entries(data)) {
    if (value == null) continue
    else if (typeof value === 'string' || value instanceof Blob) form.append(key, value)
    else if (typeof value === 'number') form.append(key, value.toFixed())
    else form.append(key, JSON.stringify(value))
  }
  return form
}

export function toBody(data: Data | undefined): string | FormData | undefined {
  if (data == null) return undefined
  const blob = Object.values(data).find(v => v instanceof Blob)
  if (blob) return toFormData(data)
  else return JSON.stringify(data)
}

export function getBodyContentType(body: undefined | string | FormData): string | undefined {
  if (body instanceof FormData) return undefined
  else return 'application/json'
}

export function toStringFile(file: File | unknown): unknown {
  if (file == null) return file
  if (typeof file == 'string') return file
  if (file instanceof File) {
    const { name, type, size } = file
    return [size, type, name].map(v => '' + v).join('|')
  }
  return file.toString()
}

export function toExtraJson(body: unknown): unknown {
  if (typeof body === 'string') return JSON.parse(body)
  if (body instanceof FormData) {
    const entries = Array.from(body.entries()).map(([key, value]) => [key, toStringFile(value)])
    return Object.fromEntries(entries)
  }
  if (body == null) return body
  return body.toString()
}
