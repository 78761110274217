import { makeAutoObservable } from 'mobx'
import { ObjectStore } from 'store/base/ObjectStore'
import { StringStore } from 'store/base/StringStore'
import { ProductStore } from 'store/product/index'
import { ExternalStatus } from 'type/ExternalStatus'
import { InternalStatus } from 'type/InternalStatus'
import { matchSearch } from 'util/search'

export class ProductFiltersStore {
  readonly search = new StringStore('')
  readonly status = new ObjectStore<InternalStatus | ''>()
  readonly review = new ObjectStore<ExternalStatus | ''>()

  constructor() {
    makeAutoObservable(this)
  }

  resetFilters(): void {
    this.search.value = ''
    this.status.value = undefined
    this.review.value = undefined
  }

  get filter(): (product: ProductStore) => boolean {
    const search = this.search.value
    const status = this.status.value
    const review = this.review.value

    return function (product) {
      if (search) {
        const { product_name, brand_name, item_id, external_item_id, article } = product.data.json
        const values = [product_name, brand_name, external_item_id, item_id, article]
        if (!matchSearch(search, values)) return false
      }
      if (status) {
        const value = product.data.json.internal_status ?? ''
        if (status !== value) return false
      }
      if (review) {
        const value = product.data.json.external_status ?? ''
        if (review !== value) return false
      }
      return true
    }
  }
}
