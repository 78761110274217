import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { closeInviteUsers } from 'page/invite/store/InviteUsersStore'

export const SuccessStep = observer(() => {
  const onClose = useLatestCallback(() => {
    closeInviteUsers()
  })

  return <Vertical gap={24}>
    <Typo size={16} secondary>
      {i18n('common.Success')}
    </Typo>

    <Typo size={12}>
      {i18n('invite.InvitationsSent')}
    </Typo>

    <Space gap={12} right>
      <Button action={onClose}>
        {i18n('common.Close')}
      </Button>
    </Space>
  </Vertical>
})
