import { Card } from 'component/Card'
import { observer } from 'mobx-react-lite'
import { AddItemCard } from 'page/home/items/AddItemCard'
import { ItemCard } from 'page/home/items/ItemCard/ItemCard'
import { home } from 'store/home'

export const Products = observer(() => {
  const store = home.collection.it
  const { collectionId, items } = store

  return <Card key={collectionId} secondary gap={12} top wrap width={590} minHeight={400} pad={16}>
    <AddItemCard />
    {items.it.map(item => <ItemCard key={item.key} item={item} />)}
  </Card>
})
