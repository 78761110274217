import { makeAutoObservable } from 'mobx'
import { me } from 'store/me'
import { Comment, ExternalStatusChange, InternalStatusChange, LikeState } from 'type/Comment'
import { parseDate } from 'util/date'
import { getNextKey } from 'util/id'

export class ScrollStamp {
}

export class CommentStore {
  readonly key: string = getNextKey()

  private _scroll?: ScrollStamp

  user_id ?: string
  id?: string
  message?: string
  user_name?: string
  created?: Date
  updated?: Date
  replyTo?: string
  error = false
  like: LikeState = 0
  system: 0 | 1 = 0
  int_status?: InternalStatusChange
  ext_status?: ExternalStatusChange

  static fromComment(comment: Comment): CommentStore {
    const store = new CommentStore()
    store.applyComment(comment)
    return store
  }

  static fromRequest(message: string, replyTo?: string): CommentStore {
    const store = new CommentStore()
    store.applyRequest(message, replyTo)
    return store
  }

  private constructor() {
    makeAutoObservable(this)
  }

  get canEdit(): boolean {
    const my = this.user_id === me.user.user_id
    return !!this.id && my && !this.like && !this.system
  }

  get edited(): boolean {
    return this.created?.getTime() !== this.updated?.getTime()
  }

  afterResponse(comment: Comment) {
    this.applyComment(comment)
  }

  afterError() {
    this.error = true
  }

  get scroll(): ScrollStamp | undefined {
    return this._scroll
  }

  set scroll(value: ScrollStamp | undefined) {
    this._scroll = value
  }

  private applyComment(comment: Comment) {
    this.user_id = comment.user_id
    this.id = comment.id
    this.message = comment.message
    this.user_name = comment.user_name
    this.created = parseDate(comment.created_at)
    this.updated = parseDate(comment.updated_at || comment.created_at)
    this.replyTo = comment.reply_to
    this.like = comment.is_like
    this.system = comment.is_system
    this.int_status = comment.change_int_status || undefined
    this.ext_status = comment.change_ext_status || undefined
  }

  private applyRequest(message: string, replyTo: string | undefined) {
    this.user_id = me.user.user_id
    this.message = message
    this.user_name = me.user.name ?? 'Me'
    this.replyTo = replyTo
  }
}
