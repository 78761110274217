import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { BackgroundColorInput } from 'saas/page/product/tryon/SettingsCard/BackgroundColorInput'
import { DelayInput } from 'saas/page/product/tryon/SettingsCard/DelayInput'
import { EmailCheckbox } from 'saas/page/product/tryon/SettingsCard/EmailCheckbox'
import { LanguageSelect } from 'saas/page/product/tryon/SettingsCard/LanguageSelect'
import { PrimaryColorInput } from 'saas/page/product/tryon/SettingsCard/PrimaryColorInput'
import { TextColorInput } from 'saas/page/product/tryon/SettingsCard/TextColorInput'
import { ThemeSelect } from 'saas/page/product/tryon/SettingsCard/ThemeSelect'
import { TimeoutInput } from 'saas/page/product/tryon/SettingsCard/TimeoutInput'
import { tryon } from 'saas/store/product/ProductSaasController'

export const SettingsCard = observer(() => {
  const { busy } = tryon.it
  const { canSave } = tryon.it.edit

  const onEditText = useLatestCallback(() => {
    tryon.it.texts = true
  })

  const onSave = useLatestCallback(() => {
    void tryon.it.saveSettings()
  })

  return <Card vertical gap={24} pad={16} width={400} opposite secondary>
    <Vertical>
      <Space gap={16} opposite>
        <Typo size={16} bold>{i18n('label.Settings')}</Typo>
        <Button action={onEditText}>
          {i18n('product.EditTexts')}
        </Button>
      </Space>
      <Gap height={16} />
      <Typo size={14} bold>{i18n('label.Language')}</Typo>
      <Gap height={8} />
      <LanguageSelect />
      <Gap height={20} />
      <Typo size={14} bold>{i18n('widget.EmailsCollection')}</Typo>
      <Gap height={12} />
      <EmailCheckbox />
      <Gap height={16} />
      <DelayInput />
      <Gap height={16} />
      <TimeoutInput />
      <Gap height={20} />
      <Typo size={14} bold>{i18n('widget.Theme')}</Typo>
      <Gap height={8} />
      <ThemeSelect />
      <Gap height={20} />
      <Typo size={14} bold>{i18n('widget.Colors')}</Typo>
      <Gap height={12} />
      <PrimaryColorInput />
      <Gap height={16} />
      <TextColorInput />
      <Gap height={16} />
      <BackgroundColorInput />
    </Vertical>
    <Space right>
      <Button primary disabled={busy || !canSave} action={onSave}>
        {i18n('common.Save')}
      </Button>
    </Space>
  </Card>
})
