import { Deleting } from 'feature/deleting/Deleting'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { ReactElement, useState } from 'react'

type Callback = () => void

type State = {
  start(delay: number, callback: Callback): void
  stop(): void
  box: ReactElement | undefined
}

type Process = {
  delay: number
  timeoutId: TimeoutId
  callback: Callback
}

function exec(callback: Callback): void {
  try {
    callback()
  } catch (e) {
    console.error('callback failed', e)
  }
}

export const useDeleting = (): State => {
  const [process, setProcess] = useState<Readonly<Process>>()

  const stop = useLatestCallback(() => {
    if (!process) return
    clearTimeout(process.timeoutId)
    setProcess(undefined)
  })

  const unmount = useLatestCallback(() => {
    if (!process) return
    clearTimeout(process.timeoutId)
    exec(process.callback)
  })

  useEffectOnce(() => {
    return unmount
  })

  const start = useLatestCallback((delay: number, callback: Callback) => {
    stop()
    const timeoutId = setTimeout(() => {
      setProcess(undefined)
      exec(callback)
    }, delay)
    setProcess({ delay, timeoutId, callback })
  })

  const box = process && <Deleting delay={process.delay} undo={stop} />

  return { start, stop, box }
}
