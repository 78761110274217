import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'

export const ExternalIdInput = observer(() => {
  const value = managerSearchStore.external_item_id

  const onChange = useLatestCallback((value: string) => {
    managerSearchStore.external_item_id = value
  })

  return <Field label={i18n('label.Sku')}>
    <Input value={value} onChange={onChange} placeholder={i18n('placeholder.Sku')} />
  </Field>
})
