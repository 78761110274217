import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { InferenceCard } from 'page/home/product/result/InferenceCard/InferenceCard'
import { home } from 'store/home'
import { renderStubIcon } from 'util/render'

export const ResultsTab = observer(() => {
  const product = home.product.it
  const inferences = product.inferences.optional?.inferences

  useEffectOnce(() => {
    void product.loadInferences()
  })

  if (!inferences) {
    return <Space height={200} center>
      {renderStubIcon(product.inferences)}
    </Space>
  }

  return <Vertical gap={16} wide>
    {inferences.length == 0 && <Typo size={14} bold tertiary>
      {i18n('inference.NoResults')}
    </Typo>}
    {inferences.map(item => <InferenceCard key={item.key} item={item} />)}
  </Vertical>
})
