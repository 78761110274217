type Option = {
  value: string
  label: string
}

type Group = {
  title: string,
  radio: boolean
  options: Option[]
}

function buildOptions(labels: string[]): Option[] {
  return labels.map(label => ({ value: label, label }))
}

function buildGroups(): Group[] {
  return [{
    title: 'Owner',
    radio: false,
    options: buildOptions(['Brand collection', 'Store Collection', 'Blogger Collection', 'User Collection', 'Special Event']),
  }, {
    title: 'List modes',
    radio: true,
    options: buildOptions(['Only for Own Use', 'Public', 'Private', 'Public ONLY for admins']),
  }, {
    title: 'Use',
    radio: false,
    options: buildOptions(['Items use for random', 'Free to use', 'Sub Style', 'No items']),
  }, {
    title: 'Special Events',
    radio: false,
    options: buildOptions(['Moscow Fashion Week 2024']),
  }]
}

export class ModesCatalog {
  readonly groups: Group[]

  constructor() {
    this.groups = buildGroups()
  }

  findValueGroup(value: string): Group | undefined {
    return this.groups.find(group => group.options.some(option => option.value === value))
  }

  getLabel(value: string): string {
    for (const group of this.groups) {
      for (const option of group.options) {
        if (option.value === value) return option.label
      }
    }
    return value
  }
}
