import { FieldStore } from 'form/store/FieldStore'
import { FormStore } from 'form/store/FormStore'
import { StringStore } from 'store/base/StringStore'
import { fixText, validateHost } from 'util/form'
import { getNextKey } from 'util/id'

function extractHost(origin: string | undefined) {
  if (!origin) return ''
  // remove protocol prefix
  return origin.replace(/^[a-z]{3,5}?:\/\//, '')
}

export class OriginStore {
  readonly key = getNextKey()
  readonly host: FieldStore<string, StringStore>

  constructor(form: FormStore, origin?: string) {
    const host = extractHost(origin)
    this.host = form.field(new StringStore(host), { required: true, fix: fixText, validate: validateHost })
  }

  get origin(): string {
    const host = this.host.value
    return host ? 'https://' + host : ''
  }
}
