import { parseFinite } from 'util/number'

export const ASPECT_RATIO = 9 / 16

export function getFitSrc(src: string, width: number, height: number, contain: boolean): string {
  try {
    const url = new URL(src)
    const search = new URLSearchParams(url.search)
    const w = parseFinite(search.get('width'))
    const h = parseFinite(search.get('height'))
    const a = w && h ? w / h : ASPECT_RATIO
    const aspect = width / height

    if (contain) {
      if (a > aspect) height = width / a
      else width = height * a
    } else {
      if (a > aspect) width = height * a
      else height = width / a
    }

    search.set('width', Math.ceil(width).toFixed())
    search.set('height', Math.ceil(height).toFixed())
    url.search = search.toString()
    return url.toString()
  } catch {
    return src
  }
}
