import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { MemberStore } from 'saas/store/MemberStore'
import { api } from 'store/api'
import { ImageFile } from 'store/base/ImageFile'
import { mx } from 'store/base/mx'
import { ObjectStore } from 'store/base/ObjectStore'
import { SetStore } from 'store/base/SetStore'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'
import { MemberPreview, UpdateMemberPermissionsRequest, UpdateMemberRequest } from 'type/Member'
import { MemberRole } from 'type/MemberRole'
import { minAsync } from 'util/async'
import { fixText, validateEmail } from 'util/form'

export class MemberEditStore {
  private company: CompanySaasStore
  readonly member: MemberStore
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })
  readonly email = this.form.field(new StringStore(), { required: true, fix: fixText, validate: validateEmail })
  readonly phone = this.form.field(new StringStore(), { fix: fixText })
  readonly description = this.form.field(new StringStore())
  readonly picture = this.form.field(new ObjectStore<ImageFile>())
  readonly roles = this.form.field(new SetStore<MemberRole>())

  constructor(company: CompanySaasStore, member: MemberStore) {
    makeAutoObservable(this)
    this.company = company
    this.member = member
    const { user_name, user_email, user_phone, user_descr, user_pic } = member
    this.name.value = user_name
    this.email.value = user_email ?? ''
    this.phone.value = user_phone ?? ''
    this.description.value = user_descr ?? ''
    this.picture.value = ImageFile.fromUrlNil(user_pic)
    this.roles.value = new Set(member.roles)
  }

  check(): boolean {
    return this.form.check()
  }

  checkRole(checked: boolean, role: MemberRole) {
    if (role === MemberRole.admin) {
      this.roles.data.clear()
      if (checked) this.roles.data.add(role)
    } else {
      if (checked) this.roles.data.add(role)
      else this.roles.data.remove(role)
    }
  }

  get canSave() {
    return !this.form.error && !this.saved
  }

  save = minAsync(async () => {
    if (!this.check()) return
    const perm = this.buildPermissionsRequest()
    const save = this.buildSaveRequest()
    const preview = this.buildPreview()
    this.member.applyPreview(preview)
    const [member] = await api.updateMember(save)
    this.member.applyUpdate(member)
    await api.updatePermissions(perm)
  })

  private get saved(): boolean {
    const { user_name, user_email, user_phone, user_descr, user_pic } = this.member
    return this.name.value === user_name
      && this.email.value === (user_email ?? '')
      && this.phone.value === (user_phone ?? '')
      && this.description.value === (user_descr ?? '')
      && this.picture.value?.url === user_pic
  }

  private buildPermissionsRequest(): UpdateMemberPermissionsRequest {
    const { user_id } = me.user
    const permissions = this.member.buildPermissions(this.roles.value)
    return {
      company_id: this.company.company_id,
      user_id,
      member_id: this.member.user_id,
      permissions,
    }
  }

  private buildSaveRequest(): UpdateMemberRequest {
    const { user_id } = me.user
    return {
      user_id,
      member_id: this.member.user_id,
      name: this.name.value,
      email: this.email.value,
      phone: this.phone.value,
      descr: this.description.value,
      pic: this.picture.value?.file,
    }
  }

  private buildPreview(): MemberPreview {
    return {
      user_name: this.name.value,
      user_email: this.email.value,
      user_phone: this.phone.value,
      user_descr: this.description.value,
      user_pic: this.picture.value?.url,
      permissions_list: this.member.buildPermissions(this.roles.value),
    }
  }
}

export const memberEdit = mx.ref<MemberEditStore>()
