// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductFilters-status-94cb{width:160px;max-width:calc(100vw - 48px)}@media(width <= 320px){.ProductFilters-status-94cb{width:100%}}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/ProductFilters.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,4BAAA,CAEA,uBAJF,4BAKI,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `ProductFilters-status-94cb`
};
module.exports = ___CSS_LOADER_EXPORT___;
