import { makeAutoObservable } from 'mobx'
import { ReactElement } from 'react'
import { PageController } from 'store/base/page/PageController'
import { PageState } from 'store/base/page/PageState'
import { renderPageState } from 'store/base/page/render'

export class PageStore<P = unknown, D = unknown> {
  private state: PageState = 'none'
  private key: string | undefined
  private controller: PageController<P, D>

  constructor(controller: PageController<P, D>) {
    makeAutoObservable(this)
    this.controller = controller
  }

  async open(params: P) {
    const key = JSON.stringify(params, Object.keys(params as object).sort())
    if (this.key === key) return
    this.key = key
    this.state = 'none'
    this.controller.close()
    try {
      const data = await this.controller.load(params)
      if (this.key !== key) return
      if (data) this.controller.select(params, data)
      const state = data == null ? 'unknown' : 'ready'
      this.done(key, state)
    } catch (e) {
      console.error('open page fail', e)
      this.done(key, 'error')
    }
  }

  close() {
    this.controller.close()
  }

  get stub(): ReactElement | undefined {
    return renderPageState(this.state)
  }

  private done(key: string, state: PageState) {
    if (this.key === key) this.state = state
  }
}
