// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InferenceCard-field-ce8b{width:100%}.InferenceCard-status-ce8b{position:absolute;top:0;right:8px;display:flex;align-items:center;padding:0 4px;border-radius:var(--ui-border-radius);line-height:16px;font-size:10px;color:var(--ui-text-color-secondary);background-color:var(--ui-background-color);gap:4px}.InferenceCard-icon-ce8b{color:var(--ui-text-color-tertiary)}.InferenceCard-process-ce8b .InferenceCard-icon-ce8b{color:var(--ant-color-warning)}.InferenceCard-completed-ce8b .InferenceCard-icon-ce8b{color:var(--ant-color-success)}`, "",{"version":3,"sources":["webpack://./src/page/home/product/result/InferenceCard/InferenceCard.module.scss"],"names":[],"mappings":"AAAA,0BACE,UAAA,CAGF,2BACE,iBAAA,CACA,KAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CACA,aAAA,CACA,qCAAA,CACA,gBAAA,CACA,cAAA,CACA,oCAAA,CACA,2CAAA,CACA,OAAA,CAGF,yBACE,mCAAA,CAGF,qDACE,8BAAA,CAGF,uDACE,8BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `InferenceCard-field-ce8b`,
	"status": `InferenceCard-status-ce8b`,
	"icon": `InferenceCard-icon-ce8b`,
	"process": `InferenceCard-process-ce8b`,
	"completed": `InferenceCard-completed-ce8b`
};
module.exports = ___CSS_LOADER_EXPORT___;
