import { SearchSelect, SelectOption } from 'component/Select'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupsSaas } from 'saas/store/groups/GroupsStore'
import { stand } from 'saas/store/product/ProductSaasController'

export const GroupSelect = observer(() => {
  const { group } = stand.it.edit
  const { focus, error, onChange, onFocus } = group

  const options: SelectOption[] = groupsSaas.it.groups.map(group => ({
    value: group.group_id,
    label: group.json.group_name,
  }))

  return <>
    <Typo size={14} bold>{i18n('stand.ProductGroup')}</Typo>
    <Gap height={8} />
    <SearchSelect wide onChange={onChange} onFocus={onFocus} value={group.value} options={options}
      placeholder={i18n('stand.ProductGroup')} error={!focus && error} />
  </>
})
