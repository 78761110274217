import { clsx } from 'clsx'
import { Space, SpaceProps } from 'component/Space'
import { ClickAction, useOnClickAction } from 'hook/useOnClickAction'
import { ForwardedRef, forwardRef } from 'react'
import css from './Card.module.scss'

export type CardProps = SpaceProps & {
  action?: ClickAction | false,
  active?: boolean
  error?: boolean
  shadow?: boolean
  secondary?: boolean
  tertiary?: boolean
  overflow?: boolean
  pic?: boolean
}

export const Card = forwardRef((props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { action, active,shadow, secondary, tertiary, overflow, pic } = props
  const { busy, onClick } = useOnClickAction(props)

  const className = clsx(
    props.className,
    css.card,
    action != null && css.action,
    busy && css.busy,
    active && css.active,
    shadow && css.shadow,
    secondary && css.secondary,
    tertiary && css.tertiary,
    overflow && css.overflow,
    pic && css.pic,
  )

  return <Space {...props} ref={ref} className={className} onClick={onClick} />
})
