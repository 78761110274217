import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { modal } from 'modal/index'
import { CatalogStepStore } from 'modal/UploadCollectionModal/store/CatalogStepStore'
import { UploadDataStore } from 'modal/UploadCollectionModal/store/UploadDataStore'
import { UploadItem } from 'modal/UploadCollectionModal/store/UploadItem'
import { UploadParser } from 'modal/UploadCollectionModal/store/UploadParser'
import { UploadStepStore } from 'modal/UploadCollectionModal/store/UploadStepStore'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { CollectionStore } from 'store/collection'
import { CollectionDataStore } from 'store/collection/CollectionDataStore'
import { home } from 'store/home'
import { me } from 'store/me'
import { CreateCollectionRequest } from 'type/Collection'
import { UploadItemRequest, UploadProductsRequest } from 'type/Item'
import { last } from 'util/array'
import { notNil } from 'util/null'

export type Step = 'catalog' | 'upload' | 'no_item_id' | 'existing' | 'no_url' | 'bad_url' | 'duplicates' | 'final'

export class UploadCollectionModalStore {
  readonly catalogStep = new CatalogStepStore()
  readonly uploadStep = new UploadStepStore()
  readonly data = mx.ref<UploadDataStore>()
  private _step = mx.box<Step>('catalog')

  constructor(collection?: CollectionStore) {
    if (collection) {
      this.catalogStep.create = false
      this.catalogStep.collection = collection
      this._step.it = 'upload'
    }
  }

  get step(): Step {
    return this._step.it
  }

  readonly onNext = async (): Promise<void> => {
    if (this.step === 'catalog') {
      await this.catalogStep.next()
    }

    this._step.it = this.getNextStep()
  }

  readonly onBack = (): void => {
    this._step.it = this.getBackStep()
  }

  readonly onUpload = async (): Promise<void> => {
    modal.uploadCollectionModal.close()
    const { create } = this.catalogStep
    const collection = create ? await this.createCollection() : this.getCollection()
    await this.uploadItems(collection)
  }

  private getCollection(): CollectionStore {
    const { collection } = this.catalogStep
    if (!collection) throw new Error('no collection')
    return collection
  }

  private processCsv() {
    this.data.close()

    const text = this.uploadStep.text.value
    const existingItemIds = this.getItemsIds()
    const parser = new UploadParser(text, existingItemIds)

    const data = parser.buildData()
    this.data.it = data
  }

  private getItemsIds(): string[] {
    const items = this.catalogStep.collection?.items.it.value ?? []
    return items.map(item => item.store.it.data.json.external_item_id).filter(notNil)
  }

  private getNextStep(): Step {
    switch (this._step.it) {
      case 'catalog':
        return this.catalogStep.done ? 'upload' : 'catalog'
      case 'upload':
        if (!this.uploadStep.form.check()) return 'upload'
        this.processCsv()
        return this.getNextErrorStep() ?? this.getFinalStep()
      case 'no_item_id':
      case 'existing':
      case 'no_url':
      case 'bad_url':
      case 'duplicates':
        return this.getNextErrorStep() ?? this.getFinalStep()
      case 'final':
        return 'final'
    }
  }

  private getFinalStep(): Step {
    const data = this.data.optional
    const result = data && data.result.length
    if (!result) {
      notice.error(i18n('catalog.NothingToUpload'))
      return 'upload'
    }
    return 'final'
  }

  private getBackStep(): Step {
    switch (this._step.it) {
      case 'catalog':
        return 'catalog'
      case 'upload':
        return 'catalog'
      case 'no_item_id':
      case 'existing':
      case 'no_url':
      case 'bad_url':
      case 'duplicates':
      case 'final':
        return this.getBackErrorStep() ?? 'upload'
    }
  }

  private getNextErrorStep(): Step | undefined {
    const steps = this.getErrorSteps()
    const index = steps.indexOf(this._step.it)
    return index < 0 ? steps[0] : steps[index + 1]
  }

  private getBackErrorStep(): Step | undefined {
    const steps = this.getErrorSteps()
    const index = steps.indexOf(this._step.it)
    return index < 0 ? last(steps) : steps[index - 1]
  }

  private getErrorSteps(): Step[] {
    const data = this.data.optional
    if (!data) return []

    const steps: Step[] = []
    if (data.no_item_id.items.length) steps.push('no_item_id')
    if (data.existing.items.length) steps.push('existing')
    if (data.no_url.items.length) steps.push('no_url')
    if (data.bad_url.items.length) steps.push('bad_url')
    if (data.duplicates.items.length) steps.push('duplicates')
    return steps
  }

  private async createCollection() {
    const request = this.buildCreate()
    const response = await api.createCollection(request)
    const data = new CollectionDataStore(response)
    const store = new CollectionStore(data)
    home.collections.it.collections.addFirst(store)
    notice.success(i18n('collection.CollectionCreated'))
    return store
  }

  private buildCreate(): CreateCollectionRequest {
    const user_id = me.user.user_id
    const company_id = home.company.optional?.company.company_id
    const name = this.catalogStep.catalog_name.value
    const desc = this.catalogStep.description.value
    const site = this.catalogStep.website.value
    return {
      user_id,
      company_id,
      collection_name: name,
      collection_title: name,
      description: desc,
      links: site,
    }
  }

  private async uploadItems(collection: CollectionStore) {
    const request = this.buildUpload(collection.collectionId)
    await api.uploadProducts(request)
    await collection.reloadItems()
    notice.success(i18n('catalog.UploadedCountItems', { count: request.items.length }))
  }

  private buildUpload(collection_id: string): UploadProductsRequest {
    const user_id = me.user.user_id
    return {
      user_id,
      collection_id,
      items: this.data.it.result.map(this.buildItem),
    }
  }

  private buildItem(item: UploadItem): UploadItemRequest {
    const { item_id, url, item_name } = item
    return {
      external_item_id: item_id,
      product_name: item_name || item_id,
      links: url,
    }
  }
}
