import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'

export const ItemIdInput = observer(() => {
  const value = managerSearchStore.item_id

  const onChange = useLatestCallback((value: string) => {
    managerSearchStore.item_id = value
  })

  return <Field label={i18n('label.ItemId')}>
    <Input value={value} onChange={onChange} placeholder={i18n('placeholder.ItemId')} />
  </Field>
})
