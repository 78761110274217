import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { LimitDropdown } from 'page/internal/search/LimitDropdown'
import { managerSearchStore } from 'store/manager/search'
import css from './Pagination.module.scss'

export const Pagination = observer(() => {
  const store = managerSearchStore
  const { busy, result } = store
  const { page = 1, pages = 1 } = result ?? {}
  const start = page <= 1
  const end = page >= pages
  const none = start && end

  const onLeft = useLatestCallback(async () => {
    await store.shiftPage(-1)
    document.documentElement.scrollTop = 0
  })

  const onRight = useLatestCallback(async () => {
    await store.shiftPage(+1)
    document.documentElement.scrollTop = 0
  })

  return <Space center className={css.root}>
    <Space className={css.limit}>
      <LimitDropdown />
    </Space>
    {!none && <Space>
      <IconButton disabled={start} action={busy ? false : onLeft}>
        <Icon name="keyboard_arrow_left" size={16} />
      </IconButton>
      <Space gap={4} center>
        <Typo size={14}>{page}</Typo>
        <Typo size={14} tertiary>/</Typo>
        <Typo size={14} tertiary>{pages}</Typo>
      </Space>
      <IconButton disabled={end} action={busy ? false : onRight}>
        <Icon name="keyboard_arrow_right" size={16} />
      </IconButton>
    </Space>}
  </Space>
})
