import { MutableRefObject, Ref } from 'react'
import { md5 } from 'util/md5'

type Value = string | number | boolean | null | undefined

export function key(...args: Value[]): string {
  const text = args.map(arg => '' + arg).join(' ')
  return md5(text).substring(0, 8)
}

export function setRef<T>(ref: Ref<T> | null | undefined, value: T | null): void {
  if (!ref) {
    return
  } else if (typeof ref === 'function') {
    ref(value)
  } else {
    const mutable = ref as MutableRefObject<T | null>
    mutable.current = value
  }
}
