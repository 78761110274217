// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DemoCatalogLogo-logo-ae0f{overflow:hidden}.DemoCatalogLogo-logo-ae0f svg{width:auto;height:24px}.DemoCatalogLogo-logo-ae0f img{width:auto;height:40px;max-width:200px}`, "",{"version":3,"sources":["webpack://./src/page/demo-catalog/DemoCatalogLogo/DemoCatalogLogo.module.scss"],"names":[],"mappings":"AAAA,2BACE,eAAA,CAEA,+BACE,UAAA,CACA,WAAA,CAGF,+BACE,UAAA,CACA,WAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `DemoCatalogLogo-logo-ae0f`
};
module.exports = ___CSS_LOADER_EXPORT___;
