import { makeAutoObservable, runInAction } from 'mobx'
import { CompanyConfigStore } from 'saas/store/CompanyConfigStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { MemberStore } from 'saas/store/MemberStore'
import { api } from 'store/api'
import { SetStore } from 'store/base/SetStore'
import { SaasCompany } from 'type/Company'
import { onceAsync } from 'util/async'
import { by } from 'util/sort'

export class CompanySaasStore {
  ready = false
  json: SaasCompany
  readonly keys = new SetStore<ApiKeyStore>()
  readonly members = new SetStore<MemberStore>()
  config!: CompanyConfigStore

  constructor(json: SaasCompany) {
    this.json = json
    makeAutoObservable(this)
  }

  get company_id() {
    return this.json.company_id
  }

  get title() {
    return this.json.company_name || this.json.company_id
  }

  readonly reload = async () => {
    const { company_id } = this
    const _keys = api.getApiKeys(company_id)
    const _members = api.getMembers(company_id)
    const _config = api.getCompanyConfig(`${company_id}/company`)
    const keysRaw = await _keys
    const membersRaw = await _members
    const config = await _config
    const keys = keysRaw.filter(m => !m.is_deleted).sort(by(m => m.created_at))
    const members = membersRaw.filter(m => !m.is_deleted /* && !!m.is_external */)
      .sort(by(m => m.created_at, true))
    runInAction(() => {
      this.keys.value = keys.map(ApiKeyStore.fromJson)
      this.members.value = members.map(MemberStore.fromJson)
      this.config = new CompanyConfigStore(company_id, config)
      this.ready = true
    })
  }

  readonly load = onceAsync(this.reload)

  update(json: SaasCompany) {
    this.json = json
  }
}
