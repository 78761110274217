import { Contexts } from 'app/Contexts'
import { lang } from 'app/controller/language'
import { Notifications } from 'app/Notifications'
import { configureAntd } from 'app/Theme'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { Modals } from 'modal/Modals'
import { createRoot } from 'react-dom/client'
import { Routing } from 'routing/Routing'

export const App = observer(() => {
  if (!lang.ready) {
    return <Contexts>
      <LoadingPage />
    </Contexts>
  }

  return <Contexts>
    <Routing />
    <Modals />
    <Notifications />
  </Contexts>
})

export function renderApp() {
  const container = document.getElementById('root')!
  configureAntd(container)
  const root = createRoot(container)
  root.render(<App />)
}
