import { initI18next } from 'i18n/config/config'
import i18next from 'i18next'
import { makeAutoObservable, runInAction } from 'mobx'

class LanguageController {
  private _ready = false
  private _init?: Promise<unknown>

  constructor() {
    makeAutoObservable(this)
  }

  get ready(): boolean {
    return this._ready
  }

  async change(lang: string) {
    if (!['en', 'ru'].includes(lang)) {
      console.error('unknown lang', lang)
      return
    }
    await this._init
    if (i18next.resolvedLanguage === lang) return
    this._init = i18next.changeLanguage(lang)
  }

  async start() {
    this._init = initI18next()
    await this._init
    runInAction(() => this._ready = true)
  }
}

export const lang = new LanguageController()
