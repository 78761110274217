import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Back } from 'feature/back/Back'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DataSection } from 'saas/page/company/manage/DataSection'
import { KeySection } from 'saas/page/company/manage/KeySection'
import { MemberSection } from 'saas/page/company/manage/MemberSection'
import { CompanyDeleteModal } from 'saas/page/company/manage/modal/CompanyDeleteModal'
import { EditMemberModal } from 'saas/page/company/manage/modal/EditMemberModal/EditMemberModal'
import { KeyDeleteModal } from 'saas/page/company/manage/modal/KeyDeleteModal'
import { MemberDeleteModal } from 'saas/page/company/manage/modal/MemberDeleteModal'
import { WebhookSection } from 'saas/page/company/manage/WebhookSection'
import { KeyEditModal } from 'saas/page/company/modal/KeyEditModal/KeyEditModal'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { keyModal } from 'saas/store/key/ApiKeyModalStore'
import { memberEdit } from 'saas/store/MemberEditStore'
import { can } from 'store/can'
import { route } from 'store/route'

export const CompanyManageView = observer(() => {
  const { company_id, companyDeleting, keyDeleting, memberDeleting } = companyEdit.it
  const back = companiesSaas.companies.length > 1

  const onBack = useLatestCallback(() => {
    route.push('/companies')
  })

  return <Vertical>
    {back && <Back action={onBack} />}
    <Gap height={16} />
    <Space gap={16} wrap top>
      <Card gap={24} pad={24} width={600} vertical>
        <Typo size={18} bold>
          {i18n('company.ManageCompany')}
        </Typo>
        <DataSection />
      </Card>
      {can.ViewApiKeys(company_id) && <Vertical gap={16}>
        <Card pad={24} width={600}><KeySection /></Card>
        <Card pad={24} width={600}><WebhookSection /></Card>
      </Vertical>}
    </Space>
    <Gap height={16} />
    <MemberSection />

    {companyDeleting && <CompanyDeleteModal />}
    {keyModal.present && <KeyEditModal />}
    {keyDeleting && <KeyDeleteModal />}
    {memberEdit.present && <EditMemberModal />}
    {memberDeleting && <MemberDeleteModal />}
  </Vertical>
})
