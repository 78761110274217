import { makeAutoObservable } from 'mobx'
import { getNextId } from 'util/id'
import { nil } from 'util/null'

export class ImageFile {
  readonly id = getNextId()
  private _url: string
  private _file?: File

  static fromFile(file: File): ImageFile {
    const url = URL.createObjectURL(file)
    return new ImageFile(url, file)
  }

  static fromUrl(url: string): ImageFile {
    return new ImageFile(url)
  }

  static fromUrlNil(url: string | nil): ImageFile | undefined {
    return url ? new ImageFile(url) : undefined
  }

  private constructor(url: string, file?: File) {
    this._url = url
    this._file = file
    makeAutoObservable(this)
  }

  get url(): string {
    return this._url
  }

  get file(): File | undefined {
    return this._file
  }

  update(url: string): void {
    this._file = undefined
    this._url = url
  }
}
