import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { LegendItem } from 'saas/page/statistics/chart/LegendItem'
import { chart } from 'saas/store/stat/ChartStore'

export const Legend = observer(() => {
  const { series } = chart

  return <Space gap={24} center>
    {series.map(serie => <LegendItem key={serie.id} serie={serie} />)}
  </Space>
})
