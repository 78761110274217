import { AppSvg } from 'app/config/icon'
import { getAppTitle, getDomainTitle } from 'app/config/title'
import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ConnectButton } from 'page/login/ConnectButton'
import { LoginStore } from 'page/login/LoginStore'
import { useMemo } from 'react'
import video from 'video/login-background.mp4'
import css from './Login.module.scss'

let store!: LoginStore

export const Login = observer(() => {
  useMemo(() => store = new LoginStore(), [])

  const { error, connections } = store
  const year = new Date().getFullYear()

  if (error) return <ErrorPage />
  if (!connections) return <LoadingPage />

  return <div className={css.container}>
    <div className={css.aside}>
      <div className={css.asideBackground}>
        <video src={video} height="100%" muted autoPlay loop />
      </div>
      <div className={css.inner}>
        <AppSvg className={css.logo} />
        <h1>{getAppTitle()}</h1>
      </div>
    </div>
    <div className={css.content}>
      <div className={css.inner}>
        <h2>{i18n('login.SignIn')}</h2>
        <div className={css.connections}>
          {connections.map((connection) => {
            const { name } = connection
            return <ConnectButton key={name} connection={connection} />
          })}
        </div>
      </div>
      <div className={css.sideFooter}>
        <div className={css.copyright}>&copy; {getDomainTitle()}, {year}</div>
      </div>
    </div>
  </div>
})
