import { ItemStore } from 'store/base/ItemStore'
import { InferenceStore } from 'store/inference/InferenceStore'
import { InferenceStubStore } from 'store/inference/InferenceStubStore'

export class InferenceItem extends ItemStore<InferenceStubStore, InferenceStore> {
  constructor(store?: InferenceStore) {
    super()
    if (store) this.store.it = store
    else this.stub.it = new InferenceStubStore()
  }
}
