import { FormStore } from 'form/store/FormStore'
import { StringStore } from 'store/base/StringStore'

export class UploadStepStore {
  readonly form = new FormStore()
  readonly text = this.form.field(new StringStore(''), { required: true })

  get done(): boolean {
    return !this.form.error
  }
}
