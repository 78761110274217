// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoInput-logo-e14d{overflow:hidden;min-width:48px;min-height:48px;padding:0 4px}.LogoInput-logo-e14d svg{width:auto;height:24px}.LogoInput-logo-e14d img{width:auto;height:40px;max-width:200px}.LogoInput-pale-e14d{opacity:.2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/LogoInput/LogoInput.module.scss"],"names":[],"mappings":"AAAA,qBACE,eAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CAEA,yBACE,UAAA,CACA,WAAA,CAGF,yBACE,UAAA,CACA,WAAA,CACA,eAAA,CAIJ,qBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `LogoInput-logo-e14d`,
	"pale": `LogoInput-pale-e14d`
};
module.exports = ___CSS_LOADER_EXPORT___;
