// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemTag-tag-2cfb{position:relative;display:flex;align-items:center;justify-content:center;margin:0;font-size:14px;background-color:var(--ui-background-color-soft);overflow:hidden;text-overflow:ellipsis;height:22px}.ItemTag-addon-2cfb{position:absolute;top:0;right:0;bottom:0;display:flex;align-items:center;justify-content:center;padding:0 2px;background-color:var(--ui-background-color-soft);border-left:1px solid var(--ui-background-color);transition:opacity 200ms ease-in-out;opacity:0;cursor:pointer}.ItemTag-icon-2cfb{transition:opacity 200ms ease-in-out;opacity:0}.ItemTag-tag-2cfb:hover .ItemTag-addon-2cfb{opacity:1}.ItemTag-tag-2cfb:hover .ItemTag-icon-2cfb{opacity:.5}.ItemTag-tag-2cfb:hover .ItemTag-addon-2cfb:hover .ItemTag-icon-2cfb{opacity:1}`, "",{"version":3,"sources":["webpack://./src/component/Tag/ItemTag.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,cAAA,CACA,gDAAA,CACA,eAAA,CACA,sBAAA,CACA,WAAA,CAGF,oBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,aAAA,CACA,gDAAA,CACA,gDAAA,CACA,oCAAA,CACA,SAAA,CACA,cAAA,CAGF,mBACE,oCAAA,CACA,SAAA,CAGF,4CACE,SAAA,CAGF,2CACE,UAAA,CAGF,qEACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `ItemTag-tag-2cfb`,
	"addon": `ItemTag-addon-2cfb`,
	"icon": `ItemTag-icon-2cfb`
};
module.exports = ___CSS_LOADER_EXPORT___;
