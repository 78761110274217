import IconCloth from 'asset/icon-cloth.svg'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on } from 'feature/widget/TryOnStore'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { BuyModal } from 'page/demo-catalog/BuyModal/BuyModal'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { DemoCatalogFilters } from 'page/demo-catalog/DemoCatalogFilters/DemoCatalogFilters'
import { DemoCatalogItem } from 'page/demo-catalog/DemoCatalogItem/DemoCatalogItem'
import { DemoCatalogLogo } from 'page/demo-catalog/DemoCatalogLogo/DemoCatalogLogo'
import { ShareButton } from 'page/demo-catalog/ShareButton/ShareButton'
import { ShopButton } from 'page/demo-catalog/ShopButton/ShopButton'
import { buyModal } from 'page/demo-catalog/store/BuyModalStore'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import css from './DemoCatalogPage.module.scss'

export const DemoCatalogPage = observer(() => {
  const { company_id, stand, visibleItems, canMore } = demo
  const { icon, title, shareCatalogButton } = demo.product

  useEffectOnce(() => {
    if (title) document.title = title
    if (icon) {
      const [place, ...links] = Array.from(document.querySelectorAll('link[rel="icon"]'))
      links.forEach(link => link.remove())
      const link = document.createElement('link')
      link.rel = 'icon'
      link.href = icon
      if (place) {
        place.replaceWith(link)
      } else {
        document.head.prepend(link)
      }
    }
  })

  const onOutfits = useLatestCallback(async () => {
    if (company_id) await try_on.open(company_id)
  })

  const onMore = useLatestCallback(async () => {
    demo.more()
  })

  return <Vertical>
    <Space className={css.header} opposite gap={[16, 8]} wrap>
      <Space gap={[0, 24]} wrap>
        <DemoCatalogLogo />
      </Space>
      <Space gap={8} flex="1" opposite>
        {stand ? <div /> : <ShopButton />}
        <Space gap={[0, 8]}>
          <DemoCatalogButton className={css.headerButton} text xl icon={<IconCloth />} action={onOutfits}>
            {i18n('demo.MyOutfits')}
          </DemoCatalogButton>
          {shareCatalogButton && <ShareButton />}
        </Space>
      </Space>
    </Space>
    <div className={css.filters}>
      <DemoCatalogFilters />
    </div>
    {!visibleItems.length && <Space height={400} wide center>
      <Typo size={14} secondary>
        {i18n('demo.NoItems')}
      </Typo>
    </Space>}
    {!!visibleItems.length && <div className={css.list}>
      {visibleItems.map(it => <DemoCatalogItem key={it.item_id} item={it} />)}
    </div>}
    <Gap height={32} />
    {canMore && <Space center pad={[0, 16]}>
      <DemoCatalogButton className={css.more} text xl action={onMore}>
        {i18n('demo.ShowMore')}
      </DemoCatalogButton>
    </Space>}
    {canMore && <Gap height={32} />}
    {buyModal.present && <BuyModal />}
  </Vertical>
})
