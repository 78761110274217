import { LinkButton } from 'component/Button/LinkButton'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { route } from 'store/route'

export const HomeButton = observer(() => {
  const companyId = home.company.optional?.company.company_id
  const path = `/company/${companyId}`
  const active = route.pathname !== '/search'

  return <LinkButton path={path} active={active}>
    <Typo size={16}>{i18n('title.Catalog')}</Typo>
  </LinkButton>
})
