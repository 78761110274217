import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { getGapStyle, getPaddingStyle, getPositionStyle, Padding, Position, styles } from 'util/style'
import css from './Sticker.module.scss'

type Props = {
  children?: ReactNode
  className?: string
  full?: boolean
  gap?: number
  hidden?: boolean
  highlight?: boolean
  none?: boolean
  pad?: Padding
  pin: Position
  vertical?: boolean
}

function isVertical(props: Props) {
  const [top, right, bottom, left] = props.pin
  if (props.vertical != null) return props.vertical
  if (top == null || bottom == null) return false
  return left == null || right == null
}

function buildStyle(props: Props) {
  const { pad } = props
  const position = getPositionStyle(props.pin)
  const gap = getGapStyle(props.gap)
  const padding = getPaddingStyle(pad)
  return styles({ ...position, ...gap, ...padding })
}

export function Sticker(props: Props) {
  const { className, full, children, hidden, highlight, none } = props
  const vertical = isVertical(props)
  const flex = vertical ? css.vertical : css.horizontal
  const classes = clsx(className, css.sticker, flex, hidden && css.hidden,
    none && css.none, highlight && css.highlight, full && css.full)
  const style = buildStyle(props)

  return <div className={classes} style={style}>
    {children}
  </div>
}
