import { development } from 'app/config/constants'
import { mx } from 'store/base/mx'
import { CollectionStore } from 'store/collection'
import { home } from 'store/home'

export class DeleteCollectionModalStore {
  private _text = mx.box('')
  readonly collection: CollectionStore

  constructor(collection: CollectionStore) {
    this.collection = collection
  }

  get name(): string {
    return this.collection.data.name
  }

  get text(): string {
    return this._text.it
  }

  set text(text: string) {
    this._text.it = text
  }

  get canDelete(): boolean {
    if (development && this.text === 'x') return true
    return this.text === this.name
  }

  readonly deleteCollection = async () => {
    await home.deleteCollection(this.collection)
  }
}
