import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { AppearanceCard } from 'saas/page/product/stand/SettingsCard/AppearanceCard'
import { SettingsCard } from 'saas/page/product/stand/SettingsCard/SettingsCard'
import { stand } from 'saas/store/product/ProductSaasController'

export const Main = observer(() => {
  const { tab } = stand.it.edit

  return <Space gap={16} parallel>
    {tab === 'settings' && <SettingsCard />}
    {tab === 'appearance' && <AppearanceCard />}
  </Space>
})
